import axios from "axios";
import { getQueryString } from "utils/utilFn";

import CONFIG from "../../config";
const baseURL = `${CONFIG.BASE_URL}/payment-gateway/api/v1/transactions`;

const refURL = `${CONFIG.BASE_URL}/payment-gateway/api/v1/reference`;

const baseURL2 = `${CONFIG.BASE_URL}/payment-gateway/api/v1/transaction`;

const revenueURL = `${CONFIG.BASE_URL}/payment-gateway/api/v1/revenue`;

const userBaseURL = `${CONFIG.BASE_URL}/auth-service/api/v1/dashboard`;
const baseURL3 = `${CONFIG.BASE_URL}/wayapay-settlement-service/api/v1`;


export const getReportOverview = async (page = 0, rowsPerPage = 10) =>
  await axios
    .get(`${baseURL}/users/corporate-profile?page=${page}&size=${rowsPerPage}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));

export const getTransaction = () => {
  const response = Promise.all([
    axios
      .get(`${baseURL}/report/overview`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err)),

    axios
      .get(`${userBaseURL}/total-registered-users`)
      .then((res) => res.data)
      .catch((err) => console.log(err)),
  ]);
  return response;
};

export const getTransactionStats = () => {
  const response = Promise.all([
    axios
      .get(`${baseURL3}/transactions/settlements/report/stats/byAdmin`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err)),
    axios
      .get(`${revenueURL}/query`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err)),
  ]);
  return response;
};

export const fetchYearMonthStats = ({ startDate, endDate }) =>
  axios
    .get(
      `${baseURL}/report/year-month-stats?startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res.data.data)
    .catch((err) => console.log(err));

export const getTransactionList = ({ page, size }) => {
  const queryString = getQueryString(page, size);
  return axios
    .get(`${baseURL2}/report${queryString}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};
export const getSettlementStats = () =>
  axios
    .get(`${baseURL3}/transactions/settlements/report/stats/byAdmin`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));

export const getSettlementList = async ({ page, size }) => {
  const queryString = getQueryString(page, size);
  try {
    const res = await axios.get(
      `${baseURL3}/transactions/settlements/all/byAdmin?field=${'All'}&pageNo=${page + 1}&pageSize=${size}&value=${'All'}`
    );
    return res.data.data;
  } catch (err) {
    return console.log(err);
  }
};

export const queryByRefId = ({ txRef }) => {
  return axios
    .get(`${refURL}/query/${txRef}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const getSettlementById = ({ settlementRef }) => {
  /*  return axios
    .get(`${baseURL}/settlement/${settlementRef}`) */
  return Promise.resolve({
    refId: settlementRef,
    activity: "1 transactions 1 deductions",
    completedOn: "2022-03-23T23:00:00",
    actualAmount: 100,
    processingFee: 1.5,
    settledAmount: 98.5,
    date: "2022-03-23T23:00:00",
    status: "PENDING",
    bussinessName: "WAYA MERCHANT",
  });
};
