import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { CreateOneTimePaymentLink } from "./CreateOneTimePaymentLink";
import { CreateSubscriptionPaymentLink } from "./CreateSubscriptionLink";

export const UpdatePaymentLink = () => {
  const detail = useLocation().state;

  return (
    <Box>
      {detail.linkType === "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK" ? (
        <CreateOneTimePaymentLink detail={detail} />
      ) : (
        <CreateSubscriptionPaymentLink detail={detail} />
      )}
    </Box>
  );
};
