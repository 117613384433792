import React, { useRef } from "react";
import SearchIcon from "../../../../components/Icons/SearchIcon";
import "./styles.css";

const SearchInput = () => {
  const inputRef = useRef();

  return (
    <label className='referrals__search-container'>
      <div className='referrals__search-icon'>
        <SearchIcon size={17} />
      </div>
      <input
        className='referrals__search-input'
        ref={inputRef}
        placeholder='Search Users by Name, Email or Date'
      />
    </label>
  );
};

export default SearchInput;
