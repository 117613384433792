import { TxTabPanel } from "components/tabs/transaction/TxTabPanel";
import { SettlementDetail } from "./settlementDetail/SettlementDetail";
import { Transaction } from "./Transaction";
import TransactionDetail from "./transactionDetail";

const TransactionRoutes = [
  {
    path: "/transactions/settlement/:refId",
    element: <SettlementDetail />,
  },
  {
    path: "/transactions/:refId",
    element: <TransactionDetail />,
  },

  {
    path: "transactions",
    element: <Transaction />,
    children: [
      {
        path: "_t",
        element: <TxTabPanel />,
      },
      {
        path: "settlements",
        element: <Transaction />,
      },
    ],
  },
];

export default TransactionRoutes;
