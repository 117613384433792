import {
  Box,
  Button,
  ClickAwayListener,
  Stack,
  Tooltip,
  styled,
} from "@mui/material";
import { FilterForm } from "./FilterForm";
import { GenericTab } from "components/tabs/Tab";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { createDialog } from "redux/actions/AlertActions";
import { getDisputeList } from "services/https/disputeService";
import { CSVLink } from "react-csv";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const HeadSearch = ({ setOpenDialog, openDialog, activeTab }) => {
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_DISPUTES')

  const StyledHeadSearch = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  }));
  useEffect(() => {
    getDisputeList({ exports: true }).then((data) => {
      const { totalElements } = data || {};
      if (totalElements) {
        getDisputeList({ exports: true, size: totalElements }).then((data) => {
          const { content } = data || {};
          setData(content);
        });
      }
    });
  }, []);

  const dispatch = useDispatch();
  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <StyledHeadSearch>
      <Stack direction={"row"}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<FilterForm close={handleTooltipClose} />}
              arrow
            >
              <Button
                onClick={handleTooltipOpen}
                startIcon={<FilterAltIcon />}
                sx={{ mr: 4 }}
              >
                {"Filter"}
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button variant="contained">
          <CSVLink data={data} filename={"dispute.csv"}>
            {"Export"}
          </CSVLink>
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={(e) => handleBtnClick(e, "createMerchantDispute")}
          sx={{display: showManaging ? 'flex' : 'none' }}
        >
          {"Create New Dispute"}
        </Button>
      </Stack>
    </StyledHeadSearch>
  );
};

const Dispute = () => {
  const { setOpenDialog, openDialog } = useOutletContext();

  const [name, setName] = useState("dispute");
  const [activeTab, setActiveTab] = useState(name);
  return (
    <GenericTab
      name={name}
      setName={setName}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {<HeadSearch setOpenDialog={setOpenDialog} openDialog={openDialog} />}
    </GenericTab>
  );
};

export default Dispute;
