import { StyledTableCell } from "./UserTableMenu";
import { Box, Stack, Button, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

const BusinessKYCActionMenu = ({ setOpenDialog, row }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/kyc/merchant/${row.id}`);
  };
  return (
    <StyledTableCell
      align="right"
      sx={{ paddingRight: "1em", wordBreak: "break-word" }}
    >
      <Box>
        <Button
          variant="outlined"
          startIcon={<SettingsIcon />}
          onClick={handleClick}
          sx={{ width: "fit-content", wordBreak: "break-word" }}
        >
          {"Manage KYC"}
        </Button>
      </Box>
    </StyledTableCell>
  );
};

export default BusinessKYCActionMenu;
