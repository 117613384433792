import { styled } from "@mui/material";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUniqueCell } from "services/https/fetchers";
import { customSearch } from "utils/customSearch";
import { SearchAction } from "redux/actions/SearchAction";

const SelectRoot = styled("div")(({ theme }) => ({
  select: {
    width: "154px",
    height: "35px",
    background: theme.palette.background.default,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    marginBlock: "29px 35px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
  },
}));

export const Filter = ({
  name,
  uniqueCells,
  allData,
  setChainedFilters,
  chainedFilters,
  searchKey,
}) => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value, name } = e.target;
    let { keys } = chainedFilters;
    keys.push(searchKey);
    const keyCopy = [...new Set(keys)];
    setChainedFilters((filter) => ({
      ...filter,
      keys: keyCopy,
      [searchKey]: value,
    }));
  };

  const unique = getUniqueCell({ cellName: name, allData, uniqueCells });

  useEffect(() => {
    setData(unique);
  }, [unique.length]);

  useEffect(() => {
    const filterCopy = Object.assign({}, chainedFilters);
    delete filterCopy.keys;
    const q = Object.entries(filterCopy).map(([key, value]) => ({
      [key]: value,
    }));
    customSearch(allData, { $and: q }, chainedFilters.keys ?? []).then(
      (data) => {
        const searchResult = data.map(({ item }) => item);
        dispatch(SearchAction({ searchResult, query: chainedFilters }));
      }
    );
  }, [chainedFilters]);

  return (
    <SelectRoot>
      <select name={name} onChange={handleChange}>
        <option>{name}</option>
        {!_.isEmpty(data) &&
          data?.map(
            (i, idx) =>
              i && (
                <option
                  key={`${i}-${idx}`}
                  value={i === "ACTIVE" ? true : i === "INACTIVE" ? false : i}
                >
                  {i}
                </option>
              )
          )}
      </select>
    </SelectRoot>
  );
};
