import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputBase,
  Box,
  styled,
  Button,
} from "@mui/material";
import { H5 } from "components/typography/Typography";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { createSubscription } from "services/https/paymentLink";
import { FormStep1 } from "./FormStep1";
import { FormStep2 } from "./FormStep2";
import { FormStep3 } from "./FormStep3";
import Stepper from "./Stepper";

export const CreateSubscriptionPlan = ({ openDialog, setOpenDialog }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [disabled, setDisable] = useState(true);
  const { userId } = useParams();
  const [values, setValues] = useState({});

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    getMerchantById({ userId }).then((data) => {
      const { merchantID } = data || {};
      if (merchantID) {
        setValues((value) => ({ ...value, merchantId: merchantID }));
      }
    });
  }, [userId]);
  const steps = ["Plan Details", "Link Details", "Review"];

  useEffect(() => {
    if (activeStep === 2) {
      setDisable(false);
    } else {
      setDisable(!(activeStep === values.validStep));
    }
  }, [activeStep, values.validStep]);

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleNext = () => {
    if (activeStep === 2) {
      delete values.validStep;
      createSubscription({ sub: values }).then((data) => {
        console.log(data);
      });

      setOpenDialog(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
      setValues({});
      handleClose();
    }
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth={true}
      sx={{
        "& .MuiDialog-paper": { width: "60vw", maxWidth: "60vw" },
      }}
    >
      <DialogContent>
        <Grid container columns={12}>
          <Grid item xs={4}>
            <H5>{"Create Subscription"}</H5>
            <Typography>
              {"Provide details to create a subscription link"}
            </Typography>
            <Stepper
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              steps={steps}
            />
          </Grid>
          <Grid item xs={8}>
            <H5 sx={{ p: 2 }}>{"Plan Details"}</H5>
            <Box sx={{ backgroundColor: "#f2f2f2", p: 5 }}>
              {activeStep === 0 && (
                <FormStep1 formValues={values} setFormValues={setValues} />
              )}
              {activeStep === 1 && (
                <FormStep2 formValues={values} setFormValues={setValues} />
              )}
              {activeStep === 2 && (
                <FormStep3 formValues={values} setFormValues={setValues} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {activeStep === steps.length ? (
              <Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "end",
                  }}
                >
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant={"contained"}
                  >
                    {"Previous"}
                  </Button>

                  <Button
                    onClick={handleNext}
                    disabled={disabled}
                    variant={"contained"}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
