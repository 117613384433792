import {
  Box,
  CircularProgress,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { useEffect, useState, useContext} from "react";
import { getKycStats } from "services/https/kycService";
import Table from "components/table/Table";
import { dataKeysAndRepr, TotalSummary } from "./data";
import useMenu from "hooks/useMenu";
import { KYC_TABLE_MENU } from "utils/constant";
import _ from "lodash";
import { getKycList } from "services/https/updatedKYCService";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

export const BusinessKYCList = () => {
  const [kycStat, setKycStat] = useState();
  const [kycList, setKycList] = useState([]);
  const [allData, setData] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [exportedData, setExportedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { setTableMenu } = useMenu();
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_KYC')

  const {
    alerts: { alertType, msg },
    search: { searchResult },
  } = useSelector((state) => {
    return state;
  });
  const destructureNeed = (kycList) => {
    const data = kycList?.map((kycObj) => {
      const {
        businessName,
        businessEmail,
        businessPhoneNumber,
        businessType,
        approvalStatus,
        statusLastModifiedOn,
        merchantId,
      } = kycObj || {};
      return {
        businessName,
        businessEmail,
        businessPhoneNumber,
        businessType:
          businessType === "REGISTERED_BUSINESS" ? "REGISTERED BUSINESS" : "",
        approvalStatus: approvalStatus === "DRAFT" ? "PENDING" : approvalStatus,
        statusLastModifiedOn: Boolean(statusLastModifiedOn)
          ? format(new Date(statusLastModifiedOn), "MMM do yyyy hh:m:s a")
          : "Not Verified",
        id: merchantId,
      };
    });
    return data;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setKycList(
      allData.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage)
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setTableMenu(KYC_TABLE_MENU);
    if (_.isEmpty(kycStat)) {
      getKycStats().then((data) => {
        setKycStat(data);
      });
    }
  }, [kycStat]);
  useEffect(() => {
    if (searchResult) {
      if (!_.isEmpty(searchResult)) {
        const search =
          searchResult.length > rowsPerPage
            ? searchResult.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchResult;
        setData({
          count: searchResult.length,
          allRows: destructureNeed(search),
        });
      } else {
        setData({
          count: 0,
          allRows: [],
        });
      }
    } else {
      getKycList({ page, size: rowsPerPage }).then((data) => {
        const res = destructureNeed(data);
        setData({ allRows: res, count: exportedData?.length });
      });
    }
  }, [page, rowsPerPage, searchResult, msg]);

  return (
    <Box>
      <Stack direction={"row"} spacing={2} sx={{ mb: 2 }}>
        {TotalSummary.map(({ title, objKeyRepr }, idx) => (
          <SimpleCard
            key={`${objKeyRepr}-${idx}`}
            title={title}
            width={"fit-content"}
            height={"120px"}
          >
            {
              <Typography variant={"h6"}>
                {(kycStat && kycStat[objKeyRepr]) ?? (
                  <CircularProgress size={12} />
                )}
              </Typography>
            }
          </SimpleCard>
        ))}
      </Stack>

      <Table
        dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'action')}
        dataItemRows={allData?.allRows}
      />
      {
        <TablePagination
          component="div"
          count={allData?.length ?? 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Box>
  );
};
