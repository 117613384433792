import axios from "axios";
import CONFIG from "../../config";
const baseURL = `${CONFIG.BASE_URL}/identity-manager-service/api/v1/admin/webpos`;
const url = `${CONFIG.BASE_URL}/identity-manager-service/api/v1/webpos`;

export const createNewPaymentLink = async ({ newPaymentLink }) =>
  await axios
    .post(`${baseURL}/payment-link`, newPaymentLink)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const getSubscriptionPaymentLinkDetail = async ({
  paymentLinkId,
  merchantId,
}) =>
  await axios
    .get(
      `${url}/customer-subscription/payment-link/${paymentLinkId}?merchantId=${merchantId}`
    )
    .then((res) => {
      console.log(res.data);
      return res.data.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

export const updatePaymentLink = async ({ newPaymentLink }) =>
  await axios
    .patch(`${baseURL}/payment-link`, newPaymentLink)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const deletePaymentLink = async ({ merchantId, paymentLinkId }) =>
  await axios
    .delete(`${baseURL}/payment-link/${merchantId}/${paymentLinkId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
export const createPlan = async ({ plan }) =>
  await axios
    .post(`${baseURL}/plan`, plan)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const createSubscription = async ({ sub }) =>
  await axios
    .post(`${baseURL}/customer-subscription`, sub)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
export const getPaymentLink = ({ merchantId, size, page }) =>
  axios
    .get(
      `${baseURL}/payment-link/filter-search?merchantId=${merchantId}&order=DESC&page=${page}&size=${size}`
    )
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        throw new Error(err);
      }
    });

export const getPaymentLinkDetail = ({ paymentLinkId, merchantId }) =>
  axios
    .get(`${baseURL}/payment-link/fetch/${merchantId}/${paymentLinkId}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response?.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
      }
    });
export const getSubscriptionDetail = ({ customerSubscriptionId, merchantId }) =>
  axios
    .get(
      `${baseURL}/customer-subscription/fetch/${merchantId}/${customerSubscriptionId}`
    )
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

export const getSubscriptionList = ({ merchantId, size, page }) =>
  axios
    .get(
      `${baseURL}/customer-subscription/filter-search?merchantId=${merchantId}&order=DESC&page=${page}&size=${size}`
    )
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

export const getPlanList = (q) => {
  const params = new URLSearchParams(q).toString();
  return axios
    .get(`${baseURL}/plan/filter-search?${params}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });
};

export const getPlanById = async ({ merchantId, planId }) => {
  try {
    const res = await axios.get(
      `${baseURL}/plan/fetch/${merchantId}/${planId}`
    );
    return res.data.data;
  } catch (err) {
    if (err.response.status === 400) {
      return err.response.data;
    } else {
      console.log(err);
    }
  }
};

export const getCustomerDetail = ({ customerId, merchantId }) =>
  axios
    .get(`${baseURL}/customer/${merchantId}/${customerId}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });

export const getCustomerList = (q) => {
  const params = new URLSearchParams(q).toString();
  return axios
    .get(`${baseURL}/customer/filter-search?${params}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        console.log(err);
        // Handle else
      }
    });
};

export const toggleLinkStatus = (data) => {
  return axios
    .patch(`${baseURL}/payment-link`, data)
    .then((res) => {
      console.log(res.status, "nmy");
      return res.data.data;
    })
    .catch((err) => {
      throw new Error(err.response.data.details[0]);
    });
};

export const createNewCustomer = async ({ customer }) =>
  await axios
    .post(`${baseURL}/customer`, customer)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const getSubsData = ({ customerId, merchantId, paymentLinkId }) => {
  const response = Promise.all([
    getCustomerDetail({ customerId, merchantId }),
    getPaymentLinkDetail({ paymentLinkId, merchantId }),
  ]);
  return response;
};
