import { Alert, Box, Snackbar } from "@mui/material";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export const PaymentLinkTabPanel = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const {
    alerts: { alertType, msg, dialogType },
  } = useSelector((state) => {
    return state;
  });

  return (
    <Box>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Outlet context={{ setOpenDialog, openDialog, dispatch }} />
    </Box>
  );
};
