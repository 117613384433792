import React from "react";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { useEffect, useState } from "react";
import _ from "lodash";
import { getSubscriptionPaymentLinkDetail } from "services/https/paymentLink";
import { useNavigate, useOutletContext } from "react-router-dom";
import Table from "components/table/Table";
import { CONFIRMATION } from "utils/constant";
import { createDialog, notifyFailure } from "redux/actions/AlertActions";

export const Header = ({ title }) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "block",
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>
    </Stack>
  );
};

export const PaperListItem = ({
  title = "",
  children,
  fetchData,
  data = [],
  styles,
}) => {
  return (
    <Paper sx={{ mb: 6, paddingBlockEnd: 2, ...styles }}>
      <Header title={title} />
      <List>
        {data.map(({ label, objKey }) => (
          <ListItem sx={{ pt: 2 }}>
            <ListItemText>{label}</ListItemText>
            <ListItemText sx={{ textAlign: "end" }}>
              {fetchData && (fetchData[objKey] ?? "Not available")}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {children}
    </Paper>
  );
};

export const paymentLinkData = {
  customerDetail: [
    { label: "Customer Name", objKey: "customerName" },
    { label: "Customer Email", objKey: "email" },
    { label: "Customer Card", objKey: "customerCard" },
  ],
  subscriptionDetail: [
    { label: "Payment Link Name", objKey: "paymentLinkName" },
    { label: "Status", objKey: "" },
    { label: "Subscription Code", objKey: "" },
    { label: "Amount", objKey: "totalRevenueGenerated" },
    { label: "Interval", objKey: "" },
    { label: "Payments", objKey: "payments" },
    { label: "Subscription Date", objKey: "" },
    { label: "Next Charge Date", objKey: "" },
  ],
};

const dataKeysAndRepr = [
  { objKey: "paymentLinkName", repr: "Payment Link Name" },
  { objKey: "name", repr: "Subscriber" },
  { objKey: "amount", repr: "Amount" },
];

const SummaryTimeline = ({ totalRevGen, noOfSub }) => {
  return (
    <Timeline
      sx={{
        p: 4,
        "& .MuiTimelineItem-root:before": { flex: "none" },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Box>
            <Typography>{"No. of Subscriptions"}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{noOfSub ?? 0}</Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>
          <Box>
            <Typography>{"Total Subscriptions Revenue"}</Typography>

            <Typography sx={{ fontWeight: "bold" }}>{`NGN ${
              totalRevGen ?? 0
            }`}</Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export const RecurrentPaymentLinkDetail = ({ details }) => {
  const navigate = useNavigate();
  const { paymentLinkId, merchantId } = details;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState();
  const [selected, setSelected] = useState({ id: null, selectedData: {} });
  const [page, setPage] = useState(0);
  const { dispatch, setOpenDialog, openDialog } = useOutletContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const destructureNeed = (objArr) => {
    const data = objArr.map(
      ({
        customerSubscriptionId,
        paymentLinkName,
        firstName,
        lastName,
        totalRevenueGenerated,
      }) => {
        return {
          paymentLinkName: paymentLinkName ?? "Not available",
          name: `${firstName} ${lastName}`,
          amount: totalRevenueGenerated,
          id: customerSubscriptionId,
        };
      }
    );
    return data;
  };
  const handleRowClick = (id) => {
    const foundObj = data.find(
      ({ customerSubscriptionId }) => id === customerSubscriptionId
    );
    const {
      firstName,
      lastName,
      email,
      numberOfTimesCharged,
      totalChargeCount,
    } = foundObj;
    const selectedData = {
      customerName: `${firstName} ${lastName}`,
      email,
      payments: `${numberOfTimesCharged} of ${totalChargeCount}`,
    };
    setSelected((sel) => ({ ...sel, id, selectedData }));
  };
  const handleClick = () => {
    setOpenDialog(true);
    const payload = {
      title: "Cancel Subscription",
      subTitle: "Are you sure you want to cancel this subscription",
      handleOk: () => {},
    };
    dispatch(createDialog({ type: CONFIRMATION, payload }));
  };
  useEffect(() => {
    if (_.isEmpty(data) && paymentLinkId && merchantId) {
      getSubscriptionPaymentLinkDetail({
        paymentLinkId,
        merchantId,
      })
        .then((data) => {
          setTableData(destructureNeed(data)?.slice(0, rowsPerPage));
          setData(data);
        })
        .catch((err) => {
          dispatch(notifyFailure({ msg: err.message }));
          navigate(-1);
        });
    } else {
      const idx = page * rowsPerPage;
      setTableData(destructureNeed(data)?.slice(idx, idx + rowsPerPage));
    }
  }, [paymentLinkId, merchantId, page, tableData?.length, data?.length]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6} sx={{ mt: 2 }}>
          <Table
            dataKeysAndRepr={dataKeysAndRepr}
            dataItemRows={tableData}
            pageData={data}
            handleRowClick={handleRowClick}
          />
          <TablePagination
            component="div"
            count={data?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
        <Grid item xs={6}>
          {selected?.id ? (
            <Box>
              <PaperListItem
                title={"Customer Details"}
                data={paymentLinkData.customerDetail}
                fetchData={selected?.selectedData}
                styles={{ backgroundColor: "rgba(196, 196, 196, 0.2);" }}
              />
              <PaperListItem
                title={"Subscription Detail"}
                styles={{ backgroundColor: "rgba(196, 196, 196, 0.2);" }}
                data={paymentLinkData.subscriptionDetail}
                fetchData={selected?.selectedData}
              />
              <Button variant={"contained"} disabled onClick={handleClick}>
                {"Cancel Subscription"}
              </Button>
            </Box>
          ) : (
            <Paper sx={{ background: " rgba(196, 196, 196, 0.15)" }}>
              <SummaryTimeline
                noOfSub={data?.length}
                totalRevGen={data?.reduce(
                  (sum, dataObj) => sum + dataObj?.totalRevenueGenerated,
                  0
                )}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};
