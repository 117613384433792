import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Icon,
  useTheme,
  CircularProgress,
  InputBase,
  InputLabel,
  FormHelperText,
  useFormControl,
  FormControl,
} from "@mui/material";
import { useSelector } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import { Span } from "components/typography/Typography";
import { StyledBox } from "../shared/sharedStyles";
import { useMemo, useState } from "react";
import _ from "lodash";

function MyFormHelperText({ reason }) {
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (!focused && _.isEmpty(reason)) {
      return "Provide a reason";
    }
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

export const KYC_ConfirmationDialog = ({ openDialog, setOpenDialog, user }) => {
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  const theme = useTheme();
  const { error, success } = theme.palette.primary;
  const { title, subTitle, handleOk, loading, type } = payload || {};
  const [reason, setReason] = useState("");
  const handleInputChange = (e) => {
    const { value } = e.target;
    setReason(value);
  };
  const handleCancel = () => {
    setOpenDialog(false);
    setReason("");
  };
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogTitle>
          <Box
            sx={{
              color: `${type === "accept" ? success : error}`,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Icon sx={{ mr: 1 }}>
              <HighlightOff />
            </Icon>
            {title}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: "center" }}>{subTitle}</Typography>
          {type === "reject" && (
            <StyledBox>
              <InputLabel>{"State Reason"}</InputLabel>
              <FormControl fullWidth>
                <InputBase
                  multiline={true}
                  rows={2}
                  fullWidth
                  required
                  onChange={handleInputChange}
                  value={reason}
                />
                <MyFormHelperText reason={reason} />
              </FormControl>
            </StyledBox>
          )}
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f2f2f2", justifyContent: "space-evenly" }}
        >
          <Button autoFocus onClick={handleCancel}>
            {"Cancel"}
          </Button>
          <Button
            onClick={(e) => handleOk(e, reason)}
            sx={{ display: "flex", alignItems: "center" }}
            disabled={type === "reject" && _.isEmpty(reason)}
          >
            <Span>{"Yes, Proceed"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
