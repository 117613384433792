import React from "react";
import map from "lodash/map";

const FILTERS = [
  "Admin",
  "Transaction Type",
  "Recipient",
  "Transaction Owner",
  "Transaction Date",
  "Status",
  "Extract All",
];

const Dropdown = ({ show }) => {
  if (!show) return null;
  return (
    <ul className='rename-account-modal-dropdown'>
      {map(FILTERS, (filter, index) => (
        <li className='rename-account-modal-dropitem' key={index}>
          <p>{filter}</p>
          <button
            className={
              "rename-account-modal-checkbox" +
              (index % 2 ? "" : " rename-account-modal-checkbox-active")
            }
          ></button>
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
