import { StyledTableCell } from "./UserTableMenu";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import { TX_ROUTING } from "utils/constant";

const TransactionRouteMenu = ({ setOpenDialog, row }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    setOpenDialog(true);
    dispatch(createDialog({ type: TX_ROUTING, payload: row }));
  };
  return (
    <StyledTableCell
      align="right"
      sx={{ paddingRight: "1em", wordBreak: "break-word" }}
    >
      <Box>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{ width: "fit-content", wordBreak: "break-word" }}
        >
          {"Update Processor"}
        </Button>
      </Box>
    </StyledTableCell>
  );
};

export default TransactionRouteMenu;
