import React from "react";
import { map } from "lodash";

const dropItems = [
  "Transfer To Account",
  "Fund with Linked Cards",
  "Fund with Bank Accounts",
  "Transfer with another Waya Official Account",
];

const FundingMethodDropdown = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <ul className='rename-account-modal-dropdown'>
      {map(dropItems, (dropItem, index) => (
        <li className='rename-account-modal-dropitem' key={index}>
          <button>{dropItem}</button>
        </li>
      ))}
    </ul>
  );
};

export default FundingMethodDropdown;
