import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { H3 } from "components/typography/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        width: "183px",
        height: "64px",
        backgroundColor: "primary.main",
        textAlign: "center",
        color: "#fff",
        cursor: "pointer",

        ...sx,
      }}
      {...other}
    />
  );
}

export const OTPButtonDialog = ({ openDialog, setOpenDialog }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOTPVia = (e, option) => {
    setOpenDialog(false);
    dispatch(createDialog({ type: "otpVia", payload: option }));
    setOpenDialog(true);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
          alignItems: "center",
        }}
      >
        <H3>{"Send OTP"}</H3>
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Item onClick={(e) => handleOTPVia(e, "emailPassword")}>
            {"Send OTP for Password  Via Email"}
          </Item>
          <Item>{"Send OTP for Password  Via SMS"}</Item>
          <Item>{"Send OTP for PIN  Via Email"}</Item>
          <Item>{"Send OTP for PIN  Via SMS"}</Item>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
