import { useTheme } from "@emotion/react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { H6 } from "components/typography/Typography";
import * as _ from "lodash";

export const CustomTableHead = ({
  data = [],
  action = "",
  alignAction = "right",
}) => {
  const theme = useTheme();
  return (
    <TableHead sx={{ background: theme.palette.primary.light }}>
      <TableRow variant={"head"} sx={{ fontWeight: "bold" }}>
        <TableCell sx={{ pl: 1, width: 200 }}>
          <H6>{data[0].toUpperCase()}</H6>
        </TableCell>
        {data?.slice(1, -1)?.map((i, idx) => (
          <TableCell key={`name-${idx}`} sx={{ pl: 2, width: 200 }}>
            <H6>{i.toUpperCase()}</H6>
          </TableCell>
        ))}
        {Boolean(action) ? (
          <TableCell
            align={alignAction}
            sx={{ paddingRight: `${theme.spacing(4)}`, width: 200 }}
          >
            {action.toUpperCase()}
          </TableCell>
        ) : (
          <TableCell
            sx={{ paddingRight: `${theme.spacing(4)}`, width: 200 }}
          >
            <H6>{data[data.length - 1].toUpperCase()}</H6>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
