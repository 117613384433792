import { GenericTab } from "components/tabs/Tab";
import { allHeadsFSE } from "components/shared/filterSearchExport/data";
import { useState } from "react";

export const Transaction = () => {
  const [name, setName] = useState("transactions");
  const [activeTab, setActiveTab] = useState(name);

  return (
    <GenericTab
      name={name}
      setName={setName}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {allHeadsFSE[activeTab]}
    </GenericTab>
  );
};
