import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";

function SingleSelectPlaceholder({
  sx,
  label,
  menuItems = [{ value: "", placeholder: "" }],
  defaultx = true,
}) {
  const [values, setValue] = React.useState(defaultx ? menuItems[0] : {});

  const handleChange = (event) => {
    const { value } = event.target;
    setValue({ value: value });
  };

  const BoxRoot = styled("div")(({ theme }) => ({
    label: {
      marginBottom: theme.spacing(1),
    },
  }));

  return (
    <BoxRoot>
      <Box sx={{ minWidth: 120, ...sx }}>
        <FormControl fullWidth>
          <label>{label}</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={values.value}
            onChange={handleChange}
          >
            {menuItems.map(({ value, placeholder }, idx) => (
              <MenuItem
                id={placeholder.toLowerCase()}
                key={`${value}-${idx}`}
                value={value}
              >
                {placeholder}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </BoxRoot>
  );
}

export default SingleSelectPlaceholder;
