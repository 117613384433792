import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import accept from "assets/images/accept.png";
import reject from "assets/images/reject.png";
import { H4 } from "components/typography/Typography";
export const KYCSuccessful = ({ openDialog, setOpenDialog, user }) => {
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  const { title } = payload || {};
  const displayForReject = {
    titleImg: reject,
    titleTxt: "KYC Rejected",
    subText: "KYC has been successfully rejected",
  };
  const displayForAccept = {
    titleImg: accept,
    titleTxt: "Successful",
    subText: "KYC has been successfully approved",
  };
  const { titleImg, titleTxt, subText } =
    title === "REJECTED"
      ? displayForReject
      : title === "APPROVED"
      ? displayForAccept
      : {};
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogTitle sx={{ margin: "auto" }}>
          <Box>
            <img src={titleImg} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <H4 sx={{ pb: 1, fontWeight: "bold" }}>{titleTxt}</H4>
          <Typography>{subText}</Typography>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f2f2f2", justifyContent: "center" }}
        >
          <Button autoFocus onClick={handleCancel}>
            {"ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
