import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { CustomTableHead } from "./CustomTableHead";
import { CustomTableBody } from "./CustomTableBody";
import _ from "lodash";

export default function CustomTable({
  headers = true,
  dataKeysAndRepr = [{}],
  dataItemRows,
  alignAction,
  handleRowClick,
  pageData,
}) {
  const action = dataKeysAndRepr.find(({ objKey }) => objKey === "action");
  const actionExist = _.isEmpty(action);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        {headers && (
          <CustomTableHead
            data={dataKeysAndRepr.map(({ repr }) => repr)}
            action={actionExist ? "" : action.objKey}
            alignAction={alignAction}
          />
        )}
        <CustomTableBody
          dataItemRows={dataItemRows}
          dataKeys={dataKeysAndRepr.map(({ objKey }) => objKey)}
          action={!actionExist}
          handleRowClick={handleRowClick}
          pageData={pageData}
        />
      </Table>
    </TableContainer>
  );
}
