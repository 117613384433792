import React from "react";
import { useFormik } from "formik";
import CancelIcon from "../../../../../components/Icons/CancelIcon";
import Input from "./Input";
import "./styles.css";

const AssignReferralCodeModal = ({ show, onCloseBtnClick }) => {
  const formik = useFormik({
    initialValues: {
      User: "",
      "Referral Code": "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  if (!show) return null;

  return (
    <div className='assign-referral-code-modal'>
      <div className='assign-referral-code-main-content'>
        <div className='assign-referral-code-title-container'>
          <h3>{"Assign Referral Code"}</h3>

          <button onClick={onCloseBtnClick}>
            <CancelIcon />
          </button>
        </div>

        <div className='assign-referral-code-inputs-container'>
          <Input name='User' formik={formik} />
          <Input name='Referral Code' formik={formik} />
        </div>

        <div className='assign-referral-code-save-btn-container'>
          <button>{"Save Change"}</button>
        </div>
      </div>
    </div>
  );
};

export default AssignReferralCodeModal;
