import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";

import { H3, H6, Span } from "components/typography/Typography";
import { useEffect, useRef, useState, useContext } from "react";
import { formInput } from "./data";
import { createDispute } from "services/https/disputeService";
import { useDispatch } from "react-redux";
import { notifySuccess } from "redux/actions/AlertActions";
import { StyledBox } from "../shared/sharedStyles";
import { queryByRefId } from "services/https/transactionFetchers";
import _, { debounce } from "lodash";


const FormContent = ({ closeAction }) => {
  const [isSubmitting, setSubmitting] = useState();
  const [updatedValues, setUpdatedValues] = useState({});
  const [otherData, setOtherData] = useState({});
  const [errors, setErrors] = useState([]);
  
  const handleClose = (e) => {
    closeAction();
  };
  const dispatch = useDispatch();

  const handleUpdateOthers = (e) => {
    const { name, value } = e.target;
    setOtherData((otherData) => ({ ...otherData, [name]: value }));
  };

  const debouncedChange = useRef(
    debounce(async (name, value) => {
      if (value) {
        queryByRefId({ txRef: value }).then((data) => {
          if (data) {
            const {
              Amount,
              customer: { customerId },
              merchantId,
              TransactionDate,
            } = data;
            const update = {
              transactionAmount: Number.isInteger(Amount)
                ? Amount.toFixed(2)
                : Amount,
              customerId,
              transactionDate: `${TransactionDate.split(" ").join("T")}`,
              merchantId,
              transactionReference: value,
            };
            setUpdatedValues((initials) => ({ ...initials, ...update }));
          }
        });
      }
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, [debouncedChange]);

  const handleTxRefChange = (e) => {
    const { name, value } = e.target;
    debouncedChange(name, value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const allValues = { ...updatedValues, ...otherData };
    const errKeys = [
      "disputeType",
      "transactionReference",
      "reasonForDisputeInDetails",
    ];
    const errors = errKeys.filter(
      (key) => !allValues[key] || allValues.key == ""
    );
    setErrors(errors);
    if (_.isEmpty(errors)) {
      setSubmitting(true);
      createDispute({ dispute: allValues })
        .then((data) => {
          dispatch(notifySuccess({ msg: "Dispute successfully created" }));
        })
        .catch((err) => {
          dispatch(notifySuccess({ msg: "System cannot process request" }));
        })
        .finally(() => {
          setSubmitting(false);
          handleClose();
        });
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <StyledBox>
              <label>{"Transaction Reference *"}</label>
              <InputBase
                name={"transactionReference"}
                fullWidth
                onChange={handleTxRefChange}
                value={updatedValues["transactionReference"]}
              />
              {errors.includes("transactionReference") && (
                <Typography color={"primary.main"}>
                  {"Transaction refrence is required"}
                </Typography>
              )}
            </StyledBox>
            {formInput.map(({ label, name }) => (
              <StyledBox>
                <label>{label}</label>
                <InputBase
                  disabled={true}
                  name={name}
                  fullWidth
                  onChange={handleTxRefChange}
                  value={updatedValues[name]}
                />
              </StyledBox>
            ))}
            <StyledBox>
              <label>{"Dispute Type"}</label>
              <select
                name={"disputeType"}
                className="disputeType"
                onChange={handleUpdateOthers}
                value={updatedValues.disputeType}
              >
                <option value={""}>{"Select dispute type"}</option>
                <option value={"CHARGEBACK"}>{"Charge Back"}</option>
              </select>
              {errors.includes("disputeType") && (
                <Typography color={"primary.main"}>
                  {"Dispute type is required"}
                </Typography>
              )}
            </StyledBox>

            <StyledBox>
              <label>{"Dispute Reason"}</label>
              <textarea
                name={"reasonForDisputeInDetails"}
                value={updatedValues.reasonForDisputeInDetails}
                onChange={handleUpdateOthers}
              />
              {errors.includes("reasonForDisputeInDetails") && (
                <Typography color={"primary.main"}>
                  {"Dispute reason is required"}
                </Typography>
              )}
            </StyledBox>
          </Grid>

          <DialogActions
            sx={{
              justifyContent: "end",
              width: "100%",
              my: 3,
              pt: 0,
              columnGap: 2,
            }}
            
          >
            <Button
              variant={"contained"}
              sx={{ backgroundColor: "#fff", color: "black" }}
              onClick={handleClose}
            >
              {"Cancel"}
            </Button>
            <Button variant={"contained"} type={"submit"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Span>{"Create Dispute"}</Span>
                {isSubmitting ? (
                  <CircularProgress
                    sx={{ color: "#fff", marginLeft: "5px" }}
                    size={20}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </>
  );
};

export const CreateMerchantDispute = ({ openDialog, setOpenDialog }) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        <H3>{"Raise a dispute to merchant"}</H3>
      </DialogTitle>
      <>
        <H6 sx={{ textAlign: "center", m: "auto", pt: 2, width: "250px" }}>
          {
            "Kindly provide the following requirement to complete dispute initiation"
          }
        </H6>
        <DialogContent sx={{ px: "48px" }}>
          <FormContent closeAction={handleClose} />
        </DialogContent>
      </>
    </Dialog>
  );
};
