import axios from "axios";
import { getQueryString } from "utils/utilFn";
import CONFIG from "../../config";
const baseURL = `${CONFIG.BASE_URL}/wayapay-settlement-service/api/v1`;

export const getAllPricing = async ({ page, size }) => {
  let queryString = getQueryString(page, size);
  return await axios
    .get(
      `${baseURL}/merchant-product-pricing/filter-search`
    )
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};
export const getPricingStats = async () => {
  return await axios
    .get(`${baseURL}/merchant-product-pricing/report/stats`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const getOneProduct = async ({ productId }) => {
  return await axios
    .get(`${baseURL}/merchant-product-pricing/fetch/${productId}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const updateProduct = async (product, id, productName) => {
  return await axios
    .put(`${baseURL}/merchant-product-pricing/update/${id}?productName=${productName}`, JSON.stringify(product), {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const getProduct = async (product) => {
  return await axios
    .get(`${baseURL}/product`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const getProductPricing = async (product) => {
  return await axios
    .get(`${baseURL}/product-pricing`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const productPricingUpdate = async (product, productName) => {
  return await axios
    .put(`${baseURL}/merchant-product-pricing/updateAll/${productName}`, JSON.stringify(product), {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};
