import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputBase,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import avatar from "assets/images/defaultPic.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { createRequest, getUserById, updateUser } from "services/https/fetchers";
import { formItems, roles, validationSchema } from "./data";
import { CopyAble } from "components/tabs/transaction/data";
import { ErrorMessage, Formik } from "formik";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";

const BoxRoot = styled(Box)(() => ({}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  label: {
    display: "block",
  },
  ["input,select"]: {
    width: "100%",
    border: "1px solid #e9e9e9",
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.4),
    color: "#000",
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const UpdateProfile = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [editable, setEditable] = useState(false);
  const [initialVal, setInitialVal] = useState({});
  const dispatch = useDispatch();
  const [showUpdate, setShowUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUserById(userId).then((data) => {
      setData(data);
      const {
        email,
        dateOfBirth,
        firstName,
        gender,
        lastName,
        phoneNumber,
        referenceCode,
        userId,
        active,
      } = data;
      setInitialVal({
        email,
        dateOfBirth,
        firstName,
        gender,
        surname: lastName,
        phoneNumber,
        userId,
        referenceCode,
        active,
      });
    });
  }, [userId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  const handleClick = () => {
    setEditable(true);
    setShowUpdate(true);
  };

  return (
    <BoxRoot>
      <Box>
        <Button
          sx={{ color: "black" }}
          startIcon={<ArrowLeftIcon />}
          onClick={() => navigate("/users/manage-users")}
        >
          {"Back"}
        </Button>
      </Box>
      <Typography variant="h5" sx={{ fontWeight: "bold", my: 3 }}>
        {"User Profile"}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <Avatar
            alt="user-profile-pic"
            src={avatar}
            sx={{ width: 72, height: 72 }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant={"h5"}>{`${data?.firstName ?? ""} ${
              data?.lastName ?? ""
            }`}</Typography>
            <Typography variant={"body2"}>
              {roles[data?.roles?.slice(-1).pop()]}
            </Typography>
          </Box>
        </Box>
        {!showUpdate && (
          <Button
            variant={"contained"}
            onClick={handleClick}
            sx={{ height: "40px" }}
          >
            {"Edit user's details"}
          </Button>
        )}
      </Box>
      <Divider sx={{ m: 2 }} />
      {!_.isEmpty(initialVal) ? (
        <Formik
          initialValues={initialVal}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            let { active } = values || "";
            active = active === "true";
            const newValues = { ...values, active };
            setSubmitting(true);
            let d = new Date()
            let payload = {
             customerName: `${values?.firstName} ${values?.surname}`,
             payload: JSON.stringify({...values, userId}),
             requestId: d.getTime(),
             requestType: "updateUserProfile"
            }
//           updateUser({ userId, updateData: newValues })

           createRequest(payload)
              .then((data) => {
                if(data.status){
                dispatch(
                  notifySuccess({ msg: data?.message|| data })
                );
                }else{
                  dispatch(
                  notifyFailure({msg: data})
                  );
                }
              })
              .finally(() => {
                setSubmitting(false);
                navigate("/users/manage-users");
              });
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  mr: 16,
                }}
              >
                {formItems.map(
                  ({ label, objKey, type, copyable, notEditable, options }) => {
                    return (
                      <StyledBox key={objKey}>
                        <label>{label}</label>
                        {type === "switch" ? (
                          <Stack
                            direction={"row"}
                            sx={{
                              border: "1px solid #e9e9e9",
                              mt: 1,
                              alignItems: "center",
                              justifyContent: "space-between",
                              p: 1.4,
                              color: "#000",
                            }}
                          >
                            <Typography variant={"body2"}>
                              {"SMS Alert"}
                            </Typography>
                            <AntSwitch
                              defaultChecked
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Stack>
                        ) : type === "select" ? (
                          <select
                            name={objKey}
                            value={values[objKey]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editable}
                          >
                            {options.map(({ name, value }, idx) => (
                              <option key={`${name}-${idx}`} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <InputBase
                            fullWidth
                            name={objKey}
                            onChange={handleChange}
                            value={values[objKey]}
                            disabled={notEditable ? notEditable : !editable}
                            endAdornment={
                              copyable && data[objKey]
                                ? CopyAble({
                                    data: data[objKey],
                                    showText: false,
                                    sx: { width: "auto" },
                                  })
                                : ""
                            }
                          />
                        )}
                        <Typography color={"primary.main"}>
                          <ErrorMessage name={objKey} />
                        </Typography>
                      </StyledBox>
                    );
                  }
                )}
              </Box>
              <Box sx={{ display: "flex", mt: 4, justifyContent: "end" }}>
                {showUpdate && (
                  <Button
                    variant={"contained"}
                    sx={{ height: "40px" }}
                    type={"submit"}
                  >
                    {"Update User"}
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <CircularProgress />
      )}
    </BoxRoot>
  );
};
