import React from "react";
import Spinner from "../Spinner";
import "./styles.css";

const LoadingStatusModal = ({ show, title, text }) => {
  if (!show) return null;
  return (
    <div className='loading-status-modal'>
      <div className='loading-status-modal__main-content'>
        <div className='loading-status-modal__title-container'>
          <h3 className='loading-status-modal__title'>{title}</h3>
        </div>
        <div className='loading-status-modal__body'>
          <Spinner />
          <p className='loading-status-modal__text'>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingStatusModal;
