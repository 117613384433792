import React from "react";

const AddIcon = ({ size = 10, color = "white" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${10} ${10}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='4.19336' width='1.6129' height='10' rx='0.806452' fill={color} />
      <rect
        y='5.80859'
        width='1.6129'
        height='10'
        rx='0.806452'
        transform='rotate(-90 0 5.80859)'
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
