import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deletePaymentLink,
  getPaymentLinkDetail,
  toggleLinkStatus,
} from "services/https/paymentLink";
import _ from "lodash";
import { H6 } from "components/typography/Typography";
import { CopyAble } from "components/tabs/transaction/data";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { TextToLabel } from "utils/textToLabel";
import { Visibility } from "@mui/icons-material";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import { RecurrentPaymentLinkDetail } from "./RecurrentPaymentLinkDetail";

export const PaymentLinkDetail = () => {
  const { paymentLinkId } = useParams() || {};
  const { merchantId } = useLocation().state || {};
  const [detailObj, setDetailObj] = useState({});
  const navigate = useNavigate();
  const { dispatch } = useOutletContext;
  useEffect(() => {
    if (_.isEmpty(detailObj)) {
      getPaymentLinkDetail({ paymentLinkId, merchantId }).then((data) => {
        setDetailObj(data);
      });
    }
    return () => {};
  }, [paymentLinkId, merchantId]);

  const {
    payableAmount,
    paymentLinkName,
    currency,
    paymentLinkType,
    customerPaymentLink,
    createdAt,
    status,
  } = detailObj || {};

  const handleToggle = () => {
    let resp;
    const data = {
      payableAmount,
      paymentLinkName,
      currency,
      merchantId,
      paymentLinkId,
    };
    if (status === "ACTIVE") {
      resp = toggleLinkStatus({
        ...data,
        status: "INACTIVE",
      });
    } else {
      resp = toggleLinkStatus({
        ...data,
        status: "ACTIVE",
      });
    }
    resp
      .then((data) => {
        dispatch(
          notifySuccess({
            msg: `Link successfully ${
              status === "ACTIVE" ? "deactivate" : "activated"
            }`,
          })
        );
      })
      .catch((error) => {
        dispatch(notifyFailure({ msg: error.message }));
      });
  };

  const handleDelete = () => {
    deletePaymentLink({ merchantId, paymentLinkId })
      .then((data) => {
        const { message } = data;
        dispatch(
          notifySuccess({
            msg: `${message}`,
          })
        );
      })
      .catch((data) => {
        dispatch(
          notifyFailure({
            msg: "Error while deleting paymentlink",
          })
        );
      })
      .finally(() => {
        navigate(-1);
      });
  };
  return (
    <Box>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>
      {_.isEmpty(detailObj) ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {paymentLinkType === "SUBSCRIPTION_PAYMENT_LINK" ? (
            <Box>
              <RecurrentPaymentLinkDetail details={detailObj} />
            </Box>
          ) : (
            <>
              <Box>
                <Typography variant="h4">{`${currency} ${payableAmount}`}</Typography>

                <Typography variant="body1">
                  {"some information about the payment link"}
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  border: "1px solid grey",
                  marginBlock: 2,
                  p: 2,
                  borderRadius: "8px",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    marginBlock: 2,
                  }}
                >
                  <H6>{"Plan Name"}</H6>

                  <H6>{"Plan Type"}</H6>

                  <H6>{"Payment URL"}</H6>

                  <H6>{"Date Created"}</H6>
                </Stack>
                <Divider sx={{ width: "100%" }} variant={"fullWidth"} />
                <Stack
                  flexDirection={"row"}
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    paddingBlockEnd: 2,
                  }}
                >
                  <H6>{paymentLinkName}</H6>

                  <H6>{`${
                    paymentLinkType === "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK"
                      ? "ONE-TIME PAYMENT LINK"
                      : "SUBSCRIPTION PAYMENT LINK"
                  }`}</H6>

                  <H6>
                    <CopyAble str={customerPaymentLink} length={20} />
                  </H6>

                  <H6>
                    {createdAt &&
                      `${format(new Date(createdAt), "MMM dd yyyy hh:mm aaa")}`}
                  </H6>
                </Stack>
                <Stack
                  direction={"row"}
                  sx={{ justifyContent: "space-between", width: "100%" }}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Typography>{"Status :"}</Typography>
                    <span>{TextToLabel(status)}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Chip
                      icon={<EditIcon />}
                      label="Edit"
                      variant="outlined"
                      color="default"
                      onClick={() => navigate("edit", { state: detailObj })}
                    />
                    <Chip
                      icon={
                        status === "ACTIVE" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <Visibility />
                        )
                      }
                      label={status === "ACTIVE" ? "Deactivate" : "Activate"}
                      onClick={handleToggle}
                      variant="filled"
                      color="default"
                    />

                    <Chip
                      icon={<DeleteIcon />}
                      label="Delete"
                      variant="filled"
                      color="error"
                      onClick={handleDelete}
                    />
                  </Box>
                </Stack>
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
};
