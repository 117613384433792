import { styled } from "@mui/material";

export const TextToLabel = (status, sx = {}, caseStyle = { ACTIVE: {} }) => {
  const StyledBox = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    borderRadius: "4px",
    padding: theme.spacing(1),
  }));
  switch (status) {
    case "ACTIVE":
    case "SETTLED":
    case "CUSTOMER_WON":
    case "MERCHANT_WON":
    case "SUCCESSFUL":
    case "DEFAULT":
    case "OPEN":
      return (
        <StyledBox
          sx={{
            background: "rgba(5, 184, 98, 0.2)",
            border: "1px solid #05B862",
            color: "#05B862",
            ...sx,
            ...caseStyle[status],
          }}
        >
          {status}
        </StyledBox>
      );
    case "INACTIVE":
    case "DECLINED":
    case "ABANDONED":
    case "INITIATED":
      return (
        <StyledBox
          sx={{
            background: "rgba(0, 0, 0, 0.15)",
            border: "1px solid #000000",
            color: "#828282",
            ...sx,
            ...caseStyle[status],
          }}
        >
          {status}
        </StyledBox>
      );
    case "DELETED":
    case "FAILED":
    case "EXPIRED":
    case "CUSTOM":
    case "CLOSED":
      return (
        <StyledBox
          sx={{
            background: "#ff4b01",
            border: "1px solid  #FF0000",
            color: "#fff",
            ...sx,
            ...caseStyle[status],
          }}
        >
          {status}
        </StyledBox>
      );

    case "PENDING":
    case "REFUNDED":
    case "RESPONSE_NEEDED":
      return (
        <StyledBox
          sx={{
            background: "rgba(246, 48, 2, 0.2)",
            border: "1px solid #FF0000",
          }}
        >
          {status}
        </StyledBox>
      );
    default:
      return status;
  }
};
