import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/system";
import { H4, Span } from "components/typography/Typography";
import DoughnutChart from "../shared/Doughnut";
import SimpleCard from "../shared/SimpleCard";

export const MerchantOverview = () => {
  const theme = useTheme();
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 3);

  return (
    <SimpleCard>
      <Paper sx={{ width: "fit-content", margin: "auto" }}>
        <Box>
          <H4>
            <Span>{"From"}</Span>
            <Span>{"29 Jun 2021"}</Span>
            <Span>{"To"}</Span>
            <Span>{"06 Jul 2021"}</Span>
          </H4>
          <Span>{"Merchant Overview"}</Span>
        </Box>
      </Paper>
      <DoughnutChart
        height="350px"
        color={[
          theme.palette.primary.dark,
          theme.palette.primary.main,
          theme.palette.primary.light,
        ]}
      />
    </SimpleCard>
  );
};
