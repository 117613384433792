import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { useEffect, useState, useContext} from "react";
import Table from "components/table/Table";
import { dataKeysAndRepr, TotalSummary } from "./data";
import useMenu from "hooks/useMenu";
import _ from "lodash";
import { KYC_BUSINESS_INDUSTRY } from "utils/constant";
import { getBusinessType } from "services/https/updatedKYCService";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { clearSearch } from "redux/actions/SearchAction";
import { useDispatch } from "react-redux";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";


export const BusinessIndustry = () => {
  const [businessIndustryStat, setBusinessIndustryStat] = useState();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { setTableMenu } = useMenu();
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_KYC')
  const {
    alerts: { alertType, msg },

    search: { searchResult },
  } = useSelector((state) => {
    return state;
  });
  const destructureNeed = (businessIndustries) => {
    const data = businessIndustries?.map((businessIndustry) => {
      const { id, name, updatedAt } = businessIndustry || {};
      return {
        id,
        name,
        updatedAt: format(new Date(updatedAt), "MMM do yyyy hh:m:s a"),
      };
    });
    return data;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setTableMenu(KYC_BUSINESS_INDUSTRY);
    if (_.isEmpty(businessIndustryStat)) {
      getBusinessType().then((data) => {
        setBusinessIndustryStat({ totalBusinessType: data?.length });
      });
    }
    return () => {
      dispatch(clearSearch);
    };
  }, []);

  useEffect(() => {
    if (searchResult) {
      if (!_.isEmpty(searchResult)) {
        const search =
          searchResult.length > rowsPerPage
            ? searchResult.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchResult;
        setData({
          count: searchResult.length,
          allRows: destructureNeed(search),
        });
      } else {
        setData({
          count: 0,
          allRows: [],
        });
      }
    } else {
      getBusinessType({ page, size: rowsPerPage }).then((data) => {
        const allRows = destructureNeed(data);
        setData({ allRows, count: data?.length });
      });
    }
  }, [data?.length, msg, page, rowsPerPage, searchResult]);

  return (
    <Box>
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Stack direction={"row"} spacing={2} sx={{ mb: 2 }}>
        {TotalSummary.map(({ title, objKeyRepr }, idx) => (
          <SimpleCard
            key={`${objKeyRepr}-${idx}`}
            title={title}
            width={"fit-content"}
            height={"120px"}
          >
            {
              <Typography variant={"h6"}>
                {(businessIndustryStat && businessIndustryStat[objKeyRepr]) ?? (
                  <CircularProgress size={12} />
                )}
              </Typography>
            }
          </SimpleCard>
        ))}
      </Stack>

      <Table dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'action')}
       dataItemRows={data.allRows}
       />
      {
        <TablePagination
          component="div"
          count={data?.count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Box>
  );
};
