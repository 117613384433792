import { authRoles } from "auth/authRoles";
import { AuthTabPanel, WayaPayTabPanel } from "components/tabs/dispute";
import { Log } from "./Log";
import { AdminLog, Dispute, FraudManagement, RequestLogs } from "./pages";
import { DisputeResolution } from "./pages/dispute/DisputeResolution";

const LogRoutes = [
  {
    path: "/logs",
    element: <Log />,
    auth: authRoles.admin,
    children: [
      {
        path: "dispute/resolution/:disputeId",
        element: <DisputeResolution />,
      },
      {
        path: "disputes",
        element: <Dispute />,
        children: [
          {
            path: "wayapay",
            element: <WayaPayTabPanel />,
          },
          {
            path: "notify_auth",
            element: <AuthTabPanel />,
          },
        ],
      },
      {
        path: "admin-logs",
        element: <AdminLog />,
      },
      {
        path: "fraud-management",
        element: <FraudManagement />,
      },
      {
        path: "request-logs",
        element: <RequestLogs />,
      },
    ],
  },
];

export default LogRoutes;
