import { Box, Button, IconButton, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { StyledTableCell } from "./UserTableMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const WayaPayTableMenu = ({ row, pageData }) => {
  const navigate = useNavigate();
  const actions = (status) => {
    if (status === "INITIATED" || status === "RESPONSE_NEEDED") {
      return (
        <Box>
          <Button
            variant={"outlined"}
            startIcon={<CheckCircleIcon />}
            endIcon={<ArrowRightIcon />}
            sx={{
              paddingRight: "2em",
              color: "#05B862",
              background: "rgba(5, 184, 98, 0.2)",
              border: "1px solid #05B862",
            }}
          >
            <Link
              to={`/logs/dispute/resolution/${pageData.merchantCustomerDisputeId}`}
              state={{
                ...pageData,
              }}
            >
              {"Resolve"}
            </Link>
          </Button>
        </Box>
      );
    }
    return (
      <Stack direction={"row"} sx={{ justifyContent: "center" }}>
        <IconButton
          onClick={() => {
            navigate(
              `/logs/dispute/resolution/${pageData.merchantCustomerDisputeId}`
            );
          }}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };
  return (
    <StyledTableCell align="right">
      {actions(pageData?.adminStatus)}
    </StyledTableCell>
  );
};
