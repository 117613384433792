import React from "react";

const ChevronArrowLeftIcon = ({ color = "#E7472C", size = 17, opacity = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity={opacity}
        d='M7.97174 2.23436C8.74905 1.55544 7.51831 0.480496 6.741 1.21599L1.04076 6.13811C0.716882 6.42099 0.716882 6.93018 1.04076 7.21306L6.741 12.1918C7.51831 12.8707 8.74905 11.7957 7.97174 11.1168L2.91925 6.70387L7.97174 2.23436Z'
        fill={color}
      />
    </svg>
  );
};

export default ChevronArrowLeftIcon;
