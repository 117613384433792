import React, { cloneElement, useContext } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  InputBase,
  Snackbar,
  Stack,
  styled,
  TablePagination,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomizedInputBase from "components/search/Search";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { TotalSummary } from "../../data";
import Table from "components/table/Table";
import Filter from "components/filter";
import { useEffect, useState } from "react";
import {
  getAllUsers,
  downloadTemplate,
  getUserStatus,
} from "services/https/fetchers";
import { useDispatch, useSelector } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import {
  DialogFactory,
  dialogOptions,
} from "../../../../components/dialog/dialogFactory/DialogFactory";
import { USER_TABLE_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import { InviteUserMenu } from "components/menu/InviteUserMenu";
import { AddUserMenu } from "components/menu/AddUserMenu";
import _ from "lodash";
import { clearSearch } from "redux/actions/SearchAction";
import { searchData } from "utils/customSearch";
import { format, parseISO } from "date-fns";
import { dataKeysAndRepr } from "./data";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const HeaderSearch = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(4),
}));

const SectionRoot = styled("section")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const ManageUsers = () => {
  const [anchorEl, setAnchorEl] = useState({ el: null, id: "" });

  const [data, setData] = useState({});

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDialog, setOpenDialog] = useState(false);

  const { setTableMenu } = useMenu();

  const [userStat, setUserStat] = useState();

  const dispatch = useDispatch();

  const [chainedFilters, setChainedFilters] = useState({ keys: [] });

  const [allSize, setSize] = useState({});

  const [allData, setAllData] = useState();

  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_MERCHANTS')

  const {
    alerts: { alertType, msg, dialogType, payload },
    search: { searchResult },
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    searchData["manageUser"]().then((data) => {
      setAllData(data);
    });
  }, []);

  const destructureNeed = (users) => {
    const data = users.map((user) => {
      if (user && user.otherDetails) {
        const {
          otherDetails: {
            organisationName,
            organisationPhone,
            organizationState,
            organizationCity,
            organisationType,
          },
          createdAt,
          userId,
          active,
        } = user;
        return {
          organisationName: organisationName,
          organisationPhone: organisationPhone,
          organisationState: organizationState,
          organisationCity: organizationCity,
          organisationType: organisationType,
          status: Boolean(active) ? "ACTIVE" : "INACTIVE",
          createdAt: createdAt,
          id: parseInt(userId),
        };
      }
    });
    return data;
  };
  useEffect(() => {
    setTableMenu(USER_TABLE_MENU);

    getUserStatus().then((userStats) => {
      !_.isEmpty(userStats) &&
        setUserStat({
          activeUsers: userStats[0],
          inActiveUsers: userStats[1],
          totalRegisteredUsers: userStats[2],
        });
    });
    return () => {
      dispatch(clearSearch);
    };
  }, []);
  useEffect(() => {
    if (searchResult) {
      const search =
        searchResult.length > rowsPerPage
          ? searchResult.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : searchResult;
      setData({
        count: searchResult.length,
        users: destructureNeed(search),
      });
    } else {
      getAllUsers({ page, size: rowsPerPage })
        .then((resp) => {
          const { totalItems: count, totalPages, users } = resp || {};
          !_.isEmpty(users) &&
            setData({
              count: count,
              totalPages: totalPages,
              users: destructureNeed(users),
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [page, rowsPerPage, searchResult, msg]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const openMenu = (e, id) => {
    setAnchorEl({ el: e.currentTarget, id: id });
  };
  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };
  const handleDownloadTemplate = () => {
    downloadTemplate({ isCorporate: true });
  };
  const handleClose = () => {
    setAnchorEl((anchor) => ({ ...anchor, el: null }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectDate = (e) => {
    const { value } = e.target;
    try {
      const selectDate = format(parseISO(value), "dd-MM-yyyy");
      let { keys } = chainedFilters;
      const keyCopy = () => {
        if (keys.includes("createdAt")) {
          return keys;
        }
        keys.push("createdAt");
        return keys;
      };
      setChainedFilters((filter) => ({
        ...filter,
        keys: keyCopy(),
        createdAt: selectDate,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <SectionRoot>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              payload,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <HeaderSearch>
        <CustomizedInputBase
          searchSpace={"manageUser"}
          keys={[
            "deleted",
            "createdAt",
            "userId",
            "otherDetails.organisationName",
            "otherDetails.organisationPhone",
            "otherDetails.organizationState",
            "otherDetails.organizationCity",
            "otherDetails.organisationType",
          ]}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "end",
            rowGap: "8px",
          }}
        >
          <Button
            size={"small"}
            variant="contained"
            sx={{
              p: 1,
              lineHeight: 1,
              display: showManaging ? 'flex' : 'none'
            }}
            onClick={(e) => handleBtnClick(e, "deactivateUser")}
          >
            {"Deactivate Users"}
          </Button>
          <Button
            size="small"
            sx={{
              p: 1,
              lineHeight: 1,
              display: showManaging ? 'flex' : 'none'
            }}
            variant="contained"
            onClick={(e) => openMenu(e, "inviteMenu")}
            handleBtnClick={handleBtnClick}
          >
            {"Invite Users"}
          </Button>
          {anchorEl?.id === "inviteMenu" && (
            <InviteUserMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleBtnClick={handleBtnClick}
            />
          )}
          <Button
            variant="contained"
            sx={{
              p: 1,
              lineHeight: 1,
              display: showManaging ? 'flex' : 'none'
            }}
            onClick={handleDownloadTemplate}
          >
            {"Download Template"}
          </Button>
          <Button
            sx={{
              lineHeight: 1,
              display: showManaging ? 'flex' : 'none'
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={(e) => openMenu(e, "addMenu")}
          >
            {"Create New User"}
          </Button>
          {anchorEl?.id === "addMenu" && (
            <AddUserMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleBtnClick={handleBtnClick}
            />
          )}
          <Button
            variant="contained"
            sx={{
              p: "1",
              lineHeight: 1,
              display: showManaging ? 'flex' : 'none'
            }}
            startIcon={<AddIcon />}
          >
            {"Update KYC"}
          </Button>
        </Stack>
      </HeaderSearch>

      <Stack direction={"row"} spacing={2}>
        {TotalSummary.map(({ title, objKeyRepr }, idx) => (
          <SimpleCard
            key={`${objKeyRepr}-${idx}`}
            title={title}
            width={"fit-content"}
            height={"120px"}
          >
            <Typography variant={"h6"}>
              {(userStat && userStat[objKeyRepr]) ?? (
                <CircularProgress size={12} />
              )}
            </Typography>
          </SimpleCard>
        ))}
      </Stack>
      <Stack direction={"row"} spacing={3}>
        <Filter
          name={"State"}
          searchSpace={"manageUser"}
          uniqueCells="otherDetails.organizationState"
          chainedFilters={chainedFilters}
          setChainedFilters={setChainedFilters}
          allData={allData}
          searchKey="otherDetails.organizationState"
        />
        <Filter
          name={"City"}
          searchSpace={"manageUser"}
          allData={allData}
          chainedFilters={chainedFilters}
          setChainedFilters={setChainedFilters}
          searchKey="otherDetails.organizationCity"
        />
        <Filter
          name={"Business Type"}
          searchSpace={"manageUser"}
          uniqueCells="otherDetails.organisationType"
          chainedFilters={chainedFilters}
          setChainedFilters={setChainedFilters}
          allData={allData}
          searchKey="otherDetails.organisationType"
        />
        <Filter
          name={"Status"}
          searchSpace={"manageUser"}
          uniqueCells={["ACTIVE", "INACTIVE"]}
          chainedFilters={chainedFilters}
          setChainedFilters={setChainedFilters}
          allData={allData}
          searchKey="active"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            type={"date"}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.15)",
              height: "35px",
              mt: -1,
            }}
            onChange={handleSelectDate}
          />
        </Box>
      </Stack>
      <Table dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'action')} dataItemRows={data?.users} />
      <TablePagination
        component="div"
        count={data?.count ?? 0}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
    </SectionRoot>
  );
};

export default ManageUsers;
