import { Menu, MenuItem } from "@mui/material";
export const AddUserMenu = ({ anchorEl, handleClose, handleBtnClick }) => {
  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl?.el}
      open={Boolean(anchorEl?.el)}
      onClose={handleClose}
    >
      <MenuItem onClick={(e) => handleBtnClick(e, "createSingleUser")}>
        {"Create Single User"}
      </MenuItem>
      <MenuItem onClick={(e) => handleBtnClick(e, "createBulkUser")}>
        {"Upload Excel Sheet to Create"}
      </MenuItem>
    </Menu>
  );
};
