import axios from "axios";
import { createContext, useReducer } from "react";

const initialState = {
  user: null,
};

const DataContext = createContext({
  ...initialState,
  getAllUsers: () => Promise.resolve(),
});

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_ALL_USER": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    default: {
    }
  }
};

export const DataProvider = ({ children }) => {
  const { REACT_APP_BASE_URL: baseUrl } = process.env;
  const [state] = useReducer(reducer, initialState);
  const getAllUsers = async (pages, limit) => {
    const response = await axios
      .get(`${baseUrl}users/corporate-profile/{isCorporate}?page=0&size=20`)
      .then((response) => response.data)
      .catch((err) => console.error(err));

    return response;
  };
  return (
    <DataContext.Provider
      value={{
        ...state,
        getAllUsers,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
