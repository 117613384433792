import React from "react";

const ThreeVerticalDotsIcon = ({ color = "#333333" }) => {
  return (
    <svg width='7' height='21' viewBox='0 0 7 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='3.5189' cy='2.5' rx='2.54233' ry='2.5' fill={color} />
      <ellipse cx='3.5189' cy='10.5' rx='2.54233' ry='2.5' fill={color} />
      <ellipse cx='3.5189' cy='18.5' rx='2.54233' ry='2.5' fill={color} />
    </svg>
  );
};

export default ThreeVerticalDotsIcon;
