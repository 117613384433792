import { useEffect, useRef, useCallback } from "react";
const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export const useSafeAsync = () => {
  const isMounted = useMountedState();
  const safeAsync = useCallback((promise) => {
    return new Promise((resolve) => {
      promise.then((value) => {
        if (isMounted()) {
          resolve(value);
        }
      });
    });
  });

  return safeAsync;
};
