import React from "react";
import "./styles.css";

const Spinner = ({ size = 5, innerMargin = 1.5 }) => {
  const innerSize = `calc(${size}rem - ${innerMargin}rem)`;
  return (
    <div
      className='waya__spinner-container'
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
      }}
    >
      <div
        className='waya__spinner-content'
        style={{
          width: `${innerSize}`,
          height: `${innerSize}`,
        }}
      ></div>
    </div>
  );
};

export default Spinner;
