import {
  Box,
  styled,
  Grid,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Dialog,
} from "@mui/material";
import { H5 } from "components/typography/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SelectCustomUserDialog = ({ openDialog, setOpenDialog }) => {
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>
        <H5>{"Select Users for Custom Pricing"}</H5>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Checkbox defaultChecked={false} />}
          label="Select all users"
        />
        <Box sx={{ border: "2px solid #fafafa", m: 2, p: 2 }}>
          <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <SelectUsers />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCustomUserDialog;

export const SelectUsers = () => {
  const AutoCompleteRoot = styled(Grid)(({ theme }) => ({
    margin: "auto",

    ".MuiAutocomplete-tag": {
      borderRadius: "4px",
    },
    label: {
      paddingBlock: theme.spacing(1),
      fontWeight: "bold",
      backgroundColor: "#f3f3f3",
      width: "100%",
      display: "block",
    },
  }));
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
  ];
  return (
    <AutoCompleteRoot item>
      <label>{"Choose new set of users"}</label>
      <Box
        sx={{
          backgroundColor: "#e3e3e3",
          p: 2,
        }}
      >
        <Autocomplete
          multiple
          id="multiple-limit-tags"
          options={top100Films}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} placeholder="add user" />
          )}
          sx={{ width: "400px", background: "#fff" }}
        />
      </Box>
    </AutoCompleteRoot>
  );
};
