import { add, format } from "date-fns";
import * as yup from "yup";

export const initialValues = {
  currency: "NGN",
  customURL: "",
  description: "",
  expiryDate: `${format(
    add(new Date(), { months: 1 }),
    "yyyy-MM-dd'T'HH:mm:ss'Z'"
  )}`,
  linkCanExpire: true,
  otherDetailsJSON: "",
  payableAmount: "",
  paymentLinkName: "",
  phoneNumber: "",
  planId: "",
  redirectLink: "",
  successMessage: "",
};
export const subscriptionInitial = {
  ...initialValues,
  chargeInterval: "",
  totalCount: 0,
};
export const oneTimePaymentLinkValidationSchema = yup.object().shape({
  paymentLinkName: yup
    .string("Enter payment link name")
    .required("Payment link name is required"),

  payableAmount: yup
    .number("Amount must be number")
    .typeError("Amount must be number")
    .min(100, "Minimum plan amount is NGN100 ")
    .required("Amount is required"),
});

export const subscriptionPaymentLinkValidationSchema = yup.object().shape({
  paymentLinkName: yup
    .string("Enter payment link name")
    .required("Payment link name is required"),
  payableAmount: yup
    .number("Amount must be number")
    .typeError("Amount must be number")
    .min(100, "Minimum plan amount is NGN100 ")
    .required("Amount is required"),
  chargeInterval: yup
    .string("Enter charge interval")
    .required("Charge interval is required"),

  totalCount: yup
    .number("billing cycle must be number")
    .typeError("billing cycle must be a number enter 0 to charge indefinitely")
    .min(0, "Minimum cycle is 0 ")
    .required("Billing cycle is required enter 0 to charge indefinitely"),
});

export const formInput = {
  main: [
    {
      label: "Payment Link Name *",
      name: "paymentLinkName",
      placeholder: "Name your payment link",
    },
    {
      label: "Description",
      name: "description",
      placeholder: "optional",
    },
    {
      label: "Amount",
      name: "amount",
      elements: [
        {
          name: "currency",
          type: "select",
          options: [
            {
              name: "NGN",
              value: "NGN",
            },
            {
              name: "$",
              value: "Dollar",
            },
          ],
        },
        {
          name: "payableAmount",
          type: "text",
          placeholder: "Enter amount customer will pay",
        },
      ],
    },
  ],
  others: [
    {
      label: "Use custom URL",
      name: "customURL",
      inputAdornment: "https://wayapaychat.com/pay/",
      placeholder: "Your URL",
    },
    {
      label: "Redirect after payment",
      name: "redirectLink",
      placeholder: "https://redirect.link",
    },
    {
      label: "Success Message",
      name: "successMessage",
      placeholder: "message to show after payment",
    },

    {
      label: "Phone Number",
      name: "phoneNumber",
      placeholder: "Add your phone number",
    },
  ],
};

export const subscriptionFormInput = {
  main: [
    {
      label: "Payment Link Name *",
      name: "paymentLinkName",
      placeholder: "Name your payment link",
    },
    {
      label: "Description",
      name: "description",
      placeholder: "optional",
    },
    {
      label: "Amount",
      name: "amount",
      elements: [
        {
          name: "currency",
          type: "select",
          options: [
            {
              name: "NGN",
              value: "NGN",
            },
            {
              name: "$",
              value: "Dollar",
            },
          ],
        },
        {
          name: "payableAmount",
          type: "text",
          placeholder: "Enter amount customer will pay",
        },
      ],
    },
    {
      label: "Interval *",
      name: "interval",
      elements: [
        {
          name: "chargeInterval",
          type: "select",
          options: [
            {
              name: "Choose an interval",
              value: "",
            },
            {
              name: "YEARLY",
              value: "YEARLY",
            },
            {
              name: "MONTHLY",
              value: "MONTHLY",
            },
          ],
        },
      ],
    },
    {
      label: "Total Count",
      name: "totalCount",
      placeholder: "Set Limit",
    },
  ],
  others: [
    {
      label: "Use custom URL",
      name: "customURL",
      inputAdornment: "https://wayapaychat.com/pay/",
      placeholder: "Your URL",
    },
    {
      label: "Redirect after payment",
      name: "redirectLink",
      placeholder: "https://redirect.link",
    },
    {
      label: "Success Message",
      name: "successMessage",
      placeholder: "message to show after payment",
    },

    {
      label: "Phone Number",
      name: "phoneNumber",
      placeholder: "Add your phone number",
    },
  ],
};

export const paymentDataKeyRepr = [
  { objKey: "paymentLinkName", repr: "Name" },
  { objKey: "paymentLinkType", repr: "Type" },
  { objKey: "amountText", repr: "Amount" },
  { objKey: "customerPaymentLink", repr: "Payment Link" },
  { objKey: "createdAt", repr: "Created At" },
  { objKey: "status", repr: "Status" },
  { objKey: "action", repr: "" },
];

export const planDataKeyRepr = [
  { objKey: "planName", repr: "Plan Name" },
  { objKey: "planId", repr: "Plan ID" },
  { objKey: "planAmount", repr: "Amount" },
  { objKey: "interval", repr: "Interval" },
  { objKey: "createdAt", repr: "Created At" },
  { objKey: "totalCount", repr: "No. of Subscription" },
  { objKey: "totalRevenueGenerated", repr: "Total Revenue" },
];

export const customerDataKeyRepr = [
  { objKey: "customerId", repr: "Customer Id" },

  { objKey: "customerName", repr: "Customer Name" },

  { objKey: "email", repr: "Email" },

  { objKey: "phoneNumber", repr: "Phone Number" },

  { objKey: "status", repr: "Status" },
];

export const subscriptionDataKeyRepr = [
  { objKey: "customerSubscriptionId", repr: "Subscription ID" },

  { objKey: "customerPaymentLink", repr: "Subscription Link" },
  { objKey: "customerId", repr: "Customer Id" },

  { objKey: "createdAt", repr: "Created At" },
  { objKey: "linkExpirationDate", repr: "Next due on" },
  { objKey: "status", repr: "Status" },
];
