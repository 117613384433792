import { useEffect, useState, useContext} from "react";
import {
  Button,
  Stack,
  Box,
  TablePagination,
  styled,
  Snackbar,
  Alert,
  CircularProgress,
  useTheme,
} from "@mui/material";
import CustomizedInputBase from "components/search/Search";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import Table from "components/table/Table";
import { dataKeysAndRepr, TotalSummary } from "./data";
import { useNavigate } from "react-router-dom";
import CustomIcon from "assets/icons";
import { getAllPricing, getPricingStats } from "services/https/pricingFetcher";
import { PRICING_TABLE_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { clearSearch } from "redux/actions/SearchAction";
import { PricingFilter } from "components/shared/filterSearchExport/pricingFilter/PricingFilter";
import { CopyAble } from "components/tabs/transaction/data";
import { TextToLabel } from "utils/textToLabel";
import { CSVLink } from "react-csv";
import { useSafeAsync } from "hooks/useSafeAsync";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const HeaderSearch = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const SectionRoot = styled("section")(({ theme }) => ({
  margin: theme.spacing(2),
  form: {
    label: {
      display: "block",
    },
  },
}));

export const Pricings = () => {
  const [data, setData] = useState({});
  const [exportedData, setExportedData] = useState([]);
  const theme = useTheme();
  const [stats, setStats] = useState({});
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_PRICING')
  const {
    alerts: { alertType, msg },
    search: { searchResult },
  } = useSelector((state) => {
    return state;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = () => {
    navigate("/tech/pricings/edit");
  };
  const { setTableMenu } = useMenu();
  useEffect(() => {
    setTableMenu(PRICING_TABLE_MENU);
    getPricingStats().then((data) => {
      const [defaultt, custom] = data;
      setStats({
        [defaultt?.status ?? "DEFAULT"]: defaultt?.count,
        [custom?.status ?? "CUSTOM"]: custom?.count ?? "--",
      });
    });
    return () => {
      dispatch(clearSearch);
    };
  }, []);
  const safeAsync = useSafeAsync();
  const getExportedData = () => {
    safeAsync(
      getAllPricing({ page, size: 10000000 }).then((data) => {
        setLoading(false);
        const { content } = data || [];
        setExportedData(content);
      })
    );
  };
  useEffect(() => {
    setLoading(true);
    if (searchResult) {
      if (!_.isEmpty(searchResult)) {
        setLoading(false);
        const search =
          searchResult.length > rowsPerPage
            ? searchResult.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchResult;
        setData({
          count: searchResult.length,
          allRows: destructureNeed(search),
        });
      } else {
        setData({
          count: 0,
          allRows: [],
        });
      }
    } else {
      getAllPricing({ page, size: rowsPerPage }).then((data) => {
        setLoading(false);
        const { content, totalElements } = data || [];
        const allRows = destructureNeed(content);
        setData({ allRows, count: totalElements });
      });
    }
  }, [page, rowsPerPage, searchResult, msg]);

  const destructureNeed = (content) => {
    return content?.map((row) => {
      const {
        id,
        merchantName,
        productName,
        localRate,
        localProcessingFeeCappedAt,
        localDiscountRate,
        pricingStatus,
        merchantProductPricingId,
      } = row;
      return {
        id: <CopyAble str={`${id}`} length={8} showText={true} />,
        merchantName,
        productName,
        localRate: `${localRate} %`,
        localProcessingFeeCappedAt,
        localDiscountRate: `${localDiscountRate} %`,
        pricingStatus: TextToLabel(
          pricingStatus,
          {
            border: "none ",
            background: "none ",
            justifyContent: "start",
            textTransform: "Capitalize",
          },
          { CUSTOM: { color: `${theme.palette.primary.main} !important` } }
        ),
        merchantProductPricingId,
      };
    });
  };

  return (
    <SectionRoot>
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={"info"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <HeaderSearch>
        <Box sx={{ display: "flex" }}>
          <PricingFilter />
          <CustomizedInputBase
            searchSpace="pricing"
            placeholder={"search here"}
          />
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleClick}
            startIcon={<CustomIcon name={"edit"} />}
            sx={{display: showManaging ? 'flex' : 'none' }}
          >
            {"Edit Pricings"}
          </Button>
        </Stack>
      </HeaderSearch>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBlock: 4,
        }}
      >
        <Stack direction={"row"} spacing={2}>
          {TotalSummary.map(({ title, total, key }) => (
            <SimpleCard title={title} width={"324px"} height={"120px"}>
              {(stats && <h4>{stats[key]}</h4>) ?? (
                <CircularProgress size={20} />
              )}
            </SimpleCard>
          ))}
        </Stack>

        <Button
          variant="contained"
          sx={{ height: "fit-content", backgroundColor: "success.main" }}
          startIcon={<CustomIcon name={"coolicon"} />}
          onClick={getExportedData}
        >
          <CSVLink data={exportedData} filename={"pricing.csv"}>
            {"Export Data"}
          </CSVLink>
        </Button>
      </Box>
      <Table
        dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'action')}
        dataItemRows={loading ? [] : data.allRows}
        alignAction={"left"}
      />
      <TablePagination
        component="div"
        count={data?.count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </SectionRoot>
  );
};

export default Pricings;
