import { AuthTabPanel, WayaPayTabPanel } from "./dispute";
import { BusinessIndustry } from "./kyc/businessIndustry/BusinessIndustry";
import { BusinessKYC } from "./kyc/businessKYC/BusinessKYC";
import { CustomerTabPanel } from "./merchantTransaction/CustomerTabPanels";
import { GettingStarted } from "./merchantTransaction/paymentLink/GettingStarted";
import { PaymentLinkTabPanel } from "./merchantTransaction/PaymentLinkTabPanel";
import { SubscriptionTabPanel } from "./merchantTransaction/SubscriptionTabPanel";
import { SettlementTabPanel } from "./transaction/SettlementTabPanel";
import { TxTabPanel } from "./transaction/TxTabPanel";

export const TabPages = {
  kyc: {
    tabName: {
      0: "BusinessKYC",
      1: "BusinessIndustry",
    },
    tabPill: [
      {
        label: "Business KYC",
        icon: "transaction",
        href: "businessKYC",
        value: "businessKYC",
      },
      {
        label: "Business Industry",
        icon: "transaction",
        href: "businessIndustry",
        value: "businessIndustry",
      },
    ],

    tabPanel: [
      {
        Component: BusinessKYC,
        compValue: "businessKYC",
      },
      {
        Component: BusinessIndustry,
        compValue: "businessIndustry",
      },
    ],

    Default: <BusinessKYC />,
  },
  transactions: {
    tabName: {
      0: "transactions",
      1: "settlements",
    },
    tabPill: [
      {
        label: "Transactions",
        icon: "transaction",
        value: "_t",
      },
      {
        label: "Settlements",
        icon: "person",
        href: "/settlements",
        value: "settlements",
      },
    ],
    tabPanel: [
      {
        Component: TxTabPanel,
        compValue: "_t",
      },
      {
        Component: SettlementTabPanel,
        compValue: "settlements",
      },
    ],

    Default: <TxTabPanel />,
  },
  dispute: {
    tabName: {
      0: "dispute",
      1: "settlements",
    },
    tabPill: [
      {
        label: "Wayapay",
        icon: "transaction",
        value: "wayapay",
      },
      {
        label: "Auth & Notification",
        icon: "person",
        value: "notify_auth",
      },

      {
        label: "Others",
        icon: "person",
      },
    ],
    tabPanel: [
      {
        compValue: "wayapay",
        Component: WayaPayTabPanel,
      },
      {
        compValue: "notify_auth",
        Component: AuthTabPanel,
      },
    ],
    Default: <WayaPayTabPanel />,
  },
  "perform-merchant-transactions": {
    tabName: {
      0: "paymentLinks",
      1: "subscriptions",
      2: "customers",
      3: "settlements",

    },
    tabPill: [
      {
        label: "Payment Link",
        icon: "transaction",
        href: "paymentlinks",
        value: "paymentlinks",
      },
      {
        label: "Subscriptions",
        icon: "transaction",
        href: "subscriptions",
        value: "subscriptions",
      },
      {
        label: "Customers",
        icon: "transaction",
        href: "customers",
        value: "customers",
      },
      {
        label: "Settlements",
        icon: "transaction",
        href: "settlements",
        value: "settlements",
      },
    ],
    tabPanel: [
      {
        Component: PaymentLinkTabPanel,
        compValue: "paymentlinks",
      },
      {
        Component: SubscriptionTabPanel,
        compValue: "subscriptions",
      },
      {
        Component: CustomerTabPanel,
        compValue: "customers",
      },
      {
        Component: PaymentLinkTabPanel,
        compValue: "settlements",
      },
    ],
    Default: <GettingStarted />,
  },
};
