import axios from "axios";
import CONFIG from "../../config";
const baseURL = `${CONFIG.API_BASE_URL}/payment-gateway/api/v1/processors`;

export const getAllProcessors = async (q) => {
  const params = new URLSearchParams(q).toString();
  return await axios
    .get(`${baseURL}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const getProcessorList = async (q) => {
  return await axios
    .get(`${baseURL}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const updateProcessorList = async ({ name, data }) => {
  return await axios
    .patch(`${baseURL}/${name}`, data)
    .then((res) => {
      if (res.data.status) return res.data.data;
    })
    .catch((err) => console.log(err));
};

export const createProcessor = async (pojo) => {
  return await axios
    .post(`${baseURL}`, pojo)
    .then((res) => {
      if (res.data.status) return res.data.data;
    })
    .catch((err) => console.log(err));
};

export const updateProcessor = async ({ id, data }) => {
  return await axios
    .post(`${baseURL}/${id}`, data)
    .then((res) => {
      if (res.data.status) return res.data.data;
    })
    .catch((err) => console.log(err));
};

