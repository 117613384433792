export const dispute = {
  disputeDetail: [
    { label: "Customer Name", objKey: "customerName" },
    { label: "Customer Email", objKey: "customerEmail" },
    { label: "Transaction Date", objKey: "transactionDate" },
    { label: "Dispute Initiation Date", objKey: "disputeInitiationDate" },
    { label: "Dispute Type", objKey: "disputeType" },
    { label: "Reference Id", objKey: "referenceId" },
    { label: "Amount", objKey: "transactionAmount" },
  ],
  merchantDetail: [
    { label: "Name", objKey: "merchantName" },
    { label: "Email Address", objKey: "merchantEmail" },
    { label: "Merchant Id", objKey: "merchantId" },
  ],
  merchantResponse: [
    { label: "Response State", objKey: "merchantStatus" },
    { label: "Response Due Date", objKey: "merchantResponseDueDate" },
    { label: "Response Date", objKey: "merchantResponseDate" },
    { label: "Document Uploaded", objKey: "attachment" },
  ],
};

export const typeOfProof = [
  {
    name: "Signed Invoices (electronic or paper)",
  },
  {
    name: "Sales receipts (electronic or paper)",
  },
  {
    name: "Delivery confirmation documents",
  },
  {
    name: "Service usage times, dates, etc",
  },
  {
    name: "Proof that a digital product was downloaded by the customer",
  },
];
