import React, { useState } from "react";
import Button from "@mui/material/Button";
import { SingleSelectPlaceholder } from "components/select";
import {
  Box,
  CircularProgress,
  IconButton,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { H3, Span } from "components/typography/Typography";
import { useDispatch } from "react-redux";

export default function OtpViaDialog({ openDialog, setOpenDialog }) {
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    setLoader(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
          alignItems: "center",
        }}
      >
        <H3>{"Send OTP"}</H3>
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SingleSelectPlaceholder
          sx={{ width: "458px", marginBottom: "24px" }}
          label={"Select / Enter Receiver"}
          menuItems={[
            { value: "email", placeholder: "Email" },
            { value: "phone", placeholder: "Phone Number" },
          ]}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 4 }}>
        <Button variant={"contained"} sx={{ width: "156px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Span>{"Send OTP"}</Span>
            {loader ? (
              <CircularProgress
                sx={{ color: "#fff", marginLeft: "5px" }}
                size={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
