import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function ConfirmationDialog({
  openDialog,
  setOpenDialog,
  user,
}) {
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });

  const { title, subTitle, handleOk } = payload || {};
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <Typography>{subTitle}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            {"Cancel"}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
