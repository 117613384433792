import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";

export default function BulkUserNotifyDialog({ openDialog, setOpenDialog }) {
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogTitle>{"Error creating user"}</DialogTitle>
        <DialogContent dividers>
          {!_.isEmpty(payload) &&
            payload?.map((i) => (
              <>
                <Typography>{i}</Typography>
                <Divider />
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
