export const listItemData = [
  {
    label: "Reference ID",
    val: "PTY_2cruye6niqx4yau",
    objKeyRepr: "refNo",
  },
  {
    label: "Channel",
    val: "Card",
    objKeyRepr: "channel",
  },
  {
    label: "Wayapaychat Fees",
    val: "7.50",
    objKeyRepr: "fee",
  },
  {
    label: "Date Paid",
    val: "Tuesday, 6 July 2021, 7:30pm GMT",
    objKeyRepr: "tranDate",
  },
  {
    label: "Message",
    val: "Successful",
    objKeyRepr: "status",
  },
];

export const analytics = [
  {
    label: "Card Type",
    val: "Mastercard",
    objKeyRepr: "scheme",
  },
  {
    label: "Card Number",
    val: "408408 **** 4081",
    objKeyRepr: "maskedPan",
  },
  {
    label: "Authorization",
    val: "AUTH_25jm16y8sy",
    objKeyRepr: "authorization",
  },
  {
    label: "Issuing Bank & Country",
    val: "United Bank for Africa (UBA) (NGN)",
    objKeyRepr: "issuer",
  },
  {
    label: "IP Adress",
    val: "154.73.8.97",
    objKeyRepr: "customerIpAddress",
  },
  {
    label: "Payment Processor",
    val: "Mastercard",
    objKeyRepr: "scheme",
  },
];

export const otherInformation = [
  {
    avatarIcon: "device",
    val: "Desktop",
    label: "DEVICE TYPE",
  },
  {
    avatarIcon: "tap",
    val: "1 time",
    label: "PAY BUTTON CLICKED TIME",
  },
  {
    avatarIcon: "cloud",
    val: "0 errors",
    label: "ERRORS",
  },
];
