import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import "./styles.css";

const RenameAccountEnterPasswordModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='rename-account-modal'>
      <div className='rename-account-modal__main-content'>
        <button className='rename-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='rename-account-modal__body'>
          <div className='rename-account-modal__title-container'>
            <h3 className='rename-account-modal__title'>{"Rename Account"}</h3>
          </div>

          <p className='rename-account-modal__text'>{"To proceed, enter your Password"}</p>

          <div className='rename___account__form'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Password"}</label>
              <input className='rename-account-modal-input' value='Primary Account' />
            </div>
          </div>

          <div className='rename-account-modal__action-btns-container'>
            <button className='rename-account-modal__btn rename-account-modal__orange-btn'>
              {"Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameAccountEnterPasswordModal;
