
import React, { Suspense } from "react";
import { AppLoader } from "..";

export const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<AppLoader/>}>
      <Component {...props} />
    </Suspense>
  );
