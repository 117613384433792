import { StyledTableCell } from "./UserTableMenu";
import { Box, Stack, Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";

const PricingActionMenu = ({ setOpenDialog, row }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    setOpenDialog(true);
    dispatch(createDialog({ type: "editPricing", payload: row }));
  };
  return (
    <StyledTableCell align="right" sx={{ paddingRight: "2em" }}>
      <Box>
        <Stack direction={"row"} spacing={2}>
          <Button variant="outlined" color="success" onClick={handleClick}>
            <EditIcon />
          </Button>

          {/*  <Button variant="outlined">
            <ReplayIcon />
          </Button> */}
        </Stack>
      </Box>
    </StyledTableCell>
  );
};

export default PricingActionMenu;
