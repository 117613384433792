import { EditPricing } from "./pages/pricings/EditPricing";
import Pricings from "./pages/pricings/Pricings";
import { TransactionRouting } from "./pages/transactionRouting/TransactionRouting";

const TechRoutes = [
  {
    path: "/tech",
    children: [
      {
        path: "transaction-routing",
        element: <TransactionRouting />,
      },
      {
        path: "pricings/edit",
        element: <EditPricing />,
      },
      {
        path: "pricings",
        element: <Pricings />,
      },
    ],
  },
];

export default TechRoutes;
