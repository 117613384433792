import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { fetchMenuItemByName } from "services/https/updatedKYCService";
import { createDialog } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import { PREVIEW_IMAGE } from "utils/constant";
import { useEffect, useState } from "react";
import _ from "lodash";
import { dropDownOptionItems } from "./data/data";
import { StyledInputBox } from "./Styled";

export const InputBox = ({
  label,
  editable,
  data,
  setUpdatedChanges,
  updatedChanges,
  setOpenDialog,
  others,
}) => {
  const {
    type = "text",
    infoText,
    selectedValue,
    selectedOption,
    name,
    fileName,
    certificateList,
  } = others || {};
  let value, selVal, selOpt;
  if (!_.isEmpty(data)) {
    value = data[name] ?? "";
    selOpt = data[selectedOption] ?? "";
    selVal = data[selectedValue];
  }
  const [uploadFile, setUploadFile] = useState([]);
  const [selOption, setSelectedOption] = useState(selOpt);
  const [inputTextValue, setInputTextValue] = useState(value);
  const [menuItem, setMenuItem] = useState({ industryType: [] });

  const dispatch = useDispatch();
  const handlePreview = (e, file) => {
    setOpenDialog(true);
    dispatch(createDialog({ type: PREVIEW_IMAGE, payload: file }));
  };

  const FileComp = ({ submittedFiles = [], uploadedFiles = [] }) => {
    return (
      <>
        {submittedFiles.map((fileName) => (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{fileName}</Typography>
            <Stack direction={"row"}>
              <Button onClick={(e) => handlePreview(e, fileName)}>
                {"View"}
              </Button>
              <IconButton>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        ))}
        {uploadedFiles.map((file) => (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{file?.name}</Typography>
            <Stack direction={"row"}>
              <Button onClick={(e) => handlePreview(e, file)}>{"View"}</Button>
              <IconButton>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </>
    );
  };

  const selectedStateCityLGA = {
    selState: updatedChanges?.state ?? data["state"],
    selCity: updatedChanges?.city ?? data["city"],
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    setUpdatedChanges((data) => ({ ...data, [name]: value }));
  };

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    setUploadFile((file) => [...file, newFile]);
    setUpdatedChanges((data) => ({ ...data, [fileName]: newFile }));
  };

  const handleTextChange = (e) => {
    const { value } = e.target;
    setInputTextValue(value);
    setUpdatedChanges((data) => ({ ...data, [name]: value }));
  };

  const RenderSelectMenu = (name, selected) => {
    const fetchSpecificList = [
      "businessIndustry",
      "country",
      "state",
      "city",
      "lga",
    ].includes(name);
    useEffect(() => {
      if (fetchSpecificList) {
        fetchMenuItemByName(name, selected).then((data) => {
          setMenuItem((menuItem) => ({ ...menuItem, [name]: data }));
        });
      }
    }, [selected?.selState, selected?.selCity]);

    if (fetchSpecificList) {
      return menuItem[name]?.map((menuOption) => (
        <MenuItem
          value={menuOption}
          disabled={menuOption?.startsWith("select")}
          key={`${menuOption}`}
        >
          {menuOption}
        </MenuItem>
      ));
    } else {
      return Object.entries(dropDownOptionItems[name] ?? {})?.map(
        ([key, value]) => {
          return (
            <MenuItem key={`${key}-${value}`} value={key}>
              {value}
            </MenuItem>
          );
        }
      );
    }
  };

  return (
    <StyledInputBox>
      <label>{label}</label>
      <Typography>{infoText}</Typography>
      {type === "select" || type === "selectUpload" ? (
        <>
          <Select
            fullWidth
            input={<OutlinedInput />}
            value={selOption}
            disabled={!editable}
            onChange={handleSelectChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                },
              },
            }}
          >
            {RenderSelectMenu(name, selectedStateCityLGA)}
          </Select>
          {type === "selectUpload" && (
            <>
              <InputBase
                type={editable ? "file" : "text"}
                fullWidth={true}
                value={editable ? "" : selVal}
                disabled={!editable}
                onChange={handleFileChange}
                endAdornment={
                  !editable && (
                    <IconButton onClick={(e) => handlePreview(e, selVal)}>
                      <Visibility />
                    </IconButton>
                  )
                }
              />
              {editable && (
                <FileComp
                  submittedFiles={[selVal]}
                  uploadedFiles={uploadFile}
                />
              )}
            </>
          )}
        </>
      ) : type === "businessTypeUpload" ? (
        <>
          {certificateList(
            updatedChanges?.businessRegistrationType ||
              data?.businessRegistrationType
          ).map(({ fileLabel, selectedValue }) => {
            let selVal = updatedChanges
              ? updatedChanges[selectedValue]
              : data[selectedValue];
            selVal = Boolean(selVal) ? selVal : "Not Available";
            return (
              <>
                <Typography>{fileLabel}</Typography>

                <InputBase
                  type={editable ? "file" : "text"}
                  fullWidth={true}
                  value={editable ? "" : selVal ?? "Not Available"}
                  disabled={!editable}
                  onChange={handleFileChange}
                  endAdornment={
                    !editable &&
                    (selVal === "Not Available" ? (
                      <IconButton disabled>
                        <VisibilityOff />
                      </IconButton>
                    ) : (
                      <IconButton onClick={(e) => handlePreview(e, selVal)}>
                        <Visibility />
                      </IconButton>
                    ))
                  }
                />
                {editable && (
                  <FileComp
                    submittedFiles={[selVal]}
                    uploadedFiles={uploadFile}
                  />
                )}
              </>
            );
          })}
        </>
      ) : (
        <InputBase
          type={type}
          fullWidth={true}
          value={inputTextValue}
          onChange={handleTextChange}
          disabled={!editable}
        />
      )}
    </StyledInputBox>
  );
};
