import axios from "axios";

import CONFIG from "../../config";
const baseURL = `${CONFIG.BASE_URL}/dispute-service/api/v2/dispute`;

export const createDispute = async ({ dispute }) =>
  await axios
    .post(`${baseURL}`, dispute)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const fetchDisputeStats = async () =>
  await axios
    .get(`${baseURL}/report/stats`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));

export const getDisputeList = async (q) => {
  const Query = { ...q, ORDER: "ASC" };
  const params = new URLSearchParams(Query).toString();
  return await axios
    .get(`${baseURL}/filter-search?${params}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));
};

export const fetchDispute = async (disputeId) =>
  await axios
    .get(`${baseURL}/fetch/${disputeId}`)
    .then((res) => res.data.data)
    .catch((err) => console.log(err));

export const resolveDispute = async ({ disputeId, status, message }) =>
  await axios
    .patch(
      `${baseURL}/resolve-dispute/${disputeId}?message=${message}&status=${status}`
    )
    .then((res) => res.data)
    .catch((err) => alert("Merchant needs to respond"));

export const acceptMerchantDispute = async ({
  merchantCustomerDisputeId,
  data,
}) => {
  return await axios
    .put(`${baseURL}/accept-dispute/${merchantCustomerDisputeId}`, data)
    .then((res) => res.data)
    .catch((err) => alert("Error while responding to dispute"));
};

export const rejectMerchantDispute = async ({
  files,
  merchantRejectionDocumentType,
  disputeRejectReason,
  merchantCustomerDisputeId,
}) => {
  let formData = new FormData();
  formData.append("files", files);
  formData.append(
    "merchantRejectionDocumentType",
    merchantRejectionDocumentType
  );
  formData.append("disputeRejectReason", disputeRejectReason);
  return await axios
    .post(`${baseURL}/reject-dispute/${merchantCustomerDisputeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((err) => alert("Error while responding to disput"));
};
