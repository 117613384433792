import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, user }) {
  const { REACT_APP_CUSTOMER_URL: customerUrl } = process.env;
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Hi ${user?.lastName ?? ""} ${
          user?.firstName ?? ""
        }!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Dear esteemed customer,
            <br />
            Kindly go to our customer page by clicking the proceed button below{" "}
            <br />
            Thanks
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>
            <a href={customerUrl}>{"Proceed"}</a>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
