export const stateAndCity = [
  {
    state: "Abia",
    city: ["Aba", "Arochukwu", "Umuahia"],
  },
  {
    state: "Adamawa",
    city: ["Jimeta", "Mubi", "Numan", "Yola"],
  },
  {
    state: "Akwa Ibom",
    city: ["Ikot Abasi", "Ikot Ekpene", "Oron", "Uyo"],
  },
  {
    state: "Anambra",
    city: ["Awka", "Onitsha"],
  },
  {
    state: "Bauchi",
    city: ["Azare", "Bauchi", "Jama′are", "Katagum", "Misau"],
  },
  {
    state: "Bayelsa",
    city: ["Brass"],
  },
  {
    state: "Benue",
    city: ["Makurdi"],
  },
  {
    state: "Borno",
    city: ["Biu", "Dikwa", "Maiduguri"],
  },
  {
    state: "Cross River",
    city: ["Calabar", "Ogoja"],
  },
  {
    state: "Delta",
    city: ["Asaba", "Burutu", "Koko", "Sapele", "Ughelli", "Warri"],
  },
  {
    state: "Ebonyi",
    city: ["Abakaliki"],
  },
  {
    state: "Edo",
    city: ["Benin City"],
  },
  {
    state: "Ekiti",
    city: ["Ado-Ekiti", "Effon-Alaiye", "Ikere-Ekiti"],
  },
  {
    state: "Enugu",
    city: ["Enugu", "Nsukka"],
  },
  {
    state: "FCT Abuja",
    city: ["Abuja"],
  },
  {
    state: "Gombe",
    city: ["Deba Habe", "Gombe", "Kumo"],
  },
  {
    state: "Imo",
    city: ["Owerri"],
  },
  {
    state: "Jigawa",
    city: ["Birnin Kudu", "Dutse", "Gumel", "Hadejia", "Kazaure"],
  },
  {
    state: "Kaduna",
    city: ["Jemaa", "Kaduna", "Zaria"],
  },
  {
    state: "Kano",
    city: ["Kano"],
  },
  {
    state: "Katsina",
    city: ["Daura", "Katsina"],
  },
  {
    state: "Kebbi",
    city: ["Argungu", "Birnin Kebbi", "Gwandu", "Yelwa"],
  },
  {
    state: "Kogi",
    city: ["Idah", "Kabba", "Lokoja", "Okene"],
  },
  {
    state: "Kwara",
    city: ["Ilorin", "Jebba", "Lafiagi", "Offa", "Pategi"],
  },
  {
    state: "Lagos",
    city: ["Badagry", "Epe", "Ikeja", "Ikorodu", "Lagos", "Mushin", "Shomolu"],
  },
  {
    state: "Nasarawa",
    city: ["Keffi", "Lafia", "Nasarawa"],
  },
  {
    state: "Niger",
    city: ["Agaie", "Baro", "Bida", "Kontagora", "Lapai", "Minna", "Suleja"],
  },
  {
    state: "Ogun",
    city: ["Abeokuta", "Ijebu-Ode", "Ilaro", "Shagamu"],
  },
  {
    state: "Ondo",
    city: ["Akure", "Ikare", "Oka-Akoko", "Ondo", "Owo"],
  },
  {
    state: "Osun",
    city: [
      "Ede",
      "Ikire",
      "Ikirun",
      "Ila",
      "Ile-Ife",
      "Ilesha",
      "Ilobu",
      "Inisa",
      "Iwo",
      "Oshogbo",
    ],
  },
  {
    state: "Oyo",
    city: ["Ibadan", "Iseyin", "Ogbomosho", "Oyo", "Saki"],
  },
  {
    state: "Plateau",
    city: ["Bukuru", "Jos", "Vom", "Wase"],
  },
  {
    state: "Rivers",
    city: ["Bonny", "Degema", "Okrika", "Port Harcourt"],
  },
  {
    state: "Sokoto",
    city: ["Sokoto"],
  },
  {
    state: "Taraba",
    city: ["Ibi", "Jalingo", "Muri"],
  },
  {
    state: "Yobe",
    city: ["Damaturu", "Nguru"],
  },
  {
    state: "Zamfara",
    city: ["Gusau", "Kaura Namoda"],
  },
];
