import { SvgIcon } from "@mui/material";

import sprite from "./sprite.svg";

const CustomIcon = ({ name, size = 24 }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: size, height: size }}>
      <use xlinkHref={`${sprite}#${name}`} />
    </SvgIcon>
  );
};

export default CustomIcon;
