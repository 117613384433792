import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingIcon from "components/Icons/SettingIcon";
import AddIcon from "components/Icons/AddIcon";
import "./styles.css";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement } from "react";
import { createDialog } from "redux/actions/AlertActions";
import {
  Alert,
  CircularProgress,
  Snackbar,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  getAdminUserList,
  getAdminUserListStats,
} from "services/https/roleAndAccessService";
import Table from "components/table/Table";
import useMenu from "hooks/useMenu";
import { ADMIN_USER, ADMIN_USER_TABLE_MENU } from "utils/constant";
import { dataKeysAndRepr, TotalSummary, formatAdminData } from "./data";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { useNavigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const ManageAdminUsersPage = () => {
  const [adminUserList, setAdminUserList] = useState({
    data: [],
    pagedData: [],
  });
  const [adminUserStats, setAdminUserStats] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTableMenu } = useMenu();
  const {
    alerts: { alertType, msg, dialogType, payload },
  } = useSelector((state) => {
    return state;
  });
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_ADMIN')

  const { settings, updateSettings } = useSettings();
  useEffect(() => {
    updateSettings({ ...settings, currentPage: ADMIN_USER });
    setTableMenu(ADMIN_USER_TABLE_MENU);
    getAdminUserListStats().then((data) => {
      setAdminUserStats(data?.data);
    });
    getAdminUserList().then((data) => {
      let mydata = []
      if(data.message !== 'Error') {
        mydata = data?.data?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      }
      setAdminUserList({ data: data?.data, pagedData: mydata });
    });
  }, [msg, page]);

  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <section className="manage-admin-users manage-admin-users__container">
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              payload,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <div className="manage-admin-users__top-actions">
        <div className="manage-admin-users__top-actions-btns">
          <button
            className={"manage-admin-users__top-actions__settings-btn"}
            onClick={() => navigate("manage-roles")}
            style={{display: showManaging ? 'flex' : 'none' }}
          >
            <SettingIcon color="#828282" />
            <p>{"MANAGE ADMIN ROLES"}</p>
          </button>

          <button
            className={"manage-admin-users__top-actions__create-btn"}
            onClick={(e) => handleBtnClick(e, "createAdminUser")}
            style={{display: showManaging ? 'flex' : 'none' }}
          >
            <AddIcon color="#ffffff" />
            <p>{"Create New Admin User"}</p>
          </button>
        </div>
      </div>

      {/* Users and their total numbers */}
      <Stack direction={"row"} spacing={2} sx={{ py: 2, mx: 2 }}>
        {TotalSummary.map(({ title, objKeyRepr }, idx) => (
          <SimpleCard
            key={`${objKeyRepr}-${idx}`}
            title={title}
            width={"fit-content"}
            height={"120px"}
          >
            <Typography variant={"h6"}>
              {(adminUserStats && adminUserStats[objKeyRepr]) ?? (
                <CircularProgress size={12} />
              )}
            </Typography>
          </SimpleCard>
        ))}
      </Stack>

      {/* Request Type and Date Filter */}

      {/* Table */}
      <div className="manage-admin-users__table-container">
        <Table
          dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'action')}
          dataItemRows={formatAdminData(adminUserList?.pagedData)}
          alignAction="center"
        />
        <TablePagination
          component="div"
          count={adminUserList?.data?.length ?? 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </section>
  );
};

export default ManageAdminUsersPage;
