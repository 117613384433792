import React, { Fragment, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Button,
  Typography,
  StepLabel,
} from "@mui/material";

export default function VerticalStepper({ setActiveStep, activeStep, steps }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} orientation={"vertical"}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
