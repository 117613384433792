import React from "react";
import { AppLayouts } from "./index";
import { AppSuspense } from "components/shared";
import useSettings from "hooks/useSettings";

const AppLayout = (props) => {
  const { settings } = useSettings();
  const Layout = AppLayouts[settings.activeLayout];

  return (
    <AppSuspense>
      <Layout {...props} />
    </AppSuspense>
  );
};

export default AppLayout;
