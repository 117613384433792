import React, { useEffect, useState, useRef } from "react";

const SwitchBtn = ({ handleOn, handleOff }) => {
  const [isOn, toggleOn] = useState(false);
  const isFirstMount = useRef(true);
  const buttonClassName = isOn ? "switch-btn-on" : "switch-btn-off";

  useEffect(() => {
    if (isFirstMount.current) return;

    if (isOn) handleOn();
    else handleOff();
  }, [handleOff, handleOn, isOn]);

  useEffect(() => {
    isFirstMount.current = false;
  }, []);

  return (
    <div className='switch-btn-container'>
      <button
        className={"switch-btn " + buttonClassName}
        onClick={() => toggleOn((s) => !s)}
      ></button>
    </div>
  );
};

export default SwitchBtn;
