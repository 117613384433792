import { AddCircleOutline } from "@mui/icons-material";
import {
  Box,
  Stack,
  CircularProgress,
  Typography,
  TablePagination,
  Snackbar,
  Alert,
  Button,
  Switch,
  styled,
} from "@mui/material";
import Table from "components/table/Table";
import useMenu from "hooks/useMenu";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import { getAllProcessors } from "services/https/techService";
import { CREATE_PROCESS, TX_ROUTING } from "utils/constant";
import { dataKeysAndRepr, TotalSummary, txRoutingLabel } from "./data";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";
import { enableProcessor, toggleProcessor, updateProcessor } from "services/https/fetchers";
import { DialogFactory, dialogOptions } from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement } from "react";
import { hideLoader, showLoader } from "utils/loader";

export const TransactionRouting = () => {
  const [allProcessor, setAllProcessor] = useState([
    { type: [], processor: "" },
  ]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRfresh] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();
  const handleBtnClick = (e, type) => {
    setOpenDialog(true);
    dispatch(createDialog({ type: CREATE_PROCESS }));  
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_TRANSACTION_ROUTING')

  const {
    alerts: { alertType, msg, dialogType, payload },
  } = useSelector((state) => {
    return state;
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { setTableMenu } = useMenu();
  /* const handleCreateProcessor = () => {
    dispatch(createDialog({ type: CREATE_PROCESS }));
  }; */
  const handleToggle = async (isActive,id) => {
    showLoader();
    toggleProcessor({isActive: !isActive, id})
    .then((res)=>{
      console.log('ggg', res)
      setRfresh(!refresh);
      hideLoader();
    })

  }

  const handleEenableProcessor = async (routingType,id, isActive) => {
   showLoader();
    enableProcessor({routingType: routingType === 'ONE' ? 'ALL' : 'ONE', id, isActive})
    .then((res)=>{
      console.log('ggg', res)
      setRfresh(!refresh);
      hideLoader();
    })

  }

  const Togle = ({isActive, id, data}) => {
    return(
      <Switch checked={isActive} onClick={()=>handleToggle(isActive,id)} />         
    )
  }
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  
  const RoutingType =({routingType, id, isActive}) =>{
    return(
      <Stack direction="row" spacing={1} alignItems="center">
        <h5>ONE</h5>
        <AntSwitch
        checked={routingType === 'ONE'? false : true }
        inputProps={{ 'aria-label': 'ant design' }} 
        onClick={()=>handleEenableProcessor(routingType, id, isActive)}
      />
        <h5>ALL</h5>
      </Stack>
    )
  }
  useEffect(() => {
    setTableMenu(TX_ROUTING);
    getAllProcessors().then((data) => {
      const needs = data?.map(
        ({
          name,
          cardAcquiring,
          ussdAcquiring,
          accountAcquiring,
          payattitudeAcquiring,
          isActive,
          merchantId,
          precedence,
          routingType,
          id,
            cardType,
            code,
            description,
            liveUrl,
            testUrl,
        }) => {
          return {
            name,
            cardAcquiring,
            ussdAcquiring,
            payattitudeAcquiring,
            accountAcquiring,
            isActive,
            merchantId,
            precedence,
            routingType,
            id,
            isActive,      
            cardType,
            code,
            description,
            liveUrl,
            testUrl,
          };
        }
      );
     
      const allProcessor = needs.map(({ 
        name,
        id,
        merchantId,
        precedence,
        routingType,
        isActive,      
        cardType,
        code,
        description,
        liveUrl,
        testUrl,
        ...others 
      }) => {
        const acc = [];
        const type = Object.entries(others).reduce((acc, [k, v]) => {
          if (v === true) acc.push(txRoutingLabel[k]);
          return acc;
        }, acc);    
             
        return {
          id,
          type,
          processor: name,
          merchantId: merchantId ? merchantId : '--', precedence,
          routingType: <RoutingType id={id}  routingType={routingType} isActive={isActive}/> ,
          isActive: <Togle id={id} data={data} isActive={isActive} /> ,
          accountAcquiring: others?.accountAcquiring,
          cardAcquiring: others?.cardAcquiring,
          cardType,
          code,
          description,
          liveUrl,
          payattitudeAcquiring: others?.payattitudeAcquiring,
          testUrl,
          ussdAcquiring: others?.ussdAcquiring, 
          active: isActive,       
        };
      });

      setAllProcessor(allProcessor);
    });
  }, [msg, refresh]);
  return (
    <Box>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              payload,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={"info"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography sx={{ p: 2 }} variant={"h5"}>
          {"Transaction Routing"}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddCircleOutline />}
          sx={{ height: "fit-content", display: showManaging ? 'flex' : 'none' }}
          onClick={handleBtnClick}
        >
          {"Add Processor"}
        </Button>
      </Stack>

      <Table 
        //  dataKeysAndRepr={showManaging ? dataKeysAndRepr : dataKeysAndRepr.filter(item => item?.objKey !== 'isActive')}
        dataKeysAndRepr= {dataKeysAndRepr}
        dataItemRows={allProcessor}
      />
      <TablePagination
        component="div"
        count={allProcessor?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
