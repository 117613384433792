import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { H3 } from "components/typography/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import { linkType } from "./data";
import { Link, useLocation } from "react-router-dom";

export const CreatePaymentLink = ({ openDialog, setOpenDialog }) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const path = useLocation().pathname;

  const Content = () => {
    return (
      <>
        {linkType.map(({ icon, primary, secondary, link }, idx) => (
          <>
            <List key={`${link}-${idx}`}>
              <ListItem
                secondaryAction={
                  <Button
                    variant={"contained"}
                    aria-label="choose"
                    onClick={handleClose}
                  >
                    <Link to={`${path}/${link ?? ""}`}>{"Choose"}</Link>
                  </Button>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ mr: 10 }}
                  primary={primary}
                  secondary={secondary}
                />
              </ListItem>
            </List>
            <Divider />
          </>
        ))}
      </>
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        marginBottom: "24px",
        "& .MuiDialog-root": {},
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
        }}
      >
        <H3>{"Choose Link Type"}</H3>
      </DialogTitle>

      <DialogContent sx={{ px: 1 }}>
        <Content />
      </DialogContent>
    </Dialog>
  );
};
