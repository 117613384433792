import {
  Stack,
  InputBase,
  styled,
  Button,
  Box,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";

import CustomIcon from "assets/icons";
import { Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { KYC_BUSINESS_INDUSTRY } from "utils/constant";
import CustomizedInputBase from "components/search/Search";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { createBusinessIndustry } from "services/https/updatedKYCService";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const StyledHeadSearch = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const HeadSearch = ({ children }) => {
  return (
    <>
      <StyledHeadSearch>
        <Stack direction={"row"}>
          <InputBase
            placeholder={"Refrence ID"}
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              width: "300px",
              px: 2,
              ml: 2,
            }}
          />
        </Stack>
        {children}
        <Button variant={"outlined"} startIcon={<CustomIcon name="save" />}>
          {"Export"}
        </Button>
      </StyledHeadSearch>
    </>
  );
};

export const BusinessIndustry = ({ children }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_KYC')
  
  const handleOk = async (name) => {
    try {
      await createBusinessIndustry({ name });
      return dispatch(
        notifySuccess({ msg: "Business industry created successfully" })
      );
    } catch (err) {
      return notifyFailure({ msg: "Error while creating business industry" });
    }
  };
  const handleClick = () => {
    setOpenDialog(true);
    const payload = {
      handleOk,
      title: "Create New Business Industry Type",
      type: "new",
    };
    dispatch(createDialog({ type: KYC_BUSINESS_INDUSTRY, payload }));
  };

  const {
    alerts: { dialogType, alertType, msg },
  } = useSelector((state) => {
    return state;
  });
  return (
    <>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <StyledHeadSearch>
        <Stack direction={"row"}>
          <CustomizedInputBase
            placeholder={"Search by business industry,date"}
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              width: "300px",
              px: 2,
              ml: 2,
            }}
            searchSpace="businessIndustry"
            keys={["name"]}
          />
        </Stack>
        {children}
        <Button variant={"outlined"} startIcon={<Add />} sx={{ display: showManaging ? 'flex' : 'none' }} onClick={handleClick}>
          {"Create New Business Type"}
        </Button>
      </StyledHeadSearch>
    </>
  );
};
