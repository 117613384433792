import React from "react";

const CreatePin = () => {
  return (
    <div className='more-settings-body'>
      <h3 className='more-settings-body-title'>{"Create Waya Official Pin"}</h3>
      <div className='more-settings-input-group'>
        <label className='more-settings-label'>{"Create New PIN"}</label>
        <input className='more-settings-input' value='' />
      </div>

      <div className='more-settings-input-group'>
        <label className='more-settings-label'>{"Confirm PIN"}</label>
        <input className='more-settings-input' value='' />
      </div>

      <div className='more-settings-radio-input-group-container'>
        <div className='more-settings-radio-input-group radio-input-group__with-border radio-input-group__with-border-active'>
          <div className='more-settings-radio-circle more-settings-radio-circle-active'></div>
          <div className='more-settings-radio-text more-settings-radio-text-active'>
            {"OTP Via SMS"}
          </div>
        </div>

        <div className='more-settings-radio-input-group radio-input-group__with-border'>
          <div className='more-settings-radio-circle'></div>
          <div className='more-settings-radio-text'>{"OTP Via Email"}</div>
        </div>
      </div>

      <button
        className='more-settings-create-btn more-settings__orange-btn'
        onClick={() => console.log("Clicked")}
      >
        {"Create Pin"}
      </button>
    </div>
  );
};

export default CreatePin;
