import React from "react";

const Input = ({ formik, name }) => {
  return (
    <div className='assign-referral-code-input-group'>
      <label htmlFor={name}>{name}</label>
      <input
        id={name}
        name={name}
        type='text'
        onChange={formik.handleChange}
        value={formik.values[name]}
      />
    </div>
  );
};

export default Input;
