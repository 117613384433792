import React from "react";

const DropIcon = ({ color = "#E7472C", size = 11, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size / 2}
      viewBox={`0 0 ${size} ${size / 2}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.3335 6.12204L0.385742 0.402344H10.2813L5.3335 6.12204Z' fill={color} />
    </svg>
  );
};

export default DropIcon;
