export const wayaSummaryCard = [
  {
    title: "TOTAL INITIATED DISPUTE",
    objKeyRepr: "initiatedDispute",
  },
  {
    title: "TOTAL OPEN DISPUTE",
    objKeyRepr: "openDispute",
  },
  {
    title: "TOTAL CLOSED DISPUTE",
    objKeyRepr: "closedDispute",
  },
];

export const wayaDataKeysAndRepr = [
  { objKey: "disputeStatus", repr: "Status" },
  { objKey: "disputeType", repr: "Dispute Type" },
  { objKey: "transaction", repr: "Transaction" },
  { objKey: "customerEmail", repr: "Customer" },
  { objKey: "dateCreated", repr: "Date Created" },
  { objKey: "dueWhen", repr: "Due date" },
  { objKey: "action", repr: "Action" },
];
