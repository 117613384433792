import { useEffect, useState } from "react";
import {
  styled,
  Paper,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  InputBase,
  Icon,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { H3, Span } from "components/typography/Typography";
import {
  formInput,
  subscriptionFormInput,
  subscriptionInitial,
  subscriptionPaymentLinkValidationSchema,
} from "../data";
import * as _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewPaymentLink,
  updatePaymentLink,
} from "services/https/paymentLink";
import { createRequest, getMerchantById } from "services/https/fetchers";
import { useDispatch } from "react-redux";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";

const FormRoot = styled(Paper)(({ theme }) => ({
  width: "50%",
  margin: `${theme.spacing(2)} auto`,
}));

const StyledBox = styled("div")(({ theme }) => ({
  ".Mui-focused": {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
  },
  label: {
    display: "block",
    marginBlock: theme.spacing(2),
    fontWeight: "bold",
  },
  ["select,input,textarea"]: {
    width: "100%",
    borderRadius: "4px",
    padding: theme.spacing(2),
    border: "2px solid #f6f6f6",
  },
  span: {
    color: theme.palette.primary.main,
  },
}));

export const CreateSubscriptionPaymentLink = ({ detail }) => {
  const { userId } = useParams();
  const [merchantId, setMerchantId] = useState();
  let updateInitial = {};
  if (!_.isEmpty(detail)) {
    Object.keys(subscriptionInitial).forEach((key) => {
      updateInitial[key] = detail[key] ?? subscriptionInitial[key];
    });
  } else {
    updateInitial = subscriptionInitial;
  }
  useEffect(() => {
    getMerchantById({ userId }).then((data) => {
      const { merchantID } = data || {};
      setMerchantId(merchantID);
    });
  }, [userId]);

  return (
    <Paper elevation={0}>
      <Box>
        <FormContent merchantId={merchantId} detail={updateInitial} />
      </Box>
    </Paper>
  );
};

const FormContent = ({ merchantId, detail }) => {
  const [showOthers, setShowOthers] = useState(false);
  const [fd, addField] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { paymentLinkId } = useParams();
  const handleAddField = () => {
    addField((fd) => [...fd, { name: `input${fd.length}`, value: "" }]);
  };
  const handleXtraFieldClose = (e) => {
    const { name } = e.target;
    const idx = fd.findIndex((i) => i.name === name);

    if (idx != -1) {
      let fdCopy = [...fd];
      fdCopy.splice(idx, 1);
      addField(fdCopy);
    }
  };
  const handleCustomChange = (e) => {
    const { name, value } = e.target;
    const idx = fd.findIndex((i) => i.name === name);
    const newObj = { ...fd[idx], value: value };
    let fdCopy = [...fd];
    fdCopy.splice(idx, 1, newObj);
    addField(fdCopy);
  };
  return (
    <Formik
      initialValues={detail || subscriptionInitial}
      validationSchema={subscriptionPaymentLinkValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let otherDetailsJSON = {};
        fd.map(({ name, value }) => {
          otherDetailsJSON[name] = value;
        });

        const val = {
          ...values,
          merchantId,
          paymentLinkType: "SUBSCRIPTION_PAYMENT_LINK",
          otherDetailsJSON: JSON.stringify(otherDetailsJSON),
        };
        let d = new Date()
        let payload = {
         customerName: `merchantId`,
         payload: JSON.stringify(val),
         requestId: d.getTime(),
         requestType: "createSubsLink"
       }
      
        if (_.isEmpty(detail?.paymentLinkName)) {
          //createNewPaymentLink({ newPaymentLink: val })
          createRequest(payload)
          .then((data) => {
            setSubmitting(false);
            dispatch(
              notifySuccess({
                msg: `CUSTOMER SUBSCRIPTION PAYMENT LINK CREATED SUCCESSFULLY, WAITING FOR APPROVAL`,
              })
            );
          });
        } else {
           let uploadPayload = {
        customerName: `$val.merchantId`,
        payload: JSON.stringify({...val,paymentLinkId, status: 'ACTIVE'}),
        requestId: d.getTime(),
        requestType: "updateCreateSubsLink",
        paymentLinkId
      }
          /*updatePaymentLink({
            newPaymentLink: { ...val, paymentLinkId, status: "ACTIVE" },
          })*/
          createRequest(uploadPayload)
            .then((data) => {
              setSubmitting(false);
              dispatch(
                notifySuccess({
                  msg: `CUSTOMER SUBSCRIPTION PAYMENT LINK UPDATED SUCCESSFULLY, WAITING FOR APPROVAL`,
                })
              );
            })
            .catch((data) => {
              setSubmitting(false);
              dispatch(
                notifyFailure({
                  msg: `CUSTOMER SUBSCRIPTION LINK UPDATE FAIL`,
                })
              );
            });
        }
        navigate(-1);
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormRoot>
            <H3
              sx={{
                p: 2,
                textAlign: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "5px",
              }}
            >
              {"Create Subscription Link"}
            </H3>
            {subscriptionFormInput.main.map((i, idx) => (
              <Box sx={{ m: 4 }} key={idx}>
                {i.elements ? (
                  <>
                    <label>{i.label}</label>
                    <StyledBox
                      sx={{
                        display: `${i.label == "Amount" ? "flex" : "block"} `,
                      }}
                    >
                      {i.elements.map(({ type, options, placeholder, name }) =>
                        type === "select" ? (
                          <>
                            <Field
                              as="select"
                              name={name}
                              style={{
                                height: "fit-content",
                                width: `${
                                  name == "currency" ? "100px" : "100%"
                                } `,
                                marginRight: "8px",
                              }}
                            >
                              {options.map(({ name, value }) => (
                                <option name={name} value={value}>
                                  {name}
                                </option>
                              ))}
                            </Field>

                            {name === "chargeInterval" && (
                              <Typography color={"primary.main"}>
                                {errors[name]}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Box sx={{ display: "block", width: "100%" }}>
                            <Field name={name} placeholder={placeholder} />
                            <Typography color={"primary.main"}>
                              {errors[name]}
                            </Typography>
                          </Box>
                        )
                      )}
                    </StyledBox>
                    <Box color={"primary.main"}></Box>
                  </>
                ) : i.type === "textArea" ? (
                  <StyledBox>
                    <label>{i.label}</label>
                    <Field as="text-area" name={i.name} />
                  </StyledBox>
                ) : (
                  <StyledBox>
                    <label>{i.label} </label>
                    <Field name={i.name} placeholder={i.placeholder} />
                    <Span>
                      <ErrorMessage name={i.name} />
                    </Span>
                  </StyledBox>
                )}
              </Box>
            ))}

            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Divider />
              <Button
                variant={"text"}
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => {
                  setShowOthers(!showOthers);
                }}
              >
                {"Show more options"}
              </Button>
            </Box>
            {showOthers && (
              <Box sx={{ m: 4 }}>
                {formInput.others.map((i) => (
                  <StyledBox>
                    <label>{i.label}</label>
                    <InputBase
                      placeholder={i.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ border: "2px solid #f6f6f6", width: "100%" }}
                      startAdornment={
                        i.inputAdornment && (
                          <Span
                            sx={{
                              background: "#f6f6f6",
                              p: 2,
                              width: "fit-content",
                            }}
                          >
                            {i.inputAdornment}
                          </Span>
                        )
                      }
                      name={i.name}
                    />
                  </StyledBox>
                ))}
                <StyledBox>
                  <label>
                    {"Add additional fields to collect more information"}
                  </label>
                  {fd.map((i, idx) => (
                    <InputBase
                      onChange={handleCustomChange}
                      sx={{ border: "2px solid #f6f6f6", width: "100%", mt: 2 }}
                      startAdornment={
                        <Span
                          sx={{
                            background: "#f6f6f6",
                            p: 2,
                            width: "180px",
                          }}
                        >
                          {"Custom Field"}
                        </Span>
                      }
                      endAdornment={
                        <Icon
                          name={i.name}
                          onClick={handleXtraFieldClose}
                          component={"button"}
                          sx={{
                            width: "48px",
                            height: "100%",
                            p: 1.8,
                            cursor: "pointer",
                            border: "none",
                            background: "#f6f6f6",
                          }}
                        >
                          close
                        </Icon>
                      }
                      name={i.name}
                    />
                  ))}
                </StyledBox>
                <Button startIcon={<AddIcon />} onClick={handleAddField}>
                  {"Add more fields"}
                </Button>
              </Box>
            )}
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", p: 4 }}
            >
              <Button onClick={() => navigate(-1)}>{"<< Go Back"}</Button>
              <Box>
                <Button
                  variant={"contained"}
                  sx={{ mr: 2 }}
                  onClick={() => navigate(-1)}
                >
                  {"Cancel"}
                </Button>
                <Button variant={"contained"} type={"submit"}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>{`${
                      _.isEmpty(detail?.paymentLinkName)
                        ? "Create Link"
                        : "Update Link"
                    }`}</span>
                    {isSubmitting ? (
                      <CircularProgress
                        sx={{ color: "#fff", marginLeft: "5px" }}
                        size={20}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Button>
              </Box>
            </Stack>
          </FormRoot>
        </form>
      )}
    </Formik>
  );
};
