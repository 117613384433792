import React, { useEffect, useState } from "react";
import CustomIcon from "assets/icons";
import { styled } from "@mui/system";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { TabPages } from "./data";
import { useDispatch } from "react-redux";
import { clearSearch } from "redux/actions/SearchAction";
import { useLocation, useNavigate } from "react-router-dom";
import { TabsProvider } from "contexts/TabContext";

export const TabPanel = (props) => {
  const { children, value, index, compValue, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== compValue ?? index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === compValue || value === index) && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export function GenericTab({
  children,
  name,
  activeTab,
  setActiveTab,
  tabpillsx,
}) {
  const [value, setValue] = useState(name === 'transactions' ? '_t': name === 'kyc' ? 'businessKYC':'');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(clearSearch);
    setValue(newValue);
    navigate(newValue);
  };
  const { active } = useLocation().state || {};
  useEffect(() => {
    console.log('act', value);
    console.log('loc', name);
    if (active) {
      setValue(active);
    }
    setActiveTab(value);
  }, [value, name, active]);

  const BoxRoot = styled("div")(({ theme, tabpillsx }) => {
    return {
      "& .MuiButtonBase-root": {
        justifyContent: "start",
      },

      "& .Mui-selected": {
        backgroundColor: `${theme.palette.primary.main}`,
        borderRadius: "30px",
        textDecoration: "none",
        color: "#fff !important",
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
      " & .MuiTab-root": {
        width: "248px",
        minHeight: "48px",
        svg: {
          marginRight: theme.spacing(2),
        },
      },
      ...tabpillsx,
    };
  });
  return (
    <BoxRoot tabpillsx={tabpillsx}>
      <TabsProvider value={{ activeTab, setActiveTab }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {TabPages[name]?.tabPill.map(
              ({ label, iconPosition = "start", icon, value }) => (
                <Tab
                  label={label}
                  iconPosition={iconPosition}
                  icon={<CustomIcon name={icon} />}
                  value={value}
                />
              )
            )}
          </Tabs>
        </Box>
        {children}
        {value === "default" ? (
          TabPages[name]?.Default
        ) : (
          <>
            {TabPages[name]?.tabPanel.map(({ Component, compValue }, idx) => (
              <TabPanel
                value={value}
                index={idx}
                key={idx}
                compValue={compValue}
              >
                <Component value={value} index={idx} />
              </TabPanel>
            ))}
          </>
        )}
      </TabsProvider>
    </BoxRoot>
  );
}
