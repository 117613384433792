import React from "react";
import { useRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Store } from "./redux/Store";
import { AllPages } from "./routes/routes";
import { AuthProvider } from "contexts/JWTAuthContext";
import { SettingsProvider } from "contexts/settingsContext";
import AppTheme from "components/shared/appTheme/AppTheme";
import { StyledEngineProvider } from "@mui/material/styles";
import { MenuActionProvider } from "contexts/MenuContext";
import SessionTimeout from "components/shared/appLoader/SessionTimeout";

function App() {
  const all_pages = useRoutes(AllPages());
  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MenuActionProvider>
          <AppTheme>
            <AuthProvider>
              <SessionTimeout />
              {all_pages}
            </AuthProvider>
          </AppTheme>
        </MenuActionProvider>
      </SettingsProvider>
    </Provider>
  );
}

export default Sentry.withProfiler(App);
