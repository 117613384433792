import { Box } from "@mui/material";
import { TabContext } from "contexts/TabContext";
import useMenu from "hooks/useMenu";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { SUBSCRIPTION_MENU } from "utils/constant";

export const SubscriptionTabPanel = () => {
  const [merchantId, setMerchantId] = useState("");
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const { setTableMenu } = useMenu();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    setTableMenu(SUBSCRIPTION_MENU);

    getMerchantById({ userId })
      .then((data) => {
        const { merchantID } = data || {};
        if (merchantID) {
          setMerchantId(merchantID);
        } else {
          alert("Users has no merchant Id");
          navigate("/users/manage-users");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  return (
    <Box>
      <Outlet context={{ merchantId }} />
    </Box>
  );
};
