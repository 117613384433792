import { Box, CircularProgress, TableBody, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/system";
import { cloneElement, useState } from "react";
import TableMenu, { menulist } from "components/menu/TableMenu";
import useMenu from "hooks/useMenu";
import { useSelector } from "react-redux";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import _ from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  wordBreak: "break-word",
  "&:first-of-type": {
    paddingLeft: theme.spacing(2),

    textOverflow: "ellipsis",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.85em",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .Mui-selected": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export const CustomTableBody = ({
  dataItemRows = [],
  dataKeys,
  action,
  handleRowClick,
  pageData,
  isLoading = false,
}) => {
  const [selectedIdx, setSelectedIdx] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const { tableMenu } = useMenu();
  const {
    alerts: { dialogType, payload },
  } = useSelector((state) => {
    return state;
  });
  const user = payload?.user ?? {};
  const handleSelected = (e, idx) => {
    setSelectedIdx(idx);
  };

  const handleClick = (e, id) => {
    if (!_.isEmpty(pageData) && typeof handleRowClick === "function") {
      handleRowClick(id);
    }
  };

  return (
    <>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              user,
            });
          }}
        />
      )}
      <TableBody
        sx={{
          margin: "auto",
        }}
      >
        {isLoading ? (
          <TableRow sx={{ position: "relative", height: "200px" }}>
            <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
              <CircularProgress sx={{}} />
            </Box>
          </TableRow>
        ) : !_.isEmpty(dataItemRows) ? (
          dataItemRows?.map((row, idx) => (
            <StyledTableRow
              key={idx}
              onClick={(e) => handleSelected(e, idx)}
              selected={idx === selectedIdx}
            >
              <StyledTableCell scope="row">
                {row && row[dataKeys[0]]}
              </StyledTableCell>
              <>
                {dataKeys.slice(1, -1).map((i, idx) => (
                  <StyledTableCell
                    key={`cell-${idx}`}
                    data-id={row?.id ?? ""}
                    onClick={(e) => handleClick(e, row.id)}
                    align="left"
                  >
                    {(row && row[i]) ?? 'N/A'}
                  </StyledTableCell>
                ))}
              </>

              {Boolean(action) ? (
                tableMenu && (
                  <TableMenu
                    render={(props) => {
                      return cloneElement(menulist[tableMenu], {
                        ...props,
                        setOpenDialog,
                        row,
                        pageData: (!_.isEmpty(pageData) && pageData[idx]) ?? [],
                      });
                    }}
                  />
                )
              ) : (
                <StyledTableCell align="left">
                  {row[dataKeys[dataKeys.length - 1]]}
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))
        ) : (
          <TableRow sx={{ position: "relative", height: "200px" }}>
            <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
              {"There is no data"}
            </Box>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};
