import { BusinessIndustry } from "components/tabs/kyc/businessIndustry/BusinessIndustry";
import { BusinessKYCList } from "components/tabs/kyc/businessKYC/BusinessKYCList";
import { KYCDetail } from "components/tabs/kyc/manageKYC/KYCDetail";
import { KYC } from "./KYC";

const KycRoutes = [
  {
    path: "/kyc/merchant/:userId",
    element: <KYCDetail />,
  },
  {
    path: "/kyc",
    element: <KYC />,
    children: [
      {
        path: "businessKYC",
        element: <BusinessKYCList />,
      },
      {
        path: "businessIndustry",
        element: <BusinessIndustry />,
      },
    ],
  },
];

export default KycRoutes;
