import { Button, Paper } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { H4, H2, Span } from "components/typography/Typography";
import { useEffect, useState } from "react";
import BarChart from "../shared/BarChart";
import SimpleCard from "../shared/SimpleCard";
import * as _ from "lodash";
import { DateRangePicker } from "materialui-daterange-picker";
import { fetchYearMonthStats } from "services/https/transactionFetchers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { format, parseISO, sub } from "date-fns";

const getLast12Month = () => {
  let res = [];
  let d = new Date();
  d.setDate(1);
  Array.from(Array(11).keys()).map((i) => {
    res.push({
      month: d.toLocaleString("en-us", { month: "short" }),
      year: d.getFullYear(),
    });
    d.setMonth(d.getMonth() - 1);
  });
  return res.reverse();
};

const formLast12Data = (last12Month = [], monthYearStat = []) => {
  let dt = [];
  let temp = [];
  monthYearStat.forEach(({ year: ryear, month: rmonth, totalRevenue }) => {
    const idx = last12Month.findIndex(
      ({ year, month }) => year === ryear && month === rmonth
    );
    if(idx >= 0) {
      last12Month[idx].totalRevenue = totalRevenue;
    }
  });
  const res = last12Month.map((i) => ({
    ...i,
    totalRevenue: i.totalRevenue ? i.totalRevenue : 0,
  }));
  return last12Month;
};

export const IncomeChart = ({ data = [] }) => {
  let last12Month = formLast12Data(getLast12Month(), data).map(
    ({ month, totalRevenue }) => [month, totalRevenue]
  );
  last12Month.unshift(["Month", "Revenue"]);
  const theme = useTheme();
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const myDate = {
    endDate: `${format(defaultDate, "yyyy-MM-dd")}`,
    startDate: `${format(sub(defaultDate, { months: 11 }), "yyyy-MM-dd")}`,
  };
  const [date_, setDate] = useState(myDate);

  const [totalRevenueForDateRange, setTotalRevenueForDateRange] = useState();

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    const { startDate, endDate } = date_;
    if (!_.isEmpty(dateRange)) {
      const { endDate, startDate } = dateRange;
      setDate({ endDate, startDate });
    }
    fetchYearMonthStats({
      startDate,
      endDate,
    }).then((data) => {
      setTotalRevenueForDateRange(
        `NGN ${data?.totalRevenueForSelectedDateRange}` ?? "invalid date range"
      );
    });
  }, [dateRange.endDate, dateRange.startDate, date_.endDate, date_.startDate]);
  return (
    <SimpleCard>
      <Paper
        elevation={0}
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Paper elevation={1} sx={{ height: "fit-content", p: 2 }}>
          {
            <H4>{`Total income for : ${date_.startDate} to ${date_.endDate}`}</H4>
          }
          <H4>{totalRevenueForDateRange}</H4>
        </Paper>
        <Paper elevation={1} sx={{ p: 2, position: "absolute", right: "56px" }}>
          <Span sx={{ verticalAlign: "middle" }}>
            {"From : "}
            <Button
              sx={{ color: "#000" }}
              endIcon={<ArrowDropDownIcon />}
              onClick={() => setOpen(true)}
            >
              {date_.startDate}
            </Button>
            {"To : "}
            <Button
              sx={{ color: "#000" }}
              endIcon={<ArrowDropDownIcon />}
              onClick={() => setOpen(true)}
            >
              {date_.endDate}
            </Button>
          </Span>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={({ endDate, startDate }) =>
              setDateRange({
                endDate: format(endDate, "yyyy-MM-dd"),
                startDate: format(startDate, "yyyy-MM-dd"),
              })
            }
          />
        </Paper>
      </Paper>
      <BarChart
        height="350px"
        data={last12Month}
        color={[theme.palette.primary?.dark, theme.palette.primary?.light]}
      />
    </SimpleCard>
  );
};
