import React, { useRef } from "react";
import SearchIcon from "components/Icons/SearchIcon";
import "./styles.css";

const SearchInput = () => {
  const inputRef = useRef();

  return (
    <label className='recent-transactions__search-action'>
      <div className='recent-transactions__search-action__icon-container'>
        <SearchIcon color={"#c4c4c4"} size={17} />
      </div>
      <input
        className='recent-transactions__search-action__input'
        ref={inputRef}
        placeholder='Search Users by Name, Email or Date'
      />
    </label>
  );
};

export default SearchInput;
