import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  Paper,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { products } from "pages/tech/pages/pricings/data";
import { AdornedInput } from "pages/tech/pages/pricings/PricingList";
import { useEffect, useState } from "react";
import { getOneProduct, updateProduct } from "services/https/pricingFetcher";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { notifySuccess } from "redux/actions/AlertActions";

export const EditPricingDialog = ({ openDialog, setOpenDialog }) => {
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const destructureNeed = (data = {}) => {
    const {
      internationalDiscountRate,
      internationalProcessingFeeCappedAt,
      internationalRate,
      localDiscountRate,
      localProcessingFeeCappedAt,
      localRate,
      merchantProductPricingId,
      merchantId,
    } = data;
    setFormData({
      internationalDiscountRate,
      internationalProcessingFeeCappedAt,
      internationalRate,
      localDiscountRate,
      localProcessingFeeCappedAt,
      localRate,
      merchantProductPricingId,
      merchantId,
      productName,
    });
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });

  const { merchantProductPricingId, productName, merchantName } = payload || {};
  console.log(payload, "admin")

  useEffect(() => {
    getOneProduct({ productId: merchantProductPricingId }).then((data) => {
      destructureNeed(data);
    });
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-paper": {
          padding: "2em",
          minWidth: "fit-content",
        },
      }}
    >
      {formData ? (
        <DialogContent
          sx={{
            minWidth: "600px",
          }}
        >
          <List component="div" disablePadding>
            <Paper sx={{ p: 4 }}>
              <Formik
                initialValues={formData}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  updateProduct({...formData, ...values}, merchantProductPricingId, productName)
                    .then((data) => {
                      dispatch(
                        notifySuccess({
                          msg: `${productName} successfully updated for ${merchantName}`,
                        })
                      );
                      setOpenDialog(false);
                    })
                    .catch((err) => {
                      console.log("error re", err);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  errors,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography
                      variant={"h5"}
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "primary.main",
                      }}
                    >
                      {"Custom Pricing"}
                    </Typography>
                    <Box sx={{ border: "2px solid #fafafa", m: 2, p: 2 }}>
                      <Grid
                        container
                        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {products[productName]?.map(({ label, name }, idx) => {
                          return (
                            <Grid item xs={6} key={idx}>
                              {AdornedInput({
                                label,
                                name,
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                              })}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Button type={"submit"} variant={"contained"}>
                        {isSubmitting ? <CircularProgress /> : "Save Changes"}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Paper>
          </List>
        </DialogContent>
      ) : (
        <CircularProgress />
      )}
    </Dialog>
  );
};
