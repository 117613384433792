import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import "./styles.css";

const AccountNameModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='create-new-account-modal'>
      <div className='create-new-account__main-content'>
        <button className='create-new-account__cancel-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='create-new-account__body'>
          <h3 className='create-new-account__title'>{"Create New Account"}</h3>

          <div className='create-new-account__text-input-group'>
            <label className='create-new-account__text-input-label'>{"Account Name"}</label>
            <input
              className='create-new-account__text-input'
              value=''
              placeholder='Enter your preferred account name here'
            />
          </div>

          <button className='create-new-account__action-btn primary-action-btn'>
            {"Create Account"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountNameModal;
