import React, { cloneElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { initialVal, products, validationSchema } from "./data";
import { Span } from "components/typography/Typography";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import {
  getProductPricing,
  productPricingUpdate,
} from "services/https/pricingFetcher";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export const AdornedInput = ({
  label,
  name,
  handleChange,
  handleBlur,
  values,
  errors,
}) => {
  const theme = useTheme();
  return (
    <>
      <label>{label}</label>
      {label === "Processing Fees Capped at" ? (
        <InputBase
          fullWidth={true}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          type={"number"}
          startAdornment={
            <Span
              sx={{
                p: 2,
                width: "fit-content",
                background: "#e0e0e0",
                mr: 2,
              }}
            >
              {"NGN"}
            </Span>
          }
          sx={{
            border: "1px solid #c4c4c4",
            marginTop: 2,
            borderRadius: "4px",
          }}
        />
      ) : (
        <InputBase
          fullWidth={true}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          type={"number"}
          endAdornment={
            <Span
              sx={{
                p: 2,
                width: "fit-content",
                background: "#e0e0e0",
              }}
            >
              {"%"}
            </Span>
          }
          sx={{
            border: "1px solid #c4c4c4",
            marginTop: 2,
            borderRadius: "4px",
            pl: 2,
          }}
        />
      )}
      {errors && (
        <Span sx={{ color: `${theme.palette.primary.main}` }}>{}</Span>
      )}
    </>
  );
};

const ListRoot = styled("div")(({ theme }) => ({
  "&Mui-selected": {
    ":hover": {
      backgroundColor: "#9b2f02",
    },
  },
  label: {
    display: "block",
  },
  input: {},
}));

export const PricingList = ({ label, idx, productDesc }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [allProductPricing, setAllProductPricing] = useState();
  const [productPricing, setProductPricing] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const handleClick = (e, label) => {
    const product = allProductPricing?.find(
      ({ productName }) => productName === label
    );
     console.log(label, "fnnn")
    const {
      internationalDiscountRate,
      internationalProcessingFeeCappedAt,
      internationalRate,
      localDiscountRate,
      localProcessingFeeCappedAt,
      localRate,
      productPricingId,
    } = product;
    setProductPricing({
      internationalDiscountRate,
      internationalProcessingFeeCappedAt,
      internationalRate,
      localDiscountRate,
      localProcessingFeeCappedAt,
      localRate,
      productPricingId,
      id: productPricingId,
    });
    setOpen(!open);
  };

  const handleChangeCheck = (e) => {
    const checked = e.target.checked;
    if (checked === false) {
      dispatch(createDialog({ type: "selectCustomUser" }));
      setOpenDialog(true);
    }
  };
  useEffect(() => {
    getProductPricing()
      .then((data) => {
        setAllProductPricing(data);
      })
      .catch((err) => console.error(err));

    return () => {
      setAllProductPricing([]);
    };
  }, []);

  const {
    alerts: { alertType, msg, dialogType },
  } = useSelector((state) => {
    return state;
  });
  return (
    <>
      {_.isEmpty(allProductPricing) ? (
        <CircularProgress />
      ) : (
        <ListRoot>
          {Boolean(dialogType) && (
            <DialogFactory
              render={() => {
                return cloneElement(dialogOptions[dialogType], {
                  openDialog,
                  setOpenDialog,
                });
              }}
            />
          )}
          <ListItemButton
            onClick={(e) => handleClick(e, label)}
            sx={{
              bgcolor: `${open ? "#9b2f02" : "primary.dark"}`,
              my: 2,
              "&:hover": {
                backgroundColor: "#9b2f02",
              },
            }}
          >
            <ListItemText
              primary={label}
              sx={{
                color: "#fff",
                fontSize: "1.5em",
                fontWeight: "bold",
                px: 2,
              }}
              disableTypography={true}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {!_.isEmpty(allProductPricing) ? (
                <Paper sx={{ p: 4 }}>
                  <Formik
                    initialValues={{ ...initialVal, ...productPricing }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      productPricingUpdate(values, label);
                      setSubmitting(true);
                      navigate("/tech/pricings");
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Typography
                          variant={"h5"}
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "primary.main",
                          }}
                        >
                          {productDesc}
                        </Typography>
                        <Box sx={{ border: "2px solid #fafafa", m: 2, p: 2 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Select all users"
                          />
                          <Grid
                            container
                            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {products[label].map(({ label, name }) => {
                              return (
                                <Grid item xs={6}>
                                  {AdornedInput({
                                    label,
                                    name,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    errors,
                                  })}
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                          <Button
                            type={"submit"}
                            variant={"contained"}
                            endIcon={
                              isSubmitting && (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "#fff" }}
                                />
                              )
                            }
                          >
                            {"Save Changes"}
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Paper>
              ) : (
                <CircularProgress />
              )}
            </List>
          </Collapse>
        </ListRoot>
      )}
    </>
  );
};
