export const dataKeysAndRepr = [
  { objKey: "name", repr: "TYPE OF INDUSTRY" },
  { objKey: "updatedAt", repr: "DATE CREATED" },
  { objKey: "action", repr: "ACTION" },
];

export const TotalSummary = [
  {
    title: "Total Business Types",
    objKeyRepr: "totalBusinessType",
  },
];
