import React from "react";
import CancelIcon from "../../../../../components/Icons/CancelIcon";
import "./styles.css";

const BulkBonusModal = ({ show, onCloseBtnClick }) => {
  if (!show) return null;
  return (
    <div className='bulk-bonus-modal'>
      <div className='bulk-bonus-modal-main-content'>
        <div className='bulk-bonus-modal-title-container'>
          <h3>{"Send Referral Bonus"}</h3>
          <button onClick={onCloseBtnClick}>
            <CancelIcon />
          </button>
        </div>

        <p className='bulk-bonus-modal-description'>
          {"Kindly upload .xslx file that contain the bonus"}
        </p>

        <div className='bulk-bonus-input-group'>
          <label>{"Select File for Upload"}</label>
          <div className='bulk-bonus-file-input-container'>
            <p>{""}</p>
            <button>{"Browse"}</button>
            <input type='input' />
          </div>
        </div>

        <div className='bulk-bonus-save-btn-container'>
          <button>{"Save Change"}</button>
        </div>
      </div>
    </div>
  );
};

export default BulkBonusModal;
