import { useEffect, useState } from "react";
import { Grid, Switch, IconButton, Paper } from "@mui/material";
import { Box, styled, useTheme } from "@mui/system";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import CustomIcon from "assets/icons";
import { H3, Span } from "components/typography/Typography";
import { Item, StackContainer } from "./styles";
import {
  doughnutChartData,
  paymentData,
  revenueCardData,
} from "../shared/data";
import { IncomeChart } from "./IncomeChart";
import { MerchantOverview } from "./MerchantOverview";
import DoughnutChart from "../shared/Doughnut";
import SimpleCard from "../shared/SimpleCard";
import { getTransaction } from "services/https/transactionFetchers";

const DashboardRoot = styled(Box)(() => ({
  textAlign: "center",
  "& .bal": {
    fontWeight: "400",
    fontSize: "1em",
    color: "#000",
    lineHeight: "30px",
  },
}));

export const RevenueCard = ({ children, size = "200px", elevation = 4 }) => {
  const sx = {
    display: "flex",
    height: "170px",
    marginBlock: "24px",
    span: {
      display: "block",
    },
    "& .item_payment": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexWrap: "nowrap",
    },
  };
  return (
    <Paper sx={sx} elevation={elevation}>
      <IconButton aria-label="previous" sx={{ margin: "auto" }}>
        <ArrowCircleLeft />
      </IconButton>
      <Grid
        item
        sx={{
          overflowX: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: "background.default",
            display: "grid",
            gridAutoFlow: "column",
            gridTemplateColumns: `repeat(auto-fit, minmax(${size},1fr))`,
            gridAutoColumns: `minmax(${size}, 1fr)`,
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Grid>
      <IconButton aria-label="previous" sx={{ margin: "auto" }}>
        <ArrowCircleRight />
      </IconButton>
    </Paper>
  );
};
export const DashboardDefaultPage = () => {
  const theme = useTheme();
  const [showBalance, setShowBalance] = useState(false);
  const [stats, setStats] = useState();
  const handleChange = (e) => {
    const showValue = e.target.checked;
    setShowBalance(showValue);
  };
  const destructureNeed = (txData, users) => {
    if (txData) {
      const {
        revenueStats: { grossRevenue, netRevenue },
        settlementStats,
        successErrorStats,
        refusalErrorStats,
        paymentChannelStats: [card, payattitude, ussd, qr],
        yearMonthStats,
      } = txData;

      setStats({
        grossRevenue: `NGN ${grossRevenue}`,
        netRevenue: `NGN ${netRevenue}`,
        pendingSettlement: `NGN ${settlementStats?.nextSettlement || 0}`,
        users: users,
        yearMonthStats,
        successRate: [
          { value: successErrorStats && successErrorStats[1]?.count, name: "Successful" },
          { value: successErrorStats && successErrorStats[0]?.count, name: "Error occured" },
        ],
        refusalResponse: [
          {
            value: 1,
            name: "Bank Error",
          },
          {
            value: 1,
            name: "Customer Error",
          },
          {
            value: 1,
            name: "Fraud Error",
          },
          {
            value: refusalErrorStats && refusalErrorStats[0]?.count,
            name: "System Error",
          },
        ],
        paymentMethod: [
          {
            value: card?.count,
            name: "Card",
          },
          {
            value: payattitude?.count,
            name: "PayAttitude",
          },
          {
            value: ussd?.count,
            name: "USSD",
          },
          {
            value: qr?.count,
            name: "QR",
          },
        ],
      });
    }
  };
  useEffect(() => {
    getTransaction().then((data) => {
      const [txData, users] = data;
      if (txData || users) {
        destructureNeed(txData, users);
      }
    });
  }, []);
  return (
    <DashboardRoot>
      <H3>{"BUSINESS PERFORMANCE METRICS"}</H3>
      <StackContainer>
        <H3>{"Show Dashboard Values"}</H3>
        <Switch onChange={handleChange} defaultChecked checked={showBalance} />
      </StackContainer>
      <RevenueCard sx={{ marginBottom: 2 }}>
        {revenueCardData.map(({ name, title, objKeyRepr }, key) => (
          <Item key={`${name}-${key}`} elevation={0}>
            <CustomIcon name={name} size={"2em"} />
            <H3>{title}</H3>
            <Span className={"bal"}>
              {showBalance ? (stats && stats[objKeyRepr]) ?? "-" : "********"}
            </Span>
          </Item>
        ))}
      </RevenueCard>
      {false && (
        <RevenueCard size={"360px"} elevation={0}>
          {paymentData.map(({ title, amount }, key) => (
            <Item
              key={key}
              elevation={4}
              sx={{ height: "100%" }}
              className={"item_payment"}
            >
              <H3 sx={{ marginBottom: "4px" }}>{title}</H3>
              <Span className={"bal"}>{showBalance ? amount : "********"}</Span>
            </Item>
          ))}
        </RevenueCard>
      )}
      <Grid container>
        <Grid item md={12}>
          <IncomeChart data={stats && stats?.yearMonthStats} />
        </Grid>
        {false && (
          <Grid item xs={"auto"} md={4}>
            <MerchantOverview />
          </Grid>
        )}
      </Grid>
      <SimpleCard>
        <Grid container>
          {doughnutChartData.map(({ label, objKeyRepr, color }) => (
            <Grid item xs={12} md={4}>
              <Span
                sx={{
                  textAlign: "start",
                  display: "inline-block",
                  width: "100%",
                  pl: theme.spacing(8),
                }}
              >{`${label}`}</Span>
              <DoughnutChart
                height="300px"
                data={(stats && stats[objKeyRepr]) ?? ""}
                name={label}
                color={color}
              />
            </Grid>
          ))}
        </Grid>
      </SimpleCard>
    </DashboardRoot>
  );
};
