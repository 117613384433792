import React from "react";
import { useTheme } from "@mui/system";
import ReactEcharts from "echarts-for-react";

const BarChart = ({ height, color = [], data }) => {
  const theme = useTheme();

  const option = {
    grid: {
      top: "10%",
      bottom: "10%",
      right: "5%",
    },
    legend: {
      show: false,
    },
    borderColor: "red",
    color: ["transparent"],
    barGap: 0,
    barMaxWidth: "64px",
    tooltip: {},
    dataset: {
      source: data,
    },
    xAxis: {
      type: "category",
      axisLine: {
        show: true,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: theme.palette.text?.secondary,
        fontSize: 16,
        fontFamily: "lato",
      },
    },
    yAxis: {
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        // show: false
        lineStyle: {
          color: theme.palette.primary?.main,
          opacity: 0.15,
        },
      },
      axisLabel: {
        color: theme.palette.text?.secondary,
        fontSize: 13,
        fontFamily: "roboto",
      },
    },
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: "bar", itemStyle: { barBorderColor: "red" } }],
  };

  return (
    <ReactEcharts
      style={{ height: height, marginTop: "2em" }}
      option={{
        ...option,
        // color: [...color],
      }}
    />
  );
};

export default BarChart;
