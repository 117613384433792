import React from "react";
import { map, range } from "lodash";
import ChevronArrowLeftIcon from "components/Icons/ChevronArrowLeftIcon";
import ChevronArrowRightIcon from "components/Icons/ChevronArrowRightIcon";
import DropIcon from "components/Icons/DropIcon";

const ROWS_PER_PAGE = 10;
const AVAILABLE_PAGE_ROWS = [10, 25, 50, 100];

const Rules = ({ show }) => {
  if (!show) return null;
  return (
    <div className='fraud-management__main-content'>
      <div className='fraud-management__statistics fraud-management__space-around'>
        <div className='fraud-management__statistic-container fraud-management__dark-statistic-container'>
          <p className='fraud-management__statistic-text'>{"All Rules"}</p>
          <p className='fraud-management__statistic-total'>{"20"}</p>
        </div>

        <div className='fraud-management__statistic-container fraud-management__green-statistic-container'>
          <p className='fraud-management__statistic-text'>{"Active Rules"}</p>
          <p className='fraud-management__statistic-total'>{"14"}</p>
        </div>

        <div className='fraud-management__statistic-container fraud-management__red-statistic-container'>
          <p className='fraud-management__statistic-text'>{"Inactive Rules"}</p>
          <p className='fraud-management__statistic-total'>{"6"}</p>
        </div>
      </div>

      <div className='fraud-management__table-container'>
        <table className='fraud-management__table' cellspacing='0' cellpadding='0'>
          <tr className='fraud-management__table__head'>
            <th className='fraud-management__table__rule-cell'>{"Rules"}</th>
            <th className='fraud-management__table__action-cel'>{"Action"}</th>
            <th className='fraud-management__table__switch-cell'>{""}</th>
          </tr>
          {map(range(ROWS_PER_PAGE), (index) => (
            <tr className='fraud-management__table__row'>
              <td className='fraud-management__table__rule-cell'>
                <div>
                  {
                    "Multiple 3 times transactions from Same IP within one hour - Either with same email or different emails, Same device signature or different device signatures, same pan or different pan."
                  }
                </div>
              </td>

              <td className='fraud-management__table__action-cell'>
                <div>
                  {
                    "All details used are initially blocked for 1 hour (Email, device signature, Pan used, IP, phone number). After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review"
                  }
                </div>
              </td>

              <td className='fraud-management__table__switch-cell'>
                <div
                  className={
                    "fraud-management__switch-btn-container" +
                    (index % 2 ? " fraud-management__switch-btn-container-active" : "")
                  }
                >
                  <button></button>
                </div>
              </td>
            </tr>
          ))}
        </table>

        <div className='fraud-management__table__footer'>
          <div className='fraud-management__table__rows-per-page-container'>
            <p>{"Rows per page: " + 10}</p>
            <div className='fraud-management__rows-per-page-drop-icon'>
              <DropIcon />
            </div>

            <ul className='fraud-management__rows-per-page-dropdown'>
              {map(AVAILABLE_PAGE_ROWS, (row, index) => (
                <li className='fraud-management__rows-per-page-dropitem' key={index}>
                  <button>{row}</button>
                </li>
              ))}
            </ul>
          </div>

          <p>{"1 - 6 of 6"}</p>

          <div className='fraud-management__page-nav-container'>
            <button>
              <ChevronArrowLeftIcon />
            </button>
            <button>
              <ChevronArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
