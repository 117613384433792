import { StyledTableCell } from "./UserTableMenu";
import { Box, Stack, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { KYC_BUSINESS_INDUSTRY } from "utils/constant";
import {
  deleteBusinessIndustry,
  updateBusinessIndustry,
} from "services/https/updatedKYCService";

const KYCBusinessIndustryMenu = ({ setOpenDialog, row }) => {
  const dispatch = useDispatch();
  const handleOk = async (newName) => {
    try {
      await updateBusinessIndustry({ id: row.id, name: newName });
      return dispatch(
        notifySuccess({ msg: "Business Industry successfully updated" })
      );
    } catch (err) {
      return notifyFailure({ msg: "Business Industry update failed" });
    }
  };
  const handleClick = () => {
    setOpenDialog(true);
    const payload = {
      handleOk,
      row,
      title: "Edit Business Industry",
      type: "edit",
    };
    dispatch(createDialog({ type: KYC_BUSINESS_INDUSTRY, payload }));
  };

  const handleDeleteOk = () => {
    deleteBusinessIndustry({ businessIndustryId: row.id })
      .then((data) =>
        dispatch(
          notifySuccess({ msg: "Business Industry successfully deleted" })
        )
      )
      .catch((err) => notifyFailure({ msg: "Cannot delete business industry" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };
  const handleDelete = () => {
    setOpenDialog(true);
    const payload = {
      title: "Confirm Delete",
      subTitle: "Are you sure you want to delete this business industry",
      handleOk: handleDeleteOk,
    };
    dispatch(createDialog({ type: "confirmation", payload }));
  };
  return (
    <StyledTableCell align="right" sx={{ paddingRight: "2em" }}>
      <Box>
        <Stack direction={"row"} justifyContent={"end"} spacing={2}>
          <IconButton color="success" onClick={handleClick}>
            <Edit />
          </IconButton>

          <IconButton color="error" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Stack>
      </Box>
    </StyledTableCell>
  );
};

export default KYCBusinessIndustryMenu;
