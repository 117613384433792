import { differenceInSeconds } from "date-fns";
import _ from "lodash";
import moment from 'moment';

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function arrayToCsv(array = []) {
  if (!_.isEmpty(array)) {
    // Use first element to choose the keys and the order
    var keys = Object.keys(array[0]);

    // Build header
    var result = keys.join("\t") + "\n";

    // Add the rows
    array.forEach(function (obj) {
      result += keys.map((k) => obj[k]).join("\t") + "\r\n";
    });

    return result;
  }
}

export const exportToCSV = (data, filename, contentType) => {
  const content = arrayToCsv(data) ?? "";
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export function fuzzySearch(items, query) {
  var search = query.toLowerCase().split(" ");
  var ret = items.reduce((found, i) => {
    var matches = 0;
    search.forEach((s) => {
      var props = 0;
      for (var prop in i) {
        if (
          typeof i[prop] === "string" &&
          i[prop].toLowerCase().indexOf(s) > -1
        ) {
          props++;
        }
      }
      if (props >= 1) {
        matches++;
      }
    });
    if (matches === search.length) {
      found.push(i);
    }
    return found;
  }, []);
  return ret;
}

export const getLastSegment = (path) =>
  path.substring(path.lastIndexOf("/") + 1);

export const getQueryString = (page, size) => {
  let queryString = "";
  //no page and no size

  if (isNaN(page) && isNaN(size)) {
    return;
  } else if (!isNaN(page) && size) {
    queryString = `?page=${page}&size=${size}`;
  } else {
    if (page) {
      queryString = `?page=${page}`;
    }
    if (size) {
      queryString = `?size=${size}`;
    }
  }
  return queryString;
};

export const isValidUrl = (url) => {
  let isUrl;
  try {
    isUrl = new URL(url);
  } catch (_) {
    isUrl = false;
  }
  return Boolean(isUrl);
};

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const formatString = (str) => {
  return str.replaceAll('_', ' ');
}

export function compareStringsInArrays(a, b) {
  // Create a map of the strings in array b
  const bMap = new Map();
  for (const str of b) {
    bMap.set(str, true);
  }

  // Loop through the strings in array a and check if they are in the map
  for (const str of a) {
    if (bMap.has(str)) {
      return true;
    }
  }

  // If we've reached this point, no strings in array a are in array b
  return false;
}

export const formatDate = (d) => {
  if(typeof d === "object") {
     d[1] = Math.abs(d[1] -1)
  }
   return moment(d).format('dddd, DD MMM YYYY');
 }

export const userHasPermission = (userPermissions=[], permissions='') => {
  return userPermissions.includes(permissions);
}
