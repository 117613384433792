import { Menu, MenuItem } from "@mui/material";
export const InviteUserMenu = ({ anchorEl, handleClose, handleBtnClick }) => {
  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl?.el}
      open={Boolean(anchorEl?.el)}
      onClose={handleClose}
    >
      <MenuItem onClick={(e) => handleBtnClick(e, "inviteViaSocialMedia")}>
        {"Via Social Media"}
      </MenuItem>
      <MenuItem>{"Via SMS"}</MenuItem>
      <MenuItem>{"Via Email"}</MenuItem>
    </Menu>
  );
};
