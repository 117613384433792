import { useState } from "react";
import {
  KYC_BUSINESS_INDUSTRY,
  TEAM_TABLE_MENU,
  TX_ROUTING,
} from "utils/constant";
import AdminUserTableMenu from "./AdminUserTableMenu";
import BusinessKYCActionMenu from "./BusinessKYCMenu";
import KYCBusinessIndustryMenu from "./KYCBusinessIndustryMenu";
import MerchantPaymentLinkMenu from "./MerchantPaymentLinkMenu";
import PricingActionMenu from "./PricingActionMenu";
import TeamTableMenu from "./TeamTableMenu";
import TransactionRouteMenu from "./TransactionRouteMenu";
import TransactionTableMenu from "./TransactionTableMenu";
import UserTableMenu from "./UserTableMenu";
import { WayaPayTableMenu } from "./WayaPayTableMenu";

const TableMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {props.render({
        open,
        id,
        handleClose,
        anchorEl,
        setAnchorEl,
      })}
    </>
  );
};

export const menulist = {
  userMenu: <UserTableMenu />,
  adminUserMenu: <AdminUserTableMenu />,
  transactionTableMenu: <TransactionTableMenu />,
  wayaPayDisputeTableMenu: <WayaPayTableMenu />,
  pricingTableMenu: <PricingActionMenu />,
  merchantPaymentLinkMenu: <MerchantPaymentLinkMenu />,
  businessKYCMenu: <BusinessKYCActionMenu />,
  [TX_ROUTING]: <TransactionRouteMenu />,
  [KYC_BUSINESS_INDUSTRY]: <KYCBusinessIndustryMenu />,
  [TEAM_TABLE_MENU]: <TeamTableMenu />,
};
export default TableMenu;
