import React, { lazy } from "react";
import Loadable from "components/shared/Loadable";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(
  lazy(() => import("./forgotPassword/ForgotPassword"))
);
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));

const ChangePassword = Loadable(
  lazy(() => import("./changePassword/ChangePassword"))
);

const sessionRoutes = [
  {
    path: "/session/login",
    element: <JwtLogin />,
  },
  {
    path: "/session/reset-password",
    element: <ForgotPassword />,
  },
  {
    path: "/session/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/session/404",
    element: <NotFound />,
  },
];

export default sessionRoutes;
