import { Button, Paper, Stack } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SearchAction, clearSearch } from "redux/actions/SearchAction";
import { customSearch } from "utils/customSearch";
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { StyledBox } from "components/shared/filterSearchExport/transactionHeader/SettlementFSE";
import { getDisputeList } from "services/https/disputeService";

export const FilterForm = ({ close }) => {
  const [date_, setDate] = useState({ startDate: "", endDate: "" });
  const [open, setOpen] = useState(false);

  const [alldata, setAllData] = useState();

  const toggle = () => setOpen(!open);
  const [filterObj, setFilterObj] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getDisputeList({ size: 1000 }).then((data) => {
      setAllData(data?.content);
    });
  }, [alldata?.length]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let filterResult = [];
    const { startDate, endDate } = date_ || {};

    if (endDate && startDate) {
      const result = alldata.filter((d) => {
        let time = new Date(d.disputeInitiationDate).getTime();
        return new Date(startDate) < time && time < new Date(endDate);
      });
      filterResult = result;
    }

    const chainedFilters = Object.entries(filterObj).map(([key, value]) => ({
      [key]: value,
    }));
    if (_.isEmpty(chainedFilters)) {
      dispatch(SearchAction({ searchResult: filterResult, query: "" }));
    } else {
      const data = _.isEmpty(filterResult) ? alldata : filterResult;

      customSearch(data, { $and: chainedFilters }, Object.keys(filterObj)).then(
        (data) => {
          const searchResult = data.map(({ item }) => item);

          dispatch(SearchAction({ searchResult, query: chainedFilters }));
        }
      );
    }
  };
  const handleClear = (event) => {
    setFilterObj({});
    dispatch(clearSearch);
    close();
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFilterObj((filterObj) => ({ ...filterObj, [name]: value }));
  };

  return (
    <Paper sx={{ p: 2 }}>
      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <StyledBox>
          <label>{"Dispute Status"}</label>
          <select name={"disputeStatus"}>
            <option>{"Show All"}</option>
            <option value={"OPEN"}>{"OPEN"}</option>
            <option value={"CLOSED"}>{"CLOSED"}</option>
          </select>
        </StyledBox>
        <StyledBox>
          <label>{"Admin Status"}</label>
          <select name={"adminStatus"}>
            <option>{"Show All"}</option>
            <option value={"INITIATED"}>{"Initiated"}</option>
            <option value={"CUSTOMER_WON"}>{"Customer Won"}</option>
            <option value={"MERCHANT_WON"}>{"Merchant Won"}</option>
          </select>
        </StyledBox>
        <StyledBox>
          <label>{"Merchant Status"}</label>
          <select name={"merchantStatus"}>
            <option>{"Show All"}</option>
            <option value={"REJECTED"}>{"Rejected"}</option>
            <option value={"ACCEPTED"}>{"Accepted"}</option>
            <option value={"NO_RESPONSE"}>{"No Response"}</option>
          </select>
        </StyledBox>

        <StyledBox>
          <label>{"Resolution"}</label>
          <select name={"resolution"}>
            <option>{"Show All"}</option>
            <option value={"ChargeBack"}>{"Charge Back"}</option>
            <option value={"Fraud"}>{"Fraud"}</option>
          </select>
        </StyledBox>
        <StyledBox sx={{ mb: 1 }}>
          <label>{"Date Range"}</label>
          <Paper elevation={1} sx={{ p: 2, width: "fit-content" }}>
            <Stack direction={"row"} sx={{ verticalAlign: "middle" }}>
              {"From : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.startDate}
              </Button>
              {"To : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.endDate}
              </Button>
            </Stack>
            <DateRangePicker
              styles={{ marginBottom: "4px" }}
              open={open}
              toggle={toggle}
              onChange={({ startDate, endDate }) =>
                setDate({
                  startDate: format(startDate, "yyyy-MM-dd"),
                  endDate: format(endDate, "yyyy-MM-dd"),
                })
              }
            />
          </Paper>
        </StyledBox>

        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between", columnGap: 1 }}
        >
          <Button variant={"contained"} onClick={handleClear}>
            {"Clear Filter"}
          </Button>
          <Button variant={"contained"} type={"submit"}>
            {"Apply Filter"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
