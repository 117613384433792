import {
  Grid,
  Paper,
  Button,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
} from "@mui/material";

import { Span } from "components/typography/Typography";
import { listItemData } from "../data";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { StyledBox } from "./TransactionDetail";
import { useLocation } from "react-router-dom";
import { TextToLabel } from "utils/textToLabel";

export const DetailSideOne = () => {
  const {
    refNo,
    channel,
    fee,
    tranDate,
    customerName,
    customerEmail,
    status,
    amount,
  } = useLocation()?.state;
  const row = { refNo, channel, fee, tranDate, status };
  return (
    <Grid item xs={6}>
      <Paper sx={{ p: 4 }}>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <StyledBox sx={{ display: "block" }}>
            <Span>{"Amount"}</Span>
            <Typography variant="h5">{amount}</Typography>
          </StyledBox>
          {TextToLabel(status, {
            margin: "auto",
            marginRight: 0,
          })}
        </Stack>
        <List>
          {listItemData.map(({ label, objKeyRepr }) => (
            <>
              <ListItem sx={{ pt: 4 }}>
                <ListItemText>{label}</ListItemText>
                <ListItemText sx={{ textAlign: "end" }}>
                  {row[objKeyRepr]}
                </ListItemText>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
        <Paper
          sx={{ p: 3, my: 6, display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ display: "flex" }}>
            <Avatar />
            <Box sx={{ ml: 2 }}>
              <Span sx={{ display: "block" }}>{customerName}</Span>
              <Span>{customerEmail}</Span>
            </Box>
          </Box>
          <Button endIcon={<ArrowRightAltIcon />}>{"View Customer"}</Button>
        </Paper>
      </Paper>
    </Grid>
  );
};
