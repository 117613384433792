export const NOTIFY_SUCCESS = "NOTIFY_SUCCESS";
export const NOTIFY_DONE = "NOTIFY_DONE";

export const NOTIFY_FAIL = "NOTIFY_FAIL";
export const CREATE_DIALOG = "CREATE_DIALOG";

export const notifySuccess = (msg) => (dispatch) => {
  dispatch({ type: NOTIFY_SUCCESS, payload: msg });
  setTimeout(() => {
    dispatch({ type: NOTIFY_DONE });
  }, 6000);
};
export const notifyFailure = (msg) => (dispatch) => {
  dispatch({ type: NOTIFY_FAIL, payload: msg });
  setTimeout(() => {
    dispatch({ type: NOTIFY_DONE });
  }, 6000);
};
export const createDialog = (type) => (dispatch) => {
  dispatch({
    type: CREATE_DIALOG,
    payload: type,
  });
};
