import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { Button, Grid, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DetailSideOne } from "./DetailSideOne";
import { DetailSideTwo } from "./DetailSideTwo";

export const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const TxTimeline = () => {
  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          {"09:30"}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={"primary"} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{"Set payment method to: bank"}</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          {"10:00"}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{"Set payment method to: bank"}</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          {"12:00"}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{"Set payment method to: bank"}</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          {"9:00"}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{"Set payment method to: bank"}</TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export const TransactionDetail = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>

      <Grid container spacing={3}>
        <DetailSideOne />
        <DetailSideTwo />
      </Grid>
    </>
  );
};
