export const revenueCardData = [
	{
		name: 'creditcard',
		title: 'Total Revenue Acquired',
		objKeyRepr: 'grossRevenue'
	},
	{
		name: 'creditcard',
		title: 'Total Revenue Settled',
		objKeyRepr: 'netRevenue'
	},
	{
		name: 'airline',
		title: 'Total Pending Settlements',
		objKeyRepr: 'pendingSettlement'
	},
	// {
	//   name: "failed",
	//   title: "Total Failed Settlements",
	//   amount: "234440",
	// },
	{
		name: 'return',
		title: 'Total Revenue Refunded',
		amount: '234440'
	},
	/*  {
    name: "coins",
    title: "Total Commision Disbursed",
    amount: "234440",
  }, */
	{
		name: 'alluser',
		title: 'Total onboarded merchants',
		objKeyRepr: 'users'
	}
];

export const paymentData = [
	{
		title: 'Total transaction via Unified payment',
		amount: 'NGN 345,000,000'
	},
	{
		title: 'Total Pending from Unified payment',
		amount: 'NGN 345,000,000'
	},
	{
		title: 'Total transaction via Mastercard',
		amount: 'NGN 345,000,000'
	},
	{
		title: 'Total Pending from Matsercard',
		amount: 'NGN 345,000,000'
	},
	{
		title: 'Total Pending settlement from Matsercard',
		amount: 'NGN 345,000,000'
	}
];

export const doughnutChartData = [
	{
		label: 'Success Rate',
		objKeyRepr: 'successRate',
		color: [ '#27AE60', '#DDD7D7' ]
	},
	{
		label: ' Refusal Reasons',
		objKeyRepr: 'refusalResponse',
		color: [ '#FEE664', '#F3CE00', '#F63002', '#FF4B01' ]
	},
	{
		label: 'Payment Method',
		objKeyRepr: 'paymentMethod',
		color: [ '#05B862', '#4A4AFF', '#DF6817', '#F3CE00' ]
	}
];
