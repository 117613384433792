import { useReducer, createContext } from "react";

export const TabContext = createContext({
  activeTab: "",
  setActiveTab: () => ({}),
});

export const TabsProvider = ({ children, value }) => {
  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};
