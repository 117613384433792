import React from "react";
import { map } from "lodash";

const dropItems = [
  ["Guaranty Trust Bank", "Wayagram Official", "6125724089"],
  ["Wema Bank", "Wayagram Official", "6125724089"],
  ["Zenith Bank", "Wayagram Official", "6125724089"],
  ["First Bank", "Wayagram Official", "6125724089"],
];

const ToAccountDropdown = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <ul className='rename-account-modal-dropdown'>
      {map(dropItems, ([bank, type, no], index) => (
        <li className='rename-account-modal-dropitem' key={index}>
          <button className='to-account-dropdown-btn'>
            <p>{bank}</p>
            <p>{type}</p>
            <p>{no}</p>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ToAccountDropdown;
