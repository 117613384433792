import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { Span } from "components/typography/Typography";
import _, { capitalize } from "lodash";
import { txRoutingLabel } from "pages/tech/pages/transactionRouting/data";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import {
  getAllProcessors,
  getProcessorList,
  updateProcessor,
  updateProcessorList,
} from "services/https/techService";
import { StyledBox } from "../shared/sharedStyles";

const ChangeProcessor = ({ openDialog, setOpenDialog }) => {
  const {
    alerts: {
      payload: {
        id,
        type, 
        processor,
        merchantId,
        precedence,
        routingType,
        accountAcquiring,
        cardAcquiring,
        cardType,
        code,
        description,
        liveUrl,
        payattitudeAcquiring,
        testUrl,
        ussdAcquiring,
        active
      },
    },
  } = useSelector((state) => state);

  const [processorOption, setProcessorOption] = useState([]);
  const [newProcessor, setNewProcessor] = useState(processor);
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postData, setPostData] = useState({
    accountAcquiring,
    cardAcquiring,
    cardType,
    code,
    description,
    isActive: active,
    liveUrl,
    merchantId: merchantId==='--'?'':merchantId,
    name: processor,
    payattitudeAcquiring,
    precedence,
    routingType,
    testUrl,
    ussdAcquiring,    
  })  
  const handleClick = (e) => {
    console.log('postData', postData);
    console.log(postData)
    if(!postData?.code || !postData?.name || !postData?.precedence || !postData?.routingType) {
        dispatch(
            notifyFailure({ msg: "Fields are empty" })
          );
        return
    }

    Object.keys(postData).forEach(k => postData[k] = postData[k] === '' ? null : postData[k])
    
    //console.log(found, "fd");
     updateProcessor({id, data: postData})      
      .then((res) => {
        console.log(res);
        dispatch(
            notifySuccess({ msg: "Successful" })
          );
        setOpenDialog(false);
        setLoading(false); 
      }).catch((error) => {
        setLoading(false); 
        console.log(error)
      });
  };
  const handleChange = (e) => {
    setNewProcessor(e.target.value);
  };
  useEffect(() => {
    if (_.isEmpty(processorOption)) {
      getProcessorList().then((data) => {
        const list = data.map(({ code, name }) => {
          return { name, code };
        });
        setProcessorOption(list);
      });
    }

    if (!_.isEmpty(processorOption)) {
      const optionArrCopy = [...processorOption];
      const idx = processorOption.findIndex(({ name }) => {
        return name === processor;
      });
      console.log(idx, processorOption);
      optionArrCopy.splice(idx, 1);
      setOption(optionArrCopy);
    }
  }, [processor, processorOption]);
  const handleClose = () => {
    setNewProcessor();
    setOpenDialog(false);
  };
  return (
    <Box sx={{ width: "100%", maxWidth: 360 }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>{`Update Processor for ${processor}`}</DialogTitle>
        <DialogContent>
        <StyledBox>
            <label>Card Acquiring</label>
            <select 
                defaultValue={true} 
                value={postData?.cardAcquiring}
                onChange={(e)=>setPostData({...postData, cardAcquiring: e.target.value})}
            >
                <option value={true}> True </option>
                <option value={false}> False </option>
            </select>
          </StyledBox>
          <StyledBox>
            <label>Account Acquiring</label>
            <select 
                defaultValue={true} 
                value={postData?.accountAcquiring}
                onChange={(e)=>setPostData({...postData, accountAcquiring: e.target.value})}
            >
                <option value={true}> True </option>
                <option value={false}> False </option>
            </select>
          </StyledBox>
          <StyledBox>
            <label>Card Type</label>
            <select defaultValue="MASTERCARD">
                <option value="MASTERCARD"> MASTERCARD </option>
                <option value="VISA"> VISA </option>
            </select>
          </StyledBox>
          <StyledBox>
            <label>{"Code"}</label>
            <InputBase
              fullWidth
              placeholder="Code"
              value={postData?.code}
              onChange={(e)=>setPostData({...postData, code: e.target.value})}              
            />
          </StyledBox>
          <StyledBox>
            <label>{"Description"}</label>
            <InputBase
              fullWidth
              placeholder="Description"
              value={postData?.description}
              onChange={(e)=>setPostData({...postData, description: e.target.value})}              
            />
          </StyledBox>
          <StyledBox>
            <label>Active</label>
            <select 
                value={postData?.isActive}
                onChange={(e)=>setPostData({...postData, isActive: e.target.value})}
            >
                <option value={true}> True </option>
                <option value={false}> False </option>
            </select>
          </StyledBox>
          <StyledBox>
            <label>{"Live Url"}</label>
            <InputBase
              fullWidth
              placeholder="Live Url"
              value={postData?.liveUrl}
              onChange={(e)=>setPostData({...postData, liveUrl: e.target.value ?? null })}              
            />
          </StyledBox> <StyledBox>
            <label>{"Name"}</label>
            <InputBase
              fullWidth
              placeholder="Name"
              value={postData?.name}
              onChange={(e)=>setPostData({...postData, name: e.target.value })}              
            />
          </StyledBox>
          <StyledBox>
            <label>{"Merchant Id"}</label>
            <InputBase
              fullWidth
              placeholder="merchantId"
              value={postData?.merchantId}
              onChange={(e)=>setPostData({...postData, merchantId: e.target.value ?? null})}              
            />
          </StyledBox>
          <StyledBox>
            <label>Pay Attitude Acquiring</label>
            <select 
                defaultValue={true} 
                value={postData?.payattitudeAcquiring}
                onChange={(e)=>setPostData({...postData, payattitudeAcquiring: e.target.value })}
            >
                <option value={true}> True </option>
                <option value={false}> False </option>
            </select>
          </StyledBox>
          <StyledBox>
            <label>{"Precedence"}</label>
            <InputBase
              fullWidth
              placeholder="precedence"
              value={postData?.precedence}
              onChange={(e)=>setPostData({...postData, precedence: e.target.value})}              
            />
          </StyledBox>
          <StyledBox>
            <label>Routing Type</label>
            <select 
                defaultValue={"ALL"} 
                value={postData?.routingType}
                onChange={(e)=>setPostData({...postData, routingType: e.target.value})}
            >
                <option value='ALL'> All </option>
                <option value='ONE'> One </option>

            </select>
          </StyledBox>
          <StyledBox>
            <label>{"Test Url"}</label>
            <InputBase
              fullWidth
              placeholder="Test url"
              value={postData?.testUrl}
              onChange={(e)=>setPostData({...postData, testUrl: e.target.value})}              
            />
          </StyledBox>
          <StyledBox>
            <label>USSD Acquiring</label>
            <select 
                defaultValue={true}
                value={postData?.ussdAcquiring}
                onChange={(e)=>setPostData({...postData, ussdAcquiring: e.target.value})}
            >
                <option value={true}> True </option>
                <option value={false}> False </option>            
            </select>
          </StyledBox>

         
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"}
            fullWidth
            sx={{ mx: 2 }}
            onClick={handleClick}
          >
            <Span>{"Save Changes"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChangeProcessor;
