import React from "react";

const FilterIcon = ({ size = 20, color = "#FF4B01" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.77202 9.8718C7.09278 10.3266 6.99689 9.94762 6.99689 19.0123C6.99689 19.8235 7.94149 20.2877 8.60724 19.8025C11.4521 17.7011 12.0205 17.5191 12.0205 16.5362C12.0205 9.93055 11.9419 10.302 12.2454 9.8718L16.8651 3.71094H2.15234L6.77202 9.8718Z'
        fill={color}
      />
      <path
        d='M18.61 0.502344C18.4449 0.192578 18.1212 0 17.7648 0H1.2523C0.48261 0 0.0297246 0.850664 0.471247 1.46875C0.474875 1.47469 0.42113 1.40262 1.27344 2.53906H17.7436C18.4699 1.57059 18.9554 1.15215 18.61 0.502344Z'
        fill={color}
      />
    </svg>
  );
};

export default FilterIcon;
