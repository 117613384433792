import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import "./styles.css";

const AddBankAccountModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='rename-account-modal'>
      <div className='rename-account-modal__main-content'>
        <button className='rename-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='rename-account-modal__body'>
          <div className='rename-account-modal__title-container'>
            <h3 className='rename-account-modal__title'>{"Add Bank Account"}</h3>
          </div>

          <div className='rename___account__form'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Select in Bank"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Account Number"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Account Name"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"BVN"}</label>
              <input
                className='rename-account-modal-input rename-account-modal-input-active'
                value=''
              />
            </div>
          </div>

          <div className='rename-account-modal__action-btns-container'>
            <button className='rename-account-modal__btn rename-account-modal__orange-btn'>
              {"Add Account"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBankAccountModal;
