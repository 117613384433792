export const topBarHeight = 96;
export const sideNavWidth = 310;
export const navbarHeight = 60;
export const sidenavCompactWidth = 100;
export const containedLayoutWidth = 1200;

export const ADMIN__ROLE = "ROLE_ADMIN_APP";
export const ADMIN_ROLE = "ROLE_APP_ADMIN";
export const USER_TABLE_MENU = "userMenu";
export const ADMIN_USER_TABLE_MENU = "adminUserMenu";
export const DEACTIVATE_USER = "Deactivate User";
export const ACTIVATE_USER = "Activate User";
export const MANAGE_TEAM_MEMBERS = "Manage Team Members";
export const MANAGE_KYC = "Manage KYC";
export const PERFORM_TRANSACTION = "Perform Transactions";
export const MANAGE_USER_PROFILE = "Manage Profile";
export const ACTIVITY_LOG = "Activitiy Log";
export const TRANSACTION_TABLE_MENU = "transactionTableMenu";
export const TEAM_TABLE_MENU = "teamTableMenu";
export const TX_ROUTING = "txRouting";
export const CREATE_PROCESS = "createProcess";
export const WAYAPAY_DISPUTE_TABLE_MENU = "wayaPayDisputeTableMenu";
export const PRICING_TABLE_MENU = "pricingTableMenu";
export const MERCHANT_PAYMENTLINK_MENU = "merchantPaymentLinkMenu";
export const SUBSCRIPTION_MENU = "subscriptionMenu";
export const PLAN_MENU = "planMenu";
export const CREATE_CUSTOMER = "createCutomer";
export const KYC_TABLE_MENU = "businessKYCMenu";
export const PREVIEW_IMAGE = "previewImage";
export const CONFIRMATION = "confirmation";
export const KYC_CONFIRMATION = "KYC_ConfirmationDialog";
export const KYC_SUCCESSFUL = "KYC_Successful";
export const KYC_BUSINESS_INDUSTRY = "KYC_BusinessIndustry";
export const SETTLE_MANUALLY = "settleManually";
export const CREATE_CUSTOM_ROLE = "createCustomRole";
export const ADMIN_USER = "Admin User";
export const ADMIN_MODAL = "AdminModal";
export const EDIT_CUSTOM_ROLE = "editCustomRole";
