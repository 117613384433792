import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DeleteIcon from "components/Icons/DeleteIcon";
import "./styles.css";

const DeleteAccountPasswordModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='delete-account-modal'>
      <div className='delete-account-password-modal__main-content'>
        <button className='delete-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='delete-account-modal__body'>
          <div className='delete-account-password-modal__title-container'>
            <DeleteIcon color='#FF6700' />
            <h3 className='delete-account-password-modal__title'>{"Delete Account"}</h3>
          </div>

          <p className='delete-account-password-modal__text'>{"To proceed, enter your Password"}</p>

          <div className='delete___account__form'>
            <div className='delete-account-modal-input-group'>
              <label className='delete-account-modal-label'>{"Email"}</label>
              <input className='delete-account-modal-input' value='Templar' />
            </div>

            <div className='delete-account-modal-input-group'>
              <label className='delete-account-modal-label'>{"Phone Number"}</label>
              <input className='delete-account-modal-input' value='philiptemplar@gmail.com' />
            </div>
            <div className='delete-account-modal-radio-input-group-container'>
              <div className='delete-account-modal-radio-input-group'>
                <div className='delete-account-modal-radio-circle delete-account-modal-radio-circle-active '></div>
                <div className='delete-account-modal-radio-text more-settings-radio-text-active'>
                  {"OTP Via SMS"}
                </div>
              </div>

              <div className='delete-account-modal-radio-input-group'>
                <div className='delete-account-modal-radio-circle'></div>
                <div className='delete-account-modal-radio-text'>{"OTP Via Email"}</div>
              </div>
            </div>
          </div>

          <div className='delete-account-modal__action-btns-container'>
            <button className='delete-account-modal__btn'>{"No, Cancel"}</button>
            <button className='delete-account-modal__btn delete-account-modal__orange-btn'>
              {"Yes, Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPasswordModal;
