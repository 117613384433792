import React from "react";

const DeleteIcon = ({ size: width = 10, color = "#FF0000" }) => {
  const height = (width / 10) * 14;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.9375 1.5H6.875V1.125C6.875 0.503672 6.37133 0 5.75 0H4.25C3.62867 0 3.125 0.503672 3.125 1.125V1.5H1.0625C0.544742 1.5 0.125 1.91974 0.125 2.4375V3.1875C0.125 3.39462 0.292883 3.5625 0.5 3.5625H9.5C9.70712 3.5625 9.875 3.39462 9.875 3.1875V2.4375C9.875 1.91974 9.45526 1.5 8.9375 1.5ZM3.875 1.125C3.875 0.918281 4.04328 0.75 4.25 0.75H5.75C5.95672 0.75 6.125 0.918281 6.125 1.125V1.5H3.875V1.125Z'
        fill={color}
      />
      <path
        d='M0.835933 4.3125C0.769019 4.3125 0.715698 4.36842 0.718886 4.43527L1.02826 10.9284C1.05685 11.5294 1.55045 12 2.15185 12H7.8467C8.4481 12 8.9417 11.5294 8.97029 10.9284L9.27967 4.43527C9.28286 4.36842 9.22953 4.3125 9.16262 4.3125H0.835933ZM6.49928 5.25C6.49928 5.04281 6.66709 4.875 6.87428 4.875C7.08146 4.875 7.24928 5.04281 7.24928 5.25V10.125C7.24928 10.3322 7.08146 10.5 6.87428 10.5C6.66709 10.5 6.49928 10.3322 6.49928 10.125V5.25ZM4.62428 5.25C4.62428 5.04281 4.79209 4.875 4.99928 4.875C5.20646 4.875 5.37428 5.04281 5.37428 5.25V10.125C5.37428 10.3322 5.20646 10.5 4.99928 10.5C4.79209 10.5 4.62428 10.3322 4.62428 10.125V5.25ZM2.74928 5.25C2.74928 5.04281 2.91709 4.875 3.12428 4.875C3.33146 4.875 3.49928 5.04281 3.49928 5.25V10.125C3.49928 10.3322 3.33146 10.5 3.12428 10.5C2.91709 10.5 2.74928 10.3322 2.74928 10.125V5.25Z'
        fill={color}
      />
    </svg>
  );
};

export default DeleteIcon;
