import React from "react";

const TopActionBtn = ({ className, onClick, content }) => {
  return (
    <button className={"referrals__top-actions__btn " + className} onClick={onClick}>
      {content}
    </button>
  );
};

export default TopActionBtn;
