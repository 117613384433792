import layout1Settings from "./Layout1/Layout1Settings";
import { themes } from "../appTheme/initThemes";

// UPDATE BELOW CODE
export const AppLayoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  activeTheme: "waya",
  perfectScrollbar: false,
  currentPage: "",
  themes: themes,
  layoutSettings: layout1Settings,
  footer: {
    show: true,
    fixed: false,
    theme: "waya",
  },
};
