import React, { useState } from "react";
import Rules from "./Rules";
import FilterIcon from "components/Icons/FilterIcon";
import SearchIcon from "components/Icons/SearchIcon";
import FilterDropdown from "./FilterDropdown";
import "./styles.css";
import Events from "./Events";

const FraudManagement = () => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isRulesContent, setIsRulesContent] = useState(true);
  return (
    <div className='fraud-management fraud-management__container'>
      <div className='fraud-management__nav-btns'>
        <button
          className='fraud-management__nav-btn fraud-management__nav-btn-active'
          onClick={() => setIsRulesContent(true)}
        >
          {"Web Acquiring Fraud Rules"}
        </button>
        <button className='fraud-management__nav-btn' onClick={() => setIsRulesContent(false)}>
          {"Web Acquiring Fraud Events"}
        </button>
      </div>

      <div className='fraud-management__filtering-container'>
        <div className='fraud-management__search-container'>
          <button className='fraud-management__search-btn'>
            <SearchIcon />
          </button>

          <input
            className='fraud-management__search-input'
            value={""}
            placeholder='Search rules by status, keywords...'
          />
        </div>

        <div className='fraud-management__filter-btn-container'>
          <button
            className='fraud-management__filter-btn'
            onClick={() => setShowFilterDropdown((s) => !s)}
          >
            <FilterIcon />
          </button>
          <FilterDropdown
            show={showFilterDropdown}
            handleClose={() => setShowFilterDropdown(false)}
          />
        </div>
      </div>
      <Rules show={false} />
      <Events show={true} />
    </div>
  );
};

export default FraudManagement;
