import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  useTheme,
  CircularProgress,
  InputBase,
  InputLabel,
  FormHelperText,
  useFormControl,
  FormControl,
  IconButton,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { H4, Span } from "components/typography/Typography";
import { StyledBox } from "../shared/sharedStyles";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

function MyFormHelperText({ businessType }) {
  const theme = useTheme();
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (!focused && _.isEmpty(businessType)) {
      return "Add business type";
    }
  }, [focused]);

  return (
    <FormHelperText sx={{ color: theme.palette.primary.error }}>
      {helperText}
    </FormHelperText>
  );
}

export const BusinessIndustryKYC = ({ openDialog, setOpenDialog, user }) => {
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  const { title, subTitle, handleOk, row, type } = payload || {};
  const [loader, setLoader] = useState(false);
  const [businessType, setBusinessType] = useState();
  const handleInputChange = (e) => {
    const { value } = e.target;
    setBusinessType(value);
  };
  const handleCancel = () => {
    setBusinessType("");
    setOpenDialog(false);
  };
  const handleProceedClick = (e, businessType) => {
    setLoader(true);
    handleOk(businessType).finally(() => {
      setLoader(false);
      handleCancel();
    });
  };
  useEffect(() => {
    type && type === "edit" && setBusinessType(row?.name ?? "");
    type && type === "new" && setBusinessType("");
    return () => {
      setBusinessType("");
    };
  }, [type, row?.name]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
        onClose={handleCancel}
      >
        <DialogTitle>
          <Stack direction={"row"} justifyContent={"end"}>
            <IconButton onClick={handleCancel}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <H4 sx={{ textAlign: "center" }}>{title}</H4>
          <Typography sx={{ textAlign: "center" }}>{subTitle}</Typography>
          <StyledBox>
            <InputLabel>{"Business Type"}</InputLabel>
            <FormControl fullWidth>
              <InputBase
                rows={2}
                fullWidth
                required
                onChange={handleInputChange}
                value={businessType}
              />
              <MyFormHelperText businessType={businessType} />
            </FormControl>
          </StyledBox>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f2f2f2", justifyContent: "center" }}
        >
          <Button
            onClick={(e) => handleProceedClick(e, businessType)}
            sx={{ display: "flex", alignItems: "center" }}
            disabled={_.isEmpty(businessType)}
            variant={"contained"}
          >
            <Span>{"Proceed"}</Span>
            {loader && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
