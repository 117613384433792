export const SEARCH = "SEARCH";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const SearchAction =
  ({ searchResult, query }) =>
  (dispatch) => {
    dispatch({
      type: SEARCH,
      payload: {
        searchResult,
        query,
      },
    });
  };

export const clearSearch = (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH,
  });
};
