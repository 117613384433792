import DropIcon from "components/Icons/DropIcon";
import SelectDropdown from "../SelectDropdown";
import React, { useState, useContext } from "react";
import { map, range } from "lodash";
import ChevronArrowLeftIcon from "components/Icons/ChevronArrowLeftIcon";
import ChevronArrowRightIcon from "components/Icons/ChevronArrowRightIcon";
import DeleteIcon from "components/Icons/DeleteIcon";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const ROWS_PER_PAGE = 10;
const AVAILABLE_PAGE_ROWS = [10, 25, 50, 100];


const Rules = ({ show }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'VIEW_FRAUD_MANAGEMENT')
  if (!show) return null;
  return (
    <div className='fraud-management__main-content'>
      <div className='fraud-management__statistics fraud-management__flex-start'>
        <div className='fraud-management__statistic-container fraud-management__dark-statistic-container'>
          <p className='fraud-management__statistic-text'>{"All Fraud Events"}</p>
          <p className='fraud-management__statistic-total'>{"200"}</p>
        </div>
      </div>

      <div className='fraud-management__filter-input-group'>
        <div className='fraud-management__filter-dropdown__input-group'>
          <label className='fraud-management__filter-dropdown__label'>{"Customer Type"}</label>

          <div className='fraud-management__filter-dropdown__select-dropdown-container'>
            <p>{"Active"}</p>
            <button onClick={() => setShowDropdown((s) => !s)}>
              <DropIcon color='#666666' />
            </button>
            <SelectDropdown
              show={showDropdown}
              onSelect={() => {}}
              dropdownItems={["Customer", "Merchant", "Admin"]}
              getTextFromDropdownItem={(text) => text}
            />
          </div>
        </div>

        <div className='fraud-management__filter-dropdown__input-group'>
          <label className='fraud-management__filter-dropdown__label'>{"Date"}</label>
          <input className='fraud-management__filter-dropdown__input-date' type='date' />
        </div>
      </div>

      <div className='fraud-management__table-container'>
        <table className='fraud-management__table' cellspacing='0' cellpadding='0'>
          <tr className='fraud-management__table__head'>
            <th className='fraud-management__events-table__name-cell'>{"Customer"}</th>
            <th className='fraud-management__events-table__ip-cell'>{"IP Address"}</th>
            <th className='fraud-management__events-table__scenario-cell'>{"Scenario"}</th>
            <th className='fraud-management__events-table__action-performed-cell'>
              {"Action Performed"}
            </th>
            <th className='fraud-management__events-table__date-cell'>{"Date"}</th>
            <th>{""}</th>
          </tr>
          {map(range(ROWS_PER_PAGE), (index) => (
            <tr className='fraud-management__table__row'>
              <td className='fraud-management__events-table__name-cell'>
                <div>{"Philip Templar"}</div>
              </td>

              <td className='fraud-management__events-table__ip-cell'>
                <div>{"192.158. 1.38"}</div>
              </td>

              <td className='fraud-management__events-table__scenario-cell'>
                <div>
                  {
                    "Multiple 3 times transactions from Same IP within one hour - Either with same email or different emails, Same device signature or different device signatures, same pan or different pan."
                  }
                </div>
              </td>
              <td className='fraud-management__events-table__action-performed-cell'>
                <div>
                  {
                    "If there is a balance of funds in the wallet, upon user’s request to Customer Service team, funds will be made available for pick up at agent locations."
                  }
                </div>
              </td>
              <td className='fraud-management__events-table__date-cell'>
                <div>{"Feb, 9th 2021"}</div>
                <div>{"08:57:01 PM"}</div>
              </td>

              <td>
                <button
                style={{display: showManaging ? 'flex' : 'none' }} >
                  <DeleteIcon />
                  
                </button>
              </td>
            </tr>
          ))}
        </table>

        <div className='fraud-management__table__footer'>
          <div className='fraud-management__table__rows-per-page-container'>
            <p>{"Rows per page: " + 10}</p>
            <div className='fraud-management__rows-per-page-drop-icon'>
              <DropIcon />
            </div>

            <ul className='fraud-management__rows-per-page-dropdown'>
              {map(AVAILABLE_PAGE_ROWS, (row, index) => (
                <li className='fraud-management__rows-per-page-dropitem' key={index}>
                  <button>{row}</button>
                </li>
              ))}
            </ul>
          </div>

          <p>{"1 - 6 of 6"}</p>

          <div className='fraud-management__page-nav-container'>
            <button>
              <ChevronArrowLeftIcon />
            </button>
            <button>
              <ChevronArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
