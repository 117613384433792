import * as yup from "yup";

export const TotalSummary = [
  {
    title: "Total users with default pricing",
    total: 0,
    key: "DEFAULT",
  },
  {
    title: "Total users with custom pricing",
    total: 0,
    key: "CUSTOM",
  },
];

export const initialVal = {
  internationalDiscountRate: 0,
  internationalProcessingFeeCappedAt: 0,
  internationalRate: 0,
  localDiscountRate: 0,
  localProcessingFeeCappedAt: 0,
  localRate: 0,
  productPricingId: "",
};

export const products = {
  CARD: [
    {
      label: "Local Transaction Rate",
      name: "localRate",
    },
    {
      label: "International Transactional Rate",
      name: "internationalRate",
    },
    {
      label: "Discount",
      name: "localDiscountRate",
    },
    {
      label: "Discount",
      name: "internationalDiscountRate",
    },
    {
      label: "Processing Fees Capped at",
      name: "localProcessingFeeCappedAt",
    },
    {
      label: "Processing Fees Capped at",
      name: "internationalProcessingFeeCappedAt",
    },
  ],
  USSD: [
    {
      label: "Local Transaction Rate",
      name: "localRate",
    },
    {
      label: "Discount",
      name: "localDiscountRate",
    },
    {
      label: "Processing Fees Capped at",
      name: "localProcessingFeeCappedAt",
    },
  ],

  BANK: [
    {
      label: "Local Transaction Rate",
      name: "localRate",
    },
    {
      label: "Discount",
      name: "localDiscountRate",
    },
    {
      label: "Processing Fees Capped at",
      name: "localProcessingFeeCappedAt",
    },
  ],
  WALLET: [
    {
      label: "Local Transaction Rate",
      name: "localRate",
    },
    {
      label: "Discount",
      name: "localDiscountRate",
    },
    {
      label: "Processing Fees Capped at",
      name: "localProcessingFeeCappedAt",
    },
    {
      label: "international Discount Rate",
      name: "internationalDiscountRate",
    },
    {
      label: "international Processing Fee Capped At",
      name: "internationalProcessingFeeCappedAt",
    },
    {
      label: "international Rate",
      name: "internationalRate",
    },
  ],
  PAYATTITUDE: [
    {
      label: "Local Transaction Rate",
      name: "localRate",
    },
    {
      label: "Discount",
      name: "localDiscountRate",
    },
    {
      label: "Processing Fees Capped at",
      name: "localProcessingFeeCappedAt",
    },
  ],
};

export const dataKeysAndRepr = [
  { objKey: "id", repr: "USER ID" },
  { objKey: "merchantName", repr: "USERS" },
  { objKey: "productName", repr: "PRODUCTS" },
  { objKey: "localRate", repr: "RATE" },
  { objKey: "localProcessingFeeCappedAt", repr: "LOCAL FEES CAP" },
  { objKey: "localDiscountRate", repr: "DISCOUNT" },
  { objKey: "pricingStatus", repr: "Pricing Status" },
  { objKey: "action", repr: "Action" },
];

export const validationSchema = yup.object().shape({
  internationalDiscountRate: yup
    .number("Enter international discount rate")
    .min(0, "International discount rate must be greater than 0"),
  internationalProcessingFeeCappedAt: yup
    .number("Enter Processing Fee Capped At rate")
    .min(0, "Processing Fee Capped rate must be greater than 0"),
  internationalRate: yup
    .number("Enter international rate")
    .min(0, "Rate must be greater than 0"),
  localDiscountRate: yup
    .number("Enter local discount rate")
    .min(0, "Rate must be greater than 0"),
  localProcessingFeeCappedAt: yup
    .number("local processing fees capped rate")
    .min(0, "minimum value is 0")
    .min(0, "Rate must be greater than 0"),
  localRate: yup
    .number("local discount rate")
    .min(0, "Rate must be greater than 0"),
});
