import React from "react";
import { Card } from "@mui/material";
import { styled, Box } from "@mui/system";

const CardRoot = styled(Card)(({ width }) => ({
  height: "100%",
  padding: "20px 24px",
  width: width ?? "auto",
  h4: {
    span: {
      marginLeft: "1em",
    },
  },
}));

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
  marginBottom: !subtitle && "16px",
}));

const SimpleCard = ({ children, title, subtitle, width, height }) => {
  return (
    <CardRoot elevation={6} width={width}>
      <CardTitle
        subtitle={subtitle}
        sx={{ fontSize: "1.2em", fontWeight: "bold" }}
      >
        {title}
      </CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
