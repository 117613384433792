import { useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  InputBase,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { typeOfProof } from "./data";
import { H4 } from "components/typography/Typography";
import { acceptMerchantDispute } from "services/https/disputeService";
import _ from "lodash";

export const StyledInputBox = styled(Box)(({ theme }) => ({
  label: {
    display: "block",
    fontWeight: "bold",
    paddingTop: "1em",
  },
  input: {
    display: "block",
    padding: "16px 4px",
  },
}));

export const StyledMerchantDisputeResolution = styled(Box)(({ theme }) => ({
  "& .MuiTabs-flexContainer": { justifyContent: "space-evenly", width: "100%" },
}));

export const MerchantDisputeResolution = ({ data }) => {
  const [value, setValue] = useState("accept");
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <StyledMerchantDisputeResolution>
      {" "}
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{}}
        fullWidth
        aria-label="merchant dispute response"
      >
        <Tab value="accept" label="Accept Chargeback" />
        <Tab value="reject" label="Reject Chargeback" />
      </Tabs>
      <TabPanel value={value} index={"accept"}>
        <AcceptChargeBack data={data} />
      </TabPanel>
      <TabPanel value={value} index={"reject"}>
        <RejectChargeBack />
      </TabPanel>
    </StyledMerchantDisputeResolution>
  );
};

const AcceptChargeBack = ({ data }) => {
  const theme = useTheme();
  const { merchantCustomerDisputeId } = data || {};
  const [acceptData, setAcceptData] = useState({
    merchantAcceptanceComment: "",
    newRefundAmount: data?.transactionAmount,
  });
  const handleClick = () => {
    acceptMerchantDispute({ merchantCustomerDisputeId, data: acceptData })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const actualVal = name === "newRefundAmount" ? parseInt(value) : value;
    setAcceptData((acceptDT) => ({ ...acceptDT, [name]: actualVal }));
  };
  return (
    <Box>
      <Typography>
        {
          "By accepting this chargeback, you’re instructing us to process a refund that will be deducted from your next settlement."
        }
      </Typography>
      <StyledInputBox>
        <Box sx={{ my: 2 }}>
          <label>{"How much should we refund"}</label>
          <InputBase
            type={"number"}
            fullWidth
            sx={{
              border: `1px solid ${theme.palette.disabled.main}`,
              mt: 2,
              px: 2,
              borderRadius: "5px",
            }}
            startAdornment={"NGN"}
            name={"newRefundAmount"}
            value={acceptData?.newRefundAmount}
            onChange={handleChange}
          />
        </Box>
        <TextField
          fullWidth
          label="Add a comment [Optional]"
          variant="standard"
          name={"merchantAcceptanceComment"}
          value={acceptData?.merchantAcceptanceComment}
          onChange={handleChange}
        />
      </StyledInputBox>
      <Box
        sx={{
          mt: theme.spacing(4),
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant={"contained"} onClick={handleClick}>
          {"Accept ChargeBack"}
        </Button>
      </Box>
    </Box>
  );
};

const RejectChargeBack = () => {
  const [file, setFile] = useState();
  const [err, setError] = useState({ errState: true, errMsg: "" });
  const theme = useTheme();
  const [rejectValues, setRejectValue] = useState({
    merchantRejectionDocumentType: "",
    files: [],
    reason: "",
  });
  const inputFile = useRef(null);
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const handleChange = (event) => {
    const [name, value, files] = event.target;
    if (files) {
      setRejectValue((rej) => ({ ...rej, files: event.target.files[0] }));
    }
    //const { merchantRejectionDocumentType, files } = rejectValues;
    //
  };

  /*  const formik = useFormik({
    initialValues: {
      files,
      merchantRejectionDocumentType,
      disputeRejectReason,
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  }); */
  return (
    <Box>
      <H4>{"Upload Document"}</H4>
      <Typography>
        {
          "You now need to upload further evidence. This could be a proof of delivery, an invoice or some user corespondence."
        }
      </Typography>

      <form>
        <StyledInputBox>
          <label>{"Type of Proof"}</label>
          <Select
            fullWidth
            onChange={handleChange}
            name={"merchantRejectionDocumentType"}
            value={rejectValues?.merchantRejectionDocumentType}
          >
            {typeOfProof.map(({ name }) => (
              <MenuItem value={name}>{name}</MenuItem>
            ))}
          </Select>
        </StyledInputBox>
        <Box sx={{ mt: 3 }}>
          <label style={{ fontWeight: "bold" }}>
            {"Select File to upload"}
          </label>
          <ButtonGroup sx={{ width: "100%", marginTop: "1em" }}>
            <Button
              variant="outlined"
              onClick={onButtonClick}
              sx={{ width: "100%" }}
            >
              {rejectValues?.files?.name ?? ""}
            </Button>
            <Button variant={"contained"} onClick={onButtonClick}>
              {"Browse"}
              <input
                id="file"
                ref={inputFile}
                type="file"
                hidden
                onChange={handleChange}
              />
            </Button>
          </ButtonGroup>
        </Box>
        <StyledInputBox>
          <label>{"Reason for rejection"}</label>
          <InputBase
            fullWidth
            sx={{ border: `1px solid ${theme.palette.disabled.main}`, px: 2 }}
            type={"text"}
            multiline
            rows={3}
          />
        </StyledInputBox>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant={"contained"} type="submit">
            {"Reject ChargeBack"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
