import { Button, Grid, Paper, Stack, styled } from "@mui/material";
import { Link } from "react-router-dom";

import GetStarted from "assets/images/createLink.svg";
import { H2, H4, Paragraph, Span } from "components/typography/Typography";
import { Box } from "@mui/system";

const PaperRoot = styled(Paper)(({ theme }) => ({
  hr: {
    margin: "10px ",
    width: theme.spacing(10),
    height: theme.spacing(1),
    background: theme.palette.primary.main,
    border: "none",
  },
  "& .content-txt": {},
  "& .imgContainer": {
    width: "100%",
    overflow: "hidden",
  },
  "& .cta": {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    margin: "auto",
  },
}));

export const GettingStarted = () => {
  return (
    <PaperRoot>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={7}>
          <Box className={"imgContainer"}>
            <img src={GetStarted} />
          </Box>
        </Grid>
        <Grid item xs={5} justifyContent="center">
          <Box
            className={"content-txt"}
            sx={{ maxWidth: "325px", margin: "auto" }}
          >
            <Stack direction={"row"}>
              <hr />
              <Span>{"Wayapaychat"}</Span>
            </Stack>
            <H4 sx={{ my: 2 }}>{"Payment Links"}</H4>
            <Paragraph>
              {
                " Create and share a Wayapaychat  Payment Link in under a minute with your customers via email, SMS, messenger, chatbot etc."
              }
            </Paragraph>
            <Box
              sx={{
                marginTop: "4em",
              }}
            >
              <Button
                className={"cta"}
                variant={"contained"}
                sx={{ textAlign: "center" }}
              >
                <Link to={"paymentlinks"} state={{ active: "paymentlinks" }}>
                  {"Create Link Now"}
                </Link>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PaperRoot>
  );
};
