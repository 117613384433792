import React, { useState } from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DropIcon from "components/Icons/DropIcon";
import DateIcon from "components/Icons/DateIcon";
import SelectDropdown from "./Dropdown";
import DatePickerModal from "../DatePickerModal";
import { DateRangePicker } from "materialui-daterange-picker";
import map from "lodash/map";
import "./styles.css";

const FILTERS = [
  "Admin",
  "Transaction Type",
  "Recipient",
  "Transaction Owner",
  "Transaction Date",
  "Status",
  "Extract All",
];

const ExportDataModal = ({ show, handleClose }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  if (!show) return null;

  const handleSelectTransaction = () => {};

  return (
    <div className='rename-account-modal'>
      <div className='rename-account-modal__main-content'>
        <button className='rename-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='rename-account-modal__body'>
          <div className='rename-account-modal__title-container'>
            <h3 className='rename-account-modal__title'>{"Export Data"}</h3>
          </div>

          <div className='rename___account__form'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Filter"}</label>

              <div className='rename-account-modal-dropdown-container'>
                <p>{"Selected Value"}</p>
                <button onClick={() => setShowDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <SelectDropdown show={showDropdown} />
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Start Date"}</label>
              <input type='date' className='rename-account-modal-input' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"End Date"}</label>
              <input type='date' className='rename-account-modal-input' />
            </div>

            {/* <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Start Date"}</label>
              <div className='rename-account-modal-dropdown-container'>
                <p>{"Selected Value"}</p>
                <button onClick={() => setShowDatePicker((s) => !s)}>
                  <DateIcon color='#666666' />
                </button>

                <DatePickerModal show={false} />
              </div>
            </div> */}
          </div>

          <div className='rename-account-modal__action-btns-container'>
            <button className='rename-account-modal__btn rename-account-modal__orange-btn'>
              {"Proceed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportDataModal;
