import { formatDate } from '../../../../utils/utilFn'

export const dataKeysAndRepr = [
  { objKey: "fullName", repr: "Full Name" },
  { objKey: "emailAddress", repr: "Email" },
  { objKey: "role", repr: "Role" },
   { objKey: 'createdAt', repr: "Date-Created" },
  { objKey: "action", repr: "Action" },
];

export const TotalSummary = [
  {
    title: "All Admin Users",
    objKeyRepr: "totalAdminUser",
  },
  // {
  //   title: "Total Pending Invites",
  //   objKeyRepr: "totalInviteesPending",
  // },
];

export const formatAdminData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      createdAt: formatDate(item.createdAt)
    }
  })
}
