import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import CustomIcon from "assets/icons";
import { useLocation } from "react-router-dom";
import { analytics, otherInformation, listItemData } from "../data";
import { StyledBox, TxTimeline } from "./TransactionDetail";
import { cloneElement, useState } from "react";
import { DialogFactory, dialogOptions } from "components/dialog/dialogFactory/DialogFactory";
import { useDispatch, useSelector } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import { SETTLE_MANUALLY } from "utils/constant";

const StyledTimeline = styled("div")(() => ({
  ".MuiTimeline-root": {
    alignItems: "start",
  },
}));



export const DetailSideTwo = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { customerIpAddress, scheme, maskedPan,
    refNo,
    channel,
    fee,
    tranDate,
    customerName,
    customerEmail,
    status,
    amount,
  } = useLocation()?.state;
  const row = { customerIpAddress, scheme, maskedPan };
  const dispatch = useDispatch();
  const {
    alerts: { dialogType },
  } = useSelector((state) => {
    return state;
  });
  const handleSettleManually = () => {
    console.log('state', listItemData);

    setOpenDialog(true);
    dispatch(createDialog({ type: SETTLE_MANUALLY, payload: {settlementGrossAmount: amount, settlementNetAmount:amount, settlementAccountName:customerName, paymentRefNo:refNo,} }));
  };

  return (
    <>
     {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
    <Grid item xs={6}>
      <Paper sx={{ backgroundColor: "#F6EFEF", px: 3, py: 2 }}>
        <Paper elevation={0} sx={{ border: "1px solid #FF6634;", p: 2 }}>
          <Button
            variant={"contained"}
            startIcon={<CustomIcon name={"replay"} />}
            onClick={handleSettleManually}
          >
            {"Settle Manually"}
          </Button>
        </Paper>
        <StyledBox>
          <List sx={{ width: "50%" }}>
            {analytics.slice(0, 3).map(({ label, objKeyRepr }) => (
              <ListItem>
                <ListItemText
                  primary={label}
                  secondary={row[objKeyRepr] ?? "Not Available"}
                />
              </ListItem>
            ))}
          </List>
          <List>
            {analytics.slice(3, 6).map(({ label, objKeyRepr }) => (
              <ListItem>
                <ListItemText
                  primary={label}
                  secondary={row[objKeyRepr] ?? "Not Available"}
                />
              </ListItem>
            ))}
          </List>
        </StyledBox>
        <Divider />
        <StyledBox sx={{ height: "240px", pt: 4 }}>
          <List sx={{ width: "50%" }}>
            {otherInformation.map(({ label, val, avatarIcon }) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                    <CustomIcon name={avatarIcon} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={label} secondary={val} />
              </ListItem>
            ))}
          </List>
          <Box className="conttainer" sx={{ position: "relative" }}>
            <Box sx={{ transform: `rotate(225deg)`, position: "relative" }}>
              <CircularProgress
                variant="determinate"
                value={75}
                size={"200px"}
                thickness={0.2}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "34%",
                left: "37%",
                textAlign: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`12:23`}</Typography>
              <Typography variant="span">{"Minutes"}</Typography>
            </Box>
            <Typography
              variant={"body1"}
              sx={{
                width: "160px",
                textAlign: "center",
                position: "relative",
                top: "-32px",
                left: "20px",
              }}
            >
              {"GATEWAY MODAL LOAD TIME"}
            </Typography>
          </Box>
        </StyledBox>
        <Divider sx={{ my: 3 }} />
        <StyledTimeline>
          <TxTimeline />
        </StyledTimeline>
      </Paper>
    </Grid>
    </>
  );
};
