import React from "react";
import { authRoles } from "auth/authRoles";
import WayaOfficial from "./WayaOfficial";

const WayaOfficialRoutes = [
  {
    path: "/waya-official",
    element: <WayaOfficial />,
    auth: authRoles.admin,
  },
];

export default WayaOfficialRoutes;
