import { Box, Button, Paper, Stack } from "@mui/material";
import { H2 } from "components/typography/Typography";
import Table from "components/table/Table";
import React, { useEffect } from "react";
import { TEAM_TABLE_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";

const headers = {
  name: "Name",
  email: "Email",
  role: "Role",
  status: "2FA Status",
  actions: "Actions",
};

export const dataKeysAndRepr = [
  { objKey: "name", repr: " Name" },
  { objKey: "email", repr: "Email" },
  { objKey: "role", repr: "Role" },
  { objKey: "status", repr: "2faStatus" },
];

const sampleData = [
  {
    name: "Adeyanju Akorede",
    email: "adeyanju.celestial.com",
    role: "Business Owner",
    status: "Enabled",
  },
  {
    name: "Adeyanju Akorede",
    email: "adeyanju.celestial.com",
    role: "Customer Support",
    status: "Disabled",
  },
  {
    name: "Adeyanju Akorede",
    email: "adeyanju.celestial.com",
    role: "Customer Support",
    status: "Enabled",
  },
];

const Teams = () => {
  const { setTableMenu } = useMenu();
  useEffect(() => {
    setTableMenu(TEAM_TABLE_MENU);
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
        <H2>{"Team Members"}</H2>
        <Box>
          <Button variant={"contained"} sx={{ mx: 2 }}>
            {"Manage Roles"}
          </Button>
          <Button variant={"contained"}>{"Invite Someone"}</Button>
        </Box>
      </Stack>
      <Stack direction={"row"} sx={{ justifyContent: "space-evenly", my: 2 }}>
        {Object.values(headers).map((i) => (
          <Box sx={{ width: "100%" }}>{i}</Box>
        ))}
      </Stack>
      <Table
        headers={false}
        dataKeysAndRepr={dataKeysAndRepr}
        dataItemRows={sampleData}
        alignAction={"left"}
      />
    </Paper>
  );
};

export default Teams;
