import { CLEAR_SEARCH, SEARCH } from "redux/actions/SearchAction";

const initialState = {
  searchResult: null,
  query: "",
};

const SearchReducer = function (state = initialState, action) {
  switch (action.type) {
    case SEARCH: {
      const { searchResult, query } = action.payload;
      return { ...state, searchResult, query };
    }
    case CLEAR_SEARCH: {
      return { ...state, searchResult: null, query: "" };
    }
    default: {
      return state;
    }
  }
};

export default SearchReducer;
