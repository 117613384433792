import {
  Alert,
  Box,
  Snackbar,
  TablePagination,
  Typography,
} from "@mui/material";
import folder from "assets/images/folder.svg";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { paymentDataKeyRepr } from "../data";
import Table from "components/table/Table";
import _ from "lodash";
import { MERCHANT_PAYMENTLINK_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import { CopyAble } from "components/tabs/transaction/data";
import { format } from "date-fns";
import { TextToLabel } from "utils/textToLabel";
import Loading from "components/shared/appLoader/AppLoader";
import { getPaymentLink } from "services/https/paymentLink";
import { useDispatch, useSelector } from "react-redux";
import { notifyFailure } from "redux/actions/AlertActions";

export const PaymentLinkList = () => {
  const { userId } = useParams();
  const [paymentLinks, setPaymentLinks] = useState({});
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [merchantId, setMerchantId] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    if (newPage) {
      setPage(newPage);
    }
  };

  const { setTableMenu } = useMenu();
  const { dispatch } = useOutletContext();
  useEffect(() => {
    setLoading(true);
    setTableMenu(MERCHANT_PAYMENTLINK_MENU);

    getMerchantById({ userId })
      .then((data) => {
        const { merchantID } = data || {};
        if (merchantID) {
          setMerchantId(merchantID);
        } else {
          alert("Users has no merchant Id");
          navigate("/users/manage-users");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);
  useEffect(() => {
    setLoading(true);
    if (merchantId) {
      getPaymentLink({ merchantId, size: rowsPerPage, page })
        .then((data) => {
          const { content, totalElements } = data || {};
          setPaymentLinks({
            content: destructureNeed(content),
            totalElements,
            raw: content,
          });
        })
        .catch((err) => {
          dispatch(notifyFailure({ msg: err.message }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, rowsPerPage, page]);

  const destructureNeed = (content) => {
    const res = content?.map(
      ({
        paymentLinkName,
        paymentLinkType,
        amountText,
        customerPaymentLink,
        createdAt,
        status,
        paymentLinkId,
      }) => ({
        paymentLinkName,
        paymentLinkType:
          paymentLinkType === "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK"
            ? "ONE-TIME PAYMENT LINK"
            : "SUBSCRIPTION PAYMENT LINK",
        amountText,
        customerPaymentLink: <CopyAble str={customerPaymentLink} length={20} />,
        createdAt: format(new Date(createdAt), "MMM dd yyyy h:m aaa"),
        status: TextToLabel(status),
        link: customerPaymentLink,
        id: paymentLinkId,
      })
    );
    return res;
  };
  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) : _.isEmpty(paymentLinks.content) ? (
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            height: "40vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={folder} />
            <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
              {"There are no payment links yet!!!"}
            </Typography>
            <Typography>{"Start creating links now"}</Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Table
            dataKeysAndRepr={paymentDataKeyRepr}
            dataItemRows={paymentLinks.content}
            pageData={paymentLinks.raw}
            handleRowClick={(id) => {
              navigate(`${id ?? ""}`, {
                state: { merchantId },
              });
            }}
          />
          <TablePagination
            component="div"
            count={paymentLinks.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};
