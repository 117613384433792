import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SingleSelectPlaceholder } from "components/select";
import {
  Box,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Span } from "components/typography/Typography";
import { UploadBulkRequest, UploadBulkUser } from "services/https/fetchers";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";

export default function CreateBulkUserDialog({ openDialog, setOpenDialog }) {
  const [file, setFile] = useState();
  const [status, setStatus] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const inputFile = useRef(null);
  /* const handleClickOpen = () => {
    setOpen(true);
  }; */
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
    setFile(null);
    setStatus(null);
    setErrMsg("");
    setLoader(false);
  };

  const handleUpload = () => {
    setLoader(true);
    if (file) {
      setErrMsg("");
      
      
      //UploadBulkUser({ file: file })
      UploadBulkRequest({file})
        .then((res) => {
          if (res.length > 0) {
            dispatch(
              createDialog({ type: "bulkUserNotifyDialog", payload: res })
            );
          } else {
            dispatch(
              notifySuccess({
                msg: `Request to create bulk users is successfully`,
              })
            );
          }
        })
        .catch((err) => {
          setStatus({ success: false, msg: "Cannot create users" });
          dispatch(notifyFailure({ msg: err.message }));
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setErrMsg("No file Added");
      setLoader(false);
    }
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
          {"Upload Excel Sheet to Create User"}
        </Typography>
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <SingleSelectPlaceholder
          sx={{ width: "458px", marginBottom: "24px" }}
          label={"Send Registration Continuation Link"}
          menuItems={[
            { value: "email", placeholder: "Email" },
            { value: "phone", placeholder: "Phone Number" },
          ]}
        /> */}
        <label>{"Select Excel Sheet"}</label>
        <ButtonGroup sx={{ width: "100%", marginTop: "1em" }}>
          <Button
            variant="outlined"
            onClick={onButtonClick}
            sx={{ width: "100%" }}
          >
            {file?.name ?? ""}
          </Button>
          <Button variant={"contained"} onClick={onButtonClick}>
            {"Browse"}
            <input
              id="file"
              ref={inputFile}
              type="file"
              hidden
              onChange={handleChange}
              accept=".xlsx, .xls"
            />
          </Button>
        </ButtonGroup>
      </DialogContent>
      <Typography sx={{ textAlign: "center" }} variant="h6">
        {errMsg}
      </Typography>
      <DialogActions sx={{ justifyContent: "center", p: 4 }}>
        <Button
          variant={"contained"}
          sx={{ width: "156px" }}
          onClick={handleUpload}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Span>{"Upload"}</Span>
            {loader ? (
              <CircularProgress
                sx={{ color: "#fff", marginLeft: "5px" }}
                size={20}
              />
            ) : (
              ""
            )}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
