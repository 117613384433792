import { add, format } from "date-fns";
import * as yup from "yup";

export const initialValues = {
  currency: "NGN",
  interval: "ANNUALLY",
  planAmount: 0,
  planDescription: "",
  planName: "",
  totalCount: null,
};
export const PaymentPlanValidationSchema = yup.object().shape({
  planName: yup
    .string("Enter payment plan name")
    .required("Plan name is required"),
  planAmount: yup
    .number("Enter plan amount ")
    .min(0, "Minimum plan amount is NGN0 ")
    .required("Amount is required"),
  totalCount: yup
    .number("Enter total count")
    .required("total count is required"),
});

export const formInput = [
  {
    label: "Plan Name *",
    name: "planName",
    placeholder: "Name of your plan",
  },
  {
    label: "Plan Description",
    name: "planDescription",
    placeholder: "optional",
  },
  {
    label: "Plan Amount",
    name: "amount",
    elements: [
      {
        name: "currency",
        type: "select",
        options: [
          {
            name: "NGN",
            value: "NGN",
          },
          {
            name: "$",
            value: "Dollar",
          },
        ],
      },
      {
        name: "planAmount",
        type: "number",
        placeholder: "Cost of the plan per unit",
      },
    ],
  },
  {
    label: "Interval *",
    name: "interval",
    elements: [
      {
        name: "interval",
        type: "select",
        options: [
          {
            name: "monthly",
            value: "MONTHLY",
          },
          {
            name: "annually",
            value: "ANNUALLY",
          },
        ],
      },
    ],
  },
  {
    label: "Total count",
    name: "totalCount",
    placeholder: "Set limit",
  },
];

export const paymentDataKeyRepr = [
  { objKey: "paymentLinkName", repr: "Name" },
  { objKey: "paymentLinkType", repr: "Type" },
  { objKey: "amountText", repr: "Amount" },
  { objKey: "customerPaymentLink", repr: "Payment Link" },
  { objKey: "createdAt", repr: "Created At" },
  { objKey: "status", repr: "Status" },
  { objKey: "action", repr: "" },
];
