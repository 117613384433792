import React from "react";
import map from "lodash/map";

const SelectDropdown = ({ dropdownItems, getTextFromDropdownItem, show, onSelect }) => {
  if (!show) return null;
  return (
    <ul className='fraud-transactions__filter-dropdown__select-dropdown'>
      {map(dropdownItems, (dropItems, index) => (
        <li key={index} className='fraud-transactions__filter-dropdown__select-dropitem'>
          <button onClick={() => onSelect(dropItems)}>{getTextFromDropdownItem(dropItems)}</button>
        </li>
      ))}
    </ul>
  );
};

export default SelectDropdown;
