import * as types from "../types/AdminTypes";

const initialState = {
    admins: {}, // admin_id => fullName,roleId,  email, role, dateCreated, hasNotAcceptedInvite
    roles: {}, // rold_id => roleName, adminMembers: {fullName: string}[]
    defaultRoleIds: [], // roldId
    adminAnalysis: [], // category, total
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POPULATE_ADMINS: {
            const { admins } = action.payload;
            return { ...state, admins };
        }

        case types.ADD_ADMIN: {
            const { admins } = action.payload;
            return { ...state, admins: { ...state.admins, ...admins } };
        }

        case types.REMOVE_ADMIN: {
            const { userId } = action.payload;
            const newAdmins = state.admins;
            delete newAdmins[userId];
            return { ...state, admins: { ...newAdmins } };
        }

        case types.EDIT_ADMIN: {
            const { userId, userPatchData } = action.payload;
            return {
                ...state,
                admins: {
                    ...state.admins,
                    [userId]: { ...state.admins[userId], ...userPatchData },
                },
            };
        }

        case types.POPULATE_ROLES: {
            const { roles } = action.payload;
            return { ...state, roles };
        }

        case types.POPULATE_DEFAULT_ROLES: {
            const { defaultRoleIds } = action.payload;
            return { ...state, defaultRoleIds };
        }

        case types.POPULATE_ADMIN_ANALYSIS: {
            const { adminAnalysis } = action.payload;
            return { ...state, adminAnalysis };
        }

        default:
            return state;
    }
};

export default adminReducer;
