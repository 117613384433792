import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
  ListItemButton,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { H3 } from "components/typography/Typography";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import { CREATE_CUSTOM_ROLE } from "utils/constant";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement } from "react";
import {
  getAllPermisions,
  getAllRoles,
  getRolePermisionList,
} from "services/https/roleAndAccessService";
import { SuperAdmin } from "./SuperAdmin";
import { EDIT_CUSTOM_ROLE } from "utils/constant"
import { ArrowBack } from "@mui/icons-material";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  header: {
    h3: {
      textAlign: "start",
      padding: theme.spacing(2),
    },
  },
}));

const ManageAdminUsersRoles = () => {
  const theme = useTheme();
  const [roles, setRoles] = useState({
    "Super Admin": "Super Admin",
    admin: "Admin",
  });
  const [selectedIndex, setSelectedIndex] = useState("ALL ROLES");
  const [selectedRoleId, setSelectedRoleId] = useState();
  const [allPermissions, setAllPermissions] = useState();
  const [permission, setPermission] = useState();
  const [roleDescription, setRoleDescription] = useState('');
  const [noPermission, setNoPermission] = useState();
  const [openDialog, setOpenDialog] = useState(false);
 

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleListItemClick = (roleId, index) => {
    setSelectedRoleId(roleId);
    setSelectedIndex(index);
  };

  const {
    alerts: { alertType, msg, dialogType, payload },
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    const roles = {};
    getAllRoles().then((data) => {
      JSON.parse(data)?.map(({ id, role }) => {
        if (id && role) {
          roles[role] = { role, id };
        }
      });
      setRoles(roles);
    }); 
    getAllPermisions().then((data) => {
      const p = data.map((item) => item);
      setAllPermissions(p);
    });
  }, [msg]);

  useEffect(() => {
    if(selectedRoleId) {
      getRolePermisionList(selectedRoleId).then((data) => {
        const p = data?.data?.permissions?.map((item) => item);
        const nP = data?.data?.noPriviledge?.map((item) => item);
        setRoleDescription(data?.data?.description || '');
        setNoPermission(nP || []);
        setPermission(p || []);
      });
    }
  }, [selectedRoleId, selectedIndex]);

  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };

  return (
    <>
     <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              payload: {
                roleId: roles[selectedIndex]?.id,
                roleName: selectedIndex,
                roleDescription,
                rolePermissions: permission,
                allPermissions,
              }
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType || 'info'}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} sx={{ padding: "10px 10px" }}>
        <Grid item xs={2}>
          <Item>
            <header>
              <H3>{"Roles"}</H3>
            </header>
            <List
              component="nav"
              aria-label="role"
              sx={{ marginBlock: "120px" }}
            >
              {Object.entries(roles).map(([key, values], idx) => (
                <ListItem alignItems={"flex-start"} sx={{ padding: "0" }} key={idx}>
                  <ListItemButton
                    selected={selectedIndex === key}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    }}
                    onClick={(event) => handleListItemClick(values.id, key)}
                  >
                    <ListItemText primary={key} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => handleBtnClick(e, CREATE_CUSTOM_ROLE)}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add Role" />
                </ListItemButton>
              </ListItem>
            </List>
          </Item>
        </Grid>
        <Grid item xs={10}>
          <Item>
            <SuperAdmin 
              role={selectedIndex}
              permissions={permission}
              noPermission={noPermission}
              roleId={selectedRoleId}
              setOpenDialog={setOpenDialog}
              setPermission={setPermission}
              setSelectedIndex={setSelectedIndex}
              setSelectedRoleId={setSelectedRoleId}
              handleBtnClick={handleBtnClick}
            />
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageAdminUsersRoles;
