import { add, format } from "date-fns";
import * as yup from "yup";

export const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
};
export const customerValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("email is required"),
  firstName: yup.string().max(255).min(3).required("first name is required"),
  lastName: yup.string().max(255).min(3).required("last name is required"),
  phoneNumber: yup.number().min(9).required("Phone number is required"),
});

export const formInput = [
  {
    label: "First Name *",
    name: "firstName",
    placeholder: "first name",
  },

  {
    label: "Last Name *",
    name: "lastName",
    placeholder: "last name",
  },
  {
    label: "Email Address *",
    name: "email",
    placeholder: "",
  },
  {
    label: "Phone Number *",
    name: "phone",
    elements: [
      {
        name: "countryCode",
        type: "select",
        options: [
          {
            name: "+234",
            value: "+234",
          },
        ],
      },
      {
        name: "phoneNumber",
        type: "number",
      },
    ],
  },
];

export const customerDataKeyRepr = [
  { objKey: "paymentLinkName", repr: "Name" },
  { objKey: "paymentLinkType", repr: "Type" },
  { objKey: "amountText", repr: "Amount" },
  { objKey: "customerPaymentLink", repr: "Payment Link" },
  { objKey: "createdAt", repr: "Created At" },
  { objKey: "status", repr: "Status" },
  { objKey: "action", repr: "" },
];
