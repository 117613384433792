import { StyledBox } from "./Styled";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { InputBase, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createDialog } from "redux/actions/AlertActions";
import { getCustomerList } from "services/https/paymentLink";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { format } from "date-fns";

const step2Schema = yup.object().shape({
  customerId: yup
    .string("choose a customer")
    .required("You need to select a customer"),

  linkExpirationDate: yup
    .string("choose a customer")
    .required("Select expiry date"),

  note: yup.string("add a note").required("add a note"),
});
export const FormStep2 = ({ formValues, setFormValues }) => {
  const [customers, setCustomers] = useState([]);
  const { merchantId } = formValues;
  const [checked, setChecked] = useState();

  const dateInstance = format(
    new Date(formValues.startDateAfterFirstPayment),
    "yyyy-MM-dd'T'HH:mm"
  );
  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };
  useEffect(() => {
    getCustomerList({ size: 100, merchantId }).then((data) => {
      const { content } = data;
      const customerArr = content.map(
        ({ firstName, lastName, customerId }) => ({
          name: `${firstName} ${lastName}`,
          customerId,
        })
      );
      setCustomers(customerArr);
    });
  }, []);
  const dispatch = useDispatch();
  return (
    <Box>
      <Formik
        initialValues={{
          customerId: "",
          notifyCustomer: true,
          linkExpirationDate: "",
          note: "",
        }}
        validationSchema={step2Schema}
        onSubmit={(values, actions) => {
          setFormValues((formValues) => ({
            ...formValues,
            ...values,
            validStep: 1,
          }));
        }}
      >
        {({ handleChange, errors }) => (
          <Form>
            <StyledBox>
              <label>{"Select Customer *"}</label>
              <Box sx={{ display: "block" }}>
                <Typography color={"primary.main"}>
                  <ErrorMessage name="customerId" />
                </Typography>
                <Field as="select" name="customerId">
                  <option value={""}>{"Choose customers"}</option>
                  {customers.map(({ name, customerId }, idx) => (
                    <option value={customerId}>{name}</option>
                  ))}
                </Field>
                <Box sx={{ mt: 1 }}>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      dispatch(createDialog({ type: "createCustomer" }));
                    }}
                  >
                    {"Create Customer"}
                  </Button>
                </Box>
              </Box>
            </StyledBox>

            <StyledBox>
              <label>{"Start Date *"}</label>
              <Box sx={{ display: "block" }}>
                <Box>
                  <InputBase className="check" type="checkbox" />
                  <span>{"Notify Customer"}</span>
                </Box>
                <Box>
                  <InputBase
                    className="check"
                    type="checkbox"
                    onChange={handleChecked}
                  />{" "}
                  <span>{"Link Expiry Date"}</span>
                </Box>
                {
                  <input
                    type="datetime-local"
                    name={"linkExpirationDate"}
                    disabled={!checked}
                    onChange={handleChange}
                    min={dateInstance}
                  />
                }
              </Box>
            </StyledBox>

            <StyledBox>
              <label>{"Add Note *"}</label>
              <Box sx={{ display: "block" }}>
                <Field
                  as={"textarea"}
                  name="note"
                  placeholder={"describe your sub option"}
                ></Field>
                <Typography color={"primary.main"}>
                  <ErrorMessage name={"note"} />
                </Typography>
              </Box>
            </StyledBox>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button type="submit" variant="contained">
                {"Okay"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
