import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function PreviewDialog({ openDialog, setOpenDialog }) {
  const [open, setOpen] = useState(openDialog);
  const [fileDataURL, setFileDataURL] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
    setFileDataURL(null);
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  const file = payload;
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (typeof file === "string") {
      setFileDataURL(file);
    } else {
      if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setFileDataURL(result);
          }
        };
        fileReader.readAsDataURL(file);
      }
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <Dialog
        sx={{ "& .MuiDialog-paper": { minWidth: "50%", minHeight: "90%" } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogContent dividers>
          <iframe
            src={fileDataURL}
            alt="preview"
            style={{ width: "100%", height: "80vh" }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {"Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
}
