import React from "react";

const AccountTypeDropdown = ({ show }) => {
  if (!show) return null;
  return (
    <ul className='account-type-card__more-dropdown'>
      <li className='account-type-card__more-dropitem'>
        <button>{"Rename Account"}</button>
      </li>
      <li className='account-type-card__more-dropitem'>
        <button>{"Fund Account"}</button>
      </li>
      <li className='account-type-card__more-dropitem'>
        <button>{"Block Account"}</button>
      </li>
      <li className='account-type-card__more-dropitem delete_item'>
        <button>{"Delete Account"}</button>
      </li>
    </ul>
  );
};

export default AccountTypeDropdown;
