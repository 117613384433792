import React, { useState, useEffect, useContext } from "react";
import FilterIcon from "components/Icons/FilterIcon";
import SearchIcon from "components/Icons/SearchIcon";
import FilterDropdown from "./FilterDropdown";
import DropIcon from "components/Icons/DropIcon";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import SelectDropdown from "./SelectDropdown";
import { map, range } from "lodash";
import "./styles.css";
import CopyIcon from "components/Icons/CopyIcon";
import ChevronArrowLeftIcon from "components/Icons/ChevronArrowLeftIcon";
import ChevronArrowRightIcon from "components/Icons/ChevronArrowRightIcon";
import DeleteIcon from "components/Icons/DeleteIcon";
import ThreeVerticalDotsIcon from "components/Icons/ThreeVerticalDotsIcon";
import { Box, TablePagination } from "@mui/material";
import Table from "components/table/Table";
import { getAllRequestLog, getAllStat, processRequest } from "services/https/fetchers";
import { columns, dataKeysAndRepr } from "./data";
import useMenu from "hooks/useMenu";
import { ADMIN_USER_TABLE_MENU, USER_TABLE_MENU } from "utils/constant";
import useMatStyles from "./style";
import AntDropdown from "components/dialog/dropdown";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import Loading from "components/shared/appLoader/AppLoader";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";
import { formatDate } from '../../../../utils/utilFn'

const ROWS_PER_PAGE = 10;
const AVAILABLE_PAGE_ROWS = [10, 25, 50, 100];



const RequestLogs = () => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [err, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [stat, setStat] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchResult, setSearchResult] = useState('');
  const [searchText, setSearchText] = useState('');
  const [ status, setStatus] = useState('All');
  const [filterItem, setFilterItem] = useState('');
  const [loading, setLoading] = useState(false)
  const styles = useMatStyles();
  const { userPermissions } = useContext(AuthContext);
  // const showManaging = userHasPermission(userPermissions, 'MANAGE_REQUEST')



  
  useEffect(() => {
    
    if (searchResult) {
      const search =
        searchResult.length > rowsPerPage
          ? searchResult.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : searchResult;
      setData({...data,
        count: searchResult.length,
        users: search,
      });
    } else {
       getAllRequestLog({ page, size: rowsPerPage })
        .then((resp) => {
          const { totalItems: count, totalPages, users } = resp || {};
            setData({
              count: resp?.length,
              totalPages: totalPages,
              users: resp,
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [page, rowsPerPage, searchResult]);

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    //setPagination({ ...pagination, current: newPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (value) => {
    //setPagination({ ...pagination, current: 0, pageSize: value });
    setRowsPerPage(value);
  };


  useEffect(() => {
    setLoading(true)
     getAllStat({ page, size: rowsPerPage })
        .then((resp) => {
          const {  totalRequest, totalInitiated, totalApproved, totalRejected, totalFailed } = resp || {};
          setLoading(false)
          setStat({
              totalRequest,
              totalInitiated,
              totalApproved,
              totalRejected,
              totalFailed
            });
        })
        .catch((err) => {
          setLoading(false)
          console.log(err);
        });
    
  }, []);
  

  
  const actionOptions = ["Approve", "Reject", 'Delete']
  const handleAction = async(e, id)=>{
    setLoading(true)
    let postData ={
      action: e==='Approve'?'Approved': e==='Reject'?'Rejected': 'Delete' ,
      requestId: id
    }
     processRequest(postData)
      .then((res)=>{
        setLoading(false)
        if (res?.status) {
          dispatch(
            notifySuccess({ msg: res?.message })
          );
        } else {
          dispatch(
            notifyFailure({ msg: res?.message })
          );
          setErrMsg(res.message || "An error occured, please try again.");          
        }
      })
      .finally(() => {
        setLoading(false)
      });  
  
  }
  
  const columns1 = React.useMemo(() => [
    {
      field: "requestId",
      headerName: "Request Id",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,

    },
    {
      field: "createdBy",
      headerName: "Requested By",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,

    },
    {
      field: "initiatorRole",
      headerName: "Role",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.initiatorRole?.replace(/_/g, ' ')

    },
    {
      field: "customerName",
      headerName: "Requested For",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,

    },
    {
      field: "requestType",
      headerName: "Request Type",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,

    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.status === 'Initiated'?'Pending':row?.status ==='Deleted'?'Failed':row?.status
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> formatDate(row?.dateCreated)
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={actionOptions}
          handleDropdownAction={(item) => handleAction(item, row?.requestId)}
          userId={row}
          isArrow
        />
      ),

    }
  ])
    return (
    <div className='request-logs request-logs__container'>
      <div className='request-logs__actions-container'>
        <div className='request-logs__filter-btn-container'>
          <button
            className='request-logs__filter-btn'
            onClick={() => setShowFilterDropdown((s) => !s)}
          >
            <FilterIcon />
          </button>
          <FilterDropdown
            show={showFilterDropdown}
            setFilterItem= {setFilterItem}
            setSearchText = {setSearchText}
            handleClose={() => setShowFilterDropdown(false)}
          />
        </div>

        <div className='request-logs__search-container'>
          <button className='request-logs__search-btn'>
            <SearchIcon />
          </button>

          <input
            className='request-logs__search-input'
            value={searchText}
            onChange={(e)=>setSearchText(e.target.value)}
            placeholder='Search rules by status'
          />
        </div>

        <button className='request-logs__export-btn'>
        {data?.users?.length>0&&<CSVLink data={data?.users} filename={"Request_Log.csv"}>
            {"Export"}
          </CSVLink>
          }
        
        </button>

      </div>

      <div className='request-logs__statistics'>
        <div className='request-logs__statistic-container request-logs__dark-statistic-container'>
          <p className='request-logs__statistic-text'>{"Total Requests"}</p>
          <p className='request-logs__statistic-total'>{stat?.totalRequest??0}</p>
        </div>

        <div className='request-logs__statistic-container request-logs__dark-statistic-container'>
          <p className='request-logs__statistic-text'>{"Total approved Requests"}</p>
          <p className='request-logs__statistic-total'>{stat?.totalApproved??0}</p>
        </div>

        <div className='request-logs__statistic-container request-logs__dark-statistic-container'>
          <p className='request-logs__statistic-text'>{"Total pending Requests"}</p>
          <p className='request-logs__statistic-total'>{stat?.totalInitiated ?? 0}</p>
        </div>

        <div className='request-logs__statistic-container request-logs__dark-statistic-container'>
          <p className='request-logs__statistic-text'>{"Total rejected Requests"}</p>
          <p className='request-logs__statistic-total'>{stat?.rejected ?? 0}</p>
        </div>
        <div className='request-logs__statistic-container request-logs__dark-statistic-container'>
          <p className='request-logs__statistic-text'>{"Total Failed Requests"}</p>
          <p className='request-logs__statistic-total'>{stat?.totalFailed ?? 0}</p>
        </div>
      </div>

      {/* Request Type and Date Filter */}
     {/*} <div className='request-logs__filter-input-group'>
        <div className='request-logs__filter-dropdown__input-group'>
          <label className='request-logs__filter-dropdown__label'>{"Request Type"}</label>

          <div className='request-logs__filter-dropdown__select-dropdown-container'>
            <p>{"Active"}</p>
            <button onClick={() => setShowDropdown((s) => !s)}>
              <DropIcon color='#666666' />
            </button>
            <SelectDropdown
              show={showDropdown}
              onSelect={() => {}}
              dropdownItems={["Customer", "Merchant", "Admin"]}
              getTextFromDropdownItem={(text) => text}
            />
          </div>
        </div>

        <div className='request-logs__filter-dropdown__input-group'>
          <label className='request-logs__filter-dropdown__label'>{"Date"}</label>
          <input className='request-logs__filter-dropdown__input-date' type='date' />
        </div>
      </div>*/}

      {/* Table */}
      
      <div className="request-logs__table-container" style={{ width:'400', height: '400' }}>
      <div  style={{ flexGrow: 1 }}>
      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) :
      <div style={{ height: 800, width: "100%" }}>
      {data?.users?.length>0 && <DataGrid
            className={styles.table}     //requestType
            rows={(searchText)?data?.users?.filter(e=>e.requestId.toLowerCase().includes(searchText?.toLowerCase())||e.requestType.toLowerCase().includes(searchText?.toLowerCase())||e.status.toLowerCase().includes(searchText?.toLowerCase()))
            :data?.users}
            columns={columns1}
            page={page}
           pageSize={rowsPerPage}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[2, 10, 20, 50, 100]}
           // components={{ Toolbar: GridToolbar }}
            rowCount={data?.count}
          />}
      {/*<Table dataKeysAndRepr={dataKeysAndRepr }  dataItemRows={data?.users} />
      <TablePagination
        component="div"
        count={data.count?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        
          />*/}
              </div>}

      </div>
      </div>
    </div>
  );
};

export default RequestLogs;
