import {
  Button,
  Tooltip,
  ClickAwayListener,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import {
  approveRejectKYC,
  uploadDocx,
  uploadWithoutFile,
} from "services/https/updatedKYCService";
import { HighlightOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { KYC_CONFIRMATION, KYC_SUCCESSFUL } from "utils/constant";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Span } from "components/typography/Typography";
import { createRequest } from "services/https/fetchers";

export const Help = () => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<div>{"required document to be uploaded"}</div>}
        >
          <IconButton onClick={handleTooltipOpen}>{<HelpOutline />}</IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export const TopActionBar = ({
  editable,
  setEditable,
  updatedChanges,
  merchantData,
  setOpenBackDrop,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  return (
    <Stack sx={{ justifyContent: "space-between", py: 4 }} direction={"row"}>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>

      <>
        {!editable && (
          <Button
            color={"disabled"}
            variant={"contained"}
            onClick={() => setEditable(true)}
          >
            {"Edit KYC"}
          </Button>
        )}

        {editable && (
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              setOpenBackDrop(true);
              setLoading(true);
              uploadDocx(updatedChanges, merchantData?.userId)
                .then((data) => {
                  const all = data.every((i) => i === true);
                  all &&
                    dispatch(
                      notifySuccess({ msg: "Uploaded file successfully" })
                    );
                })
                .catch((err) => {
                  dispatch(notifyFailure({ msg: "Uploading data failed" }));
                })
                .finally(() => {
                  const changesWithoutFile = _.omit(updatedChanges, [
                    "BUSINESS_CERTIFICATE",
                    "BUSINESS_ADDRESS_PROVE",
                    "ID_CARD",
                  ]);
                  const trimMerchantData = _.omit(merchantData, [
                    "createdAt",
                    "dateDeleted",
                    "deleted",
                    " deletedBy",
                    "statusLastModifiedOn",
                    "approvalStatus",
                    "updatedAt",
                    "deletedBy",
                    "statusModifiedBy",
                  ]);
                 /* uploadWithoutFile({
                    ...trimMerchantData,
                    ...changesWithoutFile,
                  })*/
                 // return alert(JSON.stringify(trimMerchantData))
                  let d = new Date()
                  let payload = {
                    customerName: `${trimMerchantData?.businessName}`,
                    payload: JSON.stringify({...trimMerchantData, ...changesWithoutFile}),
                    requestId: d.getTime(),
                    requestType: "kycUpdate"
                  }
                  createRequest(payload)
                    .then((data) => {
                      dispatch(
                        notifySuccess({ msg: "Data updated successful" })
                      );
                    })
                    .catch((err) => {
                      dispatch(notifyFailure({ msg: "Data update failed" }));
                    })
                    .finally(() => {
                      //navigate("/kyc");
                      setLoading(false);
                      setOpenBackDrop(false);
                      setEditable(false);
                    });
                });
            }}
          >
            <Span>{"Save Changes"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress size={20} />
              </Span>
            )}
          </Button>
        )}
      </>
    </Stack>
  );
};

export const RejectAcceptComponent = ({
  setOpenDialog,
  openDialog,
  merchantId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleKYC = (status, reasonForRejection) => {
    setLoading(true);
    approveRejectKYC({ status, merchantId, reasonForRejection })
      .then((data) => {
        setLoading(false);
        const payload = { title: data?.data.data.approvalStatus };
        dispatch(createDialog({ type: KYC_SUCCESSFUL, payload }));
      })
      .catch((err) => {
        setOpenDialog(false);
        dispatch(notifyFailure({ msg: "Error processing request" }));
      });
  };
  const handleClick = (e, type) => {
    const rejectPayload = {
      title: "Reject KYC",
      subTitle: "Are you sure you want to reject KYC for this merchant?",
      handleOk: (e, rejectReason) => handleKYC("REJECTED", rejectReason),
      loading,
      type,
    };
    const acceptPayload = {
      title: "Approve KYC",
      subTitle: "Are you sure you want to approve KYC for this merchant?",
      handleOk: () => handleKYC("APPROVED"),
      loading,
      type,
    };
    const payload = type === "accept" ? acceptPayload : rejectPayload;
    dispatch(createDialog({ type: KYC_CONFIRMATION, payload }));
    setOpenDialog(true);
  };
  return (
    <Stack
      sx={{
        mb: 8,
        bottom: 0,
        py: 2,
        position: "fixed",
        backgroundColor: "#fff",
        width: "100%",
      }}
      columnGap={3}
      direction={"row"}
    >
      <Button
        variant={"contained"}
        color={"error"}
        startIcon={<HighlightOff />}
        onClick={(e) => handleClick(e, "reject")}
      >
        {"Reject KYC"}
      </Button>
      <Button
        variant={"contained"}
        color={"success"}
        startIcon={<HighlightOff />}
        onClick={(e) => handleClick(e, "accept")}
      >
        {"Approve KYC"}
      </Button>
    </Stack>
  );
};
