import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  styled,
  CircularProgress,
  Box,
} from "@mui/material";
import { H3, Span } from "components/typography/Typography";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getPlanById } from "services/https/paymentLink";

const BoxRoot = styled(Box)(({ theme }) => ({}));
export const FormStep3 = ({ formValues, setFormValues }) => {
  const { merchantId, planId } = formValues;
  const {} = formValues;
  const [reviewData, setReviewData] = useState();
  useEffect(() => {
    getPlanById({ merchantId, planId }).then((data) => {
      if (data) {
        const { planAmount, planName, interval, currency, totalCount } = data;
        setReviewData({
          amount: planAmount,
          currency,
          cycle: totalCount,
          planName,
          interval:
            interval === "ANNUALLY"
              ? "year"
              : interval === "MONTHLY"
              ? "month"
              : "",
        });
      }
    });
  }, [formValues]);
  const { currency, amount, cycle, interval, planName } = reviewData || {};
  const steps = [
    {
      title: "First Payment",
      type: `Authorization Payment : ${currency}${amount}`,
      subscription: `Subscription Amount : ${currency}${amount} X ${cycle}`,
    },
    {
      title: `Every ${interval} after the first payment`,
      type: `Recurrent payment : NGN${amount}`,
    },
    {
      title: `No of Cycles : ${cycle}`,
    },
  ];
  return (
    <>
      {_.isEmpty(reviewData) ? (
        <Box sx={{ minHeight: "350px", display: "flex", margin: "auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <BoxRoot>
            <H3>{"Plan Name"}</H3>
            <Typography variant={"body1"} sx={{ textIndent: 2 }}>
              {planName}
            </Typography>
          </BoxRoot>
          <BoxRoot>
            <H3>{"Billing Details"}</H3>
            <Typography variant={"body2"}>
              {`Every ${interval} the customer will be charged ${currency}${amount}`}
            </Typography>
          </BoxRoot>
          <BoxRoot>
            <Stepper orientation="vertical" activeStep={-1}>
              {steps.map(({ title, type, subscription }) => {
                return (
                  <Step key={title}>
                    <StepLabel>
                      <Typography variant={"body2"}>{title}</Typography>
                      <Span>
                        <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                          {type}
                        </Typography>
                        <Typography variant={"body"}>{subscription}</Typography>
                      </Span>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </BoxRoot>
        </Box>
      )}
    </>
  );
};
