import React, { useState } from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DropIcon from "components/Icons/DropIcon";
import SelectDropdown from "./SelectDropdown";
import "./styles.css";
import AddCircleIcon from "components/Icons/AddCircleIcon";

const TransferModal = ({ show, handleClose }) => {
  const title = "Wallet Transfer with Waya Official";
  const [showTransactionTypeDropdown, setShowTransactionTypeDropdown] = useState(false);
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [showRecipientTypeDropdown, setShowRecipientTypeDropdown] = useState(false);

  if (!show) return null;
  return (
    <div className='rename-account-modal'>
      <div className='rename-account-modal__main-content with-overflow-auto wider-main-content-REMOVE-THIS-ADDITIONAL'>
        <button className='rename-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='rename-account-modal__title-container'>
          <h3 className='rename-account-modal__title'>{title}</h3>
        </div>
        <div className='rename-account-modal__body'>
          <div className='rename___account__form rename___account__form_2_per_row rename-account-with-col-form-REMOVE-THIS-ADDITIONAL'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Transaction Type"}</label>

              <div className='rename-account-modal-dropdown-container'>
                <p>{"Selected Value"}</p>
                <button onClick={() => setShowTransactionTypeDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <SelectDropdown
                  show={showTransactionTypeDropdown}
                  onSelect={() => {}}
                  dropdownItems={[
                    "Wallet Transfer",
                    "Bank Transfer",
                    "Bills Payment",
                    "Non Waya Transfer",
                    "Payment Request",
                  ]}
                  getTextFromDropdownItem={(text) => text}
                />
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Select Waya Official Wallet"}</label>

              <div className='rename-account-modal-dropdown-container'>
                <p>{"Selected Value"}</p>
                <button onClick={() => setShowWalletDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <SelectDropdown
                  show={showWalletDropdown}
                  onSelect={() => {}}
                  dropdownItems={[
                    "Wallet Name 1",
                    "Wallet Name 2",
                    "Wallet Name 3",
                    "Wallet Name 4",
                  ]}
                  getTextFromDropdownItem={(text) => text}
                />
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Recipient Type"}</label>

              <div className='rename-account-modal-dropdown-container'>
                <p>{"Selected Value"}</p>
                <button onClick={() => setShowRecipientTypeDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <SelectDropdown
                  show={showRecipientTypeDropdown}
                  onSelect={() => {}}
                  dropdownItems={["Single User", "Excel Upload", "Web Forms"]}
                  getTextFromDropdownItem={(text) => text}
                />
              </div>
            </div>
            <div></div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Recipient"}</label>
              <div className='rename-account-modal-input-for-file-upload '>
                <input type='file' className='hidden' />
                <button className='rename-account-modal-input-for-file-upload-btn'>
                  {"Browse"}
                </button>
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Recipient"}</label>
              <input className='rename-account-modal-input' value='Philip Templar' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Email Address"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Mobile Number"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Description"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Account Number"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Amount"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Enter PIN"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>
          </div>

          <div className='waya-transfer-modal__wallet-section'>
            <div className='rename___account__form'>
              <div className='waya-transfer-modal__wallet-section__top'>
                <p className='waya-transfer-modal__wallet-section__title'>{"Request 1"}</p>

                <button className='waya-transfer-modal__wallet-section__delete-wallet'>
                  {"Delete Wallet"}
                </button>
              </div>

              <div className='rename___account__form rename-account-with-col-form'>
                <div className='rename-account-modal-input-group'>
                  <label className='rename-account-modal-label'>{"Email Address"}</label>
                  <input className='rename-account-modal-input' value='' />
                </div>

                <div className='rename-account-modal-input-group'>
                  <label className='rename-account-modal-label'>{"Phone Number"}</label>
                  <input className='rename-account-modal-input' value='' />
                </div>

                <div className='rename-account-modal-input-group'>
                  <label className='rename-account-modal-label'>{"Amount"}</label>
                  <input className='rename-account-modal-input' value='' />
                </div>

                <div className='rename-account-modal-input-group'>
                  <label className='rename-account-modal-label'>{"Description"}</label>
                  <input className='rename-account-modal-input' value='' />
                </div>
              </div>

              <div className='waya-transfer-modal__add-another-request'>
                <button className='waya-transfer-modal__add-another-request-icon-btn'>
                  <AddCircleIcon />
                </button>
                <p className='waya-transfer-modal__add-another-request-text'>
                  {"Add Another Request"}
                </p>
              </div>
            </div>
          </div>

          <div className='rename___account__form'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Enter PIN"}</label>
              <input className='rename-account-modal-input' value='' />
            </div>
          </div>

          <div className='rename-account-modal__action-btns-container'>
            <button className='rename-account-modal__btn rename-account-modal__orange-btn'>
              {"Proceed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferModal;
