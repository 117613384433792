import React, { useState } from "react";
import DropIcon from "components/Icons/DropIcon";
import SelectDropdown from "./SelectDropdown";

const FilterDropdown = ({ show, handleClose, setFilterItem,setSearchText }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  if (!show) return null;

  return (
    <div className='request-logs__filter-dropdown'>
      <div className='request-logs__filter-dropdown__input-group'>
        <label className='request-logs__filter-dropdown__label'>{"Active Status"}</label>

        <div className='request-logs__filter-dropdown__select-dropdown-container'>
          <p>{"All"}</p>
          <button onClick={() => setShowDropdown((s) => !s)}>
            <DropIcon color='#666666' />
          </button>
          <SelectDropdown
            show={showDropdown}
            onSelect={() => {}}
            onChange={(e)=>{setFilterItem(e);setSearchText(e==='Pending'?'Initiated':e==='All'?'':e); handleClose(true)} }
            dropdownItems={["All", "Pending", "Approved", "Rejected", "Failed"]}
            getTextFromDropdownItem={(text) => text}
          />
        </div>
      </div>

      {/*<div className='request-logs__filter-dropdown__btns-container'>
        <button onClick={handleClose}>{"Clear Filter"}</button>
        <button onClick={handleClose}>{"Apply Filter"}</button>
      </div>
    */}
    </div>
  );
};

export default FilterDropdown;
