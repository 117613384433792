import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import NavItem from "./NavItem";
import CreatePin from "./CreatePin";
import LinkedCards from "./LinkedCards";
import BankAccounts from "./BankAccounts";
import "./styles.css";
import ResetWayaPassword from "./ResetWayaPassword";
import ResetPin from "./ResetPin";
import UpdateWayaOfficialDetails from "./UpdateWayaOfficialDetails";

const MoreSettingsModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='more-settings-modal'>
      <div className='more-settings-main-content'>
        <button className='more-settings-cancel-btn' onClick={handleClose}>
          <CancelIcon />
        </button>
        <ul className='more-settings-nav-container'>
          <NavItem text={"Create Pin"} active />
          <NavItem text={"Linked Cards"} />
          <NavItem text={"Bank Accounts"} />
          <NavItem text={"Reset Waya Password"} />
          <NavItem text={"Reset Pin"} />
          <NavItem text={"Update Waya Details"} />
        </ul>
        <CreatePin />
        {/* <LinkedCards /> */}
        {/* <BankAccounts /> */}
        {/* <ResetWayaPassword /> */}
        {/* <ResetPin /> */}
        {/* <UpdateWayaOfficialDetails /> */}
      </div>
    </div>
  );
};

export default MoreSettingsModal;
