import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { createDialog } from "redux/actions/AlertActions";
import { fetchDispute } from "services/https/disputeService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { dispute } from "./data";
import { H4 } from "components/typography/Typography";
import _ from "lodash";
import { PREVIEW_IMAGE } from "utils/constant";
import imgPlaceholder from "assets/images/imgPlaceholder.png";
import { MerchantDisputeResolution } from "./MerchantDisputeResolution";

export const Header = ({ title, status, children }) => {
  const FirstHeaderChildren = () => {
    return (
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <Typography>{"Dispute Status"}</Typography>
        <Paper sx={{ p: 2, ml: 1 }}>{status}</Paper>
      </Stack>
    );
  };
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "block",
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>
      {title === "Dispute Details" && <FirstHeaderChildren />}
    </Stack>
  );
};

export const PaperListItem = ({
  title = "",
  children,
  fetchData,
  data = [],
  styles,
  setOpenDialog,
}) => {
  const dispatch = useDispatch();
  const handlePreview = (e, file) => {
    setOpenDialog(true);
    dispatch(createDialog({ type: PREVIEW_IMAGE, payload: file }));
  };
  return (
    <Paper sx={{ mb: 6, paddingBlockEnd: 2, ...styles }}>
      <Header title={title} status={fetchData?.disputeStatus} />
      <List>
        {data.map(({ label, objKey }) => (
          <>
            {label === "Document Uploaded" ? (
              !_.isEmpty(fetchData) &&
              fetchData[objKey] && (
                <Box sx={{ p: 2 }}>
                  <H4>{label}</H4>
                  <Stack direction={"row"}>
                    {JSON.parse(fetchData[objKey])?.map(({ fileUrl }) => (
                      <Stack>
                        <Box
                          onClick={(e) => handlePreview(e, fileUrl)}
                          sx={{
                            width: "80px",
                            height: "80px",
                            "&:hover": { opacity: "0.7", cursor: "pointer" },
                          }}
                        >
                          <img
                            style={{ maxWidth: "100%", height: "100%" }}
                            src={imgPlaceholder}
                            alt="document preview placeholder"
                          />
                        </Box>
                        <Button onClick={(e) => handlePreview(e, fileUrl)}>
                          {"Preview"}
                        </Button>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              )
            ) : (
              <>
                <ListItem sx={{ pt: 2 }}>
                  <ListItemText>{label}</ListItemText>
                  <ListItemText sx={{ textAlign: "end" }}>
                    {fetchData && (fetchData[objKey] ?? "")}
                  </ListItemText>
                </ListItem>
                <Divider />
              </>
            )}
          </>
        ))}
      </List>
      {children}
    </Paper>
  );
};
export const DisputeResolution = () => {
  const { disputeId } = useParams();
  const showDetails = Boolean(disputeId);
  const { setOpenDialog, openDialog } = useOutletContext();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { details } = useLocation().state || {};
  const [actForMerchant, setActForMerchant] = useState(false);
  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };
  useEffect(() => {
    if (disputeId) {
      fetchDispute(disputeId).then((data) => {
        setData(data);
      });
    }
  }, []);
  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          {"Back"}
        </Button>
        {data?.disputeResolutionStatus === "RESPONSE_NEEDED" && (
          <Button variant={"contained"} onClick={() => setActForMerchant(true)}>
            {"Resolve Dispute"}
          </Button>
        )}
      </Stack>
      <Grid container spacing={3}>
        {!showDetails && !details && (
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Stack direction={"row"}>
                <Box sx={{ width: "50%" }}>
                  <Typography variant={"h6"}>{"Resolution State"}</Typography>
                  <Typography>{data?.disputeResolutionStatus}</Typography>
                </Box>
                <Box>
                  <Typography variant={"h6"}>{"Message :"}</Typography>
                  <Typography>{data?.message ?? "No message"}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        )}
        <Grid item xs={6}>
          <PaperListItem
            title={"Dispute Details"}
            data={dispute.disputeDetail}
            fetchData={data}
            styles={{ backgroundColor: "rgba(196, 196, 196, 0.2);" }}
          />
          <PaperListItem
            title={"Reason for dispute in details"}
            styles={{ backgroundColor: "rgba(196, 196, 196, 0.2);" }}
          >
            <Box sx={{ minHeight: "150px", px: 3 }}>
              <Typography>{data?.reasonForDisputeInDetails ?? ""}</Typography>
            </Box>
          </PaperListItem>
        </Grid>
        <Grid item xs={6}>
          <PaperListItem
            title={"Merchant Details"}
            fetchData={data}
            data={dispute.merchantDetail}
          />
          {data?.disputeResolutionStatus === "RESPONSE_NEEDED" &&
          actForMerchant ? (
            <MerchantDisputeResolution data={data} />
          ) : (
            <PaperListItem
              title={"Merchant Response"}
              data={dispute.merchantResponse}
              fetchData={data}
              setOpenDialog={setOpenDialog}
            >
              {showDetails && !details && (
                <Box sx={{ p: 2 }}>
                  <Typography variant={"h6"}>
                    {`Reason for ${data?.disputeType.toLowerCase()}`}
                  </Typography>
                  <Stack
                    direction={"row"}
                    sx={{ p: 5, justifyContent: "center", columnGap: 2 }}
                  >
                    <Button
                      variant={"contained"}
                      disabled={
                        data?.disputeResolutionStatus === "RESPONSE_NEEDED"
                      }
                      onClick={(e) => {
                        handleBtnClick(e, "wonDisputeDialog");
                      }}
                    >
                      {"Dispute Won"}
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        data?.disputeResolutionStatus === "RESPONSE_NEEDED"
                      }
                      onClick={(e) => {
                        handleBtnClick(e, "lostDisputeDialog");
                      }}
                    >
                      {"Dispute Lost"}
                    </Button>
                  </Stack>
                </Box>
              )}
            </PaperListItem>
          )}
        </Grid>
      </Grid>
    </>
  );
};
