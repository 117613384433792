import React from "react";

const NavItem = ({ active, text }) => {
  const className = active ? " more-settings-nav-item-active" : "";

  return (
    <li className={"more-settings-nav-item" + className}>
      <button>{text}</button>
    </li>
  );
};

export default NavItem;
