import React from "react";

const ExportIcon = ({ size = 20, color = "#27AE60" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${20} ${20}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {" "}
      <path
        d='M14.1673 16.6673H5.00065C4.08018 16.6673 3.33398 15.9211 3.33398 15.0007V5.83398C3.33398 4.91351 4.08018 4.16732 5.00065 4.16732H8.33398V5.83398H5.00065V15.0007H14.1673V11.6673H15.834V15.0007C15.834 15.9211 15.0878 16.6673 14.1673 16.6673ZM9.75065 11.4232L8.57565 10.2448L13.8198 5.00065H10.834V3.33398H16.6673V9.16732H15.0007V6.17982L9.75065 11.4232Z'
        fill={color}
      />{" "}
    </svg>
  );
};

export default ExportIcon;
