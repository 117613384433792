import { StyledTableCell } from "./UserTableMenu";
import { Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";

const TransactionTableMenu = ({ row, pageData }) => {
  return (
    <StyledTableCell align="right" sx={{ paddingRight: "2em" }}>
      <Box>
        <Button variant="outlined">
          <Link
            to={`${row.userId}`}
            state={{
              ...pageData,
            }}
          >
            {"View More"}
          </Link>
        </Button>
      </Box>
    </StyledTableCell>
  );
};

export default TransactionTableMenu;
