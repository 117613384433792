import { useTheme } from "@emotion/react";
import { Grid, List, ListItemText, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { Paragraph, Span } from "components/typography/Typography";
import { useOutletContext } from "react-router-dom";
import { formatString } from "utils/utilFn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {  IconButton } from "@mui/material";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { deleteRole } from "services/https/roleAndAccessService";
import { EDIT_CUSTOM_ROLE } from "utils/constant"
import { useDispatch } from "react-redux";




const Item = styled("div")(() => ({
  "& h3,p": {
    margin: "0",
  },
}));

const user = [
    "Adeyanju Akorede",
    "Philip Templar",
    "Adeyanju Gabriel",
    "Clever Eziogor",
    "Samuel Aniefiok",
  ];

const permission = [
    { name: "Can view dashboard" },
    { name: "Can create role and access for Super Admin" },
    { name: "Can create role and access for others" },
    { name: "Can View all Personal Users" },
    { name: "Can Manage all Personal Users" },
    { name: "Can VIew all Corporate Users" },
    { name: "Can Manage All Corporate Users" },
    { name: "Can View user’s Referals" },
    { name: "Can Manage User’s Referals" },
    { name: "Can Access Waya Official page" },
    { name: "Can View Disputes" },
    { name: "Can Manage Disputes" },
    { name: "Can View QR Codes" },
    { name: "Can Manage QR Codes" },
    { name: "Can View Transactions" },
    { name: "Can Manage Transactions" },
    { name: "Can View OTP" },
    { name: "Can View KYC Configuration" },
    { name: "Can View Admin Tech page" },
    { name: "Can Manage Admin Tech page" },
    { name: "Can View Admin Logs" },
    { name: "Can Manage Admin Logs" },
  ]
  

export const SuperAdmin = ({
  role = "ALL ROLES",
  users = user,
  permissions = permission,
  noPermission=[],
  roleId,
  setOpenDialog,
  setPermission,
  setSelectedIndex,
  setSelectedRoleId,
  handleBtnClick,
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const handleDeleteOk = () => {
    deleteRole({ roleId: roleId })
      .then((data) =>{
        dispatch(
          notifySuccess({ msg: "Role Deleted successfully" })
          )
          setSelectedIndex("ALL ROLES")
          setPermission(permission)
          setSelectedRoleId()
        }
      )
      .catch((err) => notifyFailure({ msg: "Cannot delete Role" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleDelete = () => {
    const payload = {
      title: "Confirm Delete",
      subTitle: "Are you sure you want to delete this Role",
      handleOk: handleDeleteOk,
    };
    dispatch(createDialog({ type: "confirmation", payload }));
    setOpenDialog(true);
  };

  

  return (
    <Grid container spacing={4} sx={{ padding: theme.spacing(4) }}>
      <Grid item xs={12} sx={{ textAlign: "start" }}>
        <Item>
          <h3>
            {role}
          <IconButton 
             onClick={(e) => handleBtnClick(e, EDIT_CUSTOM_ROLE)}
            color="primary" 
            sx={{ visibility: roleId ? 'visible' : 'hidden' }}
            >
            <EditIcon />
          </IconButton>
          <IconButton 
            onClick={handleDelete} 
            color="warning" 
            sx={{ visibility: roleId ? 'visible' : 'hidden' }}
            >
            <DeleteIcon />
          </IconButton>

            </h3>
          <p>{"This role grants users the permissions listed below."}</p>
        </Item>
      </Grid>
      {/* <Grid item xs={12}>
        <Paragraph
          sx={{
            textAlign: "start",
            border: "1px solid grey",
            padding: "8px",
          }}
        >
          <Span>{"10 Members with this role:"}</Span>
          {users?.map((i, idx) => (
            <span key={`${i}-${idx}`}>{`${i}, `}</span>
          ))}
        </Paragraph>
      </Grid> */}
      <Grid item xs={6}>
        <Paper sx={{ textAlign: "start", paddingLeft: theme.spacing(2) }}>
          <List>
            <ListItemText primary={"What this role can do"} />
          </List>
          {permissions?.map((i, idx) => (
            <List key={idx}>
              <ListItemText primary={formatString(i?.name)} />
            </List>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ textAlign: "start", paddingLeft: theme.spacing(2) }}>
          <List>
            <ListItemText primary={"What this role can't do"} />
          </List>
          <List>
            {noPermission?.map((i, idx) => (
              <List key={idx}>
                <ListItemText primary={formatString(i?.name)} />
              </List>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
