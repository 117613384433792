import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";

import folder from "assets/images/folder.svg";
import { useEffect, useState } from "react";
import {
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { paymentDataKeyRepr, subscriptionDataKeyRepr } from "../data";

import Table from "components/table/Table";
import _ from "lodash";
import { SUBSCRIPTION_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import { CopyAble } from "components/tabs/transaction/data";
import { format } from "date-fns";
import { TextToLabel } from "utils/textToLabel";
import Loading from "components/shared/appLoader/AppLoader";
import { getSubscriptionList } from "services/https/paymentLink";

export const SubscriptionList = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [subList, setSubList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { merchantId } = useOutletContext();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    if (merchantId) {
      getSubscriptionList({ merchantId, size: rowsPerPage, page })
        .then((data) => {
          const { content, totalElements } = data || {};
          setSubList({
            content: destructureNeed(content),
            totalElements,
            raw: content,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, rowsPerPage, page]);

  const destructureNeed = (content) => {
    const res = content?.map(
      ({
        customerSubscriptionId,
        customerPaymentLink,
        customerId,
        linkExpirationDate,
        createdAt,
        status,
      }) => ({
        customerSubscriptionId,
        customerId,
        createdAt: format(new Date(createdAt), "MMM dd yyyy hh:mm aaa"),
        id: customerSubscriptionId,
        customerPaymentLink: <CopyAble str={customerPaymentLink} length={20} />,
        linkExpirationDate: format(
          new Date(linkExpirationDate),
          "MMM dd yyyy hh:mm aaa"
        ),
        status: TextToLabel(status),
      })
    );
    return res;
  };
  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) : _.isEmpty(subList.content) ? (
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            height: "40vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={folder} />
            <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
              {"There are no subscriptions links yet!!!"}
            </Typography>
            <Typography>
              {"create a plan first to create subscription"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Table
            dataKeysAndRepr={subscriptionDataKeyRepr}
            dataItemRows={subList.content}
            pageData={subList.raw}
            handleRowClick={(id) => {
              navigate(`${id ?? ""}`, {
                state: merchantId,
              });
            }}
          />
          <TablePagination
            component="div"
            count={subList.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};
