export const TotalSummary = [
  {
    title: "Total Registered Users",
    objKeyRepr:"totalRegisteredUsers"
  },
  {
    title: "Total Active Users",
    objKeyRepr: "activeUsers",
  },
  {
    title: "Total Inactive Users",
    objKeyRepr:"inActiveUsers"
  },
];


export const ReferralSummary = [
  {
    title: "Total Number of Referral Codes Used",
    total: 850,
  },
  {
    title: "Total Users Registered with Referral Code",
    total: 20000,
  },

];

export const FilterReferralBy = [{name:"Location"},{name:"Date"}]


