export const cardData = [
  {
    title: "Settled Amount",
    val: "0",
    objKey: "settlementNetAmount",
  },
  {
    title: "Activity",
    val: "1 transaction(s),1 deduction(s)",
    objKey: "activity",
  },
  {
    title: "Completed on",
    val: "Not Available",
    objKey: "settlementDate",
  },
];
export const SettlementDetailsDataKeysAndRepr = [
  {
    objKey: "settlementReferenceId",
    repr: "Refrence Id",
  },
  {
    objKey: "settlementGrossAmount",
    repr: "Actual Amount",
  },
  {
    repr: "fee",
    objKey: "totalFee",
  },
  {
    objKey: "settlementNetAmount",
    repr: "Settled Amount",
  },
  {
    objKey: "dateCreated",
    repr: "Date",
  },
  {
    objKey: "settlementAccountName",
    repr: "Settlement Account Name",
  },
  {
    objKey: "settlementAccount",
    repr: "Settlement Account",
  },
  {
    objKey: "settlementBeneficiaryAccount",
    repr: "Settlement Beneficial Account",
  },
  {
    objKey: "settlementBankName",
    repr: "Settlement Bank",
  },
  {
    objKey: "settlementDate",
    repr: "Settlement Date",
  },
  {
    objKey: "settlementStatus",
    repr: "Settlement Status",
  },
  {
    objKey: "settlementInitiationDate",
    repr: "Settlement Intiation Date",
  },
  {
    objKey: "settlementInterval",
    repr: "Settlement Interval",
  },
  {
    objKey: "settlementType",
    repr: "Settlement Type",
  },
  {
    objKey: "merchantName",
    repr: "Merchant Name",
  },
  {
    objKey: "payoutStatus",
    repr: "Payout Status",
  },
  {
    objKey: "accountSettlementOption",
    repr: "Settlement Option",
  },
];
      