import { authRoles } from "auth/authRoles";
import OTP from "./OTP";

const OTPRoutes = [
  {
    path: "/otp",
    element: <OTP />,
    auth: authRoles.admin,
  },
];

export default OTPRoutes;
