import {
  Box,
  Button,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import * as _ from "lodash";
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Span } from "components/typography/Typography";
import { useEffect, useState } from "react";
import { format, sub } from "date-fns";
import CustomIcon from "assets/icons";
import { CSVLink } from "react-csv";
import Loading from "components/shared/appLoader/AppLoader";
import { ActivityDataKeysAndRepr } from "./data";
import Table from "components/table/Table";
import { useParams } from "react-router-dom";

export const ActivityLog = () => {
  const [dateRange, setDateRange] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [activities, setActivities] = useState({});
  const [open, setOpen] = useState(false);
  let defaultDate = new Date();
  const myDate = {
    endDate: `${format(defaultDate, "yyyy-MM-dd")}`,
    startDate: `${format(sub(defaultDate, { months: 11 }), "yyyy-MM-dd")}`,
  };
  const [date_, setDate] = useState(myDate);
  const [page, setPage] = useState(0);
  const toggle = () => setOpen(!open);
  const [loading, setLoading] = useState(false);
  const { userId } = useParams();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    setLoading(true);
    if (userId) {
    }
  }, []);

  return (
    <>
      <Box>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "top",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ mr: 2 }}>{"Duration"}</Typography>
            <Box elevation={1} sx={{ p: 1 }}>
              <Span sx={{ verticalAlign: "middle" }}>
                {"From : "}
                <Button
                  sx={{ color: "#000" }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => setOpen(true)}
                >
                  {date_.startDate}
                </Button>
                {"To : "}
                <Button
                  sx={{ color: "#000" }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => setOpen(true)}
                >
                  {date_.endDate}
                </Button>
              </Span>
              <DateRangePicker
                open={open}
                toggle={toggle}
                maxDate={myDate.endDate}
                onChange={({ endDate, startDate }) =>
                  setDateRange({
                    endDate: format(endDate, "yyyy-MM-dd"),
                    startDate: format(startDate, "yyyy-MM-dd"),
                  })
                }
              />
            </Box>
          </Box>
          <Button
            sx={{ height: "fit-content" }}
            variant={"outlined"}
            startIcon={<CustomIcon name="save" />}
            endIcon={<ArrowDropDownIcon />}
          >
            <CSVLink filename={"user-activity.csv"} data={[]}>
              {"Export"}
            </CSVLink>
          </Button>
        </Stack>
      </Box>

      <>
        {loading ? (
          <Box sx={{ width: "100%", height: "40vh" }}>
            <Loading />
          </Box>
        ) : _.isEmpty(activities.content) ? (
          <Box
            sx={{
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              height: "40vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
                {"There are no Activities yet for this user!!!"}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Table
              dataKeysAndRepr={ActivityDataKeysAndRepr}
              dataItemRows={activities.content}
              pageData={activities.raw}
              handleRowClick={(id) => {
                console.log(id);
              }}
            />
            <TablePagination
              component="div"
              count={activities.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </>
        )}
      </>
    </>
  );
};
