import { Feedback } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Stack,
  InputBase,
  Icon,
  useTheme,
  styled,
} from "@mui/material";
import Swal from 'sweetalert2'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import { enableProcessor, settleManually } from "services/https/fetchers";
import { hideLoader, showLoader } from "utils/loader";

export const StyledInputBox = styled(Box)(({ theme }) => {
  return {
    border: `1px solid ${theme.palette.disabled.main}`,
    padding: theme.spacing(2),
    borderRadius: "8px",
    label: {
      display: "block",
      fontWeight: "bold",
    },
    input: {
      border: `1px solid ${theme.palette.disabled.main}`,
      borderRadius: "5px",
      padding: theme.spacing(1),
    },
  };
});

export default function SettleManuallyConfirmation({
  openDialog,
  setOpenDialog,
  user,
}) {
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { settlementAccountName, paymentRefNo, settlementNetAmount, settlementGrossAmount } = payload || {};
  const [amount, setAmount] = useState(settlementNetAmount);
  const handleChange = (e) => {
    setAmount(e.target.value);
  };
 
  const handleSubmit = async (event) => {
    showLoader();
    await settleManually({refId: paymentRefNo})
     .then((res)=>{
      console.log('ggg', res?.response)
      hideLoader()
      if (res?.response?.data?.status) {
        Swal.fire('Done', res?.response?.data?.message);
        
        setOpenDialog(false);
      } else{
        hideLoader();
        setOpenDialog(false);
        console.log('errr', res?.response?.data?.message);
          Swal.fire("Failed!", res?.response?.data?.message);
      }
    }).catch(e => hideLoader())

  };
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          {"Settle Manually"}
        </DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              background: `${theme.palette.primary.light}`,
              p: theme.spacing(2),
              borderRadius: 2,
            }}
          >
            <Icon color={theme.palette.primary.main}>
              <Feedback />
            </Icon>
            <Typography>
              {`You are about to settle ${settlementAccountName}
              Are you sure you want to continue?`}
            </Typography>
          </Box>
          <Stack
            direction={"row"}
            sx={{ justifyContent: "space-between", my: theme.spacing(2) }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {"Full Transaction Amount"}
            </Typography>
            <Typography>{`NGN ${settlementGrossAmount}`}</Typography>
          </Stack>
          <StyledInputBox>
            <label>{"Amount to settle(NGN)"}</label>
            <InputBase
              fullWidth
              value={amount}
              disabled
              onChange={handleChange}
            />
          </StyledInputBox>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "space-between", px: theme.spacing(3) }}
        >
          <Button
            onClick={handleCancel}
            variant={"contained"}
            sx={{
              background: theme.palette.primary.contrastText,
              color: theme.palette.text.primary,
            }}
          >
            {"Cancel"}
          </Button>
          <Button autoFocus variant={"contained"} onClick={handleSubmit}>
            {"Process Settlement"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
