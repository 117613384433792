import axios from "./global";
import CONFIG from "../../config";
import { stateAndLGA } from "components/tabs/kyc/manageKYC/data/stateAndLGData";
import { stateAndCity } from "components/tabs/kyc/manageKYC/data/stateAndCityData";
import { countries } from "components/tabs/kyc/manageKYC/data/countries";
const baseURL = `${CONFIG.API_BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`;
const fileUploadUrl = `${CONFIG.WAYABANK_URL}/file-resource-service/upload/others`;

export const getBusinessType = async () => {
  return await axios
    .get(`${baseURL}/business-industry`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw new Error("cannot fetch data");
    });
};

export const getKycList = async (q) => {
  const params = new URLSearchParams(q).toString();
  return await axios
    .get(`${baseURL}?${params}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => console.log(err));
};

export const getKycById = async ({ merchantId }) => {
  return await axios
    .get(`${baseURL}/${merchantId}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => console.log(err));
};

export const fetchMenuItemByName = async (name, selected) => {
  const { selState, selCity } = selected;
  switch (name) {
    case "businessIndustry":
      return getBusinessType()
        .then((businessTypeList) => {
          return businessTypeList.map(({ name }) => name);
        })
        .catch((err) => ["cannot fetch data"]);
    case "country":
      return new Promise(function (resolve, reject) {
        resolve(countries.map(({ name }) => name));
      });
    case "state":
      return new Promise(function (resolve, reject) {
        resolve(stateAndLGA.map(({ state }) => state));
      });
    case "city":
      return new Promise(function (resolve, reject) {
        if (selState) {
          const itemObj = stateAndCity.find(({ state }) => state === selState);
          resolve(itemObj?.city ?? []);
        } else {
          resolve(["select a state"]);
        }
      });
    case "lga":
      return new Promise(function (resolve, reject) {
        if (selState && selCity) {
          const itemObj = stateAndLGA.find(({ state }) => state === selState);
          resolve(itemObj?.lgas ?? []);
        }
        resolve(["select both state and city"]);
      });
    default:
      break;
  }
};

export const uploadDocument = async ({ filename, file, userId }) => {
  const formData = new FormData();
  formData.append("fileName", filename);
  formData.append("file", file);
  formData.append("userId", 41);

  try {
    const data = await axios.post(`${fileUploadUrl}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return console.log("successful");
  } catch (err) {
    return console.log("err uploading file");
  }
};

export const uploadWithoutFile = async (data) => {
  const { businessIndustry } = data;
  return await getBusinessType()
    .then((businessTypeList) => {
      const found = businessTypeList.find(
        ({ name }) => name === businessIndustry
      );
      const { id } = found;
      return { ...data, businessIndustry: id };
    })
    .then((data) =>
      axios
        .post(`${baseURL}`, data)
        .then((res) => res.data)
        .catch((err) => {
          throw new Error("Error updating data");
        })
    );
};

export const approveRejectKYC = async ({
  merchantId,
  status,
  reasonForRejection,
}) => {
  try {
    const data = await axios.post(
      status === "REJECTED"
        ? `${baseURL}/${merchantId}/change-status/${status}?reasonForRejection=${reasonForRejection}`
        : `${baseURL}/${merchantId}/change-status/${status}`
    );
    return data;
  } catch (err) {
    throw new Error();
  }
};

export const uploadDocx = async (updatedChanges, userId) => {
  const { BUSINESS_CERTIFICATE, BUSINESS_ADDRESS_PROVE, ID_CARD } =
    updatedChanges || {};
  const uploadable = [
    {
      filename: "BUSINESS_CERTIFICATE",
      file: BUSINESS_CERTIFICATE,
    },
    {
      filename: "BUSINESS_ADDRESS_PROVE",
      file: BUSINESS_ADDRESS_PROVE,
    },
    { filename: "ID_CARD", file: ID_CARD },
  ];
  const res = uploadable.map(
    ({ filename, file }) =>
      Boolean(file) && uploadDocument({ filename, file, userId })
  );
  return Promise.all(res);
};

export const updateBusinessIndustry = async (q) => {
  const params = new URLSearchParams(q).toString();
  return await axios
    .patch(`${baseURL}/business-industry?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const deleteBusinessIndustry = async ({ businessIndustryId }) => {
  return await axios
    .delete(`${baseURL}/business-industry/${businessIndustryId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createBusinessIndustry = async (q) => {
  const params = new URLSearchParams(q).toString();
  return await axios
    .post(`${baseURL}/business-industry?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
