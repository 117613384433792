import { useEffect, useState, useContext } from "react";
import { CopyAble, settlementData, SettlementDataKeysAndRepr } from "./data";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Table from "components/table/Table";
import {
  getSettlementList,
  getSettlementStats,
} from "services/https/transactionFetchers";
import { useNavigate } from "react-router-dom";
import { TextToLabel } from "utils/textToLabel";
import { useSelector } from "react-redux";
import _ from "lodash";
import useMenu from "hooks/useMenu";
import { TabContext } from "contexts/TabContext";
import { hideLoader, showLoader } from "utils/loader";

export const SettlementTabPanel = ({ value, index }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [stats, setStats] = useState();
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowChange = (noRow) => {
    setRowsPerPage(noRow?.target?.value);
    setPage(0);
  };
  const [pageData, setPageData] = useState();

  const destructureNeed = (settlementData, count) => {
    console.log('fffs', settlementData);
    const { failedNetSettledRevenue, lastSettlementAmount, nextSettlementAmount } =
      settlementData || {};
    const [settled, pending, failed] = count || [];
    setStats({
      totalSettled: `NGN ${failedNetSettledRevenue ?? "--"}`,
      lastSettlementAmount: `NGN ${lastSettlementAmount ?? "--"}`,
      nextSettlementAmount: `NGN ${nextSettlementAmount ?? "--"}`,
      settled: settled?.count ?? "--",
      pending: pending?.count ?? "--",
      failed: failed?.count ?? "--",
    });
  };

  const {
    search: { searchResult, query },
  } = useSelector((state) => {
    return state;
  });

  const destructureSettlementList = (settlementList) => {
    setPageData(settlementList);
    const data = settlementList.map((settlement) => {
      const {
        settlementBeneficiaryAccount,
        settlementDate,
        settlementGrossAmount,
        settlementNetAmount,
        settlementReferenceId,
        settlementStatus,
        merchantName,
        dateCreated,
        accountSettlementOption,
        dateModified,
        dateSettled,
        createdBy,
        id,
        merchantConfiguredSettlementDate,
        merchantId,
        merchantUserId,
        modifiedBy,
        paymentRefNo,
        payoutStatus,
        settlementAccount,
        settlementAccountName,
        settlementBankCode,
        settlementBankName,
        settlementInitiationDate,
        settlementInterval,
        settlementType,
        totalFee,
      } = settlement;
      return {
        refId: <CopyAble str={settlementReferenceId} />,
        businessName: merchantName,
        beneficiary: settlementBeneficiaryAccount,
        actualAmount: settlementGrossAmount,
        amount: settlementNetAmount,
        createdAt: dateCreated,
        status: TextToLabel(settlementStatus),
        id: settlementReferenceId,
        accountSettlementOption: accountSettlementOption,
        dateModified: dateModified,
        dateSettled: dateSettled,
        createdBy: createdBy,
        id,
        merchSetlDate: merchantConfiguredSettlementDate,
        merchantId,
        merchantUserId,
        modifiedBy,
        paymentRefNo,
        payoutStatus,
        settlementAccount,
        settlementAccountName,
        settlementBankCode,
        settlementBankName,
        settlementInitiationDate,
        settlementInterval,
        settlementType,
        totalFee,
        settlementDate,
      };
    });
    return data;
  };

  useEffect(() => {
    showLoader();
    getSettlementStats().then((data) => {
      const { settlementData, counts } = data || {};
      destructureNeed(data, counts);
    });
    if (!_.isEmpty(searchResult)) {
      const count = searchResult.length;
      setData({
        settlementList: destructureSettlementList(searchResult),
        count,
      });
    } else {
      getSettlementList({ page, size: rowsPerPage }).then((data) => {
        if (data) {
          const { totalElements: count, content } = data;
          setData({
            count,
            settlementList: destructureSettlementList(content),
          });
        }
      });
    }
    hideLoader();
  }, [page, searchResult, rowsPerPage]);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Grid container spacing={2}>
          {settlementData.revenue.map(({ title, objKeyRepr }) => (
            <Grid item xs={4} md={2.5}>
              <Card sx={{ px: 2, paddingBlockEnd: 2 }}>
                <Tooltip
                  title={title}
                  sx={{ left: "90%", position: "relative" }}
                >
                  <IconButton>
                    <LiveHelpIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="body1">{title}</Typography>
                <Typography variant="h6">
                  {(stats && stats[objKeyRepr]) ?? "0"}
                </Typography>
              </Card>
            </Grid>
          ))}
          <Grid item xs={4} md={4}>
            <Card sx={{ p: 1 }}>
              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{ paddingBlockEnd: 2 }}
              >
                {"TRANSACTION’S COUNT"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 2,
                }}
              >
                {settlementData.count.map(({ objKeyRepr, title }) => (
                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant="h6">
                      {(stats && stats[objKeyRepr]) ?? (
                        <CircularProgress size={16} />
                      )}
                    </Typography>
                    <Typography variant="body2">{title}</Typography>
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Table
        dataKeysAndRepr={SettlementDataKeysAndRepr}
        dataItemRows={data?.settlementList}
        pageData={pageData}
        handleRowClick={(id) =>
          navigate(`/transactions/settlement/${id}`, {
            state: pageData,
          })
        }
      />
      <TablePagination
        component="div"
        count={data?.count}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowChange}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};
