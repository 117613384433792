import Tooltip from "@mui/material/Tooltip";
import {
  ClickAwayListener,
  InputBase,
  Paper,
  Button,
  Stack,
  styled,
} from "@mui/material";
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import CustomIcon from "assets/icons";
import { useDispatch } from "react-redux";
import { customSearch } from "utils/customSearch";
import { clearSearch, SearchAction } from "redux/actions/SearchAction";
import _ from "lodash";
import { getKycList } from "services/https/updatedKYCService";
import CustomizedInputBase from "components/search/Search";

const BoxRoot = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(4),
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#fff",
  },
}));

export const StyledBox = styled("div")(({ theme }) => ({
  label: {
    display: "block",
    color: "black",
    fontWeight: "bold",
    fontSize: "16px",
    marginBlock: theme.spacing(1),
  },
  ["& span.MuiSlider-valueLabelLabel"]: {
    color: "#fff",
  },
  ["select,input,textarea"]: {
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: theme.spacing(2),
    ":focus": {
      outlineColor: theme.palette.primary.main,
    },
  },
  span: {
    color: theme.palette.primary.main,
  },
}));
export const KYCFilterSearchExport = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const keys = [
    "businessName",
    "approvalStatus",
    "businessAccountName",
    "businessAccountNumber",
    "businessEmail",
    "businessPhoneNumber",
    "businessRegistrationNumber",
    "merchantId",
  ];
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  /*  useEffect(() => {
    if (_.isEmpty(data)) {
      getKycList({ page: 0, size: 10 }).then((data) => {
        setData(data);
      });
    }
  }, []); */

  return (
    <BoxRoot>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          {
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<FilterForm close={handleTooltipClose} keys={keys} />}
                  arrow
                >
                  <Button
                    onClick={handleTooltipOpen}
                    startIcon={<FilterListIcon />}
                    sx={{ mr: 4 }}
                  >
                    {"Filter"}
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          }

          <CustomizedInputBase searchSpace={"kycList"} keys={keys} />
        </Stack>
        <Button
          variant={"outlined"}
          startIcon={<CustomIcon name="save" />}
          endIcon={<ArrowDropDownIcon />}
        >
          <CSVLink data={data} filename={"kyc-data.csv"}>
            {"Export"}
          </CSVLink>
        </Button>
      </Stack>
    </BoxRoot>
  );
};

export const FilterForm = ({ close, keys }) => {
  const [date_, setDate] = useState({ startDate: "", endDate: "" });
  const [open, setOpen] = useState(false);
  const [alldata, setAllData] = useState();
  const toggle = () => setOpen(!open);
  const [filterObj, setFilterObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getKycList({ page: 0, size: 10 }).then((data) => {
      setAllData(data);
    });
  }, [alldata?.length]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let mydata = alldata;
    if (mydata) {
      const { startDate, endDate } = date_ || {};
      if (endDate && startDate) {
        const result = mydata.filter((d) => {
          let time = new Date(d.tranDate).getTime();
          return new Date(startDate) < time && time < new Date(endDate);
        });
        mydata = result;
      }
      const chainedFilters = Object.entries(filterObj).map(([key, value]) => ({
        [key]: value,
      }));
      if (chainedFilters) {
        customSearch(mydata, { $and: chainedFilters }, keys).then((data) => {
          const searchResult = data.map(({ item }) => item);
          console.log(searchResult);
          dispatch(SearchAction({ searchResult, query: chainedFilters }));
        });
      }
    }
  };
  const handleClear = (event) => {
    setFilterObj({});
    dispatch(clearSearch);
    close();
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFilterObj((filterObj) => ({ ...filterObj, [name]: value }));
  };

  return (
    <Paper sx={{ p: 2, width: "400px" }}>
      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <StyledBox>
          <label>{"Business Name"}</label>
          <InputBase
            placeholder="search"
            fullWidth
            name={"businessName"}
            value={filterObj["businessName"]}
          />
        </StyledBox>
        <StyledBox>
          <label>{"Verification Status"}</label>
          <select name={"approvalStatus"}>
            <option>{"Select status"}</option>
            <option value={"APPROVED"}>{"APPROVED"}</option>
            <option value={"REJECTED"}>{"REJECTED"}</option>
            <option value={"PENDING"}>{"PENDING"}</option>
          </select>
        </StyledBox>
        <StyledBox>
          <label>{"Business Type"}</label>
          <select name={"channel"}>
            <option>{"Select Bussiness type"}</option>
            <option value={"CARD"}>{"REGISTERED BUSINESS"}</option>
          </select>
        </StyledBox>

        <StyledBox>
          <label>{"Verified Date Range"}</label>
          <Paper elevation={1} sx={{ p: 2, width: "fit-content" }}>
            <Stack direction={"row"} sx={{ verticalAlign: "middle" }}>
              {"From : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.startDate}
              </Button>
              {"To : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.endDate}
              </Button>
            </Stack>
            <DateRangePicker
              open={open}
              toggle={toggle}
              onChange={({ startDate, endDate }) =>
                setDate({
                  startDate: format(startDate, "yyyy-MM-dd"),
                  endDate: format(endDate, "yyyy-MM-dd"),
                })
              }
            />
          </Paper>
        </StyledBox>

        <Stack direction={"row"} sx={{ justifyContent: "space-between", m: 2 }}>
          <Button variant={"contained"} onClick={handleClear}>
            {"Clear Filter"}
          </Button>

          <Button variant={"contained"} type={"submit"}>
            {"Apply Filter"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
