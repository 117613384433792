import React from "react";

const SearchIcon = ({ color = "#E7472C", size = 17 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3983 12.6498C9.97893 13.753 8.19274 14.2734 6.40337 14.1051C4.614 13.9368 2.95601 13.0925 1.76699 11.7441C0.577967 10.3957 -0.0526838 8.64459 0.00344984 6.8473C0.0595835 5.05 0.79828 3.34169 2.06913 2.07019C3.33998 0.798688 5.04742 0.059614 6.8438 0.0034516C8.64017 -0.0527108 10.3904 0.578262 11.7381 1.76789C13.0858 2.95753 13.9297 4.61637 14.0979 6.40665C14.2661 8.19693 13.7459 9.98404 12.6434 11.4041L16.7396 15.4871C17.0863 15.8327 17.0869 16.3941 16.7408 16.7404C16.3946 17.0868 15.8332 17.0865 15.4874 16.7399L11.4071 12.6498H11.3983ZM7.07129 12.3671C7.76707 12.3671 8.45604 12.23 9.09887 11.9636C9.74169 11.6972 10.3258 11.3067 10.8178 10.8145C11.3098 10.3222 11.7 9.73784 11.9663 9.09469C12.2326 8.45154 12.3696 7.76221 12.3696 7.06607C12.3696 6.36993 12.2326 5.6806 11.9663 5.03745C11.7 4.3943 11.3098 3.80991 10.8178 3.31767C10.3258 2.82542 9.74169 2.43495 9.09887 2.16855C8.45604 1.90214 7.76707 1.76503 7.07129 1.76503C5.66608 1.76503 4.31843 2.32353 3.3248 3.31767C2.33117 4.31181 1.77296 5.66015 1.77296 7.06607C1.77296 8.47199 2.33117 9.82033 3.3248 10.8145C4.31843 11.8086 5.66608 12.3671 7.07129 12.3671Z'
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
