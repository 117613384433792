import { useEffect, useState } from "react";
import {
  styled,
  Paper,
  Box,
  Button,
  CircularProgress,
  Stack,
  Dialog,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import { H3, Span } from "components/typography/Typography";
import { formInput, initialValues, PaymentPlanValidationSchema } from "./data";
import * as _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { useDispatch } from "react-redux";
import { notifySuccess } from "redux/actions/AlertActions";
import { createPlan } from "services/https/paymentLink";

const FormRoot = styled(Paper)(({ theme }) => ({
  width: "50%",
  margin: `${theme.spacing(2)} auto`,
}));

const StyledBox = styled("div")(({ theme }) => ({
  ".Mui-focused": {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
  },
  label: {
    display: "block",
    marginBlock: theme.spacing(2),
    fontWeight: "bold",
  },
  ["select,input,textarea"]: {
    width: "100%",
    borderRadius: "4px",
    padding: theme.spacing(2),
    border: "2px solid #f6f6f6",
  },
  span: {
    color: theme.palette.primary.main,
  },
}));

export const CreatePaymentPlan = ({ openDialog, setOpenDialog }) => {
  const { userId } = useParams();
  const [merchantId, setMerchantId] = useState();
  useEffect(() => {
    getMerchantById({ userId }).then((data) => {
      const { merchantID } = data || {};
      setMerchantId(merchantID);
    });
  }, [userId]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { width: "50vw", maxWidth: "50vw" },
      }}
    >
      <FormContent merchantId={merchantId} handleClose={handleClose} />
    </Dialog>
  );
};

const FormContent = ({ merchantId, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PaymentPlanValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values, "plan values");
        const val = {
          ...values,
          merchantId,
        };

        createPlan({ plan: val })
          .then((data) => {
            setSubmitting(false);
            dispatch(
              notifySuccess({
                msg: `PAYMENT PLAN CREATED SUCCESSFULLY`,
              })
            );
          })
          .finally(() => {
            handleClose();
            navigate(pathname);
          });
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <FormRoot>
            <H3
              sx={{
                p: 2,
                textAlign: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "5px",
              }}
            >
              {"Create New Plan"}
            </H3>
            {formInput.map((i, idx) => (
              <Box sx={{ m: 4 }} key={idx}>
                {i.elements ? (
                  <>
                    <label>{i.label}</label>
                    <StyledBox sx={{ display: "flex" }}>
                      {i.elements.map(({ type, options, placeholder, name }) =>
                        type === "select" ? (
                          <Field
                            as="select"
                            name={i.name}
                            style={{
                              height: "52px",
                              width: `${
                                i.name === "amount" ? "100px" : "100%"
                              }`,
                              marginRight: "8px",
                            }}
                          >
                            {options.map(({ name, value }) => (
                              <option name={name} value={value}>
                                {name}
                              </option>
                            ))}
                          </Field>
                        ) : (
                          <Box>
                            <Field name={name} placeholder={placeholder} />

                            <ErrorMessage name={"planAmount"} />
                          </Box>
                        )
                      )}
                    </StyledBox>
                    <Span color={"primary.main"}>
                      <ErrorMessage name={"payableAmount"} />
                    </Span>
                  </>
                ) : i.type === "textArea" ? (
                  <StyledBox>
                    <label>{i.label}</label>
                    <Field as="text-area" name={i.name} />
                  </StyledBox>
                ) : (
                  <StyledBox>
                    <label>{i.label}</label>
                    <Field name={i.name} placeholder={i.placeholder} />
                    <Span>
                      <ErrorMessage name={i.name} />
                    </Span>
                  </StyledBox>
                )}
              </Box>
            ))}

            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", p: 4 }}
            >
              <Button
                variant={"contained"}
                sx={{ mr: 2 }}
                onClick={() => handleClose()}
              >
                {"Cancel"}
              </Button>
              <Button variant={"contained"} type={"submit"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>{"Create Plan"}</span>
                  {isSubmitting ? (
                    <CircularProgress
                      sx={{ color: "#fff", marginLeft: "5px" }}
                      size={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </Stack>
          </FormRoot>
        </form>
      )}
    </Formik>
  );
};
