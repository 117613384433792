import * as yup from "yup";

export const formInput = [
  {
    label: "Transaction Amount *",
    name: "transactionAmount",
  },
  {
    label: "Merchant ID *",
    name: "merchantId",
  },

  {
    label: "Customer ID *",
    name: "customerId",
  },
  {
    label: "Transaction Date *",
    name: "transactionDate",
    type: "date",
  },
];

export const validationSchema = yup.object().shape({
  transactionReference: yup
    .string("Enter Transaction Refrence Id")
    .required("Transaction reference is required"),
  customerId: yup
    .string("Enter Customer Id")
    .required("Customer Id is required"),
  merchantId: yup
    .string("Enter Merchant Id")
    .required("Merchant Id is required"),
  transactionAmount: yup
    .number("Enter the transaction amount")
    .required("The Transaction amount is required")
    .typeError("Transaction amount must be a number")
    .min(0, "Transaction amount cannot be zero"),
  transactionDate: yup
    .string("Enter date of transaction")
    .required("Enter date of transaction"),
  reasonForDisputeInDetails: yup
    .string("Dispute reason")
    .required("Provide a reason for this dispute"),
  disputeType: yup
    .string("Choose a dispute type")
    .required("Select a dispute type"),
});

export const lostDisputeValidationSchema = yup.object().shape({
  message: yup
    .string("Enter reason why dispute is lost")
    .required("Reason why dispute is lost is required"),
});

export const initialVal = {
  transactionReference: "",
  transactionAmount: "",
  transactionDate: "",
  merchantId: "",
  customerId: "",
  disputeType: "",
  reasonForDisputeInDetails: "",
};
