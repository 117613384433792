import { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { Space, Spin} from 'antd'

import { H3, Span } from "components/typography/Typography";
import { useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CustomOutlinedInput from "components/input";
import { notifySuccess, notifyFailure } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import {
  createAdminUser,
  getAllRoles,
} from "services/https/roleAndAccessService";
import _ from "lodash";
import { createRequest, getMerchantAccounts, getMerchantById, getMerchantWallet } from "services/https/fetchers";
import { createAdminForm, initialVal, validationSchema} from "components/dialog/usersDialogs/adminData";
import { Col, Row, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import Loading from "components/shared/appLoader/AppLoader";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "fit-content",
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "100%",
    padding: theme.spacing(2),
  },
  span: {
    color: theme.palette.primary.main,
    marginTop: "4px",
  },
}));

export const WithDrawalModal = ({ openDialog, setOpenDialog }) => {
  const [err, setErrMsg] = useState("");
  const dispatch = useDispatch();  
  const { userId } = useParams();
  const [merchantId, setMerchantId] = useState();
  const [wallets, setWallets] = useState([])
  const [selectedWallet, setSelectedWallet] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('')
  const [amount, setAmount] = useState('');
  const [bal, setBal] =  useState(0);
  const [options, setOptions] = useState({
    gender: [
      { name: "MALE", value: "MALE" },
      { name: "FEMALE", value: "FEMALE" },
    ],
  });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
    // formik.handleReset();
    //setInitialValues(initialValues);
  };
  let dialogRef = useRef();

  const getAccounts =(merchantId)=> {
    getMerchantAccounts(merchantId).then(res=>{
        setAccounts(res?.data);
        console.log('datta', res?.data)
   
    }).catch(err=>console.log(err));

  }
  const getWallets =(merchantId)=> {
    setLoading(true)
    getMerchantWallet(merchantId).then(res=>{
      setLoading(false)
      if(res?.data?.status){
        setWallets(res?.data?.data);
      }else{
        console.log(res)
      }
    }).catch(err=>console.log(err));

  }

  const handleSubmit =async() => {
    const { bankCode, accountName, bankName, accountNumber, merchantId} = JSON.parse(selectedAccount);
    setLoading(true)
    let  postData = {
        accountNo: accountNumber,
        bankCode: bankCode,
        bankName: bankName,
        accountName: accountName,
        sourceAccount:selectedWallet,
        merchantId: merchantId,
        amoun: amount
      }
      
    let d = new Date()
    let payload = {
     customerName: `${JSON.parse(selectedAccount)?.accountName}`,
     payload: JSON.stringify(postData),
     requestId: d.getTime(),
     requestType: "withdrawal"
    }
    createRequest(payload)
    .then((data) => {
      setLoading(false)
      if(data.status){
        setAmount('');
        setSelectedAccount('')
      dispatch(
        notifySuccess({ msg: data?.message|| data })
      );
      }else{
        dispatch(
        notifyFailure({msg: data})
        );
      }
    })
    .finally(() => {
      setLoading(false);
      handleClose()
    });
  }

  useEffect(() => {
    setLoading(true)
    getMerchantById({ userId }).then((data) => {
      const { merchantID } = data || {};
      setMerchantId(merchantID);
      getWallets(merchantID);
      getAccounts(merchantID);
      setLoading(false)
    });
  }, [userId, merchantId]);

  useEffect(() => {
    
  }, []);
  return (
  
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle>
        <Box
          ref={(node) => (dialogRef.current = node)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H3>{"Withdraw payment to your bank"}</H3><br/>
          <IconButton
            color="primary"
            aria-label="close"
            component="span"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ m: "auto", textAlign: "center", color: "primary.main" }}>
        {//<h6> Kindly note that N100 naira fixed charge will be debited </h6>
          }         
          {err}
        </Box>
      </DialogTitle>    

          <form >
          {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) :
          <DialogContent>            
            <div className="">
          <div className="">            
            <p className=" ">
            </p>
            
            <div className="">
            <div>
                <label className="text-sm">
                  Select Merchant
                </label>
                <br/>
                <select    style={{height:40}}            
                  onChange={(e) => {
                    console.log(wallets)
                    setSelectedWallet(e.target.value)
                  }}
                  className=""
                  placeholder={<>Select Merchant</>}
                >
                  <option value=''>Select Merchant</option>
                  {wallets?.map((e,i)=>
                    <option value={(e.accountNo)}>{e.accountNo}-{e.acct_name}</option>
                    )}
                  </select>
              </div>

              <div className="">
                <label className="">Balance</label>
                <input
                  type=""
                  value={`₦${(wallets.filter(e=>e.accountNo==selectedWallet))[0]?.clr_bal_amt??`0.00`}`}
                 style={{margin: 10, heigth:'200px', width:'40%'}}
                  placeholder="₦0.00"
                />
              </div>
              <div className="">
                <label className=" ">Amount to withdraw</label>
                <input 
                  type=""
                  style={{margin: 10, heigth:'100px', }}
                  placeholder="₦10,000.00"
                  value={amount}
                  onChange={(e)=>setAmount(e.target.value)}
                />
              </div>
              
              <div>
                <label className="">
                  Select preffered account to withdraw to{" "}
                  <br/>
                </label>
                <select     
                style={{height:40, width:'50%'}}           
                  onChange={(e) => {setSelectedAccount(e.target.value) }
                  }
                  className=""
                >
                  <option value=''>select Bank</option>
                { accounts?.map(e=>
                  <option value={JSON.stringify(e)}>{`${e.accountNumber}-${e.bankName}`}</option>
                )
              }
              </select>
              </div>
            </div>
          </div>
          </div>
                   
                   
                       
                
            </DialogContent>}
            
            <DialogActions sx={{ justifyContent: "center", p: 4 }}>
              <Button
                sx={{ width: "156px" }}
                type={"submit"}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Span>{"Cancel"}</Span>               
                </Box>
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "156px" }}
                onClick={handleSubmit}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Span>{"Withdraw"}</Span>
                 
                </Box>
              </Button>
            </DialogActions>
          </form>
    </Dialog>
      
  );
};
