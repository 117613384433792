import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CustomOutlinedInput from "components/input";
import * as _ from "lodash";
import { uuid4 } from "@sentry/utils";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import {editRole} from "services/https/roleAndAccessService";
import { Span } from "components/typography/Typography";

const EditRoleModal = ({ openDialog, setOpenDialog, payload={} }) => {
  const { roleName='', roleDescription='', rolePermissions=[], roleId='', allPermissions = [] } = payload;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState(rolePermissions || []);
  const [sPermissions, setSPermissions] = useState([]);
  const [formData, setFormData] = useState({
    roleName: '',
    description: ''
  });

  useEffect(()=> {
    setFormData({
      roleName,
      description: roleDescription,
    })
    setSPermissions(
      rolePermissions.map(i => i.name)
    )
  }, [roleName, rolePermissions])

  const handleClose = () => {
    setOpenDialog(false);
    setSPermissions([]);
  };

  const handleClick = () => {
    const permission = selectedPermissions.filter(item => !sPermissions.includes(item.name));
    const removePermission = rolePermissions.filter(item => !selectedPermissions.map(p => p.name).includes(item.name));
    console.table({permission, removePermission, rolePermissions, sPermissions})
    editRole({ ...formData, roleId, permission, removePermission })
      .then((data) =>
        dispatch(notifySuccess({ msg: "Role Edited successfully" }))
      )
      .catch((err) => notifyFailure({ msg: "Cannot Edit Role" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChecked = (e, name, description, id) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedPermissions([
        ...selectedPermissions,
        { name, description, id },
      ]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((item) => item.id !== id)
      );
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle>{"Edit Custom Role"}</DialogTitle>
      <DialogContent>
        <CustomOutlinedInput
          onChange={handleChange}
          name={"roleName"}
          label={"Role Name"}
          placeholder={"Give this role a name"}
          // onChange={handleChange}
          defaultValue={formData?.roleName}
        />
        <CustomOutlinedInput
          label={"Role Description"}
          placeholder={"Give this role a description"}
          onChange={handleChange}
          name={"description"}
          defaultValue={formData?.description}
        />
        <FormGroup>
          {allPermissions.map(({ name, description, id }) => (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  name={name}
                  onChange={(e) => handleChecked(e, name, description, id)}
                  defaultChecked={sPermissions.includes(name)}
                />
              }
              name={id}
              label={name}
            />
          ))}
          {/* <Button variant={"contained"} onClick={handleClick}>
            {"Create custom role"}
            {loading && (
              <CircularProgress sx={{ ml: 2 }} size={20} color={"inherit"} />
            )}
          </Button> */}
        </FormGroup>
        <Button
          variant={"contained"}
          fullWidth
          sx={{ mx: 2 }}
          onClick={handleClick}
          disabled={!Boolean(selectedPermissions.length)}
        >
          <Span>{"Edit Role"}</Span>
          {loading && (
            <Span sx={{ ml: 1, mt: "4px" }}>
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            </Span>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditRoleModal;
