import { red } from "@mui/material/colors";
import { components } from "./components";

const themeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: "Lato",
    fontWeight: 500,
    body1: {
      fontSize: "16px",
    },
  },

  status: {
    danger: red[500],
  },
  components: {
    ...components,
  },
};

export default themeOptions;
