import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CustomOutlinedInput from "components/input";
import * as _ from "lodash";
import { uuid4 } from "@sentry/utils";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import { getAllPermisions, createCustomRole } from "services/https/roleAndAccessService";
import { Span } from "components/typography/Typography";


const CreateCustomRolesModal = ({ openDialog, setOpenDialog, payload }) => {
  const dispatch = useDispatch();
  const [permissions, setPermissionList] = useState(payload?.allPermissions || []);
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // console.log(state);
    // getAllPermisions().then((data) => {
    //   const perm = [];
    //   data?.map(({ name, description, id }) => {
    //     if (name && name !== undefined) {
    //       perm.push({ name, description, id });
    //     }
    //   });
    //   setPermissionList(perm);
    // });
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClick = () => {
    console.log({formData, selectedPermissions})
    createCustomRole({ ...formData, permissions: selectedPermissions })
      .then((data) =>
        dispatch(
          notifySuccess({ msg: "Role created successfully" })
        )
      )
      .catch((err) => notifyFailure({ msg: "Cannot delete Role" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleChecked = (e, name, description, id) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedPermissions([...selectedPermissions, {name, description, id}]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((item) => item.id !== id)
      );
    }
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle>{"Create Custom Role"}</DialogTitle>
      <DialogContent>
        <CustomOutlinedInput
          onChange={handleChange}
          name={"role"}
          label={"Role Name"}
          placeholder={"Give this role a name"}
          // onChange={handleChange}
          // name={"role"}
        />
        <CustomOutlinedInput
          label={"Role Description"}
          placeholder={"Give this role a description"}
          onChange={handleChange}
          name={"description"}
        />
        <FormGroup>
          {permissions.map(({ name, description, id }) => (
            <FormControlLabel 
              control={<Checkbox
                name={name}
                onChange={(e) => handleChecked(e, name, description, id)} 
              />} 
              name={id} 
              label={name} 
            />
          ))}
          {/* <Button variant={"contained"} onClick={handleClick}>
            {"Create custom role"}
            {loading && (
              <CircularProgress sx={{ ml: 2 }} size={20} color={"inherit"} />
            )}
          </Button> */}
        </FormGroup>
        <Button
          variant={"contained"}
          fullWidth
          sx={{ mx: 2 }}
          onClick={handleClick}
          disabled={!Boolean(selectedPermissions.length)}
        >
          <Span>{"Create Role"}</Span>
          {loading && (
            <Span sx={{ ml: 1, mt: "4px" }}>
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            </Span>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCustomRolesModal;
