import React, { useState, useEffect, useCallback, useRef } from "react";
import useAuth from "hooks/useAuth";
import { differenceInMinutes } from "date-fns";

const SessionTimeout = () => {
  const [events, setEvents] = useState(["click", "load", "scroll", "wheel"]);
  const { isAuthenticated, logout } = useAuth();

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
    if (storedTimeStamp) {
      startTimerInterval.current = setTimeout(() => {
        warningInactive(storedTimeStamp);
      }, 300);
    }
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 50;
      const minPast = differenceInMinutes(new Date(), new Date(timeString));
      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        logout();
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (isAuthenticated) {
      timeStamp = new Date();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer, false);
    });
    timeChecker();
    return () => {
      clearTimeout(startTimerInterval.current);
      //   resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  // change fragment to modal and handleclose func to close
  return <></>;
};

export default SessionTimeout;
