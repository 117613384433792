import React from "react";
import VerifiedIcon from "components/Icons/VerifiedIcon";
import ThreeVerticalDotsIcon from "components/Icons/ThreeVerticalDotsIcon";
import AccountTypeDropdown from "./AccountTypeDropdown";

const AccountTypeCard = ({
  accountType,
  amountAvailable,
  accountName,
  accountNumber,
  isVerified,
}) => {
  return (
    <div className='account-type-card-container'>
      <div className='account-type-card__top'>
        {isVerified && <VerifiedIcon />}

        <p className='account-type-card__type'>{accountType}</p>

        <div className='account-type-card__more-btn-container'>
          <button className='account-type-card__more-btn'>
            <ThreeVerticalDotsIcon />
          </button>
          <AccountTypeDropdown show={false} />
        </div>
      </div>

      <p className='account-type-card__amount'>{amountAvailable}</p>

      <div className='account-type-card__detail-container'>
        <div className='account-type-card__detail'>
          <p className='account-type-card__title'>{"Account Name"}</p>
          <p className='account-type-card__content'>{accountName}</p>
        </div>

        <div className='account-type-card__detail'>
          <p className='account-type-card__title'>{"Account Number"}</p>
          <p className='account-type-card__content'>{accountNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountTypeCard;
