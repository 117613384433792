import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import CreateBulkUserDialog from "components/dialog/CreateBulkUserDialog";
import DeactivateUserDialog from "components/dialog/DeactivateUserDialog";
import { WithDrawalModal } from "components/shared/filterSearchExport/transactionHeader/withdrawalModal";
import {
  CREATE_CUSTOM_ROLE,
  KYC_BUSINESS_INDUSTRY,
  KYC_CONFIRMATION,
  KYC_SUCCESSFUL,
  PREVIEW_IMAGE,
  SETTLE_MANUALLY,
  TX_ROUTING,
  ADMIN_MODAL,
  EDIT_CUSTOM_ROLE,
  CREATE_PROCESS
} from "utils/constant";
import BulkUserNotifyDialog from "../customers/BulkUserNotifyDialog";
import { CreateCustomer } from "../customers/CreateCustomer";
import { CreateMerchantDispute } from "../disputeDialogs/CreateMerchantDispute";
import LostDisputeDialog from "../disputeDialogs/LostDisputeDialog";
import { WonDisputeDialog } from "../disputeDialogs/WonDisputeDialog";
import { InviteViaSociaMedia } from "../InviteViaSociaMedia";
import { BusinessIndustryKYC } from "../kyc/BusinessIndustryKYC";
import { KYC_ConfirmationDialog } from "../kyc/KYC_ConfirmationDialog";
import { KYCSuccessful } from "../kyc/KYC_Successful";
import { OTPButtonDialog } from "../OTPButtonDialog";
import OtpViaDialog from "../OtpViaDialog";
import { CreatePaymentLink } from "../paymentLink/CreatePaymentLink";
import { CreatePaymentPlan } from "../paymentLink/PaymentPlan/CreatePaymentPlan";
import { CreateSubscriptionPlan } from "../paymentLink/SubscriptionPlan/CreateSubscriptionPlan";
import { EditPricingDialog } from "../pricingDialogs/EditPricingDialog";
import SelectCustomUserDialog from "../pricingDialogs/SelectCustomUserDialog";
import SettleManuallyConfirmation from "../transactionDialog/SettleManuallyConfirmation";
import ChangeProcessor from "../txRouting/ChangeProcessor";
import AdminModal from "../usersDialogs/AdminModal";
import { CreateAdminUserDialog } from "../usersDialogs/CreateAdminUserDialog";
import CreateCustomRolesModal from "../usersDialogs/CreateCustomRolesModal";
import { CreateSingleUserDialog } from "../usersDialogs/CreateSingleUserDialog";
import EditRoleModal  from "../usersDialogs/EditRoleModal";
import PreviewDialog from "../util/PreviewDialog";
import { WithDrawalDialog } from "../WithdrawalDialog";
import CreateProcessor from "../txRouting/createProcessor";

export const DialogFactory = (props) => {
  return <>{props.render()}</>;
};

export const dialogOptions = {
  createPaymentLink: <CreatePaymentLink />,
  createSubscriptionPlan: <CreateSubscriptionPlan />,
  createMerchantDispute: <CreateMerchantDispute />,
  lostDisputeDialog: <LostDisputeDialog />,
  wonDisputeDialog: <WonDisputeDialog />,
  deactivateUser: <DeactivateUserDialog />,
  createSingleUser: <CreateSingleUserDialog />,
  createAdminUser: <CreateAdminUserDialog />,
  [CREATE_CUSTOM_ROLE]: <CreateCustomRolesModal />,
  createBulkUser: <CreateBulkUserDialog />,
  confirmation: <ConfirmationDialog />,
  otpButtonBox: <OTPButtonDialog />,
  otpVia: <OtpViaDialog />,
  inviteViaSocialMedia: <InviteViaSociaMedia />,
  editPricing: <EditPricingDialog />,
  selectCustomUser: <SelectCustomUserDialog />,
  createPaymentPlan: <CreatePaymentPlan />,
  createCustomer: <CreateCustomer />,
  bulkUserNotifyDialog: <BulkUserNotifyDialog />,
  [CREATE_PROCESS]: <CreateProcessor />,
  [PREVIEW_IMAGE]: <PreviewDialog />,
  [TX_ROUTING]: <ChangeProcessor />,
  [KYC_CONFIRMATION]: <KYC_ConfirmationDialog />,
  [KYC_SUCCESSFUL]: <KYCSuccessful />,
  [KYC_BUSINESS_INDUSTRY]: <BusinessIndustryKYC />,
  [SETTLE_MANUALLY]: <SettleManuallyConfirmation />,
  [ADMIN_MODAL]: <AdminModal />,
  [EDIT_CUSTOM_ROLE]: <EditRoleModal />,
  withdrawToBank: <WithDrawalModal />,

  clear: <></>,
};
