import React, { useState } from "react";
import CancelIcon from "../../../../../components/Icons/CancelIcon";
import DropIcon from "../../../../../components/Icons/DropIcon";
import SuccessIcon from "../../../../../components/Icons/SuccessIcon";
import SelectDropdown from "../ModalSelectDropdown";
import "./styles.css";

const TRANSACTION_ITEMS = [10, 25, 50, 100];

const SuccessfulStatusModal = ({ show, onCloseBtnClick }) => {
  if (!show) return null;
  return (
    <div className='successful-status-modal'>
      <div className='successful-status-main-content'>
        <div className='success-main-body'>
          <div className='success-icon-content'>
            <p>
              <SuccessIcon />
            </p>
          </div>
          <p className='successful-title'>{"Successful"}</p>
          <p className='successful-description'>{"Your account was funded successfully"}</p>
        </div>

        <div className='success-btn-container'>
          <button className='' onClick={onCloseBtnClick}>
            {"OK"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulStatusModal;
