import { useEffect, useState } from "react";
import {
  styled,
  Paper,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  InputBase,
  Icon,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { H3, Span } from "components/typography/Typography";
import {
  formInput,
  initialValues,
  oneTimePaymentLinkValidationSchema,
} from "../data";
import * as _ from "lodash";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  createNewPaymentLink,
  updatePaymentLink,
} from "services/https/paymentLink";
import { createRequest, getMerchant, getMerchantById } from "services/https/fetchers";
import { useDispatch } from "react-redux";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";

const FormRoot = styled(Paper)(({ theme }) => ({
  width: "50%",
  margin: `${theme.spacing(2)} auto`,
}));

const StyledBox = styled("div")(({ theme }) => ({
  ".Mui-focused": {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
  },
  label: {
    display: "block",
    marginBlock: theme.spacing(2),
    fontWeight: "bold",
  },
  ["select,input,textarea"]: {
    width: "100%",
    borderRadius: "4px",
    padding: theme.spacing(2),
    border: "2px solid #f6f6f6",
  },
  span: {
    color: theme.palette.primary.main,
  },
}));

export const CreateOneTimePaymentLink = ({ detail }) => {
  const { userId } = useParams();
  const [merchantId, setMerchantId] = useState();
  const updateInitial = {};

  if (!_.isEmpty(detail)) {
    Object.keys(initialValues).forEach((key) => {
      updateInitial[key] = detail[key] ?? initialValues[key];
    });
  }
  useEffect(() => {
    getMerchantById({ userId }).then((data) => {
      const { merchantID } = data || {};
      setMerchantId(merchantID);
    });
  }, [userId]);

  return (
    <Paper elevation={0}>
      <Box>
        <FormContent merchantId={merchantId} detail={updateInitial} />
      </Box>
    </Paper>
  );
};

const FormContent = ({ merchantId, detail }) => {
  const [showOthers, setShowOthers] = useState(false);
  const [fd, addField] = useState([]);
  const navigate = useNavigate();
  const { paymentLinkId } = useParams();
  const dispatch = useDispatch();
  const handleAddField = () => {
    addField((fd) => [...fd, { name: `input${fd.length}`, value: "" }]);
  };

  const handleXtraFieldClose = (e) => {
    const { name } = e.target;
    const idx = fd.findIndex((i) => i.name === name);

    if (idx != -1) {
      let fdCopy = [...fd];
      fdCopy.splice(idx, 1);
      addField(fdCopy);
    }
  };
  const handleCustomChange = (e) => {
    const { name, value } = e.target;
    const idx = fd.findIndex((i) => i.name === name);
    const newObj = { ...fd[idx], value: value };
    let fdCopy = [...fd];
    fdCopy.splice(idx, 1, newObj);
    addField(fdCopy);
  };
  return (
    <Formik
      initialValues={detail || initialValues}
      validationSchema={oneTimePaymentLinkValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        let otherDetailsJSON = {};
        fd.map(({ name, value }) => {
          otherDetailsJSON[name] = value;
        });

        const val = {
          ...values,
          merchantId,
          otherDetailsJSON: JSON.stringify(otherDetailsJSON),
          paymentLinkType: "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK",
        };

        if (_.isEmpty(detail?.paymentLinkName)) {
         // createNewPaymentLink({ newPaymentLink: val })
         let d = new Date()
         let payload = {
          customerName: merchantId,
          payload: JSON.stringify(val),
          requestId: d.getTime(),
          requestType: "oneTimePayLink"
         }
         createRequest(payload)
          .then((data) => {
            setSubmitting(false);
            dispatch(
              notifySuccess({
                msg: `ONE TIME PAYMENT LOG SUCCESSFULLY, WAITING FOR APPROVAL`,
              })
            );
          });
        } else {
          updatePaymentLink({
            newPaymentLink: { ...val, paymentLinkId, status: "ACTIVE" },
          })
            .then((data) => {
              setSubmitting(false);
              dispatch(
                notifySuccess({
                  msg: `ONE TIME PAYMENT LINK UPDATED SUCCESSFULLY`,
                })
              );
            })
            .catch((data) => {
              setSubmitting(false);
              dispatch(
                notifyFailure({
                  msg: `PAYMENT LINK UPDATE FAIL`,
                })
              );
            });
        }
        navigate(-1);
      }}
    >
      {({ handleSubmit, isSubmitting, handleChange, handleBlur }) => (
        <form onSubmit={handleSubmit}>
          <FormRoot>
            <H3
              sx={{
                p: 2,
                textAlign: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "5px",
              }}
            >
              {"Create One-Time Subscription Payment Link"}
            </H3>
            {formInput.main.map((i, idx) => (
              <Box sx={{ m: 4 }} key={idx}>
                {i.elements ? (
                  <>
                    <label>{i.label}</label>
                    <StyledBox sx={{ display: "flex" }}>
                      {i.elements.map(({ type, options, placeholder, name }) =>
                        type === "select" ? (
                          <Field
                            as="select"
                            name={i.name}
                            style={{
                              width: "100px",
                              marginRight: "8px",
                            }}
                          >
                            {options.map(({ name, value }) => (
                              <option name={name} value={value}>
                                {name}
                              </option>
                            ))}
                          </Field>
                        ) : (
                          <Field name={name} placeholder={placeholder} />
                        )
                      )}
                    </StyledBox>
                    <Span color={"primary.main"}>
                      <ErrorMessage name={"payableAmount"} />
                    </Span>
                  </>
                ) : i.type === "textArea" ? (
                  <StyledBox>
                    <label>{i.label}</label>
                    <Field as="text-area" name={i.name} />
                  </StyledBox>
                ) : (
                  <StyledBox>
                    <label>{i.label}</label>
                    <Field name={i.name} placeholder={i.placeholder} />
                    <Span>
                      <ErrorMessage name={i.name} />
                    </Span>
                  </StyledBox>
                )}
              </Box>
            ))}

            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Divider />
              <Button
                variant={"text"}
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => {
                  setShowOthers(!showOthers);
                }}
              >
                {"Show more options"}
              </Button>
            </Box>
            {showOthers && (
              <Box sx={{ m: 4 }}>
                {formInput.others.map((i) => (
                  <StyledBox>
                    <label>{i.label}</label>
                    <InputBase
                      placeholder={i.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{ border: "2px solid #f6f6f6", width: "100%" }}
                      startAdornment={
                        i.inputAdornment && (
                          <Span
                            sx={{
                              background: "#f6f6f6",
                              p: 2,
                              width: "fit-content",
                            }}
                          >
                            {i.inputAdornment}
                          </Span>
                        )
                      }
                      name={i.name}
                    />
                  </StyledBox>
                ))}
                <StyledBox>
                  <label>
                    {"Add additional fields to collect more information"}
                  </label>
                  {fd.map((i, idx) => (
                    <InputBase
                      onChange={handleCustomChange}
                      sx={{ border: "2px solid #f6f6f6", width: "100%", mt: 2 }}
                      startAdornment={
                        <Span
                          sx={{
                            background: "#f6f6f6",
                            p: 2,
                            width: "180px",
                          }}
                        >
                          {"Custom Field"}
                        </Span>
                      }
                      endAdornment={
                        <Icon
                          name={i.name}
                          onClick={handleXtraFieldClose}
                          component={"button"}
                          sx={{
                            width: "48px",
                            height: "100%",
                            p: 1.8,
                            cursor: "pointer",
                            border: "none",
                            background: "#f6f6f6",
                          }}
                        >
                          close
                        </Icon>
                      }
                      name={i.name}
                    />
                  ))}
                </StyledBox>
                <Button startIcon={<AddIcon />} onClick={handleAddField}>
                  {"Add more fields"}
                </Button>
              </Box>
            )}
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", p: 4 }}
            >
              <Button onClick={() => navigate(-1)}>{"<< Go Back"}</Button>
              <Box>
                <Button
                  variant={"contained"}
                  sx={{ mr: 2 }}
                  onClick={() => navigate(-1)}
                >
                  {"Cancel"}
                </Button>
                <Button variant={"contained"} type={"submit"}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>{"Create Link"}</span>
                    {isSubmitting ? (
                      <CircularProgress
                        sx={{ color: "#fff", marginLeft: "5px" }}
                        size={20}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Button>
              </Box>
            </Stack>
          </FormRoot>
        </form>
      )}
    </Formik>
  );
};
