import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { notifySuccess } from "redux/actions/AlertActions";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { Span } from "components/typography/Typography";
import { useParams } from "react-router-dom";
import { resolveDispute } from "services/https/disputeService";

export const WonDisputeDialog = ({ openDialog, setOpenDialog }) => {
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState();
  const handleClose = () => {
    setOpenDialog(false);
    //setInitialValues(initialVal);
  };
  const handleSubmit = () => {
    setSubmitting(true);
    resolveDispute({
      disputeId,
      status: "MERCHANT_WON",
    })
      .then(({ data }) => {
        dispatch(notifySuccess({ msg: "Dispute resolved succesfully" }));
      })
      .catch((err) => {})
      .finally(() => {
        setOpenDialog();
        setSubmitting(false);
      });
  };
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%" } }}
        maxWidth="xs"
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <FeedbackIcon color={"primary"} />
          <Typography variant={"h5"}>{"Dispute Won ?"}</Typography>
          <Typography variant={"body1"}>
            By accepting this,you agree that merchant have{" "}
            <Typography color={"success"}>{"WON"}</Typography>this dispute
            transaction, the merchant will be refunded/keep the amount received
            from the customer.
          </Typography>
        </DialogTitle>

        <DialogActions
          sx={{
            justifyContent: "center",
            width: "100%",
            my: 3,
            pt: 0,
            columnGap: 2,
          }}
        >
          <Button
            variant={"contained"}
            sx={{ backgroundColor: "#fff", color: "black" }}
            onClick={handleClose}
          >
            {"Cancel"}
          </Button>
          <Button variant={"contained"} onClick={handleSubmit}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Span>{"Yes, Accept"}</Span>
              {submitting ? (
                <CircularProgress
                  sx={{ color: "#fff", marginLeft: "5px" }}
                  size={20}
                />
              ) : (
                ""
              )}
            </Box>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
