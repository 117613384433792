import { Alert, Snackbar } from "@mui/material";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

export const Log = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    alerts: { alertType, msg, dialogType },
  } = useSelector((state) => {
    return state;
  });

  return (
    <div>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={"info"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Outlet context={{ setOpenDialog, openDialog }} />
    </div>
  );
};
