import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";

import { SingleSelectPlaceholder } from "components/select";
import { H3, Span } from "components/typography/Typography";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CustomOutlinedInput from "components/input";
import { formInput, initialVal, validationSchema } from "./data";
import { createRequest, createUsers, getBusinessType } from "services/https/fetchers";
import { notifySuccess } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "fit-content",
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "100%",
    padding: theme.spacing(2),
  },
  span: {
    color: theme.palette.primary.main,
    marginTop: "4px",
  },
}));

export const CreateSingleUserDialog = ({ openDialog, setOpenDialog }) => {
  const [err, setErrMsg] = useState("");
  const [businessTypeOption, setBusinessTypeOption] = useState([]);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenDialog(false);
    // formik.handleReset();
    //setInitialValues(initialValues);
  };
  let dialogRef = useRef();

  useEffect(() => {
    getBusinessType({ size: 1000 }).then((data) => {
      const { businessTypeList } = data || {};
      setBusinessTypeOption(businessTypeList);
    });
  }, []);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle>
        <Box
          ref={(node) => (dialogRef.current = node)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H3>{"Single User"}</H3>
          <IconButton
            color="primary"
            aria-label="close"
            component="span"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ m: "auto", textAlign: "center", color: "primary.main" }}>
          {err}
        </Box>
      </DialogTitle>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          let val = { ...values, businessType: values.orgType };
          let d = new Date()
         let payload = {
          customerName: `${val?.firstName} ${val?.surname}`,
          payload: JSON.stringify(val),
          requestId: d.getTime(),
          requestType: "createMerchant"
        }
          //</Dialog>createUsers(val)
            createRequest(payload)
            .then(({ status, message }) => {
              if (status) {
                dispatch(
                  notifySuccess({ msg: "User successfully created!!!" })
                );
                resetForm();
                handleClose();
              } else {
                setErrMsg(message);
                dialogRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {formInput.map(
                  ({
                    type,
                    label,
                    name,
                    firstOption,
                    options,
                    placeholder,
                  }) => (
                    <Grid item xs={6} key={name}>
                      {type === "SingleSelectPlaceholder" ? (
                        <StyledBox>
                          <label>{label}</label>
                          {name == "orgType" ? (
                            <select
                              name={name}
                              value={values[name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value={""}>{firstOption}</option>
                              {businessTypeOption.map(
                                ({ businessType }, idx) => (
                                  <option
                                    key={`${name}-${idx}`}
                                    value={businessType}
                                  >
                                    {businessType}
                                  </option>
                                )
                              )}
                            </select>
                          ) : (
                            <select
                              name={name}
                              value={values[name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value={""}>{firstOption}</option>
                              {options.map(({ name, value }, idx) => (
                                <option key={`${name}-${idx}`} value={value}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          )}
                          <span>
                            <ErrorMessage name={name} />
                          </span>
                        </StyledBox>
                      ) : (
                        <CustomOutlinedInput
                          name={name}
                          value={values[name]}
                          placeholder={placeholder}
                          label={label}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched[name] && Boolean(errors[name])}
                          helperText={touched[name] && errors[name]}
                        />
                      )}
                    </Grid>
                  )
                )}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", p: 4 }}>
              <Button
                variant={"contained"}
                sx={{ width: "156px" }}
                type={"submit"}
                onClick={() => console.log(errors)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Span>{"Create User"}</Span>
                  {isSubmitting ? (
                    <CircularProgress
                      sx={{ color: "#fff", marginLeft: "5px" }}
                      size={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
