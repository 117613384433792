import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import "./styles.css";

const OTPModal = ({
  show,
  handleClose,
  isValidateBtnActive = false,
  helperMsg = "Didn’t get OTP?",
}) => {
  if (!show) return null;
  return (
    <div className='create-new-account-modal'>
      <div className='create-new-account__main-content'>
        <button className='create-new-account__cancel-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='create-new-account__body'>
          <h3 className='create-new-account__title'>{"Create New Account"}</h3>

          <div className='create-new-account__otp-input-container'>
            <input className='create-new-account__otp-input' value='0' />
            <input className='create-new-account__otp-input' value='1' />
            <input className='create-new-account__otp-input' value='4' />
            <input className='create-new-account__otp-input' value='' />
            <input className='create-new-account__otp-input' value='' />
            <input className='create-new-account__otp-input' value='' />
          </div>

          <button
            className={
              "create-new-account__action-btn" + isValidateBtnActive ? " primary-action-btn" : ""
            }
          >
            {"Create Account"}
          </button>

          <p className='create-new-account__error-msg'>{"Request OTP in 30 seconds"}</p>
        </div>
      </div>
    </div>
  );
};

export default OTPModal;
