import { Paper, Stack, styled } from "@mui/material";

export const StackContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#FFFAF9",
  spacing: theme.spacing(2),
  alignItems: "center",
  flexDirection: "row",
  width: "fit-content",
  padding: 4,
  marginLeft: "auto",
  gap: 4,
  border: "1px solid #FFE3D8",
  h3: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 12,
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
}));

export const RevenueCardRoot = styled(Paper)(({ elevation }) => ({}));
