import React from "react";

const ItemAmount = ({ text, amount }) => {
  return (
    <div className='referral-item-amount'>
      <p className='referral-item-amount__content referral-item-amount__text'>{text}</p>
      <p className='referral-item-amount__content referral-item-amount__amount'>{amount}</p>
    </div>
  );
};

export default ItemAmount;
