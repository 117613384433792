import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DropIcon from "components/Icons/DropIcon";
import "./styles.css";

const AccountTypeModal = ({ show }) => {
  if (!show) return null;
  return (
    <div className='create-new-account-modal'>
      <div className='create-new-account__main-content'>
        <button className='create-new-account__cancel-btn'>
          <CancelIcon />
        </button>

        <div className='create-new-account__body'>
          <h3 className='create-new-account__title'>{"Create New Account"}</h3>

          <div className='create-new-account__text-input-group'>
            <label className='create-new-account__text-input-label'>{"Account Type"}</label>

            <div className='create-new-account__text-dropdown-input'>
              <button className='create-new-account__drop-btn'>
                <DropIcon color='#666666' />
              </button>
            </div>
          </div>

          <button className='create-new-account__action-btn primary-action-btn'>{"Create"}</button>
        </div>
      </div>
    </div>
  );
};

export default AccountTypeModal;
