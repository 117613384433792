export const TotalSummary = [
  {
    title: "Total Processor",
    objKeyRepr: "totalLogs",
  },
  {
    title: "Total routed to unified payment",
    objKeyRepr: "totalLogs",
  },
  {
    title: "Total Routed to NIBSS",
    objKeyRepr: "totalLogs",
  },
  {
    title: "Total Route to Switch",
    objKeyRepr: "totalLogs",
  },
];

export const dataKeysAndRepr = [
  { objKey: "type", repr: "Transaction Type" },
  { objKey: "processor", repr: "Processor" },
  { objKey: "precedence", repr: "Precedence" },
  { objKey: "routingType", repr: "Routing Type" },
  { objKey: "merchantId", repr: "Merchant ID" },
  { objKey: "isActive", repr: "Status" },
  { objKey: "action", repr: "Action" },
];

export const sampleData = [
  {
    type: "Card Acquiring",
    processor: "NIBSS",
  },
  {
    type: "USSD Acquiring",
    processor: "PAYATITUDE",
  },
];

export const txRoutingLabel = {
  ussdAcquiring: "USSD Acquiring",
  accountAcquiring: "Account Acquiring",
  payattitudeAcquiring: "PayAttitude Acquiring",
  cardAcquiring: " Card Acquiring",
};
