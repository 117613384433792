import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { H3, Span } from "components/typography/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import CustomIcon from "assets/icons";
import {
  FacebookIcon,
  InstapaperIcon,
  InstapaperShareButton,
  FacebookShareButton,
  FacebookShareCount,
  TwitterIcon,
  InstagramIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import ShareButton from "react-share/lib/ShareButton";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        textAlign: "center",
        color: "#fff",
        cursor: "pointer",

        ...sx,
      }}
      {...other}
    />
  );
}

export const InviteViaSociaMedia = ({ openDialog, setOpenDialog }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        marginBottom: "24px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle
        sx={{
          marginBottom: "32px",
          alignItems: "center",
        }}
      >
        <IconButton
          color="primary"
          aria-label="close"
          component="span"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <H3 sx={{ textAlign: "center" }}>{"Send Via Socia Media"}</H3>
      </DialogTitle>
      <DialogContent>
        <Stack direction={"row"}>
          <Item sx={{ margin: "0 21px" }}>
            <FacebookShareButton
              url={"https://wayapay.ng "}
              quote={"quote"}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={64} borderRadius={8} />
            </FacebookShareButton>
            <Span sx={{ display: "block", color: "#000" }}>{"Facebook"}</Span>
          </Item>
          <Item sx={{ margin: "0 21px" }}>
            <TwitterShareButton
              url={"https://wayapay.ng "}
              quote={"quote"}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={64} borderRadius={8} />
            </TwitterShareButton>
            <Span sx={{ display: "block", color: "#000" }}>{"Twitter"}</Span>
          </Item>

          <Item sx={{ margin: "0 21px" }}>
            <LinkedinShareButton
              url={"https://www.linkedin.com/company/waya-paychat/mycompany/ "}
              quote={"quote"}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={64} borderRadius={8} />
            </LinkedinShareButton>
            <Span sx={{ display: "block", color: "#000" }}>{"LinkedIn"}</Span>
          </Item>
          <Item sx={{ margin: "0 21px" }}>
            <WhatsappShareButton
              url={"https://waya-paychat.ng "}
              quote={"quote"}
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={64} borderRadius={8} />
            </WhatsappShareButton>
            <Span sx={{ display: "block", color: "#000" }}>{"Whatsapp"}</Span>
          </Item>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
