const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: "full", // full, close, compact, mobile,
    theme: "waya", // View all valid theme colors inside appTheme/themeColors.js
    // bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: "waya", // View all valid theme colors inside appTheme/themeColors.js
    txMode: false,
  },
};

export default Layout1Settings;
