import React from "react";

const AdminDetail = ({ title, content, contentClassName = "" }) => {
  return (
    <div className='admin-other-detail-container'>
      <p className='admin-other-detail-title'>{title}</p>
      <p className={"admin-other-detail-content " + contentClassName}>{content}</p>
    </div>
  );
};

export default AdminDetail;
