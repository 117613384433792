export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "dashboard",
    permissions: ["VIEW_DASHBOARD", "MANAGE_DASHBOARD"]
  },
  {
    name: "User",
    icon: "user",
    children: [
      {
        name: "Manage Admin Users",
        path: "/users/admin",
        permissions: ["VIEW_ADMIN", "MANAGE_ADMIN"]
      },
      {
        name: "Manage Users",
        path: "/users/manage-users",
        permissions: ["VIEW_MERCHANTS", "MANAGE_MERCHANTS"]
      },
      /* {
        name: "Manage Referrals",
        path: "/users/referrals",
      }, */
    ],
  },
  {
    name: "Transaction",
    path: "/transactions",
    icon: "transaction",
    permissions: ["VIEW_TRANSACTIONS", "MANAGE_TRANSACTIONS"]
  },
  {
    name: "Manage KYC",
    path: "/kyc/businessKYC",
    icon: "kyc", 
    permissions: ["VIEW_KYC", "MANAGE_KYC"]
  },
  {
    name: "LOGS",
    icon: "logs",
    children: [
      {
        name: "Disputes",
        iconText: "SI",
        path: "/logs/disputes",
        permissions: ["VIEW_DISPUTES", "MANAGE_DISPUTES"]
      },
      {
        name: "Request Logs",
        iconText: "SI",
        path: "/logs/request-logs",
        permissions: ["VIEW_SETTLEMENT", "MANAGE_SETTLEMENT"]
      },
      {
        name: "Feedback",
        iconText: "SI",
        path: "/logs/admin-logs",
        permissions: ["VIEW_PRICING", "MANAGE_PRICING"]
      },
      {
        name: "Fraud Management",
        iconText: "SI",
        path: "/logs/fraud-management",
        permissions: ["VIEW_FRAUD_MANAGEMENT", "MANAGE_FRAUD_MANAGEMENT"]
      },
    ],
  },
  /*  {
    name: "OTP",
    path: "/otp",
    icon: "otp",
  }, */
  {
    name: "Tech",
    icon: "tech",
    children: [
      {
        name: "Transaction Routing",
        iconText: "TR",
        path: "/tech/transaction-routing",
        permissions: ["VIEW_TRANSACTION_ROUTING", "MANAGE_TRANSACTION_ROUTING"]
      },
      {
        name: "Pricings",
        iconText: "Pr",
        path: "/tech/pricings",
        permissions: ["VIEW_PRICING", "MANAGE_PRICING"]
      },
    ],
  },
  /* {
    name: "Waya Official",
    path: "/waya-official",
  }, */
];
