import React from "react";
import { Outlet } from "react-router-dom";

export const BusinessKYC = (props) => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
