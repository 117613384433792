import { List, ListSubheader, Button, CircularProgress } from "@mui/material";
import { products } from "./data";
import { PricingList } from "./PricingList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProduct } from "services/https/pricingFetcher";
import _ from "lodash";

export const EditPricing = () => {
  const navigate = useNavigate();
  const [productArr, setProduct] = useState();
  useEffect(() => {
    getProduct().then((data) => {
      setProduct(data);
    });
  }, []);
  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>
      {!_.isEmpty(productArr) ? (
        <List
          sx={{ width: "100%", px: 6 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5em",
                color: "black",
              }}
              id="nested-list-subheader"
            >
              {"Edit Pricing"}
            </ListSubheader>
          }
        >
          {productArr?.map(({ productName, productDesc }, idx) => (
            <PricingList
              label={productName}
              idx={idx}
              productDesc={productDesc}
            />
          ))}
        </List>
      ) : (
        <List
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          <CircularProgress />
        </List>
      )}
    </>
  );
};
