import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { customSearch, searchData } from "utils/customSearch";
import { useDispatch } from "react-redux";
import { clearSearch, SearchAction } from "redux/actions/SearchAction";
import _ from "lodash";

export default function CustomizedInputBase({ searchSpace, keys }) {
  const theme = useTheme();
  const [searchTerms, setSearchTerms] = useState("");
  const dispatch = useDispatch();
  const [data, setAllData] = useState();

  useEffect(async () => {
    if (searchSpace) {
      await searchData[searchSpace]().then((data) => {
        setAllData(data);
      });
    }
  }, [searchSpace]);

  function handleDebounceFn({ data, searchTerms }) {
    customSearch(data, searchTerms, keys).then((data) => {
      const searchResult = data.map(({ item }) => item);
      dispatch(SearchAction({ searchResult, query: searchTerms }));
    });
  }
  const debounceFn = useCallback(
    _.debounce(
      (data, searchTerms) => handleDebounceFn({ data, searchTerms }),
      500
    ),
    [searchTerms]
  );

  function handleChange(event) {
    const search = event.target.value;
    setSearchTerms(search);
  }

  useEffect(() => {
    if (data && searchTerms) {
      debounceFn(data, searchTerms);
    }
  }, [debounceFn]);

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        height: "fit-content",
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Users by Name, Email or Date"
        inputProps={{ "aria-label": "Search Users by Name, Email or Date" }}
        onChange={handleChange}
      />
    </Paper>
  );
}
