import { KYCFilterSearchExport } from "components/shared/filterSearchExport/kycFilterSearchExport";
import { GenericTab } from "components/tabs/Tab";
import { useState } from "react";
import { BusinessIndustry } from "./shared/KYCShared";

export const KYC = () => {
  const [name, setName] = useState("kyc");
  const [activeTab, setActiveTab] = useState(name);

  const PageHeader = () => {
    switch (activeTab) {
      case "businessKYC":
        return <KYCFilterSearchExport />;
      case "businessIndustry":
        return <BusinessIndustry />;
      default:
        return <KYCFilterSearchExport />;
    }
  };

  return (
    <GenericTab
      name={name}
      setName={setName}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      <PageHeader />
    </GenericTab>
  );
};
