import React from "react";

const SettingIcon = ({ color = "#6A6A6A", size = 17 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0678 6.65408L14.7361 6.48479C14.6263 6.14692 14.491 5.82108 14.333 5.51154L15.1554 4.45258C15.4884 4.02404 15.4494 3.41913 15.069 3.05079L13.9533 1.93517C13.5814 1.55125 12.9765 1.513 12.5472 1.84521L11.4896 2.66758C11.1801 2.50962 10.8542 2.37433 10.5156 2.26454L10.3463 0.935C10.2826 0.401625 9.82995 0 9.29372 0H7.70699C7.17076 0 6.71811 0.401625 6.65436 0.932875L6.48506 2.26454C6.14646 2.37433 5.82062 2.50892 5.51106 2.66758L4.45277 1.84521C4.02492 1.513 3.41998 1.55125 3.05092 1.93162L1.93525 3.04654C1.55131 3.41913 1.51235 4.02404 1.84529 4.45329L2.66769 5.51154C2.50902 5.82108 2.37443 6.14692 2.26464 6.48479L0.935039 6.65408C0.401642 6.71783 0 7.17046 0 7.70667V9.29333C0 9.82954 0.401642 10.2822 0.932914 10.3459L2.26464 10.5152C2.37443 10.8531 2.50973 11.1789 2.66769 11.4885L1.84529 12.5474C1.51235 12.976 1.55131 13.5809 1.93171 13.9492L3.04738 15.0648C3.41998 15.448 4.02421 15.4863 4.45348 15.1541L5.51177 14.3317C5.82133 14.4904 6.14717 14.6257 6.48506 14.7347L6.65436 16.0636C6.71811 16.5984 7.17076 17 7.70699 17H9.29372C9.82995 17 10.2826 16.5984 10.3463 16.0671L10.5156 14.7355C10.8535 14.6257 11.1794 14.4904 11.4889 14.3324L12.5479 15.1548C12.9765 15.4877 13.5814 15.4488 13.9498 15.0684L15.0655 13.9527C15.4494 13.5802 15.4884 12.976 15.1554 12.5467L14.333 11.4885C14.4917 11.1789 14.627 10.8531 14.7361 10.5152L16.065 10.3459C16.5984 10.2822 17 9.82954 17 9.29333V7.70667C17.0007 7.17046 16.5991 6.71783 16.0678 6.65408ZM8.50035 12.0417C6.5474 12.0417 4.95854 10.4529 4.95854 8.5C4.95854 6.54712 6.5474 4.95833 8.50035 4.95833C10.4533 4.95833 12.0422 6.54712 12.0422 8.5C12.0422 10.4529 10.4533 12.0417 8.50035 12.0417Z'
        fill={color}
      />
    </svg>
  );
};

export default SettingIcon;
