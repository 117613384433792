import NotFound from "pages/sessions/NotFound";
import dashboardRoutes from "pages/dashboard/DashboardRoutes";
import sessionRoutes from "pages/sessions/SessionRoutes";
import AppLayout from "components/shared/appLayout/AppLayout";
import { Navigate } from "react-router-dom";
import AuthGuard from "../auth/AuthGuard";
import UserRoutes from "pages/user/UserRoutes";
import TransactionRoutes from "pages/transactions/TransactionRoutes";
import OTPRoutes from "pages/otp/OTPRoutes";
import TechRoutes from "pages/tech/TechRoutes";
import LogRoutes from "pages/logs/LogRoutes";
import KycRoutes from "pages/kyc/KycRoutes";
import WayaOfficialRoutes from "pages/waya-official/WayaOfficialRoutes";

export const AllPages = () => {
  const all_routes = [
    {
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        ...dashboardRoutes,
        ...UserRoutes,
        ...TransactionRoutes,
        ...KycRoutes,
        ...LogRoutes,
        ...OTPRoutes,
        ...TechRoutes,
        ...WayaOfficialRoutes,
      ],
    },
    ...sessionRoutes,
    {
      path: "/",
      element: <Navigate to='/dashboard' />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return all_routes;
};
