import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";

import folder from "assets/images/folder.svg";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getMerchantById } from "services/https/fetchers";
import { customerDataKeyRepr } from "./data";

import Table from "components/table/Table";
import _ from "lodash";
import { CopyAble } from "components/tabs/transaction/data";
import Loading from "components/shared/appLoader/AppLoader";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getCustomerList } from "services/https/paymentLink";

export const CustomerTabPanel = () => {
  return <CustomerList />;
};

const CustomerList = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [merchantId, setMerchantId] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    getMerchantById({ userId })
      .then((data) => {
        const { merchantID } = data || {};
        if (merchantID) {
          setMerchantId(merchantID);
        } else {
          alert("User has no merchant Id");
          navigate("/users/manage-users");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    if (merchantId) {
      const q = { merchantId, size: rowsPerPage, page, order: "DESC" };
      getCustomerList(q)
        .then((data) => {
          const { content, totalElements } = data || {};
          setCustomerList({ content: destructureNeed(content), totalElements });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, rowsPerPage, page]);

  const destructureNeed = (content) => {
    const res = content?.map(
      ({ firstName, lastName, customerId, email, phoneNumber, status }) => ({
        customerName: `${lastName} ${firstName}`,
        customerId: <CopyAble str={customerId} length={10} />,
        email,
        phoneNumber,
        status: status ? (
          <FiberManualRecordIcon color="green" />
        ) : (
          <FiberManualRecordIcon color="red" />
        ),
      })
    );
    return res;
  };
  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) : _.isEmpty(customerList.content) ? (
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            height: "40vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={folder} />
            <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
              {"There are no Customer for this merchant yet!!!"}
            </Typography>
            <Typography>{"Create a new customer"}</Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Table
            dataKeysAndRepr={customerDataKeyRepr}
            dataItemRows={customerList.content}
          />
          <TablePagination
            component="div"
            count={customerList.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};
