export const linkType = [
  {
    icon: "",
    primary: "One-Time Payment Link",
    secondary:
      "With one-time payment link your customer will pay you on a single charge",
    link: "one-time/add",
  },
  {
    icon: "",
    primary: "Subscription Payment Link",
    secondary:
      "Customer can subscribe to your service with this link and be charge on recurring payment",
    link: "subscription/add",
  },
];
