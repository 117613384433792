import { OutlinedInput } from "@mui/material";
import { styled } from "@mui/system";
import { Span } from "components/typography/Typography";

const BoxRoot = styled("div")(() => ({}));

export const CustomOutlinedInput = (props) => {
  return (
    <BoxRoot>
      <label htmlFor={props.name}>{props.label}</label>
      <OutlinedInput
        startAdornment={props.start}
        onBlur={props.handleBlur}
        {...props}
        label={""}
        sx={{ width: "100%", marginBlock: 1 }}
      />
      <Span sx={{ color: (theme) => theme.palette.primary.main }}>
        {props.helperText}
      </Span>
    </BoxRoot>
  );
};
