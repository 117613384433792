import React from "react";
import { authRoles } from "auth/authRoles";
import DashboardDefaultPage from "./dashboardPage";

const dashboardRoutes = [
  {
    path: "/dashboard",
    element: <DashboardDefaultPage />,
    auth: authRoles.admin,
  },
];

export default dashboardRoutes;
