import React, { useState } from "react";
import CancelIcon from "../../../../../components/Icons/CancelIcon";
import DropIcon from "../../../../../components/Icons/DropIcon";
import SelectDropdown from "../ModalSelectDropdown";
import "./styles.css";

const TRANSACTION_ITEMS = [10, 25, 50, 100];

const ReferralSettingModal = ({ show, onCloseBtnClick }) => {
  const [showTransactionsDropdown, setShowTransactionsDropdown] = useState(false);

  function handleSelectTransaction(transaction) {
    setShowTransactionsDropdown(false);
  }

  if (!show) return null;
  return (
    <div className='referral-setting-modal'>
      <div className='referral-setting-main-content'>
        <div className='referral-setting-title-container'>
          <h3>{"Send Referral Bonus"}</h3>
          <button onClick={onCloseBtnClick}>
            <CancelIcon />
          </button>
        </div>

        <div className='referral-setting-inputs-container'>
          <div className='referral-setting-input-group'>
            <label>{"Enter Amount To Send"}</label>
            <input value={""} className='referral-setting-input-content' />
          </div>

          <div className='referral-setting-input-group'>
            <label>{"Select Waya Official Wallet"}</label>
            <div className='referral-setting-input-content'>
              <p className='referral-setting-transaction-text'>{""}</p>
              <button
                className='referral-setting-transaction-btn'
                onClick={() => setShowTransactionsDropdown((s) => !s)}
              >
                <DropIcon color='#828282' />
              </button>
              <SelectDropdown
                dropdownItems={TRANSACTION_ITEMS}
                getTextFromDropdownItem={(item) => item + " Transactions"}
                show={showTransactionsDropdown}
                onSelect={handleSelectTransaction}
              />
            </div>
          </div>

          <div className='referral-setting-input-group'>
            <label>{"Transaction Type"}</label>
            <div className='referral-setting-input-content'>
              <p className='referral-setting-transaction-text'>{""}</p>
              <button
                className='referral-setting-transaction-btn'
                onClick={() => setShowTransactionsDropdown((s) => !s)}
              >
                <DropIcon color='#828282' />
              </button>
              <SelectDropdown
                dropdownItems={TRANSACTION_ITEMS}
                getTextFromDropdownItem={(item) => item + " Transactions"}
                show={showTransactionsDropdown}
                onSelect={handleSelectTransaction}
              />
            </div>
          </div>

          <div className='referral-setting-input-group'>
            <label>{"Description"}</label>
            <input value={""} className='referral-setting-input-content' />
          </div>
        </div>

        <div className='referral-setting-save-btn-container '>
          <button className='send-bonus-modal-btn'>{"Save Change"}</button>
        </div>
      </div>
    </div>
  );
};

export default ReferralSettingModal;
