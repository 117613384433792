import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ClickAwayListener, Paper, Button, Stack, Box } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useDispatch } from "react-redux";
import { customSearch } from "utils/customSearch";
import { clearSearch, SearchAction } from "redux/actions/SearchAction";
import _ from "lodash";

import { getAllPricing } from "services/https/pricingFetcher";
import { StyledBox } from "../transactionHeader/SettlementFSE";

export const PricingFilter = () => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          componentsProps={{
            tooltip: {
              sx: { backgroundColor: "transparent !important" },
            },
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<FilterForm close={handleTooltipClose} />}
          arrow
        >
          <Button
            onClick={() => setOpen(!open)}
            startIcon={<FilterListIcon />}
            sx={{ mr: 4 }}
          >
            {"Filter"}
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

export const FilterForm = ({ close }) => {
  const [alldata, setAllData] = useState();

  const [filterObj, setFilterObj] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getAllPricing({ page: 0, size: 1000000000 }).then((data) => {
      setAllData(data?.content);
    });
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    let mydata = alldata;
    if (mydata) {
      const chainedFilters = Object.entries(filterObj).map(([key, value]) => ({
        [key]: value,
      }));

      if (chainedFilters) {
        customSearch(mydata, { $and: chainedFilters }).then((data) => {
          const searchResult = data.map(({ item }) => item);
          dispatch(SearchAction({ searchResult, query: chainedFilters }));
        });
      }
    }
  };
  const handleClear = (event) => {
    setFilterObj({});
    dispatch(clearSearch);
    close();
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFilterObj((filterObj) => ({ ...filterObj, [name]: value }));
  };

  return (
    <Paper sx={{ p: 2, width: "300px" }}>
      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <StyledBox>
          <label>{"Product"}</label>
          <select name={"productName"}>
            <option>{"Show all"}</option>
            <option value={"CARD"}>{"Card Acquiring"}</option>
            <option value={"USSD"}>{"Ussd Acquiring"}</option>
            <option value={"BANK"}>{"Bank Account Acquiring"}</option>
            <option value={"WALLET"}>{"Wallet Acquiring"}</option>
            <option value={"PAYATTITUDE"}>{"PayAttitude Acquiring"}</option>
          </select>
        </StyledBox>
        <StyledBox>
          <label>{"Pricing Status"}</label>
          <select name={"pricingStatus"}>
            <option>{"Show All"}</option>
            <option value={"DEFAULT"}>{"Default"}</option>
            <option value={"CUSTOM"}>{"Custom"}</option>
          </select>
        </StyledBox>

        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between", m: 2, columnGap: 2 }}
        >
          <Button variant={"contained"} onClick={handleClear}>
            {"Clear"}
          </Button>

          <Button variant={"contained"} type={"submit"}>
            {"Apply"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
