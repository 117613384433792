import React, { useState } from "react";
import CancelIcon from "../../../../../components/Icons/CancelIcon";
import DropIcon from "../../../../../components/Icons/DropIcon";
import SelectDropdown from "../ModalSelectDropdown";
import "./styles.css";

const TRANSACTION_ITEMS = [10, 25, 50, 100];

const ReferralSettingModal = ({ show, onCloseBtnClick }) => {
  const [showTransactionsDropdown, setShowTransactionsDropdown] = useState(false);

  function handleSelectTransaction(transaction) {
    setShowTransactionsDropdown(false);
  }

  if (!show) return null;
  return (
    <div className='referral-setting-modal'>
      <div className='referral-setting-main-content'>
        <div className='referral-setting-title-container'>
          <h3>{"Referral Bonus Settings"}</h3>
          <button onClick={onCloseBtnClick}>
            <CancelIcon />
          </button>
        </div>

        <div className='referral-setting-inputs-container'>
          <div className='referral-setting-input-group'>
            <label>{"No of transactions a referral must make before Bonus is received"}</label>
            <div className='referral-setting-input-content'>
              <p className='referral-setting-transaction-text'>{"5 Transactions"}</p>
              <button
                className='referral-setting-transaction-btn'
                onClick={() => setShowTransactionsDropdown((s) => !s)}
              >
                <DropIcon color='#828282' />
              </button>
              <SelectDropdown
                dropdownItems={TRANSACTION_ITEMS}
                getTextFromDropdownItem={(item) => item + " Transactions"}
                show={showTransactionsDropdown}
                onSelect={handleSelectTransaction}
              />
            </div>
          </div>

          <div className='referral-setting-input-group'>
            <label>{"Amount to be received when referral requirement is met"}</label>
            <input value={"N1, 000"} className='referral-setting-input-content' />
          </div>
        </div>

        <div className='referral-setting-save-btn-container'>
          <button>{"Save Change"}</button>
        </div>
      </div>
    </div>
  );
};

export default ReferralSettingModal;
