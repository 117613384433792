import * as yup from "yup";

export const validationSchema = yup.object({
  orgName: yup
    .string("Enter business name")
    .required("Business name is required"),

  city: yup.string("Enter business name").required("Business name is required"),

  state: yup.string("Enter orgainzation state").required("state is required"),

  orgEmail: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Business email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),

  surname: yup
    .string("Enter your surname")
    .min(3, "surname should be of minimum 3 characters length")
    .required("surname is required"),

  orgType: yup
    .string("Choose a business type")
    .required("business type is required"),

  gender: yup.string("Choose a gender").required("Choose gender"),

  phoneNumber: yup
    .string("Phone number is required")
    .min(11, "phone number should be of minimum 11 characters length")
    .required("phone number is required"),

  firstName: yup
    .string("Enter your first name")
    .min(3, "firstname should be minimum of 3 characters length")
    .required("firstname is required"),

  officeAddress: yup
    .string("Enter your office Address")
    .min(3, "address should be minimum of 3 characters length")
    .required("office address is required"),
});

export const initialVal = {
  admin: true,
  orgName: "",
  businessType: "",
  orgEmail: "",
  orgPhone: "",
  firstName: "",
  surname: "",
  email: "",
  phoneNumber: "",
  city: "",
  referenceCode: "",
  password: "",
  dateOfBirth: "",
  gender: "",
  officeAddress: "",
  orgType: "",
  state: "",
};

export const formInput = [
  {
    label: "Business Name",
    placeholder: "Business Name",
    name: "orgName",
  },
  {
    label: "Business Type",
    placeholder: "Business Type",
    type: "SingleSelectPlaceholder",
    name: "orgType",
    firstOption: "Select a business type",
    options: [
      { name: "ICT", value: "ICT" },

      { name: "Music and DJ", value: "Music and DJ" },

      { name: "Farming", value: "Farming" },
    ],
  },
  {
    label: "Business Email",
    name: "orgEmail",
  },
  {
    label: "Business Phone Number",
    placeholder: "must include 234 and last 10 digits",
    name: "orgPhone",
  },
  {
    label: "Bussiness Address",
    name: "officeAddress",
  },
  {
    label: "Organization city",
    name: "city",
  },
  {
    label: "Organization state",
    name: "state",
  },
  {
    label: "Personal First Name",
    name: "firstName",
  },
  {
    label: "Personal Last Name",
    name: "surname",
  },
  {
    label: "Personal Email Address",
    name: "email",
  },
  {
    label: "Personal Phone Number",
    name: "phoneNumber",
    placeholder: "must include 234 and last 10 digits",
  },
  {
    label: "Gender",
    placeholder: "Gender",
    type: "SingleSelectPlaceholder",
    name: "gender",
    firstOption: "--Gender--",
    options: [
      { name: "MALE", value: "MALE" },
      { name: "FEMALE", value: "FEMALE" },
    ],
  },
  {
    label: "Referral Code",
    name: "referenceCode",
  },
  {
    label: "Create Password",
    name: "password",
  },
];
