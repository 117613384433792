import React from "react";

const getClassName = (status) => {
  switch (status) {
    case "Successful":
      return "waya-table__status-successful";
    case "Reversed":
      return "waya-table__status-reversed";
    case "Pending":
      return "waya-table__status-pending";
    default:
      return "";
  }
};

const TransactionTableStatus = ({ status }) => {
  return <p className={"waya-table__status " + getClassName(status)}>{status}</p>;
};

export default TransactionTableStatus;
