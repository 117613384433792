import * as yup from "yup";

export const formItems = [
  {
    label: "Date of Birth",
    objKey: "dateOfBirth",
  },
  {
    label: "Status",
    objKey: "active",
    type: "select",
    options: [
      {
        name: "active",
        label: "active",
        value: true,
      },
      {
        name: "inactive",
        label: "InActive",
        value: false,
      },
    ],
  },

  {
    label: "Email Address",
    objKey: "email",
  },
  {
    label: "First Name",
    objKey: "firstName",
  },
  {
    label: "Last Name",
    objKey: "surname",
  },
  {
    label: "Phone Number",
    objKey: "phoneNumber",
  },
  {
    label: "Gender",
    objKey: "gender",
  },
  {
    label: "Reference Code",
    objKey: "referenceCode",
    notEditable: true,
  },
  {
    label: "Alert",
    objKey: "sms",
    type: "switch",
  },
  {
    label: "User Id",
    objKey: "userId",
    notEditable: true,
  },

  {
    label: "Virtual Account Number",
    objKey: "virtualAccountNumber",
    copyable: true,
    notEditable: true,
  },
  {
    label: "Bank Name",
    objKey: "bankName",
  },
];

export const roles = {
  ROLE_USER: "User",
  ROLE_CORP: "Corporate User",
  ROLE_CORP_ADMIN: "Corporate Administrator",
};

export const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter valid email address")
    .required("Email is required"),

  gender: yup.string("Choose a gender").required("Choose gender"),

  dateOfBirth: yup
    .string("Choose a date of birth")
    .required("Choose a date of birth"),

  phoneNumber: yup
    .string("Phone number is required")
    .min(11, "phone number should be of minimum 11 characters length")
    .required("phone number is required"),

  firstName: yup
    .string("Enter your first name")
    .min(3, "firstname should be minimum of 3 characters length")
    .required("firstname is required"),

  surname: yup
    .string("Enter your last name")
    .min(3, "lastname should be minimum of 3 characters length")
    .required("lastname is required"),
});

export const dataKeysAndRepr = [
  { objKey: "organisationName", repr: "BUSINESS NAME" },
  { objKey: "organisationPhone", repr: "PHONE" },
  { objKey: "organisationState", repr: "STATE" },
  { objKey: "organisationCity", repr: "CITY" },
  { objKey: "organisationType", repr: "BUSINESS TYPE" },
  { objKey: "status", repr: "STATUS" },
  { objKey: "createdAt", repr: "DATE CREATED" },
  { objKey: "action", repr: "ACTION" },
];

export const ActivityDataKeysAndRepr = [
  { objKey: "duration", repr: "Duration" },
  { objKey: "activity", repr: "Activity" },
  { objKey: "action", repr: "ACTION" },
];
