import {
  CREATE_DIALOG,
  NOTIFY_DONE,
  NOTIFY_FAIL,
  NOTIFY_SUCCESS,
} from "redux/actions/AlertActions";

const initial = {
  alertType: "",
  msg: "",
  dialogType: "",
};

const AlertReducer = function (state = initial, action) {
  switch (action.type) {
    case NOTIFY_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, alertType: "success", msg: msg };
    }

    case NOTIFY_FAIL: {
      const { msg } = action.payload;
      return { ...state, alertType: "error", msg: msg };
    }
    case NOTIFY_DONE: {
      return { ...state, alertType: "", msg: "" };
    }
    case CREATE_DIALOG: {
      const { type, payload } = action.payload;
      return { ...state, dialogType: type, payload };
    }
    default: {
      return state;
    }
  }
};

export default AlertReducer;
