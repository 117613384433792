import React from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DeleteIcon from "components/Icons/DeleteIcon";
import "./styles.css";

const DeleteAccountModal = ({ show, handleClose }) => {
  if (!show) return null;
  return (
    <div className='delete-account-modal'>
      <div className='delete-account-modal__main-content'>
        <button className='delete-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>
        <div className='delete-account-modal__body'>
          <div className='delete-account-modal__title-container'>
            <DeleteIcon color='#FF6700' />
            <h3 className='delete-account-modal__title'>{"Delete Account"}</h3>
          </div>

          <p className='delete-account-modal__text'>
            {"Do you understand that deleting your Waya Official account will wipe all your data?"}
          </p>

          <div className='delete-account-modal__action-btns-container'>
            <button className='delete-account-modal__cancel-btn'>{"No, Cancel"}</button>

            <button className='delete-account-modal__continue-btn'>{"Yes, Continue"}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
