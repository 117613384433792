import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { H3, Span } from "components/typography/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import React from "react";
import { styled, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { DataProvider } from "contexts/DataContext";

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBlock: theme.spacing(2),
}));

function createData(name, email, role, faStatus) {
  return { name, email, role, faStatus };
}

const BulletIcon = styled("div")(({ theme }) => ({
  width: "8px",
  height: "8px",
  color: "inherit",
  overflow: "hidden",
  marginRight: "8px",
  borderRadius: "300px !important",
  // background: theme.palette.primary.contrastText,
  background: theme.palette.text.primary,
}));
const Action = ({ role }) => {
  const theme = useTheme();
  return (
    <div>
      {role === "Super Admin" ? (
        <Button variant="outlined">{"Change Business Owner"}</Button>
      ) : (
        <Stack direction={"row"} spacing={theme.spacing(2)}>
          <Button variant="outlined">{"Change Role"}</Button>
          <Button variant="outlined">{"Remove"}</Button>
        </Stack>
      )}
    </div>
  );
};

const ListAdminUsersRole = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const HandleManage = () => {
    navigate("/users/admin-roles/manage");
  };
  const rows = [
    createData(
      "Blessing Ladejobi",
      "blessing.ladejobi@gmail.com",
      "Reviewer",
      "Enabled"
    ),
    createData(
      "Sunday Daniel",
      "sunday.ladejobi@gmail.com",
      "Super Admin",
      "Enabled"
    ),
  ];
  return (
    <Paper
      elevation={2}
      sx={{
        width: "85%",
        margin: `${theme.spacing(4)} auto`,
        padding: theme.spacing(2),
      }}
    >
      <Header>
        <H3>{"Team Members-10"}</H3>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<SettingsIcon />}
            onClick={HandleManage}
          >
            {"Manage Roles"}
          </Button>
          <Button variant="contained" startIcon={<GroupAddIcon />}>
            {"Invite Someone"}
          </Button>
        </Stack>
      </Header>

      <DataProvider>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{"Name"}</TableCell>
              <TableCell align="left">{"Email"}</TableCell>
              <TableCell align="left">{"Role"}</TableCell>
              <TableCell align="left">{"2FA Status"}</TableCell>
              <TableCell align="left">{"Actions"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.role}</TableCell>
                <TableCell align="left">
                  {
                    <Stack direction={"row"} alignItems={"center"}>
                      <BulletIcon sx={{ verticalAlign: "middle" }} />
                      <Span>{row.faStatus}</Span>
                    </Stack>
                  }
                </TableCell>
                <TableCell align="left">{<Action role={row.role} />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DataProvider>
    </Paper>
  );
};

export default ListAdminUsersRole;
