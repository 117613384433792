import { Span } from "components/typography/Typography";
import { Help } from "../Component";
import * as yup from "yup";

const basedOnSelectedReg = (regType) => {
  const certs = Object.freeze({
    INCORPORATED_TRUSTEES: "INCORPORATED_TRUSTEES",
    INCORPORATED_COMPANY: "INCORPORATED_COMPANY",
    BUSINESS_NAME_REGISTRATION: "BUSINESS_NAME_REGISTRATION",
  });
  const {
    CAC_IT_1,
    MEMART,
    CERTIFICATE_OF_INCORPORATION,
    CERTIFICATE_OF_BUSINESS_NAME,
    CAC_BN_1,
  } = certificateList;

  switch (regType) {
    case certs.INCORPORATED_TRUSTEES:
      return [CAC_IT_1];
    case certs.INCORPORATED_COMPANY:
      return [MEMART, CERTIFICATE_OF_INCORPORATION];

    case certs.BUSINESS_NAME_REGISTRATION:
      return [CERTIFICATE_OF_BUSINESS_NAME, CAC_BN_1];

    default:
      return [MEMART, CERTIFICATE_OF_INCORPORATION];
  }
};

const certificateList = {
  CAC_IT_1: {
    selectedValue: "cacItCertificateUrl",
    fileLabel: "Form CAC IT/1",
    fileName: "CAC_CERTIFICATE",
    name: "cacCertificate",
    selectedOption: "cacCertificate",
  },
  MEMART: {
    selectedValue: "memartCertificateUrl",
    fileLabel: "Memart",
    fileName: "BUSINESS_CERTIFICATE",
    name: "businessCertificate",
    selectedOption: "businessCertificateType",
  },
  CERTIFICATE_OF_INCORPORATION: {
    selectedValue: "incorporationCertificateUrl",
    fileName: "BUSINESS_CERTIFICATE",
    name: "businessCertificate",
    selectedOption: "businessCertificateType",
    fileLabel: "Certficate of Incorporation",
  },

  CERTIFICATE_OF_BUSINESS_NAME: {
    selectedValue: "businessCertificateUrl",
    fileName: "BUSINESS_CERTIFICATE",
    name: "businessCertificate",
    selectedOption: "businessCertificateType",
    fileLabel: "Certificate of Business Name",
  },
  CAC_BN_1: {
    selectedValue: "cacBnCertificateUrl",
    fileName: "BUSINESS_CERTIFICATE",
    name: "businessCertificate",
    selectedOption: "businessCertificateType",
    fileLabel: "Form CAC BN 1",
  },
};

export const MerchantData = {
  profileInfo: [
    {
      label: "Legal Business Name",
      name: "businessName",
    },
    {
      label: "Business Description",
      name: "businessDescription",
    },
    {
      label: "Type of Industry",
      name: "businessIndustry",
      type: "select",
      selectedOption: "businessIndustry",
    },
    {
      label: "Type of Business",
      name: "businessType",
      type: "select",
      selectedOption: "businessType",
    },
  ],
  contactInfo: [
    {
      label: "Email Address",
      name: "businessEmail",
    },
    {
      label: "Phone Number",
      name: "businessPhoneNumber",
    },
    {
      subLabel: "Office Address",
      items: [
        {
          label: "Country",
          name: "country",
          type: "select",
          selectedOption: "country",
        },
        {
          label: "State",
          name: "state",
          type: "select",
          selectedOption: "state",
        },
        {
          label: "city",
          name: "city",
          type: "select",
          selectedOption: "city",
        },
        {
          label: "Local Government Area",
          name: "lga",
          type: "select",
          selectedOption: "lga",
        },

        {
          label: "Street Address",
          name: "streetAddress",
        },
      ],
    },

    {
      subLabel: "Website/App URL",
      items: [
        {
          label: "Website url",
          name: "websiteLink",
        },
        {
          label: "Mobile App URL",
          name: "appLink",
        },
      ],
    },
    {
      subLabel: "Social Media URL",
      items: [
        {
          label: "Facebook",
          name: "facebookUrl",
        },
        {
          label: "Instagram",
          name: "instagramUrl",
        },
        {
          label: "Twitter",
          name: "twitterUrl",
        },
      ],
    },
  ],
  acctInfo: [
    {
      subLabel: "Account Details",
      items: [
        {
          label: "Account Source",
          name: "businessAccountProvider",
        },
        {
          label: "Account Number",
          name: "businessAccountNumber",
        },
        {
          label: "Account Name",
          name: "businessAccountName",
        },
      ],
    },
  ],
  documentUpload: [
    {
      subLabel: "Uplod Relevant Business Certificate",
      items: [
        {
          label: "Type of Registration ",
          name: "businessRegistrationType",
          type: "select",
          selectedOption: "businessRegistrationType",
        },
        {
          type: "businessTypeUpload",
          certificateList: (regType) => basedOnSelectedReg(regType),
        },
        /* {
          label: (
            <Span sx={{ display: "flex", alignItems: "center" }}>
              {"Select type of document to upload"}
              <Help sx={{ mx: 2 }} />
            </Span>
          ),
          type: "selectUpload",
          selectedOption: "businessCertificateType",
          selectedValue: "businessCertificateUrl",
          fileName: "BUSINESS_CERTIFICATE",
          name: "businessCertificate",
          infoText:
            "Kindly click on the help icon to view respective required documents to be uploaded",
        } */
        {
          label: "Registration Number",
          name: "businessRegistrationNumber",
        },
        {
          label: "Tax identification Number (TIN)",
          name: "tin",
        },
        {
          label: "Bank Verification Number (BVN)",
          name: "bvn",
        },
        {
          label: "Proof of business operating address for the company",
          type: "selectUpload",
          selectedOption: "businessAddressProveType",
          selectedValue: "businessAddressProveUrl",
          name: "businessAddressProve",
          fileName: "BUSINESS_ADDRESS_PROVE",
          infoText:
            "Utility Bill/Water Bill/Electricity bill, bearing the same business address, not older than 3 months.",
        },
        {
          label: "Valid Id card",
          type: "selectUpload",
          selectedOption: "identificationType",
          selectedValue: "identificationUrl",
          fileName: "ID_CARD",
          name: "validId",
          infoText:
            "Provide any valid government issued ID either Voter’s card,  International passport or National Identification Number(NIN)",
        },
      ],
    },
  ],
};

export const sectionLabel = {
  profileInfo: "PROFILE INFORMATION",
  contactInfo: "CONTACT INFORMATION",
  acctInfo: "ACCOUNT DETAILS",
  documentUpload: "DOCUMENT UPLOAD",
};

export const dropDownOptionItems = {
  validId: {
    VOTERS_CARD: "VOTERS CARD",
    NATIONAL_ID: "NATIONAL ID",
  },

  businessAddressProve: {
    UTILITY_BILL: "UTILITY BILL",
  },
  country: {
    Nigeria: "Nigeria",
  },

  businessCertificate: {
    CAC_IT_1: "From CAC IT/1",
    CAC_BN_1: "CAC BN/1",
    CERTIFICATE_OF_INCORPORATION: "CERTIFICATE OF INCORPORATION",
    CERTIFICATE_OF_BUSINESS_NAME: "CERTIFICATE OF BUSINESS NAME",
  },
  businessRegistrationType: {
    INCORPORATED_TRUSTEES: " INCORPORATED TRUSTEES",
    INCORPORATED_COMPANY: "INCORPORATED COMPANY",
    BUSINESS_NAME_REGISTRATION: "BUSINESS NAME REGISTRATION",
  },
  country: {
    string: "Select Country",
  },
  city: {
    ["string" || "" || undefined || null]: "Select City",
  },
  state: {
    string: "Select State",
  },
  lga: {
    string: "Select Local Govt Area",
  },
  businessType: {
    REGISTERED_BUSINESS: "REGISTERED BUSINESS",
  },
};

const profileInfoValidationSchema = yup.object().shape({
  businessName: yup
    .string("Enter payment business name")
    .required("Business name is required"),
  businessDescription: yup
    .string("Business description is required")
    .required("business description is required"),
});

export const ValidationSchema = {
  ...profileInfoValidationSchema,
};
