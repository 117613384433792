import React, { useState } from "react";
import SettingIcon from "../../../../components/Icons/SettingIcon";
import DropIcon from "../../../../components/Icons/DropIcon";
import ChevronArrowLeftIcon from "../../../../components/Icons/ChevronArrowLeftIcon";
import ChevronArrowRightIcon from "../../../../components/Icons/ChevronArrowRightIcon";
import SearchInput from "./SearchInput";
import ItemAmount from "./ItemAmount";
import range from "lodash/range";
import map from "lodash/map";
import TopActionBtn from "./TopActionBtn";
import "./styles.css";
import AssignReferralCodeModal from "./AssignReferralCodeModal";
import BulkBonusModal from "./BulkBonusModal/BulkBonusModal";
import ReferralSettingModal from "./ReferralSettingModal";
import SendBonusModal from "./SendBonusModal";
import SuccessfulStatusModal from "./SuccessfulStatusModal";

const ROWS_PER_PAGE = 10;
const AVAILABLE_PAGE_ROWS = [10, 25, 50, 100];

const Referrals = () => {
  const [showAssignCodeModal, setShowAssignCodeModal] = useState(false);
  const [showBulkBonusModal, setShowBulkBonusModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showSendBonusModal, setShowSendBonusModal] = useState(false);
  const [showSuccessfulStatusModal, setShowSuccessfulStatusModal] =
    useState(true);
  return (
    <section className="referrals__container">
      <div className="referrals__top-actions">
        <SearchInput />
        <div className="referrals__top-actions__btn-container">
          <TopActionBtn
            className="assign-code-btn"
            onClick={() => setShowAssignCodeModal(true)}
            content={"Assign Code"}
          />

          <TopActionBtn
            className="bulk-bonus-btn"
            onClick={() => setShowBulkBonusModal(true)}
            content={"Bulk Bonus"}
          />

          <TopActionBtn
            className="export-referral-data-btn"
            onClick={() => {}}
            content={"Export Referral Data"}
          />

          <TopActionBtn
            className="referral-setting-btn"
            onClick={() => setShowSettingModal(true)}
            content={
              <>
                <SettingIcon color="#6a6a6a" />
                <p className="referral-setting-btn-text">Referral Settings</p>
              </>
            }
          />
        </div>
      </div>

      <div className="referral-item-amounts-container">
        <ItemAmount
          text={"Total Number of Referral Codes Used"}
          amount="2008"
        />
        <ItemAmount
          text={"Total Users Registered with Referral Code"}
          amount="20,000"
        />
      </div>

      <div className="referral__main-content">
        <label className="referral__date-container">
          <p className="referral__date-title">{"Date"}</p>
          <input
            className="referral__date"
            type="date"
            style={{ appearance: "none" }}
          />
        </label>

        <div className="referral__table-container">
          <div>
            <table className="referral__table" cellspacing="0" cellpadding="0">
              <tr className="referral__table__head">
                <th>{"Referred User"}</th>
                <th>{"Referred By"}</th>
                <th>{"Referral Phone"}</th>
                <th>{"Referral Email"}</th>
                <th>{"Date Joined"}</th>
                <th className="word-wrap">{"Referral Code"}</th>
                <th className="word-wrap">{"Referral Earnings"}</th>
                <th></th>
              </tr>
              {map(range(ROWS_PER_PAGE), () => (
                <tr className="referral__table__row">
                  <td>{"Philip Templar"}</td>
                  <td>{"John Agbo"}</td>
                  <td>{"09052378230"}</td>
                  <td>{"Philiptemplar@gmail.com"}</td>
                  <td>
                    <p>{"Feb, 9th 2021"}</p>
                    <p>{"08:57:01 PM"}</p>
                  </td>
                  <td>{"xcvuys7"}</td>
                  <td>{"N,1000"}</td>
                  <td>
                    <button
                      className="referral__table__send-btn"
                      onClick={() => setShowSendBonusModal(true)}
                    >
                      {"Send Bonus"}
                    </button>
                  </td>
                </tr>
              ))}
            </table>

            <div className="referral__table__footer">
              <div className="referral__table__rows-per-page-container">
                <p>{"Rows per page: " + 10}</p>
                <div className="referral__table__rows-per-page-drop-icon">
                  <DropIcon />
                </div>

                <ul className="rows-per-page-dropdown hidden">
                  {map(AVAILABLE_PAGE_ROWS, (row, index) => (
                    <li className="rows-per-page-dropitem" key={index}>
                      <button>{row}</button>
                    </li>
                  ))}
                </ul>
              </div>

              <p>{"1 - 6 of 6"}</p>

              <div className="referral-page-nav-container">
                <button>
                  <ChevronArrowLeftIcon />
                </button>
                <button>
                  <ChevronArrowRightIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AssignReferralCodeModal
        show={showAssignCodeModal}
        onCloseBtnClick={() => setShowAssignCodeModal(false)}
      />
      <BulkBonusModal
        show={showBulkBonusModal}
        onCloseBtnClick={() => setShowBulkBonusModal(false)}
      />
      <ReferralSettingModal
        show={showSettingModal}
        onCloseBtnClick={() => setShowSettingModal(false)}
      />
      <SendBonusModal
        show={showSendBonusModal}
        onCloseBtnClick={() => setShowSendBonusModal(false)}
      />
      <SuccessfulStatusModal
        show={showSuccessfulStatusModal}
        onCloseBtnClick={() => setShowSuccessfulStatusModal(false)}
      />
    </section>
  );
};

export default Referrals;
