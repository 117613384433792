import { styled } from "@mui/material";

export const StyledBox = styled("div")(({ theme }) => ({
  label: {
    display: "block",
    marginBlock: theme.spacing(1),
  },
  ["select,input,textarea"]: {
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
    padding: theme.spacing(2),
    "& :focus": {
      outlineColor: theme.palette.primary.main,
    },
  },
  span: {
    color: theme.palette.primary.main,
  },
}));
