import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Navigate, useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { customSearch, searchData } from "utils/customSearch";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { SearchAction } from "redux/actions/SearchAction";
import * as _ from "lodash";

const SearchRoot = styled("div")(({ theme }) => ({
  display: "flex",
  height: theme.spacing(5),
  input: {
    width: "250px",
  },
}));

const SimpleSearch = ({ searchSpace, setSearchData }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get("search");
  const [data, setAllData] = useState();
  const debounceFn = useCallback(_.debounce(handleDebounceFn, 500), []);
  const dispatch = useDispatch();
  function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let newSearch = formData.get("search");
    if (!newSearch) return;
    setSearchParams({ search: newSearch });
  }
  function handleDebounceFn(value) {
    setSearchParams({ search: value });
  }
  const handleChange = (e) => {
    const { value } = e.target;
    //  debounceFn(value);
  };

  useMemo(async () => {
    await searchData[searchSpace]({}).then((data) => {
      setAllData(data);
    });
  }, [searchSpace]);
  useEffect(() => {
    const controller = new AbortController();
    if (search) {
      customSearch(data, search).then((data) => {
        const searchResult = data.map(({ item }) => item);
        return dispatch(SearchAction({ searchResult, query: search }));
      });
    } else {
      return dispatch(SearchAction({ searchResult: [], query: "" }));
    }
    return () => {
      controller.abort();
    };
  }, [search]);

  const handleSerach = async () => {
    setSearchData([])
  }

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <FormControl sx={{ width: "25ch" }}>
        <SearchRoot>
          <OutlinedInput
            placeholder="Please enter text"
            name={"search"}
            onChange={handleChange}
            endAdornment={false && <ArrowDropDownIcon />}
          />
          <Button
            variant="contained"
            sx={{ minWidth: "auto", minHeight: "auto", ml: 1 }}
            type={"submit"}
          >
            {"Search"}
          </Button>
        </SearchRoot>
      </FormControl>
    </Box>
  );
};

export default SimpleSearch;
