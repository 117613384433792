import React from "react";
import map from "lodash/map";

const SelectDropdown = ({ dropdownItems, getTextFromDropdownItem, show, onSelect, onChange }) => {
  if (!show) return null;
  return (
    <ul className='request-logs__filter-dropdown__select-dropdown'>
      {map(dropdownItems, (dropItems, index) => (
        <li key={index} className='request-logs__filter-dropdown__select-dropitem'>
          <button onClick={() => {onSelect(dropItems); onChange(dropItems)}}>{getTextFromDropdownItem(dropItems)}</button>
        </li>
      ))}
    </ul>
  );
};

export default SelectDropdown;
