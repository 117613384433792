import CustomIcon from "assets/icons";
import * as _ from "lodash";
import { styled } from "@mui/system";
import { TextToLabel } from "utils/textToLabel";
import { Button, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export const CopyAble = ({ str, showText = true, sx, length = 7 }) => {
  const [copyOpen, setCopyOpen] = useState(false);
  const StyledCopyable = styled("div")(({}) => ({
    width: "122px",
    display: "flex",
    alignItems: "center",
    columnGap: "1em",
    ...sx,
  }));
  function handleTooltipOpen() {
    navigator.clipboard.writeText(str);
    setCopyOpen(true);
  }
  const handleTooltipClose = () => {
    setCopyOpen(false);
  };

  {
    /*
      <span onClick={handleClick} style={{ cursor: "pointer" }}>
        <CustomIcon name="copy" />
      </span>
    </StyledCopyable> */
  }
  return (
    <StyledCopyable>
      {showText && <p>{`${str?.slice(0, length)}...`}</p>}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={copyOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <IconButton onClick={handleTooltipOpen}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </StyledCopyable>
  );
};

export const txdata = {
  revenue: [
    {
      title: "Gross Revenue",
      objKeyRepr: "totalGrossRevenue",
    },
    {
      title: "Total Revenue",
      objKeyRepr: "totalNetRevenue",
    },
  ],
  count: [
    {
      objKeyRepr: "successful",
      title: "Successful",
    },
    {
      objKeyRepr: "totalFailedNetRevenue",
      title: "Failed",
    },
    {
      objKeyRepr: "abandoned",
      title: "Abandoned",
    },
    {
      objKeyRepr: "refunded",
      title: "Refunded",
    },
  ],
};

export const settlementData = {
  revenue: [
    {
      title: "Last Settlement Disbursed",
      objKeyRepr: "lastSettlementAmount",
    },
    {
      title: "Next Settlement",
      objKeyRepr: "nextSettlementAmount",
    },
    {
      title1: "Current Balance" ,
      title: "Current Balance" ,

      objKeyRepr: "totalSettled",
    },
  ],
  count: [
    {
      objKeyRepr: "settled",
      title: "Settled",
    },
    {
      objKeyRepr: "failed",
      title: "Failed",
    },
    {
      objKeyRepr: "pending",
      title: "Pending",
    },
    {
      objKeyRepr: "refunded",
      title: "Refunded",
    },
  ],
};

export const dataKeysAndRepr = [
  { objKey: "refNo", repr: "Reference Id" },
  { objKey: "merchantId", repr: "Merchant Id" },
  { objKey: "merchantName", repr: "Merchant Name" },
  { objKey: "customerName", repr: "Customer Name" },
  { objKey: "customerEmail", repr: "Customer Email" },
  { objKey: "customerId", repr: "Customer ID" },
  { objKey: "customerIpAddress", repr: "Customer IP" },
  { objKey: "processedBy", repr: "Process By" },
  { objKey: "scheme", repr: "Scheme" },
  { objKey: "settlementStatus", repr: "Settlement Status" },
  { objKey: "Transactionstatus", repr: "Transaction Status" },
  { objKey: "channel", repr: "Payment Method" },
  { objKey: "amount", repr: "Amount" },
  { objKey: "tranDate", repr: "Created At" },
  { objKey: "status", repr: "Payment Status" },
  { objKey: "action", repr: "Action" },
];

export const SettlementDataKeysAndRepr = [
  { objKey: "refId", repr: "Reference Id" },
  { objKey: "paymentRefNo", repr: "Payment Reference" },
  // { objKey: "beneficiary", repr: "Beneficiary" },
  { objKey: "actualAmount", repr: "Actual Amount" },
  { objKey: "amount", repr: "Settled Amount" },
  { objKey: "totalFee", repr: "Fee" },
  { objKey: "accountSettlementOption", repr: "Settlement Option" },
  // { objKey: "createdBy", repr: "Created By" },
  { objKey: "dateSettled", repr: "Date Settled" },
  { objKey: "merchantName", repr: "Merchant Name" },
  { objKey: "merchantEmail", repr: "Merchant Email" },
  { objKey: "merchSetlDate", repr: "Merchant Settlement Date" },
  { objKey: "merchantId", repr: "Merchant ID" },
  { objKey: "payoutStatus", repr: "Payout Status" },
  { objKey: "settlementAccount", repr: "Settlement Account" },
  { objKey: "settlementAccountName", repr: "Settlement Account Name" },
  // { objKey: "settlementBankCode", repr: "Settlement Bank Code" },
  // { objKey: "settlementBankName", repr: "Settlement Bank Name" },
  { objKey: "settlementInitiationDate", repr: "Settlement Initiation Date" },
  { objKey: "settlementInterval", repr: "Interval" },
  // { objKey: "settlementType", repr: "Settlement Type" },
  // { objKey: "modifiedBy", repr: "Modified By" },
  { objKey: "createdAt", repr: "Created At" },
  // { objKey: "dateModified", repr: "Modified At" },

  { objKey: "status", repr: "Payment Status" },
];

export const cloneTableData = ({ data }) => {
  if (!_.isEmpty(data)) {
    const items = data?.map((item) => ({
      ...item,
      customerName: item?.customerName,
      customerEmail: item?.customerEmail,
      refNoVal: item?.refNo,
      statusVal: item?.status,
      refNo: <CopyAble str={item?.refNo} />,
      status: TextToLabel(item?.status),
      userId: item?.merchantId,
    }));

    return items;
  }
};
