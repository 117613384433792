import { Box, Card, Grid, TablePagination, Typography } from "@mui/material";
import { wayaDataKeysAndRepr, wayaSummaryCard } from "./data";
import Table from "components/table/Table";
import { WAYAPAY_DISPUTE_TABLE_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import {
  fetchDisputeStats,
  getDisputeList,
} from "services/https/disputeService";
import { useEffect, useState, useContext } from "react";
import * as _ from "lodash";
import { TextToLabel } from "utils/textToLabel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthContext from "contexts/JWTAuthContext";
import { userHasPermission } from "utils/utilFn";

const WayaPayTabPanel = () => {
  const { setTableMenu } = useMenu();
  const [stats, setStats] = useState();
  const [pageData, setPageData] = useState();
  const [data, setData] = useState({ content: [] });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const {
    search: { searchResult, query },
  } = useSelector((state) => {
    return state;
  });
  const { userPermissions } = useContext(AuthContext);
  const showManaging = userHasPermission(userPermissions, 'MANAGE_DISPUTES')

  const navigate = useNavigate();
  const destructureNeed = (data = [], totalElements) => {
    const objArray = data.map((contentData) => {
      const {
        adminStatus,
        disputeType,
        transactionAmount,
        transactionDate,
        customerEmail,
        dateCreated,
        merchantResponseDueDate,
        disputeStatus,
        merchantCustomerDisputeId,
      } = contentData;
      return {
        disputeStatus: TextToLabel(disputeStatus),
        disputeType,
        transaction: `NGN${transactionAmount} on ${transactionDate}`,
        customerEmail,
        dateCreated,
        dueWhen: merchantResponseDueDate,
        id: merchantCustomerDisputeId,
      };
    });
    setData({ content: objArray, totalElements });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setTableMenu(WAYAPAY_DISPUTE_TABLE_MENU);
    fetchDisputeStats().then((data) => {
      if (!_.isEmpty(data)) {
        const {
          adminStatus: [initiate, responseNeeded],
          disputeStatus: [open, closed],
        } = data;
        setStats({
          initiatedDispute: initiate.count,
          openDispute: open.count,
          closedDispute: closed.count,
        });
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (searchResult) {
      setLoading(false);
      const search =
        searchResult.length > rowsPerPage
          ? searchResult.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : searchResult;
      setPageData(search);
      destructureNeed(search, search.length);
    } else {
      getDisputeList({ page, size: rowsPerPage }).then((data) => {
        setLoading(false);
        if (!_.isEmpty(data)) {
          const { content, totalElements } = data || {};
          setPageData(content);
          destructureNeed(content, totalElements);
        }
      });
    }
  }, [page, rowsPerPage, searchResult]);

  const handleRowClick = (id) => {
    navigate(`/logs/dispute/resolution/${id}`, { state: { details: true } });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Grid container spacing={4}>
          {wayaSummaryCard.map(({ title, objKeyRepr }) => (
            <Grid
              item
              sx={{
                width: "fit-content",
              }}
            >
              <Card
                sx={{
                  px: 2,
                  paddingBlockEnd: 2,
                  textAlign: "center",
                  height: "120px",
                }}
              >
                <Typography variant="h5" sx={{ p: 2 }}>
                  {(stats && stats[objKeyRepr]) ?? "0"}
                </Typography>
                <Typography variant="body1">{title}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Table
        dataKeysAndRepr= {showManaging ? wayaDataKeysAndRepr : wayaDataKeysAndRepr.filter(item => item?.objKey !== 'action')}
        dataItemRows={data?.content}
        pageData={pageData}
        alignAction={"center"}
        isLoading={isLoading}
        handleRowClick={handleRowClick}
      />

      <TablePagination
        component="div"
        count={data?.totalElements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};

export default WayaPayTabPanel;
