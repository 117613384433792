import axios from 'axios';
import { static5Dt } from 'components/tabs/kyc/businessKYC/data';

import CONFIG from '../../config';
const statURL = `${CONFIG.BASE_URL}/identity-manager-service/api/v1/waya-merchant/business-kyc`;

const baseURL = `${CONFIG.BASE_URL}/kyc-service`;

export const getKycStats = async () =>
	await axios.get(`${statURL}/statistics`).then((res) => res.data.data).catch((err) => console.log(err));

export const getKycList = async (q) => {
	const params = new URLSearchParams(q).toString();
	return await axios
		.get(`${baseURL}/kyc/list/all?${params}`)
		.then((res) => res.data.data)
		.catch((err) => console.log(err));
};

const getInternationalPassport = async ({ userId }) =>
	await axios.get(`${baseURL}/international/passport/get/by/user/${userId}`).then((res) => res.data).catch((err) => {
		throw new Error({ msg: 'cannot fetch user international passport' });
	});

const getVotersCard = async ({ userId }) =>
	await axios.get(`${baseURL}/user/voters/card/get/by/user/${userId}`).then((res) => res.data).catch((err) => {
		throw new Error({ msg: 'cannot fetch user voters card' });
	});

const getUtilityBill = async ({ userId }) =>
	await axios.get(`${baseURL}/utility/bill/get/by/user/${userId}`).then((res) => res.data).catch((err) => {
		throw new Error({ msg: 'cannot fetch user utility bill' });
	});

const getProveOfAddress = async ({ userId }) =>
	await axios.get(`${baseURL}/address/user/${userId}`).then((res) => res.data).catch((err) => {
		throw new Error('cannot fetch user address okay');
	});

export const getTierLevel3Data = (userId) => {
	const tier3promises = [
		getInternationalPassport,
		getVotersCard,
		getUtilityBill,
		getProveOfAddress
	].map((fetchFn) => fetchFn(userId));
	const tier3 = Promise.allSettled(tier3promises).then((data) => {
		return data.map((i) => i.value);
	});
	return tier3;
};

export const getTierLevel3_4Status = async ({ userId }) =>
	await axios
		.get(`${statURL}/fetch/status/report/${userId}`)
		.then((res) => {
			return res.data.data;
		})
		.catch((err) => {
			console.log(err);
		});

export const getTierLevel5 = async ({ userId }) =>
	new Promise((resolve) => {
		resolve(static5Dt);
	});

export const isBvnLinked = async ({ userId }) =>
	await axios.get(`${baseURL}/isBvnLinked/${userId}`).then((res) => res.data.status).catch((err) => {
		if (err.response.status === 404) {
			return err.response.data.error;
		} else {
			console.log(err);
		}
	});

export const approveKYCUrl = async () =>
	new Promise((resolve) => {
		resolve({});
	});

export const rejectKYCUrl = async () =>
	new Promise((resolve) => {
		resolve({});
	});
//updated https service
