import { useState } from "react";
import {
  Box,
  CircularProgress,
  Popover,
  Stack,
  TablePagination,
  Button,
  Typography,
  useTheme,
  InputBase,
} from "@mui/material";
import { ArrowDropDown, Search } from "@mui/icons-material";
import Table from "components/table/Table";
import CustomIcon from "assets/icons";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import { dataKeysAndRepr, sampleData, TotalSummary } from "./data";
import Filter from "components/filter";

export default function AdminLog() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [chainedFilters, setChainedFilters] = useState({ keys: [] });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [data, setData] = useState(sampleData);

  return (
    <Box>
      <Stack direction={"row"} sx={{ justifyContent: "space-between", mb: 2 }}>
        <Stack direction={"row"} columnGap={2}>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
          >
            {"Search by..."}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
          </Popover>
          <InputBase
            startAdornment={<Search />}
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: "5px",
            }}
            placeholder={"Search user by name and email"}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ height: "fit-content", backgroundColor: "success.main" }}
          startIcon={<CustomIcon name={"coolicon"} />}
        >
          {"Export Data"}
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        {TotalSummary.map(({ title, objKeyRepr }, idx) => (
          <SimpleCard
            key={`${objKeyRepr}-${idx}`}
            title={title}
            width={"fit-content"}
            height={"120px"}
          >
            <Typography variant={"h6"}>
              {1200 ?? <CircularProgress size={12} />}
            </Typography>
          </SimpleCard>
        ))}
      </Stack>

      <Stack direction={"row"} spacing={3} sx={{ alignItems: "center" }}>
        <Filter
          name={"LogType"}
          searchSpace={"manageUser"}
          chainedFilters={chainedFilters}
          setChainedFilters={setChainedFilters}
          searchKey="otherDetails.organizationCity"
        />
        <InputBase
          type={"date"}
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.15)",
            height: "35px",
            mt: -1,
          }}
        />
      </Stack>
      <Table dataKeysAndRepr={dataKeysAndRepr} dataItemRows={data} />
      <TablePagination component="div" count={data?.count ?? 0} />
    </Box>
  );
}
