import React from "react";

const CancelIcon = ({ color = "#BCBCBC", size = 17 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='transparent'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='2.88344'
        height='20.7608'
        rx='1.44172'
        transform='matrix(0.695018 -0.718992 0.695018 0.718992 0.570312 2.07422)'
        fill={color}
      />
      <rect
        width='2.88344'
        height='20.7608'
        rx='1.44172'
        transform='matrix(-0.695018 -0.718992 -0.695018 0.718992 16.4297 2.07422)'
        fill={color}
      />
    </svg>
  );
};

export default CancelIcon;
