import React, { useState } from "react";
import CancelIcon from "components/Icons/CancelIcon";
import DropIcon from "components/Icons/DropIcon";
import FundingMethodDropdown from "./FundingMethodDropdown";
import ToAccountDropdown from "./ToAccountDropdown";
import "./styles.css";

const FundAccountModal = ({ show, handleClose }) => {
  const [showFundingMethodDropdown, setShowFundingMethodDropdown] = useState(false);
  const [showToAccountDropdown, setShowToAccountDropdown] = useState(false);

  if (!show) return null;
  return (
    <div className='rename-account-modal'>
      <div className='rename-account-modal__main-content'>
        <button className='rename-account-modal__close-btn' onClick={handleClose}>
          <CancelIcon />
        </button>

        <div className='rename-account-modal__body'>
          <div className='rename-account-modal__title-container'>
            <h3 className='rename-account-modal__title'>{"Fund Account"}</h3>
          </div>

          <div className='rename___account__form'>
            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Funding Method"}</label>
              <div className='rename-account-modal-dropdown-container'>
                <p>{"Select Transfer Mode"}</p>
                <button onClick={() => setShowFundingMethodDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <FundingMethodDropdown show={showFundingMethodDropdown} />
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Account To"}</label>
              <div className='rename-account-modal-dropdown-container'>
                <p>{"To Account"}</p>
                <button onClick={() => setShowToAccountDropdown((s) => !s)}>
                  <DropIcon color='#666666' />
                </button>
                <ToAccountDropdown show={showToAccountDropdown} />
              </div>
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Amount"}</label>
              <input className='rename-account-modal-input' value='' placeholder='Enter Amount' />
            </div>

            <div className='rename-account-modal-input-group'>
              <label className='rename-account-modal-label'>{"Enter PIN"}</label>
              <input className='rename-account-modal-input' value='' placeholder='Enter PIN' />
            </div>
          </div>

          <div className='rename-account-modal__action-btns-container'>
            <button className='rename-account-modal__btn rename-account-modal__orange-btn'>
              {"Proceed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundAccountModal;
