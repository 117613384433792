import { Box, Paper, styled } from "@mui/material";

export const StyledKYCDetails = styled(Box)(({ theme }) => ({
  ".detailContainer": {
    flexGrow: 1,
    width: "100%",
    px: 4,
  },
  ".gridContainer": {},
  ".sectionLabel": {
    py: 3,
    color: `${theme.palette.primary.main}`,
    fontWeight: "bold",
  },
  ".subSectionLabel": {
    py: 3,
    fontWeight: "bold",
    color: "black",
  },
}));

export const StyledInputBox = styled(Box)(({ theme }) => ({
  label: {
    display: "block",
    fontWeight: "bold",
    paddingTop: "1em",
  },
  input: {
    background: "rgba(196, 196, 196, 0.15)",
    borderRadius: "5px",
    padding: "16px",
  },
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  color: theme.palette.text.secondary,
  width: "300px",
  a: {
    display: "block",
  },
}));
