import * as yup from "yup";

export const validationSchema = yup.object({
  emailAddress: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  
  lastName: yup
    .string("Enter your surname")
    .min(3, "surname should be of minimum 3 characters length")
    .required("surname is required"),

  firstName: yup
    .string("Enter your first name")
    .min(3, "firstname should be minimum of 3 characters length")
    .required("firstname is required"),

  gender: yup.string("Choose a gender").required("Choose gender"),

  phoneNumber: yup
    .string("Phone number is required")
    .matches(/^\d+$/, 'Phone number is not valid')

    .min(10, "phone number should be of minimum 10 characters length")
    .max(10, "phone number should be of maximum 10 characters length")
    .required("phone number is required"),

  role: yup.string("Select a role").required("Select role"),

  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const initialVal = {
  emailAddress: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  password: "",
  gender: "",
  role: "",
  dateOfBirth: ""
};

export const createAdminForm = [
  {
    label: "First Name",
    name: "firstName",
  },
  {
    label: "Last Name",
    name: "lastName",
  },
  {
    label: "Email Address",
    name: "emailAddress",
  },
  {
    label: "Phone Number",
    placeholder: "must include 234 and last 10 digits",
    name: "phoneNumber",
    start: "234",
  },

  {
    label: "Gender",
    placeholder: "Gender",
    type: "SingleSelectPlaceholder",
    name: "gender",
    firstOption: "--Gender--",
  },
  {
    label: "Role",
    name: "role",
    type: "SingleSelectPlaceholder",
    firstOption: "--Select Role--",
  },
  {
    label: "Create Password",
    name: "password",
  },
  {
    label: "Confirm Password",
    name: "passwordConfirmation",
  },
 
 
];
