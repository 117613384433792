import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  InputBase,
  Box,
  Button,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { StyledBox } from "./Styled";
import { createDialog } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import { getPlanById, getPlanList } from "services/https/paymentLink";
import { useEffect, useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import { format } from "date-fns";

const step1Schema = yup.object().shape({
  planId: yup.string("choose a plan").required("You need to choose a plan"),
  startDateAfterFirstPayment: yup
    .string("select date time")
    .required("Date from which subscription should start"),
  totalCount: yup
    .number("Billing cycle is required")
    .typeError("No of billing cycles to be charged is required")
    .required("No of billing cycles to be charged is required"),
});

export const FormStep1 = ({ formValues, setFormValues }) => {
  const [planlist, setPlanList] = useState([]);
  const [limit, setLimit] = useState(0);
  const {
    merchantId,
    planId,
    applyDateAfterFirstPayment,
    startDateAfterFirstPayment,
    totalCount,
  } = formValues || {};
  useEffect(() => {
    getPlanList({ merchantId, size: 100 }).then((data) => {
      const { content, totalElements } = data || {};
      if (!_.isEmpty(content)) {
        const plans = content.map(({ planName, planId }) => ({
          planName,
          planId,
        }));
        setPlanList(plans);
      }
    });
  }, [merchantId]);
  const dateInstance = format(new Date(), "yyyy-MM-dd'T'HH:mm");
  const dispatch = useDispatch();

  const getPlanLimit = (e) => {
    const { value } = e.target;
    getPlanById({ planId: value, merchantId }).then(({ totalCount }) => {
      setLimit(totalCount);
    });
  };
  return (
    <Box>
      <Formik
        initialValues={{
          planId: planId ?? "",
          applyDateAfterFirstPayment: applyDateAfterFirstPayment ?? false,
          startDateAfterFirstPayment: startDateAfterFirstPayment ?? "",
          totalCount: totalCount ?? limit,
        }}
        validationSchema={step1Schema}
        onSubmit={(values, actions) => {
          setFormValues((formValues) => ({
            ...formValues,
            ...values,
            validStep: 0,
          }));
        }}
      >
        {({ handleChange, values, errors }) => (
          <Form>
            <StyledBox>
              <label>{"Select Plan"}</label>
              <Box sx={{ display: "block", width: "100%", px: 4 }}>
                <Box sx={{ mb: 1 }}>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      dispatch(createDialog({ type: "createPaymentPlan" }));
                    }}
                  >
                    {"Create Plan"}
                  </Button>
                </Box>
                <Select
                  name="planId"
                  onChange={(e) => {
                    getPlanLimit(e, values);
                    handleChange(e);
                  }}
                  fullWidth
                  value={values.planId}
                >
                  <MenuItem value={""}>{"Choose a plan"}</MenuItem>
                  {planlist.map(({ planName, planId }, idx) => (
                    <MenuItem value={planId}>{planName}</MenuItem>
                  ))}
                </Select>
                <Typography color={"primary.main"}>
                  <ErrorMessage name={"planId"} />
                </Typography>
              </Box>
            </StyledBox>

            <StyledBox>
              <label>{"Start Date"}</label>
              <Box sx={{ display: "block" }}>
                <Box>
                  <InputBase
                    className="check"
                    type="checkbox"
                    name="applyDateAfterFirstPayment"
                    onChange={handleChange}
                    value={values.applyDateAfterFirstPayment}
                  />
                  <span
                    style={{
                      color: "black",
                      lineHeight: "24px",
                      verticalAlign: "top",
                    }}
                  >
                    {"Immediate subscriptions start with first payment"}
                  </span>
                </Box>
                <input
                  type="datetime-local"
                  name={"startDateAfterFirstPayment"}
                  onChange={handleChange}
                  value={values.startDateAfterFirstPayment}
                  min={dateInstance}
                />

                <Typography color={"primary.main"}>
                  <ErrorMessage name={"startDateAfterFirstPayment"} />
                </Typography>
              </Box>
            </StyledBox>

            <StyledBox>
              <label>{"Total Count *"}</label>
              <Box>
                <InputBase disabled={true} name={"totalCount"} value={limit} />

                <Typography sx={{ display: "block" }} color={"primary.main"}>
                  <ErrorMessage name={"totalCount"} />
                </Typography>
              </Box>
            </StyledBox>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button type="submit" variant="contained">
                {"Okay"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
