import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";

import folder from "assets/images/folder.svg";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { planDataKeyRepr } from "../data";

import Table from "components/table/Table";
import _ from "lodash";
import { PLAN_MENU } from "utils/constant";
import useMenu from "hooks/useMenu";
import { CopyAble } from "components/tabs/transaction/data";
import { format } from "date-fns";
import Loading from "components/shared/appLoader/AppLoader";
import { getPlanList } from "services/https/paymentLink";
import { TabContext } from "contexts/TabContext";

export const PlanList = () => {
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { merchantId } = useOutletContext();

  const { activeTab, setActiveTab } = useContext(TabContext);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { setTableMenu } = useMenu();
  useEffect(() => {
    setTableMenu(PLAN_MENU);
  }, []);

  useEffect(() => {
    if (merchantId) {
      setLoading(true);
      const q = { merchantId, size: rowsPerPage, page, order: "DESC" };
      getPlanList(q)
        .then((data) => {
          const { content, totalElements } = data || {};
          setPlanList({
            content: destructureNeed(content),
            totalElements,
            raw: content,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [merchantId, rowsPerPage, page]);

  const destructureNeed = (content) => {
    const res = content?.map(
      ({
        planName,
        planId,
        planAmount,
        interval,
        totalCount,
        createdAt,
        totalRevenueGenerated,
      }) => ({
        planName,
        planId: <CopyAble str={planId} length={10} />,
        planAmount: `NGN ${planAmount}`,
        interval,
        createdAt: format(new Date(createdAt), "MMM dd yyyy hh:mm aaa"),
        totalCount,
        totalRevenueGenerated,
        id: planId,
      })
    );
    return res;
  };
  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) : _.isEmpty(planList.content) ? (
        <Box
          sx={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            height: "40vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={folder} />
            <Typography variant={"h5"} sx={{ fontWeight: "bold" }}>
              {"There are no Plans yet!!!"}
            </Typography>
            <Typography>{"Create a new plan"}</Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Table
            dataKeysAndRepr={planDataKeyRepr}
            dataItemRows={planList.content}
            pageData={planList.raw}
            handleRowClick={(id) => {
              setActiveTab("details");
              navigate(`${id ?? ""}`, {
                state: { merchantId },
              });
            }}
          />
          <TablePagination
            component="div"
            count={planList.totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};
