export const dataKeysAndRepr = [
	{ objKey: 'businessName', repr: 'BUSINESS NAME' },
	{ objKey: 'businessEmail', repr: 'EMAIL ADDRESS' },
	{ objKey: 'businessPhoneNumber', repr: 'PHONE' },
	{ objKey: 'businessType', repr: 'BUSINESS TYPE' },
	{ objKey: 'statusLastModifiedOn', repr: 'VERIFIED DATE' },
	{ objKey: 'approvalStatus', repr: 'STATUS' },
	{ objKey: 'action', repr: 'ACTION' }
];

export const TotalSummary = [
	{
		title: 'Total Number of Users',
		objKeyRepr: 'totalSumittedKyc'
	},
	{
		title: 'Total Verified KYC users',
		objKeyRepr: 'totalVerifiedKyc'
	},
	{
		title: 'Total Rejected KYC Users',
		objKeyRepr: 'totalRejectedKyc'
	},
	{
		title: 'Total Unverified KYC users',
		objKeyRepr: 'totalUnverifiedKyc'
	},
	{
		title: 'KYC pending for approval',
		objKeyRepr: 'totalPendingKyc'
	}
];

export const businessProfileDt = [
	{
		label: 'Business Name',
		objKey: 'organisationName'
	},
	{
		label: 'Business Description',
		objKey: 'businessDescription'
	},
	{
		label: 'Business Type',
		objKey: 'businessType'
	},
	{
		label: 'Business Phone Number',
		objKey: 'organisationPhone'
	},
	{
		label: 'Business Email Address',
		objKey: 'organisationEmail'
	}
];

export const mapper = {
	1: [
		{
			mapKey: 'organisationEmail',
			objKey: 'emailVerified'
		},
		{
			mapKey: 'organisationPhone',
			objKey: 'phoneVerified'
		}
	],

	2: 'status',

	3: 'adminStatus',
	4: 'adminStatus'
};

const bvnDt = [
	{
		label: 'Bank Verification Number (BVN)',
		objKey: 'bvnNo'
	}
];

const passportDt = [
	{
		label: 'Passport Photograph',
		objKey: 'userPassportPhotograph',
		action: 'uploadType'
	},

	{
		label: 'International Passport',
		objKey: 'userInternationalPassport',
		action: 'uploadType'
	},
	{
		label: 'Voter’s Card',
		objKey: 'userVotersCard',
		action: 'uploadType'
	},
	{
		label: 'National Identity Number',
		objKey: 'userNationalId',
		action: 'uploadType'
	},
	{
		label: 'Utility Bill on the name',
		objKey: 'usersUtilityBill',
		action: 'uploadType'
	}
	/*  {
    label: "Proove of Address, not less than 1 month old",
    objKey: "addressProof",
    action: "uploadType",
  },
  {
    label: `Address verification - photo of the street, 
    house number, left and right `,
    objKey: "streetPhoto",
    action: "uploadType",
  }, */
];

const cacDt = [
	{
		label: 'CAC Document - certificate',
		objKey: 'userCAC',
		action: 'uploadType'
	},
	{
		label: 'CAC Document - memart',
		objKey: 'cacMemart',
		action: 'uploadType'
	},
	{
		label: 'Taxpayer Identification Number (TIN)',
		objKey: 'tin',
		action: 'uploadType'
	}
];

const urlDt = [
	{
		label: 'Website URL',
		action: 'viewType',
		objKey: 'websiteUrl'
	},
	{
		label: 'App URL',
		action: 'viewType',
		objKey: 'appUrl'
	},

	{
		label: 'Facebook URL',
		action: 'viewType',
		objKey: 'facebookUrl'
	},

	{
		label: 'Instagram URL',
		action: 'viewType',
		objKey: 'instagramUrl'
	},

	{
		label: 'Twitter URL',
		action: 'viewType',
		objKey: 'twitterUrl'
	}
];

export const tiers = {
	1: {
		data: businessProfileDt,
		actionKey: ''
	},
	2: {
		data: bvnDt,
		actionKey: ''
	},
	3: {
		data: passportDt,
		actionKey: 'fileName'
	},
	4: {
		data: cacDt,
		actionKey: 'fileName'
	},
	5: {
		data: urlDt,
		actionKey: 'url'
	}
};

export const static4Dt = {
	cacMemart: {
		id: null,
		imageUrl: null,
		userId: null,
		fileName: null,
		uploadBy: null
	},

	tin: {
		id: null,
		imageUrl: null,
		userId: null,
		fileName: null,
		uploadBy: null
	}
};

export const static5Dt = {
	websiteUrl: {
		id: 6,
		url: 'https://app.wayapaychat.com.ng',
		uploadBy: 2
	},
	appUrl: {
		id: 6,
		url: 'https://app.wayapaychat.com.ng',
		uploadBy: 2
	},
	facebookUrl: {
		id: 6,
		url: null,
		uploadBy: 2
	},
	instagramUrl: {
		id: 6,
		url: '/app.wayapaychat.com.ng',
		uploadBy: 2
	},
	twitterUrl: {
		id: 6,
		url: 'app.wayapaychat.com.ng',
		uploadBy: 2
	}
};
