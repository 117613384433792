import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";

import {
  Box,
  Button,
  Grid,
  styled,
  Paper,
  Typography,
  useTheme,
  Stack,
} from "@mui/material";

import Table from "components/table/Table";
import { cardData, SettlementDetailsDataKeysAndRepr } from "./data";
import { useSafeAsync } from "hooks/useSafeAsync";
import { cloneElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { searchData } from "utils/customSearch";
import CustomIcon from "assets/icons";
import { SETTLE_MANUALLY } from "utils/constant";
import { createDialog } from "redux/actions/AlertActions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

export const SettlementDetail = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [row, setRow] = useState({});
  const safeAsync = useSafeAsync();
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { refId } = useParams();
  const dispatch = useDispatch();
  const {
    alerts: { dialogType },
  } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    setLoader(true);
    safeAsync(searchData["settlement"]())
      .then((data) => {
        console.log(data, "yeah");
        console.log('refId', refId);
        const detail = data?.content?.find(
          ({ id }) => id == refId
        );
        console.log(detail, "details");
        setRow(detail ?? {});
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const handleSettleManually = () => {
    setOpenDialog(true);
    dispatch(createDialog({ type: SETTLE_MANUALLY, payload: row }));
  };

  return (
    <>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          marginBottom: `${theme.spacing(4)}`,
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          {"Back"}
        </Button>
        {row?.settlementStatus === "PENDING" && (
          <Button
            variant="contained"
            startIcon={<CustomIcon name={"manual"} />}
            onClick={handleSettleManually}
          >
            {"Settle Manually"}
          </Button>
        )}
      </Stack>
      <Paper sx={{ p: 2 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {cardData.map(({ title, objKey, val }, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item>
                <Typography color={"primary.main"}>{title}</Typography>
                <Typography>{row[objKey] ?? val}</Typography>
              </Item>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ marginBlock: theme.spacing(4) }}>
          <Table
            dataKeysAndRepr={SettlementDetailsDataKeysAndRepr}
            dataItemRows={[row]}
            pageData={row}
          />
        </Box>
        <Box
          sx={{
            paddingBlock: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Typography
            sx={{
              textAlign: "center",
              color: "primary.main",
              cursor: "pointer",
            }}
            component={"a"}
            onClick={() =>
              navigate({
                pathname: "/transactions",
                search: createSearchParams({
                  refId,
                }).toString(),
              })
            }
          >
            {"Click here to view details about this transaction"}
          </Typography> */}
        </Box>
      </Paper>
    </>
  );
};
