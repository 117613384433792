import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash";
import Loading from "components/shared/appLoader/AppLoader";
import { getPlanById } from "services/https/paymentLink";
import { TextToLabel } from "utils/textToLabel";
import { useContext } from "react";
import { TabContext } from "contexts/TabContext";

const PlanDetail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState({});
  const { merchantId } = useOutletContext();
  const { planId } = useParams();
  const { setActiveTab } = useContext(TabContext);
  const handleGoBack = () => {
    setActiveTab("subscriptions");
    navigate(-1);
  };
  useEffect(() => {
    setLoading(true);
    getPlanById({ merchantId, planId }).then((data) => {
      setLoading(false);
      setPlanData(data);
    });
  }, [merchantId]);

  const planItem = [
    { label: "Plan Status", key: "status" },
    { label: "Plan Name", key: "planName" },
    { label: "Plan Code", key: "payableAmount" },
    { label: "Plan Interval", key: "interval" },
    { label: "Max. Number of Payments", key: "totalCount" },
    { label: "No of Subscription", key: "date" },
    { label: "Total Revenue", key: "totalRevenueGenerated" },
  ];
  return (
    <Box>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
      >
        {"Back"}
      </Button>

      {loading ? (
        <Box sx={{ width: "100%", height: "40vh" }}>
          <Loading />
        </Box>
      ) : _.isEmpty(planData) ? (
        <Typography>{"Plan data is not available"}</Typography>
      ) : (
        <Grid container>
          <Grid item xs={6}>
            <Paper sx={{ mt: 3 }}>
              {planItem.map(({ label, key }) => (
                <>
                  <Stack direction={"row"} sx={{ p: 2 }} key={key}>
                    <Typography variant={"body1"} sx={{ width: "50%" }}>
                      {label}
                    </Typography>
                    <Typography variant={"body1"}>
                      {key === "status"
                        ? TextToLabel(planData[key])
                        : planData[key] ?? "Not available"}
                    </Typography>
                  </Stack>
                  <Divider />
                </>
              ))}
              <Stack
                direction={"row"}
                spacing={2}
                sx={{ p: 2, justifyContent: "center" }}
              >
                <Button variant={"contained"}>{"Update Plan"}</Button>
                <Button variant={"contained"}>{"Delete Plan"}</Button>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0}></Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PlanDetail;
