import { styled } from "@mui/material";

export const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  marginBlockEnd: theme.spacing(5),

  label: {
    margin: "auto 0",
    flexBasis: "150px",
  },
  ["select,input,textarea"]: {
    width: "350px",
    borderRadius: "4px",
    border: "1px solid black",
    padding: theme.spacing(2),
    ":focus": {
      outlineColor: theme.palette.primary.main,
    },
  },

  "& .check": {
    width: "50px !important",
  },

  span: {
    color: theme.palette.primary.main,
  },
}));
