export const TotalSummary = [
  {
    title: "Total Logs",
    objKeyRepr: "totalLogs",
  },
];

export const dataKeysAndRepr = [
  { objKey: "name", repr: "NAME" },
  { objKey: "role", repr: "ROLE" },
  { objKey: "actionPerformed", repr: "ACTION PERFORMED" },
  { objKey: "location", repr: "LOCATION" },
  { objKey: "date", repr: "DATE" },
];

export const sampleData = [
  {
    name: "Phillip Templar",
    role: "Super Admin",
    actionPerformed: "GET MASS FUNDED",
    location: "Ikeja Lagos,10.2.18.94",
    date: "Feb. 9th, 2018",
  },
  {
    name: "Phillip Templar",
    role: "Super Admin",
    actionPerformed: "GET MASS FUNDED",
    location: "Ikeja Lagos,10.2.18.94",
    date: "Feb. 9th, 2018",
  },
];
