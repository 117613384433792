import { combineReducers } from "redux";
import NotificationReducer from "./NotificationReducer";
import NavigationReducer from "./NavigationReducer";
import AlertReducer from "./AlertReducer";
import SearchReducer from "./SearchReducer";
import adminReducer from "./AdminReducer";

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    alerts: AlertReducer,
    search: SearchReducer,
    admin: adminReducer,
});

export default RootReducer;
