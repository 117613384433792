import React from "react";

const SuccessIcon = ({size: width = 42, color = '#219653'}) => {
  const height = width / 42 * 31
  return (
    <svg width='42' height='31' viewBox='0 0 42 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.69027 11.4295L15.7506 26.0677L38.9348 2.51236'
        stroke={color}
        stroke-width='6'
      />
    </svg>
  );
};

export default SuccessIcon;
