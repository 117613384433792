import React, { useState } from "react";
import AdminDetail from "./AdminDetail";
import AddIcon from "components/Icons/AddIcon";
import DropIcon from "components/Icons/DropIcon";
import ExportIcon from "components/Icons/ExportIcon";
import CopyIcon from "components/Icons/CopyIcon";
import ChevronArrowLeftIcon from "components/Icons/ChevronArrowLeftIcon";
import ChevronArrowRightIcon from "components/Icons/ChevronArrowRightIcon";
import AccountTypeCard from "./AccountTypeCard";
import SearchInput from "./SearchInput";
import SelectDropdown from "./SelectDropdown";
import TransactionTableType from "./TransactionTableType";
import TransactionTableStatus from "./TransactionTableStatus";
import SwitchBtn from "./SwitchBtn";
import map from "lodash/map";
import range from "lodash/range";
import WayapayOfficialPng from "assets/images/wayapayOfficial.png";
import "./styles.css";
import SettingIcon from "components/Icons/SettingIcon";
import DeleteIcon from "components/Icons/DeleteIcon";
import MoreSettingsModal from "./MoreSettingsModal/MoreSettingsModal";
import AccountNameModal from "./CreateNewAccountModal/AccountName";
import AccountTypeModal from "./CreateNewAccountModal/AccountType";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import DeleteAccountPasswordModal from "./DeleteAccountModal/DeleteAccountPasswordModal";
import OTPModal from "./CreateNewAccountModal/OTP";
import ExportDataModal from "./ExportDataModal";
import RenameAccountModal from "./RenameAccountModal";
import RenameAccountEnterPasswordModal from "./RenameAccountEntertPasswordModal";
import LinkCardAddCardModal from "./LinkCardAddCardModal";
import LoadingStatusModal from "./LoadingStatusModal";
import SuccessfulStatusModal from "./SuccessfulStatusModal";
import AddBankAccountModal from "./AddBankAccountModal";
import FundAccountModal from "./FundAccountModal";
import TransferModal from "./TransferModal";

const accountTypesDetails = [
  {
    accountType: "Wayapay Acquiring account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
    isVerified: true,
  },
  {
    accountType: "Card Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
  {
    accountType: "USSD Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
  {
    accountType: "Card Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
  {
    accountType: "USSD Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
  {
    accountType: "Card Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
  {
    accountType: "USSD Acquiring Commission Account",
    amountAvailable: "N30,000,000",
    accountName: "Wayapay Acquiring",
    accountNumber: "0125724099",
  },
];

const STATUS_LISTS = ["Successful", "Reversed", "Pending"];

const transactionCardDetails = [
  { title: "Total  Transactions", amount: "1,000,000" },
  { title: "Amount Sent Out", amount: "10,000,000" },
  { title: "Amount Received", amount: "20,000,000" },
];

const ROWS_PER_PAGE = 10;
const AVAILABLE_PAGE_ROWS = [10, 25, 50, 100];
const adminPhone = "+2348000000000";
const WayaOfficial = () => {
  const [showMoreSettingsModal, setShowMoreSettingsModal] = useState(false);
  const [showAccountNameModal, setShowAccountNameModal] = useState(false);
  const [showAccountTypeModal, setShowAccountTypeModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showDeleteAccountPasswordModal, setShowDeleteAccountPasswordModal] =
    useState(false);

  return (
    <section className="waya-official waya-official__container">
      <div className="admin-details-container">
        <div className="admin-details-img-container">
          <img src={WayapayOfficialPng} alt="WayaQuick Official" />
        </div>
        <div className="admin-details-name-phone-online-container">
          <div className="admin-details-name-phone-container">
            <p className="admin-details-name">{"Official Waya"}</p>
            <p className="admin-details-phone">{"(" + adminPhone + ")"}</p>
          </div>

          <p className="admin-details-online">{"ONLINE"}</p>
        </div>

        <div className="admin-other-details-container">
          <div className="admin-other-details-container-content">
            <AdminDetail title="User Id" content="ld43841" />
            <AdminDetail title="Email Address" content="admin@wayapay.com" />
            <AdminDetail
              title="Role"
              content="Super Admin"
              contentClassName="waya-super-admin"
            />
            <AdminDetail title="WayaGram" content="wayaofficial" />
          </div>
          <div className="waya-account-settings-container">
            <button className="waya-account-settings">
              {"Waya Official Account Settings"}
            </button>
            <ul className="waya-account-settings-dropdown">
              <li className="waya-account-settings-dropitem">
                <button onClick={() => setShowMoreSettingsModal(true)}>
                  <SettingIcon size={17} />
                  <p>{"More Settings"}</p>
                </button>
              </li>
              <li className="waya-account-settings-dropitem waya-account-settings-delete">
                <button>
                  <DeleteIcon size={11} />
                  <p>{"Delete Waya Official Account"}</p>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*  Account Settings And View */}
      <div className="sub-section">
        <p className="sub-section-title">{"Accounts"}</p>

        <div className="account-action-btns-container">
          <div className="switch-text-and-btn-container">
            <p className="switch-text">{"Hide Balances"}</p>
            <SwitchBtn handleOn={() => {}} handleOff={() => {}} />
          </div>

          <button className="create-account-btn">
            <p>
              <AddIcon />
            </p>
            <p className="create-account-btn-text">{"Create New Account"}</p>
          </button>
        </div>

        <div className="account-type-cards-container">
          {map(accountTypesDetails, (accountTypeDetails, index) => (
            <AccountTypeCard {...accountTypeDetails} key={index} />
          ))}
        </div>

        <div className="money-totals-container">
          {map(transactionCardDetails, (transactionCardDetail) => (
            <div className="money-total">
              <p className="money-total__title">
                {transactionCardDetail.title}
              </p>
              <p className="money-total__amount">
                {transactionCardDetail.amount}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/*  Transactions Table */}
      <div className="sub-section">
        <p className="sub-section-title">{"Recent Transactions"}</p>

        <div className="recent-transactions__actions-container">
          <SearchInput />
          <div className="recent-transactions__action-btns-container">
            <button className="recent-transactions__export-btn">
              <ExportIcon />
              <p>{"Export Data"}</p>
            </button>

            <button className="recent-transactions__perform-btn">
              {"Perform Transaction"}
            </button>
          </div>
        </div>

        <div className="recent-transactions__filter-actions-container">
          <div className="recent-transactions__filter-action">
            <label className="recent-transactions__filter-action__input-label">
              {"Status"}
            </label>

            <div className="recent-transactions__filter-action__input-container">
              <input value={"All"} />
              <button className="recent-transactions__filter-action__status-icon-btn">
                <DropIcon color="#828282" size={15} />
              </button>
              <SelectDropdown
                dropdownItems={STATUS_LISTS}
                getTextFromDropdownItem={(item) => item}
                show={true}
                onSelect={() => {}}
              />
            </div>
          </div>

          <div className="recent-transactions__filter-action">
            <label className="recent-transactions__filter-action__input-label">
              {"Date"}
            </label>
            <div className="recent-transactions__filter-action__input-container">
              <input
                type="date"
                className="recent-transactions__filter-action__date"
              />
            </div>
          </div>
        </div>

        <div className="waya-official__table-container">
          <table
            className="waya-official__table"
            cellspacing="0"
            cellpadding="0"
          >
            <tr className="waya-official__table__head">
              <th>{"Id"}</th>
              <th>{"Admin"}</th>
              <th>{"Type"}</th>
              <th>{"Category"}</th>
              <th>{"Merchant"}</th>
              <th className="waya-table-text-right">{"Amount"}</th>
              <th>{"Date"}</th>
              <th>{"Status"}</th>
            </tr>
            {map(range(ROWS_PER_PAGE), (index) => (
              <tr className="waya-official__table__row">
                <td>
                  <div className="waya-table__id-container">
                    <p className="waya-table__id-text">{"341684"}</p>
                    <button className="waya-table__id-copy-btn">
                      <CopyIcon />
                    </button>
                  </div>
                </td>
                <td>{"Philip Templar"}</td>
                <td>
                  <TransactionTableType type={index % 2 ? "Debit" : "Credit"} />
                </td>
                <td>{index % 2 ? "Transfer" : "Withdraw"}</td>
                <td>{"Samuel Aniefok"}</td>
                <td className="waya-table-text-right">{"N,1000"}</td>
                <td>
                  <p>{"Feb, 9th 2021"}</p>
                  <p>{"08:57:01 PM"}</p>
                </td>
                <td>
                  <TransactionTableStatus
                    status={index % 2 ? "Reversed" : "Successful"}
                  />
                </td>
              </tr>
            ))}
          </table>

          <div className="waya-official__table__footer">
            <div className="waya-official__table__rows-per-page-container">
              <p>{"Rows per page: " + 10}</p>
              <div className="waya-official__table__rows-per-page-drop-icon">
                <DropIcon />
              </div>

              <ul className="rows-per-page-dropdown hidden">
                {map(AVAILABLE_PAGE_ROWS, (row, index) => (
                  <li className="rows-per-page-dropitem" key={index}>
                    <button>{row}</button>
                  </li>
                ))}
              </ul>
            </div>

            <p>{"1 - 6 of 6"}</p>

            <div className="waya-table__page-nav-container">
              <button>
                <ChevronArrowLeftIcon />
              </button>
              <button>
                <ChevronArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <OTPModal show={false} />
      <AccountNameModal
        show={false}
        handleClose={() => setShowAccountNameModal(false)}
      />
      <AccountTypeModal
        show={false}
        handleClose={() => setShowAccountTypeModal(false)}
      />
      <MoreSettingsModal
        show={false}
        handleClose={() => setShowMoreSettingsModal(false)}
      />
      <DeleteAccountModal
        show={false}
        handleClose={() => setShowDeleteAccountModal(false)}
      />
      <DeleteAccountPasswordModal
        show={false}
        handleClose={() => setShowDeleteAccountPasswordModal(false)}
      />
      <RenameAccountModal show={false} handleClose={() => {}} />
      <ExportDataModal show={false} />
      <RenameAccountEnterPasswordModal show={false} />
      <LinkCardAddCardModal show={false} />
      <LoadingStatusModal
        show={false}
        title={"Linking Card"}
        text={"Please wait while we link your card..."}
      />
      <SuccessfulStatusModal
        show={false}
        message={"Your card was linked successfully"}
      />
      <AddBankAccountModal show={false} />
      <FundAccountModal show={true} />
      <TransferModal show={false} />
    </section>
  );
};

export default WayaOfficial;
