import React from "react";

const ChevronArrowRightIcon = ({ color = "#E7472C", size = 17, opacity = 1 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity={opacity}
        d='M0.313905 2.23436C-0.463401 1.55544 0.767334 0.480496 1.54464 1.21599L7.24488 6.13811C7.56876 6.42099 7.56876 6.93018 7.24488 7.21306L1.54464 12.1918C0.767334 12.8707 -0.463401 11.7957 0.313905 11.1168L5.36639 6.70387L0.313905 2.23436Z'
        fill={color}
      />
    </svg>
  );
};

export default ChevronArrowRightIcon;
