import {
  Button,
  ClickAwayListener,
  InputBase,
  Paper,
  Stack,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GenericTab } from "components/tabs/Tab";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useOutletContext } from "react-router-dom";
import { createDialog } from "redux/actions/AlertActions";
import { getLastSegment } from "utils/utilFn";
import FilterListIcon from "@mui/icons-material/FilterList";
import _ from "lodash";
import { clearSearch, SearchAction } from "redux/actions/SearchAction";
import { customSearch } from "utils/customSearch";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { StyledBox } from "components/shared/filterSearchExport/transactionHeader/SettlementFSE";
import { DateRangePicker } from "materialui-daterange-picker";
import HelpIcon from "@mui/icons-material/Help";
import { format } from "date-fns";
import CustomIcon from "assets/icons";

const HeadSearch = ({ setOpenDialog, activeTab, children }) => {
  const StyledHeadSearch = styled(Paper)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    alignContent: "center",
    a: {
      margin: "auto 0",
      color: theme.palette.info,
    },
  }));
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };
  const base = getLastSegment(useLocation().pathname);

  const showHeadSearch =
    base === "paymentlinks" ||
    activeTab === "subscriptions" ||
    activeTab === "customers"||
    activeTab === "settlements" ;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {showHeadSearch && (
        <StyledHeadSearch>
          <Stack direction={"row"}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<FilterForm close={handleTooltipClose} />}
                  arrow
                >
                  <Button
                    variant={"text"}
                    onClick={handleTooltipOpen}
                    startIcon={<FilterListIcon />}
                  >
                    {"Filter"}
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
            <InputBase
              placeholder={"Refrence ID"}
              sx={{
                border: "1px solid #d9d9d9",
                borderRadius: "8px",
                width: "300px",
                px: 2,
                ml: 2,
              }}
            />
          </Stack>
          {children}
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              startIcon={<HelpIcon />}
              href={"https://wayapay.ng/get-started"}
              target={"_blank"}
            >
              {"Need Help? View Documentation"}
            </Button>
            {/*payment links*/}
            {activeTab === "paymentlinks" && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={(e) => handleBtnClick(e, "createPaymentLink")}
              >
                {"Create Payment Link"}
              </Button>
            )}

            {/*customers*/}
            {activeTab === "customers" && (
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  onClick={(e) => handleBtnClick(e, "createCustomer")}
                >
                  {"Add Customer"}
                </Button>
                <Button
                  variant={"outlined"}
                  startIcon={<CustomIcon name="save" />}
                >
                  {"Export"}
                </Button>
              </Stack>
            )}

            {/*customers*/}
            {activeTab === "subscriptions" &&
              (base === "plans" ? (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => handleBtnClick(e, "createPaymentPlan")}
                >
                  {"Create New Plan"}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => handleBtnClick(e, "createSubscriptionPlan")}
                >
                  {"Create New Subscription"}
                </Button>
              ))}

            {activeTab === "settlements" &&
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={(e) => handleBtnClick(e, "withdrawToBank")}
                >
                  {"Withdraw"}
                </Button>
            }
          </Stack>
        </StyledHeadSearch>
      )}
    </>
  );
};

export const PerformTransaction = () => {
  const { setOpenDialog, openDialog } = useOutletContext() || {};
  const theme = useTheme();
  const [name, setName] = useState("perform-merchant-transactions");
  const [activeTab, setActiveTab] = useState("default");
  const tabpillsx = {
    "& .Mui-selected": {
      borderRadius: "0px",
      borderBottom: "2px solid #FF6700",
      color: "#FF6700",
    },
    "& .MuiTab-root": {
      width: "auto",
    },
    "& .MuiTabs-flexContainer": {
      background: "#f8f8f8",
    },
  };

  let activeStyle = {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    textUnderlineOffset: "10px",
  };

  return (
    <GenericTab
      name={name}
      setName={setName}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabpillsx={tabpillsx}
    >
      <HeadSearch
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        activeTab={activeTab}
      >
        {activeTab === "subscriptions" && (
          <>
            <NavLink
              to={"subscriptions/sub"}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              {"Subscriptions"}
            </NavLink>
            <NavLink
              to={"subscriptions/plans"}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              {"Plans"}
            </NavLink>
          </>
        )}
      </HeadSearch>
    </GenericTab>
  );
};

export const FilterForm = ({ close }) => {
  const [date_, setDate] = useState({ startDate: "", endDate: "" });
  const [open, setOpen] = useState(false);

  const [alldata, setAllData] = useState();
  const toggle = () => setOpen(!open);
  const [filterObj, setFilterObj] = useState({});

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    let mydata = alldata;
    if (mydata) {
      const { startDate, endDate } = date_ || {};
      if (endDate && startDate) {
        const result = mydata.filter((d) => {
          let time = new Date(d.tranDate).getTime();
          return new Date(startDate) < time && time < new Date(endDate);
        });
        mydata = result;
      }
      const chainedFilters = Object.entries(filterObj).map(([key, value]) => ({
        [key]: value,
      }));

      if (_.isEmpty(chainedFilters)) {
        dispatch(SearchAction({ searchResult: mydata, query: "date_amount" }));
      } else {
        customSearch(mydata, { $and: chainedFilters }).then((data) => {
          const searchResult = data.map(({ item }) => item);
          dispatch(SearchAction({ searchResult, query: chainedFilters }));
        });
      }
    }
  };
  const handleClear = (event) => {
    setFilterObj({});
    dispatch(clearSearch);
    close();
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFilterObj((filterObj) => ({ ...filterObj, [name]: value }));
  };

  return (
    <Paper sx={{ p: 2 }}>
      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <StyledBox>
          <label>{" Status"}</label>
          <select name={"status"}>
            <option>{"Select status"}</option>
            <option value={"SUCCESSFUL"}>{"Successful"}</option>
          </select>
        </StyledBox>

        <StyledBox>
          <label>{"Date Range"}</label>
          <Paper elevation={1} sx={{ p: 2, width: "fit-content" }}>
            <Stack direction={"row"} sx={{ verticalAlign: "middle" }}>
              {"From : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.startDate}
              </Button>
              {"To : "}
              <Button
                sx={{ color: "#000" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setOpen(true)}
              >
                {date_?.endDate}
              </Button>
            </Stack>
            <DateRangePicker
              open={open}
              toggle={toggle}
              onChange={({ startDate, endDate }) =>
                setDate({
                  startDate: format(startDate, "yyyy-MM-dd"),
                  endDate: format(endDate, "yyyy-MM-dd"),
                })
              }
            />
          </Paper>
        </StyledBox>

        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <Button variant={"contained"} onClick={handleClear}>
            {"Clear Filter"}
          </Button>

          <Button variant={"contained"} type={"submit"}>
            {"Apply Filter"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};
