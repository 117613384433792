import { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
} from "@mui/material";

import { H3, Span } from "components/typography/Typography";
import { useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CustomOutlinedInput from "components/input";
import { notifySuccess, notifyFailure } from "redux/actions/AlertActions";
import { useDispatch } from "react-redux";
import { createAdminForm, initialVal, validationSchema } from "./adminData";
import {
  createAdminUser,
  getAllRoles,
} from "services/https/roleAndAccessService";
import _ from "lodash";
import { createRequest } from "services/https/fetchers";
import { format } from "date-fns/esm";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "fit-content",
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "100%",
    padding: theme.spacing(2),
  },
  span: {
    color: theme.palette.primary.main,
    marginTop: "4px",
  },
}));

export const CreateAdminUserDialog = ({ openDialog, setOpenDialog }) => {
  const [err, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    gender: [
      { name: "MALE", value: "MALE" },
      { name: "FEMALE", value: "FEMALE" },
    ],
  });
  const handleClose = () => {
    setOpenDialog(false);
    // formik.handleReset();
    //setInitialValues(initialValues);
  };
  let dialogRef = useRef();
  const dateInstance = format(new Date(), "yyyy-MM-dd");


  useEffect(() => {
    getAllRoles().then((data) => {
      const myData = JSON.parse(data)?.map((i) => {
        return { ...i, ["name"]: i.role };
      });
      setOptions((opt) => {                                                                                                                   
        return { ...opt, role: myData };
      });
    });  }, []);
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      sx={{
        padding: "72px",
        "& .MuiDialog-root": {
          padding: "2em",
        },
      }}
    >
      <DialogTitle>
        <Box
          ref={(node) => (dialogRef.current = node)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H3>{"Create New Admin User"}</H3>
          <IconButton
            color="primary"
            aria-label="close"
            component="span"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ m: "auto", textAlign: "center", color: "primary.main" }}>
          {err}
        </Box>
      </DialogTitle>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
         // const payload = { ...values, phoneNumber: `0${values.phoneNumber}` }
          setSubmitting(true);
         // createAdminUser(payload)
         /* createAdminUser(values)*/
         let d = new Date()
         let payload = {
          customerName: `${values?.firstName} ${values?.lastName}`,
          payload: JSON.stringify(values),
          requestId: d.getTime(),
          requestType: "createAdminUser"
        }
          createRequest(payload)
            .then(({ status, message }) => {
              if (status) {
                dispatch(
                  notifySuccess({ msg: "Admin user created successfully, waiting for approval" })
                );
                resetForm();
                handleClose();
              } else {
                setErrMsg(message || "An error occured, please try again.");
                dialogRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {createAdminForm.map(
                  ({ type, label, name, firstOption, placeholder, start }) => (
                    <Grid item xs={6} key={name}>
                      {type === "SingleSelectPlaceholder" ? (
                        <StyledBox>
                          <label>{label}</label>
                          <select
                            name={name}
                            // value={values[name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={""}>{firstOption}</option>
                            {options[name]?.map((opt, idx) => (
                              <option
                                key={`${opt?.name}-${idx}`}
                                value={opt?.value ?? opt?.name}
                              >
                                {opt?.name}
                              </option>
                            ))}
                          </select>
                          <Span sx={{ display: "block" }}>
                            <ErrorMessage name={name} />
                          </Span>
                          {name === "role" && _.isEmpty(options) && (
                            <Button variant="contained">
                              {"Create new Role"}
                            </Button>
                          )}
                        </StyledBox>
                      ) : (
                        <CustomOutlinedInput
                          name={name}
                          start={start}
                          value={values[name]}
                          placeholder={placeholder}
                          label={label}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={touched[name] && Boolean(errors[name])}
                          helperText={touched[name] && errors[name]}
                        />
                      )}
                      
                    </Grid>
                  )
                )}
                <div style={{marginLeft:20}}>
                  <label>Date of Birth</label><br/>
                <input style={{ height:40, width:'500'}}
                  onChange={handleChange}
                  value = {values['dateOfBirth']}
                  type="date"
                  name="dateOfBirth"
                />
              </div>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", p: 4 }}>
              <Button
                variant={"contained"}
                sx={{ width: "156px" }}
                type={"submit"}
                onClick={() => console.log(errors)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Span>{"Create New User"}</Span>
                  {isSubmitting ? (
                    <CircularProgress
                      sx={{ color: "#fff", marginLeft: "5px" }}
                      size={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
