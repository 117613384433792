import React from "react";
import map from "lodash/map";

const SelectDropdown = ({ dropdownItems, getTextFromDropdownItem, show, onSelect }) => {
  if (!show) return null;
  return (
    <ul className='rename-account-modal-dropdown'>
      {map(dropdownItems, (dropItems, index) => (
        <li key={index} className='rename-account-modal-dropitem'>
          <button className='to-account-dropdown-btn' onClick={() => onSelect(dropItems)}>
            {getTextFromDropdownItem(dropItems)}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default SelectDropdown;
