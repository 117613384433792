import { useEffect, useState } from "react";
import { cloneTableData, dataKeysAndRepr, txdata } from "./data";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Table from "components/table/Table";
import useMenu from "hooks/useMenu";
import { TRANSACTION_TABLE_MENU } from "utils/constant";
import {
  getTransactionList,
  getTransactionStats,
} from "services/https/transactionFetchers";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { customSearch, searchData } from "utils/customSearch";
import { useContext } from "react";
import { TabContext } from "contexts/TabContext";

export const TxTabPanel = () => {
  const [data, setData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { setTableMenu } = useMenu();
  const [stats, setStats] = useState();
  const [txList, setTxList] = useState();
  const { activeTab, setActiveTab } = useContext(TabContext);

  const { search } = useLocation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const idx = page * rowsPerPage;
    setData(txList.slice(idx, idx + rowsPerPage));
  };
  const handleRowChange = (noRow) => {
    setRowsPerPage(noRow?.target?.value);
    const idx = page * noRow?.target?.value;
    setData(txList.slice(idx, idx + noRow?.target?.value));
  };

  const {
    search: { searchResult, query },
  } = useSelector((state) => {
    return state;
  });
  const destructureNeed = (txData, count) => {
    const { totalGrossRevenue, totalNetRevenue } = txData;
    const [
      {
        totalAbandoned,
        totalFailed,
        totalPending,
        totalRefunded,
        totalSuccess,
      },
    ] = count;
    setStats({
      totalGrossRevenue: `NGN ${totalGrossRevenue}`,
      totalNetRevenue: `NGN ${totalNetRevenue}`,
      failed: totalFailed,
      pending: totalPending,
      successful: totalSuccess,
      abandoned: totalAbandoned,
      refunded: totalRefunded,
    });
  };
  const txRefId = new URLSearchParams(search).get("refId");
  useEffect(() => {
    if (!_.isEmpty(searchResult)) {
      setTxList(searchResult);
    } else if (!_.isEmpty(txRefId)) {
      searchData["transaction"]().then((data) => {
        customSearch(data, txRefId, ["refNo"]).then((res) => {
          const searchRes = res.map(({ item }) => item);
          setTxList(searchRes);
        });
      });
    } else {
      getTransactionList({ page, size: rowsPerPage }).then((data) => {
        setTxList(data);
      });
    }
  }, [searchResult, txRefId]);

  useEffect(() => {
    setTableMenu(TRANSACTION_TABLE_MENU);
    setActiveTab("transactions");
    if (txList && page == 0) {
      setData(txList?.slice(0, rowsPerPage));
    }
    getTransactionStats().then((data) => {
      const [txData, count] = data;
      console.log('tx', txData)
      if (!_.isEmpty(txData) && !_.isEmpty(count)) {
        destructureNeed(txData, count);
      }
    });
  }, [txList, activeTab]);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Grid container spacing={2}>
          {txdata.revenue.map(({ title, objKeyRepr }) => (
            <Grid item xs={6} md={3}>
              <Card sx={{ px: 2, paddingBlockEnd: 2 }}>
                <Tooltip
                  title={title}
                  sx={{ left: "90%", position: "relative" }}
                >
                  <IconButton>
                    <LiveHelpIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="body1">{title}</Typography>
                <Typography variant="h6">
                  {(stats && stats[objKeyRepr]) ?? "0"}
                </Typography>
              </Card>
            </Grid>
          ))}
          <Grid item xs={6} md={6}>
            <Card sx={{ p: 1 }}>
              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{ paddingBlockEnd: 2 }}
              >
                {"TRANSACTION’S COUNT"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 2,
                }}
              >
                {txdata.count.map(({ objKeyRepr, title }) => (
                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant="h6">
                      {(stats && stats[objKeyRepr]) ?? (
                        <CircularProgress size={16} />
                      )}
                    </Typography>
                    <Typography variant="body2">{title}</Typography>
                  </Box>
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Table
        dataKeysAndRepr={dataKeysAndRepr}
        dataItemRows={cloneTableData({ data })}
        pageData={data}
      />
      <TablePagination
        component="div"
        count={txList?.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowChange}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};
