import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {
  ButtonGroup,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { deactivateUser } from "services/https/fetchers";
import { useDispatch } from "react-redux";
import { notifySuccess } from "redux/actions/AlertActions";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { H3, Span } from "components/typography/Typography";
import { ErrorMessage, Field, Formik } from "formik";
import { lostDisputeValidationSchema } from "./data";
import { StyledBox } from "../shared/sharedStyles";
import { useParams } from "react-router-dom";
import { resolveDispute } from "services/https/disputeService";

export default function LostDisputeDialog({ openDialog, setOpenDialog }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenDialog(false);
    //setInitialValues(initialVal);
  };
  const { disputeId } = useParams();
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%" } }}
        maxWidth="xs"
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <FeedbackIcon color={"primary"} />
          <Typography variant={"h5"}>{"Dispute Lost ?"}</Typography>
          <Typography variant={"body1"}>
            By accepting this,you agree that merchant have{" "}
            <Span sx={{ color: "primary.main" }}>LOST</Span> this dispute
            transaction and merchant will refund the transaction amount back to
            the customer
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormContent closeAction={handleClose} disputeId={disputeId} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const FormContent = ({ closeAction, disputeId }) => {
  const [file, setFile] = useState();
  const inputFile = useRef(null);
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const handleClose = (e, reset) => {
    reset();
    closeAction();
  };
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  return (
    <Formik
      initialValues={{
        message: "",
        uploadProveDocument: "",
      }}
      validationSchema={lostDisputeValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        resolveDispute({
          disputeId,
          message: values.message,
          status: "CUSTOMER_WON",
        })
          .then(({ data }) => {
            dispatch(notifySuccess({ msg: "Dispute resolve succesfully" }));
            //alert(JSON.stringify(data,null,2))
          })
          .catch((err) => {
            /* if (err.response.status === 403) {
              alert(JSON.stringify(err.data, null, 2));
            } */
          })
          .finally(() => {
            closeAction();
            setSubmitting(false);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        values,
        handleReset,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Paper elevation={0}>
            <StyledBox>
              <label>{"Reason why dispute is Lost *"}</label>
              <Field name={"message"} type={"text"} />
              <span>
                <ErrorMessage name={"message"} />
              </span>
              <label>{"Upload Prove Document"}</label>
              <Field name={"documentName"} type={"text"} />
              <span>
                <ErrorMessage name={"documentName"} />
              </span>
            </StyledBox>
            <ButtonGroup sx={{ width: "100%", marginTop: "1em" }}>
              <Button
                variant="outlined"
                onClick={onButtonClick}
                sx={{ width: "100%" }}
              >
                {file?.name ?? ""}
              </Button>
              <Button variant={"contained"} onClick={onButtonClick}>
                {"Browse"}
                <input
                  id="file"
                  ref={inputFile}
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".xlsx, .xls"
                />
              </Button>
            </ButtonGroup>
            <DialogActions
              sx={{
                justifyContent: "end",
                width: "100%",
                my: 3,
                pt: 0,
                columnGap: 2,
              }}
            >
              <Button
                variant={"contained"}
                sx={{ backgroundColor: "#fff", color: "black" }}
                onClick={(e) => handleClose(e, handleReset)}
              >
                {"Cancel"}
              </Button>
              <Button variant={"contained"} type={"submit"}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Span>{"Yes, Accept"}</Span>
                  {isSubmitting ? (
                    <CircularProgress
                      sx={{ color: "#fff", marginLeft: "5px" }}
                      size={20}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Button>
            </DialogActions>
          </Paper>
        </form>
      )}
    </Formik>
  );
};
