import { CreateOneTimePaymentLink } from "components/tabs/merchantTransaction/paymentLink/CreateOneTimePaymentLink";
import { CreateSubscriptionPaymentLink } from "components/tabs/merchantTransaction/paymentLink/CreateSubscriptionLink";
import { GettingStarted } from "components/tabs/merchantTransaction/paymentLink/GettingStarted";
import { PaymentLinkDetail } from "components/tabs/merchantTransaction/paymentLink/PaymentLinkDetails";
import { PaymentLinkList } from "components/tabs/merchantTransaction/paymentLink/PaymentLinkList";
import { UpdatePaymentLink } from "components/tabs/merchantTransaction/paymentLink/UpdatePaymentLink";
import PlanDetail from "components/tabs/merchantTransaction/subscriptions/PlanDetail";
import { PlanList } from "components/tabs/merchantTransaction/subscriptions/PlanList";
import { SubscriptionList } from "components/tabs/merchantTransaction/subscriptions/SubscriptionList";
import { SubscriptionTabPanel } from "components/tabs/merchantTransaction/SubscriptionTabPanel";
import {
  ManageAdminUsersRoles,
  ManageUser,
  ListAdminUsersRoles,
  Referrals,
} from "./pages";
import { SuperAdmin } from "./pages/manageAdminRoles/SuperAdmin";
import User from "./pages/manageUser";
import { ActivityLog } from "./pages/manageUser/ActivityLog";
import { PerformTransaction } from "./pages/manageUser/PerformTransaction";
import Teams from "./pages/manageUser/Teams";
import { UpdateProfile } from "./pages/manageUser/UpdateProfile";
import ManageAdminUsersMainPage from "./pages/manageAdminUsers/ManageAdminUsersMainPage";
import ManageAdminUsersPage from "./pages/manageAdminUsers/ManageAdminUsersPage";
import { SettlementList } from "components/tabs/merchantTransaction/paymentLink/SettlementList";

const UserRoutes = [
  {
    path: "/users",
    children: [
      {
        path: "manage-users",
        element: <User />,
        children: [
          {
            path: "merchants/:userId/perform-transaction",
            element: <PerformTransaction />,
            children: [
              {
                path: "",
                element: <GettingStarted />,
              },
              {
                path: "paymentlinks",
                element: <PaymentLinkList />,
              },
              {
                path: "settlements",
                element: <SettlementList />,
              },
              {
                path: "subscriptions",
                element: <SubscriptionTabPanel />,
                children: [
                  { path: "", element: <SubscriptionList /> },
                  { path: "sub", element: <SubscriptionList /> },
                  { path: "plans", element: <PlanList /> },
                  { path: "plans/:planId", element: <PlanDetail /> },
                ],
              },
              {
                path: "customers",
                element: <PaymentLinkList />,
              },
              {
                path: "paymentlinks/one-time/add",
                element: <CreateOneTimePaymentLink />,
              },
              {
                path: "paymentlinks/:paymentLinkId",
                element: <PaymentLinkDetail />,
              },
              {
                path: "paymentlinks/:paymentLinkId/edit",
                element: <UpdatePaymentLink />,
              },
              {
                path: "paymentlinks/subscription/add",
                element: <CreateSubscriptionPaymentLink />,
              },
            ],
          },
          /*  {
            path: "merchants/:userId/perform-transaction/:customerSubscriptionId",
            element: <SubscriptionDetail />,
          }, */

          {
            path: "merchants/:userId/teams",
            element: <Teams />,
          },
          {
            path: ":userId/profile",
            element: <UpdateProfile />,
          },
          {
            path: ":userId/activity",
            element: <ActivityLog />,
          },
          {
            path: "",
            element: <ManageUser />,
          },
        ],
      },
      {
        path: "admin",
        element: <ManageAdminUsersMainPage />,
        children: [
          {
            path: "",
            element: <ManageAdminUsersPage />,
          },
          {
            path: "manage-roles",
            element: <ManageAdminUsersRoles />,
          },
        ],
      },
      {
        path: "referrals",
        element: <Referrals />,
      },
      /*  {
        path: "admin-roles/manage",
        element: <ManageAdminUsersRoles />,
        children: [
          {
            path: "/users/admin-roles/manage/*",
            element: <SuperAdmin />,
          },
        ],
      }, 
      {
        path: "admin-roles",
        element: <ListAdminUsersRoles />,
      },*/
    ],
  },
];

export default UserRoutes;
