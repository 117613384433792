import { StyledTableCell } from "./UserTableMenu";

import { Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import _ from "lodash";

const MerchantPaymentLinkMenu = ({ row, pageData }) => {
  return (
    <StyledTableCell align="right" sx={{ paddingRight: "2em" }}>
      <Box>
        <Button variant="outlined" startIcon={<VisibilityIcon />}>
          <a href={`${row.link}`} target="_blank">
            {"View Link"}
          </a>
        </Button>
      </Box>
    </StyledTableCell>
  );
};

export default MerchantPaymentLinkMenu;
