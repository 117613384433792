import { createContext, useReducer, useState } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_OPTION": {
      return {
        ...state,
        selectedAction: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const MenuContext = createContext({
  selectedAction: "",
  tableMenu: "",
  setTableMenu: () => ({}),
  setSelectedAction: () => ({}),
});

export const MenuActionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const [tableMenu, setTableMenu] = useState();

  const setSelectedAction = (selectedOption) => {
    dispatch({
      type: "SET_SELECTED_OPTION",
      payload: selectedOption,
    });
  };

  return (
    <MenuContext.Provider
      value={{
        ...state,
        tableMenu: tableMenu,
        setSelectedAction: setSelectedAction,
        setTableMenu: setTableMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
