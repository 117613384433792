import React, { cloneElement } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Stack,
  styled,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomizedInputBase from "components/search/Search";
import SimpleCard from "pages/dashboard/shared/SimpleCard";
import Table from "components/table/Table";
import Filter from "components/filter";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { TotalSummary } from "./data";
import { createDialog } from "redux/actions/AlertActions";

const HeaderSearch = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const SectionRoot = styled("section")(({ theme }) => ({
  margin: theme.spacing(2),
}));

const dataKeysAndRepr = [
  { objKey: "receiver", repr: "RECEIVER" },
  { objKey: "otpReceived", repr: "OTP RECEIVED" },
  { objKey: "type", repr: "TYPE" },
  { objKey: "status", repr: "STATUS" },
  { objKey: "createdAt", repr: "DATE RECEIVED" },
];

const OTP = () => {
  const [data, setData] = useState({});

  const [page, setPage] = useState(data.currentPage);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();

  const {
    alerts: { alertType, msg, dialogType },
  } = useSelector((state) => {
    return state;
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSendOtp = () => {
    setOpenDialog(true);
    dispatch(createDialog({ type: "otpButtonBox" }));
  };

  return (
    <SectionRoot>
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={"info"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <HeaderSearch>
        <CustomizedInputBase />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleSendOtp}
        >
          {"Send OTP"}
        </Button>
      </HeaderSearch>

      <Stack direction={"row"} spacing={2}>
        {TotalSummary.map(({ title, total }, idx) => (
          <SimpleCard key={idx} title={title} width={"220px"} height={"120px"}>
            {total}
          </SimpleCard>
        ))}
      </Stack>
      <Filter name={"City"} />

      <Table dataKeysAndRepr={dataKeysAndRepr} dataItemRows={data?.users} />
      <TablePagination
        component="div"
        count={data?.count}
        page={data?.currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </SectionRoot>
  );
};

export default OTP;
